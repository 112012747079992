/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Select, Option, Checkbox, MemberIcon, tagRender } from "ui";
import { useTranslation, withTranslation } from "react-i18next";

const ManageAccessOwner = ({
  ownerData,
  setOwnerData,
  memberData,
  setMemberData,
  setVisible,
  updateAuth,
  setUpdateAuth,
  targetID,
  editApi,
}) => {
  const [ownerID, setOwnerID] = useState(0);
  const [membersIdList, setMembersIdList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [prevOwnerAccess, setPrevOwnerAccess] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (updateAuth) {
      const origOwner = ownerData.id.toString();
      const origMem = memberData
        .filter((member) => member.has_access)
        .filter(
          (member) =>
            !member.auth_type.includes("manager") &&
            !member.auth_type.includes("admin")
        )
        .map((member) => member.id.toString());
      const added_users = [];
      origMem.map((id) => {
        !membersIdList.includes(id) && added_users.push(parseInt(id));
        return true;
      });
      membersIdList.map((id) => {
        !origMem.includes(id) && added_users.push(parseInt(id));
        return true;
      });
      (added_users.length > 0 || origOwner !== ownerID) &&
        editApi(targetID, {
          added_users: added_users.length > 0 ? added_users : [],
          new_owner: origOwner !== ownerID ? parseInt(ownerID) : null,
          prev_owner_access: prevOwnerAccess,
        }).then((res) => {
          setMemberData(res.data.members);
          setOwnerData(res.data.owner);
        });
      //   dispatch(
      //     sendNotification({
      //       board_id: props.match.params.category,
      //       card_id: "",
      //       msg_type: "addBoardMember",
      //       team_id: user.team_id,
      //       type: "sendMessage",
      //       user_id: user.id,
      //     })
      //   );
      setVisible(false);
      setUpdateAuth(false);
    }
  }, [updateAuth]);

  useEffect(() => {
    setOwnerID(ownerData.id.toString());
    setMembersIdList(
      memberData
        .filter(
          (member) =>
            member.has_access &&
            !["admin", "manager"].includes(member.auth_type)
        )
        .map((member) => member.id.toString())
    );
    setUserList(memberData);
  }, [ownerData, memberData]);

  const OptionList = (info, exception) =>
    info
      .filter((member) => !exception.includes(member.auth_type))
      .map((member, i) => (
        <Option
          key={member.id}
          finder={`${member.user_name}${member.user_email}`}
          label={member.user_name}
        >
          <OptionItem>
            <MemberIcon member={member}></MemberIcon>
            <OptionText>
              {member.user_name}
              <br></br>
              {member.user_email}
            </OptionText>
          </OptionItem>
        </Option>
      ));

  return (
    <ManageContainer>
      <Row>
        <Title>{t("global.manageAccessOwner.selectOwner.label")}</Title>
        <ManagerSelect
          width={170}
          placeholder={t("global.manageAccessOwner.selectOwner.placeholder")}
          // mode="multiple"
          value={ownerID}
          showSearch
          onChange={(value, option, test) => {
            setOwnerID(value);
            // membersIdList.includes
          }}
          onSelect={(key) => {
            if (membersIdList.includes(key.toString())) {
              const tmp = [];
              for (let i = 0; i < membersIdList.length; i++) {
                membersIdList[i] !== key.toString() &&
                  tmp.push(membersIdList[i]);
              }
              setMembersIdList(
                membersIdList.filter((id) => id !== key.toString())
              );
            }
          }}
          filterOption={(input, option) =>
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          optionLabelProp="label"
          autoFocus
        >
          {OptionList(userList, [])}
        </ManagerSelect>
      </Row>
      <Row>
        <Title>{t("global.manageAccessOwner.selectMembers.label")}</Title>
        <MemberSelect
          width={170}
          placeholder={t("global.manageAccessOwner.selectMembers.placeholder")}
          mode="multiple"
          value={membersIdList}
          showSearch
          onChange={(value, option) => {
            setMembersIdList(value);
          }}
          // onSelect={(key) => {
          //   if (managerIdList.includes(key.toString())) {
          //     const tmp = [];
          //     for (let i = 0; i < managerIdList.length; i++) {
          //       managerIdList[i] !== key.toString() &&
          //         tmp.push(managerIdList[i]);
          //     }
          //     setManagerIdList(tmp);
          //   }
          // }}
          filterOption={(input, option) => {
            console.log(option);
            return (
              option.finder.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          optionLabelProp="label"
          tagRender={tagRender}
        >
          {OptionList(userList, ["admin", "manager"])}
        </MemberSelect>
      </Row>
      <Row>
        <Checkbox
          checked={prevOwnerAccess}
          onChange={() => setPrevOwnerAccess(!prevOwnerAccess)}
        >
          {t("global.manageAccessOwner.checkboxText")}
        </Checkbox>
      </Row>
    </ManageContainer>
  );
};

export default withTranslation()(ManageAccessOwner);

const OptionText = styled.div`
  margin-left: 10px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
`;

const ManagerSelect = styled(Select)`
  width: 400px;
`;

const MemberSelect = styled(Select)`
  width: 400px;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
  padding-left: 5px;
  margin-bottom: 5px;
  margin-top: 32px;
`;

const Row = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const ManageContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;
