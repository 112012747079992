import React from "react";
import styled from "styled-components";
import { DatePicker } from "ui";
import moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";

const DateBlock = ({ value, setValue, axios, invoiceID, type, width }) => {
  const { t } = useTranslation();

  const handleSaveDate = () => {
    if (value === null) {
      axios(invoiceID, {
        [type]: value,
      });
    } else {
      axios(invoiceID, {
        [type]: `${value.replaceAll("/", "-")}T23:59`,
      });
    }
  };

  const handleDateChange = (date, dateString) => {
    setValue(dateString === "" ? null : dateString);
    dateString === "" &&
      axios(invoiceID, {
        [type]: null,
      });
  };
  return (
    <RowBlock width={width}>
      {value === null ? (
        <DateBox
          placeholder={t("cardView.finance.dateBlock.placeholder")}
          onChange={handleDateChange}
          onBlur={handleSaveDate}
        />
      ) : (
        <DateBox
          placeholder={t("cardView.finance.dateBlock.placeholder")}
          value={moment(value, "YYYY/MM/DD")}
          format="YYYY/MM/DD"
          onChange={handleDateChange}
          onBlur={handleSaveDate}
        />
      )}
    </RowBlock>
  );
};

export default withTranslation()(DateBlock);

const DateBox = styled(DatePicker)`
  width: 100%;
  height: 30px;
  border-color: ${({ theme }) => theme.colors.color_base_dark} !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  &:focus {
    border-color: ${({ theme }) =>
      theme.colors.color_primary_regular} !important;
  }
`;
const RowBlock = styled.div`
  width: ${({ width }) => width}%;
  padding: 3px 2px;
  height: 36px;
`;
