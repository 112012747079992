import React, { useState } from "react";
import styled from "styled-components";
// import "antd/dist/antd.css";
import { TextArea, FormInput, PrimaryButton, ClearButton } from "ui";
import { putCommentAxios } from "../../../../common/ApiAxios";
import { useTranslation, withTranslation } from "react-i18next";

const CommentEditor = ({
  id,
  commnetId,
  commentContent,
  setCommentEdit,
  setLoading,
  loading,
  setEditCommentAdd,
  setIsEditing,
}) => {
  const [commentText, setCommentText] = useState(commentContent);
  const [saveCheckId, setSaveCheckId] = useState(0);

  const { t } = useTranslation();

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleHideCommentEditor = () => {
    setCommentEdit(false);
    setIsEditing(false);
  };

  const handleCommentEditSubmit = async (commnetId) => {
    setSaveCheckId(commnetId);
    if (commentText === commentContent) {
      setCommentEdit(false);
      setIsEditing(false);
    } else {
      const cardId = parseInt(id.slice(5));
      const commentEditValue = {
        content: commentText,
      };

      await putCommentAxios(commnetId, cardId, commentEditValue)
        .then((res) => {
          setLoading(false);
          setTimeout(() => {
            handleHideCommentEditor();
          }, 700);
        })
        .then(() => {
          setEditCommentAdd(true);
        });
    }
  };

  return (
    <>
      <FormInput.Item>
        <TextArea
          onChange={(e) => handleCommentChange(e)}
          value={commentText}
          autoSize={{ minRows: 2, maxRows: 20 }}
          autoFocus
        />
      </FormInput.Item>

      <CommentInfoBtnBox>
        <PrimaryButton
          className="saveCommentBtn"
          htmlType="submit"
          loading={commnetId === saveCheckId ? loading : false}
          onClick={() => {
            handleCommentEditSubmit(commnetId);
          }}
          type="primary"
        >
          {t("cardView.commentPage.commentEditor.save")}
        </PrimaryButton>

        <ClearButton
          className="closeCommentBtn"
          onClick={handleHideCommentEditor}
          type="primary"
        >
          {t("cardView.commentPage.commentEditor.close")}
        </ClearButton>
      </CommentInfoBtnBox>
    </>
  );
};

export default withTranslation()(CommentEditor);

const CommentInfoBtnBox = styled.div`
  width: 22%;
  display: flex;
  justify-content: space-between;
  margin-top: -8px;
  margin-bottom: 3px;
`;
