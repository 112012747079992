import styled from "styled-components";
import { Select } from "antd";

export const Option = styled(Select.Option)`
border-radius: 4px;
color: ${({ theme }) => theme.colors.color_text_titles};


  /* border: 1px solid #d9d9d9 !important;
  &:hover {
    border: 1px solid #d9d9d9 !important;
  }
  &:focus {
    border-color:  ${({ theme }) => theme.colors.color_text_titles} !important;
    box-shadow: none !important;
  } */
`;
