import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
export const useEmailMessageDetailEdit = () => {
  async function editMessageDetail({ id, editData }) {
    const { data } = await emailThreadAPIs.editMessageDetailAxios(id, editData);
    console.log(id, editData);
    return data;
  }
  const queryClient = useQueryClient();
  let history = useHistory();
  return useMutation(editMessageDetail, {
    onMutate: async ({ id, editData }) => {
      await queryClient.cancelQueries([
        "get/email/threads/detail/",
        history.location.hash,
      ]);

      const previousValue = queryClient.getQueryData([
        "get/email/threads/detail/",
        history.location.hash,
      ]);

      queryClient.setQueryData(
        ["get/email/threads/detail/", history.location.hash],
        (old) => {
          return {
            thread: old.thread,
            message_snippets: old.message_snippets.map((thread) => {
              if (thread.id === id) {
                return { ...thread, starred: editData.action === "star" };
              } else {
                return thread;
              }
            }),
          };
        }
      );

      return previousValue;
    },
    // On failure, roll back to the previous value
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData(
        ["get/email/threads/detail/", history.location.hash],
        previousValue
      ),
    onSettled: () => {
      queryClient.invalidateQueries("get/email/threads/detail/");
    },
  });
};
