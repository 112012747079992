import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import listsReducers from "./Kanban/kanban";
import boardSaga from "./Kanban/kanbanSaga";
import cardVisible from "./Kanban/cardVisible";
import listVisible from "./Kanban/listVisible";
import position from "./Kanban/cardPosition";
import multiEditCards from "./Kanban/multiEditCards";

import currentBoards from "./Global/currentBoards";
import currentBoardsSaga from "./Global/currentBoardsSaga";
import addTeamMetaInfo from "./Global/addTeamMetaInfo";
import addTeamMetaInfoSaga from "./Global/addTeamMetaInfoSaga";
import startingPlan from "./Global/startingPlan";
import notifications from "./Global/notificationSocket";
import tutorialProgress from "./Global/tutorialProgress";
import toolBarOpen from "./Global/toolBarOpen";
import token from "./Global/token";
import loginState from "./Global/loginState";
import notiUpdate from "./Global/webViewNotiToggle";
import emailInfo from "./Global/emailInfo";

import accountBoards from "./DealBoardList/accountBoards";
import accountBoardsSaga from "./DealBoardList/accountBoardsSaga";

import paginationLists from "./Contacts/paginationLists";
import addTeamContactsInfo from "./Contacts/addTeamContactsInfo";
import addTeamContactsInfoSaga from "./Contacts/addTeamContactsInfoSaga";

import dealUpdate from "./DealCardDetailView/toggleDealfromCard";
import toggleOfferResult from "./DealCardDetailView/toggleOfferResult";
import attachFileInfo from "./DealCardDetailView/attachFileInfo";
import currentCard from "./DealCardDetailView/currentCard";
import linkNotification from "./DealCardDetailView/linkNotification";
import cardNotification from "./DealCardDetailView/cardNotification";
import composeCardID from "./DealCardDetailView/composeCardID";
import emailFileInfo from "./DealCardDetailView/emailFileInfo";

import syncCardInfo from "./Email/syncCardInfo";

//watcher saga -> actions -> worker saga
// import loading from "./loading";
import { enableES5 } from "immer";

enableES5();

const rootReducer = combineReducers({
  accountBoards,
  listsReducers,
  cardVisible,
  listVisible,
  position,
  currentBoards,
  startingPlan,
  addTeamMetaInfo,
  addTeamContactsInfo,
  dealUpdate,
  toggleOfferResult,
  notifications,
  attachFileInfo,
  currentCard,
  tutorialProgress,
  toolBarOpen,
  multiEditCards,
  linkNotification,
  paginationLists,
  syncCardInfo,
  cardNotification,
  token,
  loginState,
  notiUpdate,
  emailInfo,
  composeCardID,
  emailFileInfo,
  // loading,
});

// export default rootReducer;
export default (state, action) =>
  rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action);

//wathcer saga
export function* rootSaga() {
  yield all([
    boardSaga(),
    currentBoardsSaga(),
    accountBoardsSaga(),
    addTeamMetaInfoSaga(),
    addTeamContactsInfoSaga(),
  ]);
}
