import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";

export const TooltipBox = withTranslation()(
  ({ tipTitle, tipContents, TipTextList }) => {
    const { t } = useTranslation();
    const [tipVisible, setTipVisible] = useState(true);

    const checkMobile = useCheckPath("mobile");

    const handleTipToggle = () => {
      setTipVisible(!tipVisible);
    };

    useEffect(() => {
      checkMobile ? setTipVisible(true) : setTipVisible(false);
    }, [checkMobile]);
    return (
      <>
        {tipVisible === false ? (
          <TipContainer>
            <TipTitleBox>
              <TipsTitle>{t(tipTitle)}</TipsTitle>
              <TipHideText onClick={handleTipToggle}>
                {t("ui.tooltipBox.hide")}
              </TipHideText>
            </TipTitleBox>
            <TipTitleContents>
              {(tipContents
                ? t(tipContents, {
                    returnObjects: true,
                  })
                : TipTextList
              ).map((content, idx) => {
                return <TipsText key={idx}>{content}</TipsText>;
              })}
            </TipTitleContents>
          </TipContainer>
        ) : (
          <TipShowBox onClick={handleTipToggle}>
            <TipShowText isMobile={checkMobile}>{`${t(
              "ui.tooltipBox.tip"
            )} : ${t(tipTitle)}`}</TipShowText>
          </TipShowBox>
        )}
      </>
    );
  }
);

// export default TooltipBox;

const TipContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.color_state_notification_light};
  /* margin-bottom: 25px; */
  padding: 16px;
  margin: 24px 0px;
  border-radius: 4px;
`;

const TipTitleContents = styled.div`
  color: ${({ theme }) => theme.colors.color_text_body};
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  padding: 0 5px;
  margin-top: 16px;
  word-break: normal;
`;

const TipsText = styled.pre`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.color_text_body};
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  word-break: keep-all;
`;

const TipTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const TipsTitle = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h4};
  color: ${({ theme }) => theme.colors.color_text_titles};
`;

const TipHideText = styled.div`
  color: ${({ theme }) => theme.colors.color_state_notification_dark};
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  text-decoration: underline;
  cursor: pointer;

  @media ${({ theme }) => theme.screenSizes.mobile} {
    min-width: 50px;
  }
`;

const TipShowBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
`;

const TipShowText = styled(TipsTitle)`
  display: inline-block;
  padding: 5px 20px;
  background-color: ${({ theme }) =>
    theme.colors.color_state_notification_light};
  border-radius: 4px;
  margin-bottom: ${(props) => (props.isMobile ? "0" : "32px")};
  cursor: pointer;
`;
