// import the library
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FontAwesomeIcons";

const Icon = (props) => {
  return (
    <FontAwesomeIcon
      icon={[`${props.type}`, `${props.name}`]}
      size={`${props.size ? props.size : "1x"}`}
      color={props.color}
      {...props}
      // pulse
      // fixedWidth
    />
  );
};

export default Icon;
