import React, { useState, memo } from "react";
import styled from "styled-components";
import { Modal, Popconfirm, MemberIcon, Link } from "ui";
import Icons from "assets/Icons";
// import DetailMeetingNote from "Components/Contacts/ContactsDetailView/MeetingNote/DetailMeetingNote";
import { deleteMeetingNote } from "../../../../../common/ApiAxios";
import loadable from "@loadable/component";
const DetailMeetingNote = loadable(() =>
  import("pages/Contacts/components/ContactsDetailView/MeetingNote/DetailMeetingNote")
);
const MeetingListItem = memo(
  ({ item, id, meetingNoteData, setMeetingNoteData, type, typeId }) => {
    const [detailVisible, setDetailVisible] = useState(false);
    const [edit, setEdit] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const showModal = () => {
      setDetailVisible(!detailVisible);
    };

    const showMenu = (bool) => {
      bool === true ? setMenuVisible(true) : setMenuVisible(false);
    };

    const handleDeleteNote = () => {
      deleteMeetingNote(id, type, typeId);
      const deleteData = meetingNoteData.filter((item) => {
        return item.id !== id;
      });
      setMeetingNoteData(deleteData);
      showModal();
    };

    const handleEdit = () => {
      setEdit(!edit);
    };

    return (
      <>
        <TableListItem
          onMouseEnter={() => showMenu(true)}
          onMouseLeave={() => showMenu(false)}
        >
          <ItemLinkBox>
            <ItemLink onClick={showModal}>{item.title}</ItemLink>
          </ItemLinkBox>
          <ItemTextBox>
            <AccountOwnerBox>
              <AccountOwnerText>
                <MemberIcon
                  member={{
                    user_name: item.author.name,
                    user_email: item.author.email,
                    user_profile_image: item.author.profile_image,
                  }}
                ></MemberIcon>
              </AccountOwnerText>
              <AccountOwnerText>{item.author.name}</AccountOwnerText>
            </AccountOwnerBox>
          </ItemTextBox>

          <ItemTextBox>{item.created_at}</ItemTextBox>
          {id === item.id && menuVisible === true ? (
            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Delete Note?"
              okText="Delete"
              okType="danger"
              onConfirm={() => handleDeleteNote(id)}
            >
              <DeleteButton>
                <Icons type="fas" name="trash" />
              </DeleteButton>
            </Popconfirm>
          ) : null}
        </TableListItem>

        <DetailNoteModal
          visible={detailVisible}
          centered={true}
          onCancel={() => {
            showModal();
            setEdit(false);
          }}
          // closable={false}
          footer={null}
          width="65%"
          destroyOnClose
        >
          <DetailMeetingNote
            type={type}
            typeId={typeId}
            detailViewId={id}
            meetingNoteData={meetingNoteData}
            setMeetingNoteData={setMeetingNoteData}
            setDetailVisible={setDetailVisible}
            edit={edit}
            handleEdit={handleEdit}
          />
        </DetailNoteModal>
      </>
    );
  }
);

export default MeetingListItem;

const TableListItem = styled.li`
  width: 100%;
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.colors.color_base_dark};
  height: 45px;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors.color_text_body};
  /* text-align: center; */
`;

const ItemLinkBox = styled.div`
  width: 50%;
`;

const ItemLink = styled(Link)`
  padding: 5px 0;
  display: inline;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
`;

const ItemTextBox = styled.div`
  width: 25%;
  /* padding: 5px 0;
  margin: 0; */
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  display: inline-block;
`;

const AccountOwnerBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AccountOwnerText = styled.div`
  margin: 0 5px 0 0;
`;

const DeleteButton = styled.div`
  position: absolute;
  /* width: 16.6%; */
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.color_state_danger_dark};
  }
`;

const DetailNoteModal = styled(Modal)`
  .ant-modal-header {
    padding: 8px;
    border-bottom: none;
  }

  .ant-modal-close-x {
    width: 80px;
    line-height: 90px;
  }

  .ant-modal-body {
    padding: 24px 24px 12px;
  }

  .ant-modal-footer {
    margin: 0px 16px;
    padding: 10px;
  }
`;
