import { library } from "@fortawesome/fontawesome-svg-core";

/* free */
import {
  faTrello,
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

/* pro */
// solid
import {
  faSave,
  faCheck,
  faEllipsisV as faEllipsisVSolid,
  faChevronRight as faChevronRightSolid,
  faEngineWarning,
  faPlus as faPlusSolid,
  faUnlink as faUnlinkSolid,
  faUpload,
  faLockAlt,
  faCaretDown,
  faPlayCircle,
  faQuestionCircle as faQuestionCircleSolid,
  faQuestion,
  faCog,
  faInbox,
  faBell,
  faColumns,
  faTrash,
  faEnvelope,
  faCheckSquare,
  faIdBadge,
  faPaperPlane,
  faEdit,
  faPen,
  faMinusCircle,
  faUserPlus,
  faPlusCircle,
  faSyncAlt,
  faTimesCircle,
  faExchangeAlt,
  faArrowToRight,
  faArrowToLeft,
  faCheckCircle,
  faExpand,
  faDownload,
  faBars,
  faTimes,
  faServer,
  faEllipsisH,
  faFileImport,
  faFileExport,
  faArrowLeft,
  faArrowToTop,
  faChartLine as faChartLineSolid,
  faSearch as faSearchSolid,
  faAt,
  faFileAlt,
  faPhone,
  faAddressBook,
  faBuilding as faBuildingSolid,
  faIndustryAlt as faIndustryAltSolid,
} from "@fortawesome/pro-solid-svg-icons";

// regular
import {
  faPlus as faPlusRegular,
  faGlobe,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faChevronRight as faChevronRightRegular,
  faCheck as faCheckRegular,
  faPaperclip,
  faArrowToBottom,
  faUnlink as faUnlinkRegular,
  faLink,
  faSortAmountDownAlt,
  faQuestionCircle as faQuestionCircleRegular,
  faExclamationCircle,
  faSearch as faSearchRegular,
  faCheckCircle as faCheckCircleRegular,
  faExternalLink,
} from "@fortawesome/pro-regular-svg-icons";

// light
import {
  faMailBulk,
  faPlus as faPlusLight,
  faTimes as faTimesLight,
  faTable,
  faBuilding,
  faIndustryAlt,
  faEllipsisV as faEllipsisVLight,
  faSlidersH,
} from "@fortawesome/pro-light-svg-icons";

// duotone
import {
  faChartLine as faChartLineDuo,
  faUserCog,
  faCalendar,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faBuildingSolid,
  faIndustryAltSolid,
  faAddressBook,
  faPhone,
  faCalendar,
  faSlidersH,
  faSave,
  faAt,
  faFileExport,
  faFileImport,
  faCheck,
  faEllipsisVSolid,
  faEllipsisVLight,
  faPlusSolid,
  faPlusRegular,
  faPlusLight,
  faTimes,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCheck,
  faCheckRegular,
  faChevronRightSolid,
  faChevronRightRegular,
  faEngineWarning,
  faMailBulk,
  faUnlinkSolid,
  faUnlinkRegular,
  faUpload,
  faUserCog,
  faLockAlt,
  faGlobe,
  faCaretDown,
  faPlayCircle,
  faQuestionCircleSolid,
  faQuestionCircleRegular,
  faQuestion,
  faCog,
  faInbox,
  faTrello,
  faTable,
  faBuilding,
  faIndustryAlt,
  faBell,
  faColumns,
  faTrash,
  faEnvelope,
  faCheckSquare,
  faIdBadge,
  faPaperPlane,
  faEdit,
  faPen,
  faMinusCircle,
  faUserPlus,
  faPlusCircle,
  faPaperclip,
  faArrowToBottom,
  faLink,
  faSyncAlt,
  faSortAmountDownAlt,
  faTimesCircle,
  faExchangeAlt,
  faArrowToRight,
  faArrowToLeft,
  faCheckCircle,
  faCheckCircleRegular,
  faExpand,
  faDownload,
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faBars,
  faTimesLight,
  faServer,
  faEllipsisH,
  faArrowLeft,
  faArrowToTop,
  faExclamationCircle,
  faChartLineDuo,
  faChartLineSolid,
  faSearchSolid,
  faSearchRegular,
  faExternalLink,
  faFileAlt
);
