import { useEffect, useState } from "react";

const useCheckPath = (name) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    setCheck(window.location.pathname.includes(name));
  }, [name]);

  return check;
};

export default useCheckPath;
