import React from "react";
import CommentListItem from "./CommentListItem";
import { List } from "ui";
// import "antd/dist/antd.css";
import styled from "styled-components";
const CommentList = ({
  comments,
  user,
  id,
  setLoading,
  loading,
  setEditCommentAdd,
  setPreventClose,
  setIsEditing,
}) => {
  return (
    <ListContainer
      dataSource={comments}
      itemLayout="horizontal"
      renderItem={(commentInfo) => (
        <CommentListItem
          user={user}
          id={id}
          key={commentInfo.id.toString()}
          commentInfo={commentInfo}
          setLoading={setLoading}
          setEditCommentAdd={setEditCommentAdd}
          loading={loading}
          setPreventClose={setPreventClose}
          setIsEditing={setIsEditing}
        ></CommentListItem>
      )}
    />
  );
};

export default CommentList;

const ListContainer = styled(List)`
  .ant-comment-actions {
    margin-top: 5px !important;
  }
`;
