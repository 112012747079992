import { handleActions, createAction } from "redux-actions";

const UPDATE = "cardNotification/UPDATE";
const RESET = "cardNotification/RESET";

export const updateCardNotification = createAction(UPDATE);
export const resetCardNotification = createAction(RESET);

const initialState = {
  new_comments: { object_ids: [], noti_ids: [] },
  new_attachments: { object_ids: [], noti_ids: [] },
  new_threads: { object_ids: [], noti_ids: [] },
};

const cardNotification = handleActions(
  {
    [UPDATE]: (state, { payload: info }) => info,
    [RESET]: (state) => initialState,
  },
  initialState
);

export default cardNotification;
