import { emailAPIs } from "api-cache/ApiAxios";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
export const useEmailThreadsSearched = (length) => {
  let history = useHistory();
  async function getSearchedEmailThreads(pageParam) {
    const { data } = await emailAPIs.getEmailThreadsAxios(
      `search/${
        decodeURIComponent(history.location.hash.slice(1)).split("::")[2]
      }/?folder=${
        decodeURIComponent(history.location.hash.slice(1)).split("::")[0]
      }&month=${pageParam}&index=${length}`
    );
    return data;
  }
  return useInfiniteQuery(
    [
      "get/email/threads/search",
      history.location.hash,
      decodeURIComponent(history.location.hash.slice(1)).split("::")[2],
    ],
    ({ pageParam = 1 }) => getSearchedEmailThreads(pageParam),
    {
      enabled:
        history.location.hash.length > 0 &&
        history.location.hash.includes("searching::"),
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length + 1;
      },
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5,
    }
  );
};
