import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "langs/en";
import translationKo from "langs/ko";

const resource = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    resources: resource,
    fallbackLng: "en",

    // 브라우저 언어 정보 가져와서 적용
    // userLanguage 는 IE 인 경우 적용
    // 따로 설정을 안 해주면 브라우저 언어 값으로 지정, 설정을 하면 설정값으로 언어 지정
    // lng: `${
    //   navigator.language.toLocaleLowerCase().split("-")[0] ||
    //   navigator.userLanguage.toLocaleLowerCase().split("-")[0] ||
    //   "en"
    // }`,

    debug: false,
    //keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
