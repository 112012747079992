import { emailAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";
import { useTeamMetaInfo } from "api-cache";
export const useEmailFolders = () => {
  const { data: metaInfo } = useTeamMetaInfo();
  async function getEmailFoldersData() {
    const { data } = await emailAPIs.getEmailFoldersAxios();
    return data;
  }
  return useQuery({
    queryKey: "get/email/folders/",
    queryFn: getEmailFoldersData,
    placeholderData: { children: [], loading: true },
    enabled: metaInfo.synced_state,
  });
};
