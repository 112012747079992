import { handleActions } from "redux-actions";

const ADD = "accountBoards/ADD_SUCCESS";
const REMOVE = "accountBoards/REMOVE_SUCCESS";
const INITIALIZE = "accountBoards/INITIALIZE_SUCCESS";

// export const addBoard = createAction(ADD);
// export const removeBoard = createAction(REMOVE);

const initialState = [];

const accountBoards = handleActions(
  {
    [ADD]: (state, { payload: newBoard }) => [...state, newBoard],
    [REMOVE]: (state, { payload: removed }) =>
      state.filter((board) => board.id !== removed),
    [INITIALIZE]: (state, { payload: boards }) => [...boards],
  },
  initialState
);

export default accountBoards;
