import { createAction, handleActions } from "redux-actions";
import { changeCardListAxios, editBoardAxios } from "../../common/ApiAxios";

//Action variables
const ADD_Card = "board/ADD_CARD_SUCCESS";
const ADD_LIST = "board/ADD_LIST_SUCCESS";
const DRAG_HAPPENED = "lists/DRAG_HAPPENED";
const INITIALIZE_BOARD = "board/INITIALIZE_SUCCESS";
const REMOVE_LIST = "board/REMOVE_LIST_SUCCESS";

export const sort = createAction(
  DRAG_HAPPENED,
  (
    droppableIdStart,
    droppableIdEnd,
    droppableIndexStart,
    droppableIndexEnd,
    draggableId,
    type
  ) => ({
    droppableIdStart,
    droppableIdEnd,
    droppableIndexStart,
    droppableIndexEnd,
    draggableId,
    type,
  })
);

// export const initialize = createAction(INITIALIZE_SUCCESS);

//Reducer
const initialState = [];

const listsReducers = handleActions(
  {
    [ADD_Card]: (state, { payload: cardinfo }) => {
      const newState = state.map((list) => {
        if (list.id === `list_${cardinfo.card_list}`) {
          const newCard = {
            id: `card_${cardinfo.id}`,
            name: cardinfo.name,
            customer: cardinfo.customer,
            supplier: cardinfo.supplier,
            quantity: cardinfo.quantity,
            quantity_unit: cardinfo.quantity_unit,
            amount: cardinfo.amount,
            currency: cardinfo.currency,
            user: cardinfo.owner,
          };
          return {
            ...list,
            card_set: [...list.card_set, newCard],
          };
        } else {
          return list;
        }
      });
      return newState;
    },
    [ADD_LIST]: (state, { payload: listinfo }) => {
      const newList = {
        ...listinfo,
      };
      return [...state, newList];
    },
    [REMOVE_LIST]: (state, { payload: listinfo }) => {
      const id = `list_${listinfo}`;
      const newState = state.filter((list) => list.id !== id && list);
      return newState;
    },
    [DRAG_HAPPENED]: (state, { payload: draginfo }) => {
      const {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexStart,
        droppableIndexEnd,
        // draggableId,
        type,
      } = draginfo;

      const newState = [...state];

      // dragging lists around
      if (type === "list") {
        const list = newState.splice(droppableIndexStart, 1);
        newState.splice(droppableIndexEnd, 0, ...list);
        const list_order = newState.map((list) => parseInt(list.id.slice(5)));
        editBoardAxios(window.location.pathname.split("/")[2], {
          list_order: list_order,
        });
        return newState;
      }

      // In the same list
      if (droppableIdStart === droppableIdEnd) {
        const list = state.find((list) => droppableIdStart === list.id);
        const card = list.card_set.splice(droppableIndexStart, 1);
        list.card_set.splice(droppableIndexEnd, 0, ...card);
        const card_order = list.card_set.map((cards) =>
          parseInt(cards.id.slice(5))
        );
        changeCardListAxios(droppableIdStart.slice(5), {
          card_order: card_order,
        });
      }

      // other list

      if (droppableIdStart !== droppableIdEnd) {
        // find the list where drag happened
        const listStart = state.find((list) => droppableIdStart === list.id);

        // pull out the card from this list
        const card = listStart.card_set.splice(droppableIndexStart, 1);

        // find the list where drag ended
        const listEnd = state.find((list) => droppableIdEnd === list.id);
        // put the card in the new list
        listEnd.card_set.splice(droppableIndexEnd, 0, ...card);

        const start_order = listStart.card_set.map((cards) =>
          parseInt(cards.id.slice(5))
        );
        changeCardListAxios(droppableIdStart.slice(5), {
          card_order: start_order,
        });

        const end_order = listEnd.card_set.map((cards) =>
          parseInt(cards.id.slice(5))
        );
        changeCardListAxios(droppableIdEnd.slice(5), {
          card_order: end_order,
        });
      }

      return newState;
    },
    [INITIALIZE_BOARD]: (state, { payload: data }) => {
      return data;
    },
  },
  initialState
);

export default listsReducers;
