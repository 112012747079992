import { emailAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailDraftDeletion = () => {
  async function disconnectEmail(deleteData) {
    const { data } = await emailAPIs.deleteDraftsAxios(deleteData);
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(disconnectEmail, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/threads/");
    },
  });
};
