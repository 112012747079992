import { dealBoardAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useBoardAccess = (id) => {
  async function getBoardAccess() {
    const { data } = await dealBoardAPIs.getBoardAccessAxios(id);
    return data;
  }
  return useQuery({
    queryKey: ["get/board/access/", id],
    queryFn: getBoardAccess,
  });
};
