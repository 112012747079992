export const url = "http://172.16.101.234:8000";
//0. 준표
//1. 승호님
//2. elastic beanstalk
const i = 2;
const urls = [
  "http://10.90.100.39:8000/api/",
  "http://10.90.103.66:8000/api/",
  "https://testapp.tradir.io/api/",
];
const tokens = [
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjAyMjA5NTkwLCJqdGkiOiJhNWRlMThjYTIxZDY0OGVlOGI2Y2NhODQwZjI2N2JlYSIsInVzZXJfaWQiOjEwfQ.Ua0_wu10gYBfqk1avTZUPF1qh7deme__W4RgSwJBEtg",
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjAyMTU0OTE0LCJqdGkiOiI1MzBiYzU0MTFhMzA0NDVlYjdlYzY5MjkzZjZkNjgyYiIsInVzZXJfaWQiOjd9.fKnOZYER0suS9DCm-E1pRpyuFDnFZAw-ANZQFrGudtU",
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjAyNzUzNzM4LCJqdGkiOiI5ZmZlNDNmZGViOTY0ZWE4OGM0ZmU5ZjA4MjJkNzYzYSIsInVzZXJfaWQiOjF9.PEmVyVJuq0oTeSAuxzdXeI2-BfyhAJyhpJoPZGhYlJU",
];

//GA
const trackingId = "UA-189797644-1";

// tutorial notion link
const notionLink = [
  "https://www.notion.so/dccc86699b144febb69a86c8299132d6",
  "https://www.notion.so/3b47a016325246948eb2bd501ed1f25c",
  "https://www.notion.so/df7a3625011b459193aea231b0cb34a9",
  "https://www.notion.so/b83fda6596df473b911de92e83b7ba33",
  "https://www.notion.so/2cbc9d45bb7b4d32aba4d0aac3f087e1",
  "https://www.notion.so/Tradir-io-ecfd9ac6d60345f1a47b31eafad58127",
];

const notionLinkEn = [
  "https://www.notion.so/Create-a-team-board-4131117953b64f30b8094f21c1e6e73d",
  "https://www.notion.so/Invite-team-members-835c21fe80f040a58157b2beaaa98cf8",
  "https://www.notion.so/Setting-up-the-list-03716154519c435fa698c28deeea2b3e",
  "https://www.notion.so/Create-a-profile-aa80ecfe400d44259742e1adb4d522aa",
  "https://www.notion.so/Integrating-Email-8f3203073fb744a6a1656252dd930737",
  "https://www.notion.so/Tradir-io-User-Guide-5638f0bc2aee41d1aaab32a2d141cc8e",
];

export const apiUrl = urls[i];
export const accessToken = tokens[i];
export const TRACKING_ID = trackingId;
export const notionLinkList = notionLink;
export const notionLinkListEn = notionLinkEn;
