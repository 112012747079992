import { handleActions } from "redux-actions";

const UPDATE = "addTeamContactsInfoSaga/UPDATE_SUCCESS";

const initialState = {
  all_customers: [],
  all_suppliers: [],
};

const addTeamContactsInfo = handleActions(
  {
    [UPDATE]: (state, { payload: info }) => {
      return info;
    },
  },
  initialState
);

export default addTeamContactsInfo;
