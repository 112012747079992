import { takeLatest } from "redux-saga/effects";
// import { startLoading, finishLoading } from "../Modules/loading";
import createRequestSaga from "../CreateSaga/createRequestSaga";
import createDeleteBoardSaga from "../CreateSaga/createDeleteBoardSaga";
import createAddBoardSaga from "../CreateSaga/createAddBoardSaga";
import { createAction } from "redux-actions";
import * as api from "../../common/ApiAxios";

const ADD = "accountBoards/ADD";
const REMOVE = "accountBoards/REMOVE";
const INITIALIZE = "accountBoards/INITIALIZE";

export const addBoard = createAction(ADD);
export const removeBoard = createAction(REMOVE);
export const initializeAccountBoards = createAction(INITIALIZE);

const addSaga = createAddBoardSaga(ADD, api.createBoardAxios);
const removeSaga = createDeleteBoardSaga(REMOVE, api.deleteBoardAxios);
const initializeSaga = createRequestSaga(INITIALIZE, api.getAccountBoardsAxios);

export default function* accountBoardsSaga() {
  yield takeLatest(ADD, addSaga);
  yield takeLatest(REMOVE, removeSaga);
  yield takeLatest(INITIALIZE, initializeSaga);
}
