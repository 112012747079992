import React, { useState } from "react";
import { AutoComplete } from "ui";
import styled from "styled-components";
const { Option } = AutoComplete;
const data = [
  {
    account_id: "x2x2x2x2x2x2x2x2x2x2x2",
    company_name: "Nylas",
    emails: [
      {
        email: "john@doe.com",
        type: "work",
      },
    ],
    given_name: "John",
    id: "z3z3z3z3z3z3z3z3z3z3z3",
    job_title: "Software Engineer",
    manager_name: "Bill the manager",
    middle_name: "Jacob",
    nickname: "JD",
    picture_url: "https://api.nylas.com/contacts/427abc427abc427abc/picture",
    suffix: "Jr.",
    surname: "Doe",
  },
];
export const EmailInput = ({ emailList }) => {
  // const [value, setValue] = useState("");
  const [result, setResult] = useState(data);

  const handleSearch = (value) => {
    value.length > 1 &&
      setResult(
        data.filter((contact) =>
          contact.emails[0].email
            .toLowerCase()
            .split("@")[0]
            .includes(value.toLowerCase())
        )
      );
  };

  return (
    <div>
      <MyAutoComplete
        style={{
          width: 200,
        }}
        onSearch={handleSearch}
        placeholder="input here"
      >
        {result.map((contact) => (
          <Option key={contact.emails[0].email} value={contact.emails[0].email}>
            {contact.given_name
              ? `${contact.given_name} ( ${contact.emails[0].email} )`
              : contact.emails[0].email}
          </Option>
        ))}
      </MyAutoComplete>
    </div>
  );
};

const MyAutoComplete = styled(AutoComplete)`
  /* border: none;
  box-shadow: none;
  &:hover {
    border: none;
    box-shadow: none;
  }
  &:focus {
    border: none;
    box-shadow: none;
  }
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  } */
`;
