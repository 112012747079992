import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const hasNumber = (value) => {
  return new RegExp(/[0-9]/).test(value);
};

const hasMixed = (value) => {
  return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
};

const hasSpecial = (value) => {
  return new RegExp(/[!#@$?%^&*)(+=._-]/).test(value);
};

const strengthColor = (count) => {
  if (count < 2) return "red";

  if (count < 3) return "yellow";

  if (count < 4) return "orange";

  if (count < 5) return "lightgreen";

  if (count < 6) return "green";
};

const strengthIndicator = (value) => {
  let strengths = 0;
  if (value && value.indexOf(" ") !== -1) return 0;

  if (value.length > 5) strengths++;

  if (value.length > 7) strengths++;

  if (hasNumber(value)) strengths++;

  if (hasSpecial(value)) strengths++;

  if (hasMixed(value)) strengths++;

  return strengths;
};

const PasswordStrength = ({ value }) => {
  const strength = strengthIndicator(value);
  const [color, setColor] = useState("");

  useEffect(() => {
    setColor(strengthColor(strength));
  }, [strength]);

  return (
    <PasswordStrengthWrapper>
      <PasswordStrengthIndicator
        className="red yellow orange lightgreen green"
        color={color}
      ></PasswordStrengthIndicator>
      <PasswordStrengthIndicator
        className="yellow orange lightgreen green"
        color={color}
      ></PasswordStrengthIndicator>
      <PasswordStrengthIndicator
        className="orange lightgreen green"
        color={color}
      ></PasswordStrengthIndicator>
      <PasswordStrengthIndicator
        className="lightgreen green"
        color={color}
      ></PasswordStrengthIndicator>
      <PasswordStrengthIndicator
        className="green"
        color={color}
      ></PasswordStrengthIndicator>
    </PasswordStrengthWrapper>
  );
};

export default PasswordStrength;

const PasswordStrengthWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 12px 0;
`;

const PasswordStrengthIndicator = styled.div`
  box-sizing: border-box;
  width: 18%;
  background-color: ${({ theme }) => theme.colors.color_base_dark};
  height: 0.25rem;

  .red {
    background-color: red;
  }

  ${({ color }) =>
    css`
      &.${color} {
        background-color: ${color};
      }
    `}
`;
