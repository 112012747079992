import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";
export const useEmailMessageBody = (id, placeholder, successAction, open) => {
  async function getEmailMessageBody() {
    const { data } = await emailThreadAPIs.getMessageBodyAxios(id);
    console.log(data);
    return data;
  }
  return useQuery({
    enabled: open,
    queryKey: ["get/email/messages/", id],
    queryFn: getEmailMessageBody,
    onSuccess: successAction,
  });
};
