import { takeLatest } from "redux-saga/effects";
// import { startLoading, finishLoading } from "../Modules/loading";
import createRequestSaga from "../CreateSaga/createRequestSaga";
import createAddInfoSaga from "../CreateSaga/createAddInfoSaga";
import { createAction } from "redux-actions";
import * as api from "common/ApiAxios";
import { listsAPI } from "common/ApiAxios";
import createAddCardSaga from "../CreateSaga/createAddCardSaga";
import createDeleteBoardSaga from "../CreateSaga/createDeleteBoardSaga";

const INITIALIZE = "board/INITIALIZE";
const ADD_LIST = "board/ADD_LIST";
const ADD_CARD = "board/ADD_CARD";
const REMOVE_LIST = "board/REMOVE_LIST";

export const initialize = createAction(INITIALIZE);
export const addList = createAction(ADD_LIST);
export const addCard = createAction(ADD_CARD);
export const removeList = createAction(REMOVE_LIST);

const initializeSaga = createRequestSaga(INITIALIZE, listsAPI.boardInfoAxios);

const createCardList = createAddInfoSaga(ADD_LIST, api.createCardListAxios);

const createCard = createAddCardSaga(ADD_CARD, api.createCardAxios);

const deleteList = createDeleteBoardSaga(REMOVE_LIST, api.removeCardListAxios);

export default function* boardSaga() {
  yield takeLatest(INITIALIZE, initializeSaga);
  yield takeLatest(ADD_LIST, createCardList);
  yield takeLatest(ADD_CARD, createCard);
  yield takeLatest(REMOVE_LIST, deleteList);
}
