import { useState, useEffect, useRef } from "react";

export default function useResponsiveHeight(elRef) {
  const [currentHeight, setCurrentHeight] = useState(0);
  const observer = useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      const { width } = entries[0].contentRect;
      setCurrentHeight(width);
    })
  );

  useEffect(() => {
    if (elRef) {
      observer.current.observe(elRef);
    }
  }, [elRef, observer]);

  return currentHeight;
}
