import { handleActions, createAction } from "redux-actions";

const TOGGLE = "toggleDealfromCard/TOGGLE";

export const toggleDeal = createAction(TOGGLE);

const initialState = false;

const dealUpdate = handleActions(
  {
    [TOGGLE]: (state) => !state,
  },
  initialState
);

export default dealUpdate;
