import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { Select, Option } from "ui";
import { Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
type SetState<S> = Dispatch<SetStateAction<S>>;
interface Props {
  placeholder1: string;
  id?: string;
  itemList: Array<any>;
  width?: string;
  value: string;
  itemType: string;
  autoFocus?: boolean;
  defaultOpen?: boolean;
  onBlur?: Function;
  idsetter?: Function;
  onChange?: Function;
  addNewValue?: Function;
  onOptionClick?: Function;
  onDeselect?: Function;
  onSelect?: Function;
  mode?: "multiple" | "tags" | undefined;
  allowClear?: boolean;
  nullOption?: boolean;
  onClear?: Function;
}

export const SelectAddNewCommon: React.FC<Props> = ({
  placeholder1,
  itemList,
  width,
  value,
  itemType,
  onBlur,
  defaultOpen,
  autoFocus,
  addNewValue,
  onOptionClick,
  onDeselect,
  onSelect,
  mode,
  allowClear,
  nullOption,
  onClear,
  onChange,
}) => {
  const [newValue, setNewValue] = useState<string | undefined>(undefined);

  // useEffect(() => {
  //   const ac = new AbortController();
  //   setValue(value);
  //   return () => ac.abort(); // Abort both fetches on unmount
  // }, [value, setValue]);

  // const items = itemList.map((list, i) => (
  //   <Option
  //     id={list.id}
  //     key={list.id}
  //     value={`${list.name}${list.id}`}
  //     val={list.name}
  //   >
  //     <div>{list.name}</div>
  //   </Option>
  // ));

  return (
    <ClickAwayListener
      onClickAway={() => {
        // onBlur && onBlur();
      }}
    >
      <SAddNew
        autoFocus={autoFocus && autoFocus}
        defaultOpen={defaultOpen && defaultOpen}
        style={{
          width: width ? width : "100%",
          height: "30px",
          textAlign: "left",
          color: "black",
          display: "flex",
          alignItems: "center",
        }}
        mode={mode && mode}
        allowClear={allowClear}
        placeholder={placeholder1}
        showSearch
        onBlur={() => onBlur && onBlur()}
        filterOption={(input, option) =>
          option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={async (value, option) => onChange && onChange(value, option)}
        onSearch={(val) => setNewValue(val)}
        onSelect={async (value, option) => onSelect && onSelect(value, option)}
        onDeselect={async (value, option) =>
          onDeselect && onDeselect(value, option)
        }
        onClear={() => onClear && onClear()}
        value={value}
        notFoundContent={<div></div>}
        dropdownRender={(menu) => (
          <div
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <AddNew
              onClick={(e) => {
                addNewValue && addNewValue(e, newValue);
              }}
            >
              <PlusOutlined />
              <Info>
                Add "{newValue}" as new {itemType}
              </Info>
            </AddNew>
          </div>
        )}
      >
        {nullOption && (
          <Option key={"null"} value={""}>
            <div onClick={() => onOptionClick && onOptionClick()}></div>
          </Option>
        )}
        {itemList}
      </SAddNew>
    </ClickAwayListener>
  );
};

const AddNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: all;
  padding:0  10px;
  color: ${({ theme }) => theme.colors.color_state_notification_dark};
  cursor: pointer;
  /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
  }
`;

const Info = styled.div`
  padding-left: 10px;
  line-height: 25px;
  word-break: break-all;
`;

const SAddNew = styled(Select)`
  padding-left: 2px;
  .ant-select-selector {
    padding: 0 10px !important;
    width: 100%;
    /* padding-left: 10px !important; */
  }
  .ant-select-selection-search-input {
    /* padding-left: 10px !important; */
  }

  .ant-select-selection-search {
    left: 0 !important;
  }

  .ant-select-selection-item {
    div {
      margin: 0;
    }
  }
`;
