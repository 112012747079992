import React, { useRef, useState, useEffect } from "react";
import MaterialTable from "material-table";
import styled from "styled-components";
import columnGenerator from "./columnGenerator";
import handleExport from "./handleExport";
// import { updateReportColumnTypesAxios } from "common/ApiAxios";
import {
  Select,
  Option,
  SecondaryButton,
  CloseModal,
  ClearButton,
  PrimaryButton,
} from "ui";
import { useTranslation } from "react-i18next";
import { SpreadSheets } from "@grapecity/spread-sheets-react";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013lightGray.css";
import * as spreadExcel from "@grapecity/spread-excelio";
import * as GC from "@grapecity/spread-sheets";
import "@grapecity/spread-sheets-designer-resources-en";
import "@grapecity/spread-sheets-designer";
import useViewport from "hooks/useViewPort";
if (window.location.origin.includes("testing.tradir.io")) {
  GC.Spread.Sheets.LicenseKey =
    "E499651173151541#B0vouHalFTUFx4UK5WQwtCeIN7S5Z5QhVnTQtSbCRXWkhmZURWW9xUMw3SWxQnMrM5N8o6QW5UMTF4d8JmYCZVZUVEeHVWOwdFNKR7RUZXTvoETsV6dIhDWqREdEJXOVRUN5AXUxdDTvREcPZjRvZVMD3icyFVeN5kah3WSIJXdoZlYDV4LzlmQud6LtFkRsNUWzg7SVp7Z0ZWcWFjcwVjYKlHbMJETD96Y7UDe9IWRZRXbhpXNi9mMZNWNmBjdPh6SSJ7RBVHV82ydUplSyEFeCFmSVBlaLlzRzIlVihkMpZkUxF4Lk9WZ8l5ZiojITJCLiU4N9IkNygzNiojIIJCLxQDM5IjMwcTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI7IDMycDMgMTMxATMyAjMiojI4J7QiwiIzEDNwEjMwIjI0ICc8VkIsISkwqegYyuI0ISYONkIsUWdyRnOiwmdFJCLiEDN5ETNxMzNxETN6kTO4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBZ5SCFndlVWWYB7Z7pHMhJWUENkSld6Ul5URUBnN8E4aUFkRwlDNqBlbWdzS7ZDWKd4YLpXbut4QUlnQahDVIBzYK3UYKRDRxN7VhFWeTFzSjJ4Z5k5ZDJjbvFDWVlDRy34QzE4SBVFvmzl";
} else {
  GC.Spread.Sheets.LicenseKey =
    "tradir.io,261274735935899#B0Emnzo5LkRDNyF6d4F5RJNVcJNnMzhVZ9VFaNdldzBXZyJ6czpFWPdEaTFVRPlkTIlXcVV6bMdGNxBDW4gUcKJFTUBHUrUVO42GS9gWNvBjQStidsJFZrh4NZ3CSJp5Rap7b5dTNxU4c52WNkhVSQtmYLBVbYNXeaRHSXZXNZN4Q7MGRrNXNPFWdjpXbk5EMX3Ca5Z6Q7YEMndXbNRGU8U6VUpWSnhDS0NDRTJDRzIVSQF6bxU6Y9RTNzMjdBVTYQh6LZRUVaR7c4kmMzBlNxJVW8NGTOJ4SplXQuhmUDpWMaZkTPpkI0IyUiwiIwIDNGVkRDRjI0ICSiwiM7ETO9UDN5gTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIwUDOxQDMggTMxATMyAjMiojI4J7QiwiIvlmLylGZhJHdiojIz5GRiwiIRCr0BiJ1iojIh94QiwiI9kDO5MTO5MzN4cjMxYjMiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUD5GUqNDe4FVeLh6c45URPVWVvcXdxVVdvhTMxtidtdUUX56KZNnYzV4aNF5aoNnR85WOpVnayEkU7YjRUFGdyo6KOh6TFpnVlhGe7oWUBN7ZpdkSydUQXd7Nq3EOJNmb0p7M6gFNK3SWroVMQDE20";
  spreadExcel.LicenseKey =
    "tradir.io,261274735935899#B0Emnzo5LkRDNyF6d4F5RJNVcJNnMzhVZ9VFaNdldzBXZyJ6czpFWPdEaTFVRPlkTIlXcVV6bMdGNxBDW4gUcKJFTUBHUrUVO42GS9gWNvBjQStidsJFZrh4NZ3CSJp5Rap7b5dTNxU4c52WNkhVSQtmYLBVbYNXeaRHSXZXNZN4Q7MGRrNXNPFWdjpXbk5EMX3Ca5Z6Q7YEMndXbNRGU8U6VUpWSnhDS0NDRTJDRzIVSQF6bxU6Y9RTNzMjdBVTYQh6LZRUVaR7c4kmMzBlNxJVW8NGTOJ4SplXQuhmUDpWMaZkTPpkI0IyUiwiIwIDNGVkRDRjI0ICSiwiM7ETO9UDN5gTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIwUDOxQDMggTMxATMyAjMiojI4J7QiwiIvlmLylGZhJHdiojIz5GRiwiIRCr0BiJ1iojIh94QiwiI9kDO5MTO5MzN4cjMxYjMiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUD5GUqNDe4FVeLh6c45URPVWVvcXdxVVdvhTMxtidtdUUX56KZNnYzV4aNF5aoNnR85WOpVnayEkU7YjRUFGdyo6KOh6TFpnVlhGe7oWUBN7ZpdkSydUQXd7Nq3EOJNmb0p7M6gFNK3SWroVMQDE20";
}
GC.Spread.Sheets.Designer.LicenseKey =
  "tradir.io,423825181336266#B0rzsXOz4keP9mYkhlVW3COPVXQYVXeHRWevIGVp36b9UTMWJzQhlWbWFnYZNHVDVjNC9GTsp7anpXSC3yKLx4dIJUMrUXNpJXVI9WZkZDNroHOKpUTxg4YFBDMiVEeoJUWlZEVKFXNFZmQygVOux4R7cjRyUTc9R6Lu5mekhnRwkWbUdTOHFmb5VleyhXOkhmYNJFc9Z4LnhlVrRXVuh7SOZFTNJzUzUGZvAVNGFFdDF5QZ3CR5IGbWFFZxBncCl6dZlGcvJUN5xmQ4R6YORDUNhVTLpVUpB7Lr3iZNBDW4YEWBJiOiMlIsISREJERwIEN7IiOigkIscTO7ETOyAzM0IicfJye&Qf35VfiklNFdjI0IyQiwiI4EjL6BibvRGZB5icl96ZpNXZE5yUKRWYlJHcTJiOi8kI1tlOiQmcQJCLiMTN8EDNwACOxEDMxIDMyIiOiQncDJCLi2WauIXakFmc4JiOiMXbEJCLiEJsqHImsLiOiEmTDJCLiYjNyYzMzEDOxUjM8MjM4IiOiQWSisnOiQkIsISP3EUSWZEcuhXUNdWbLp4YmhmbOBjQChUWzk5V52iVVNHVBJ6ctFmW4EGbadXNoZkWKtUTrc5ZLRER75mRlZWdwtyYGlTTwM7Krw6QvE7cMpHeIRnMhZFe8QDdyskcU3GWoBzY7REe7Q6boB5TFhkbUFDLZuj";
export const DynamicTable = ({
  title,
  data,
  toggle,
  getAllColumnTypes,
  getCurrentColumnTypes,
  updateColumnTypes,
}) => {
  const { height } = useViewport();
  const [allColumnTypes, setAllColumnTypes] = useState([]);
  const [columnTypes, setColumnTypes] = useState([]);
  const [selectColumnTypes, setSelectColumnTypes] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const attachRef = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    getAllColumnTypes().then((res) => setAllColumnTypes(res.data));
    getCurrentColumnTypes().then((res) => {
      setColumnTypes(res.data);
      setSelectColumnTypes(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateFields = () => {
    setColumnTypes(selectColumnTypes);
    updateColumnTypes(selectColumnTypes).then((res) => {
      setEditOpen(false);
      toggle();
    });
  };

  const handleSelect = (value) => {
    setSelectColumnTypes([...selectColumnTypes, value]);
  };

  const handleDeselect = (value) => {
    setSelectColumnTypes(selectColumnTypes.filter((type) => type !== value));
  };

  const handleColumnDragged = (sourceIndex, destinationIndex) => {
    let newList = [...columnTypes];
    const tmp = newList[sourceIndex];
    newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, tmp);
    setColumnTypes(newList);
    setSelectColumnTypes(newList);
    // updateReportColumnTypesAxios(reportType, {
    //   table_fields: newList,
    // });
    updateColumnTypes(newList);
  };

  return (
    <TableComp height={height}>
      <SpreadSheets
        ref={attachRef}
        allowUserDragMerge
        backColor="white"
        hostStyle={{
          display: "none",
        }}
      ></SpreadSheets>
      <EditFieldsModal
        visible={editOpen}
        title={<ModalTitle>{t("reporting.reportsTable.edit")}</ModalTitle>}
        footer={
          <FooterBox>
            <EventBtnBox>
              <CancelButton
                onClick={() => {
                  setEditOpen(false);
                }}
              >
                {t("myCompany.cancel")}
              </CancelButton>
              <PrimaryButton onClick={() => handleUpdateFields()}>
                {t("myCompany.update")}
              </PrimaryButton>
            </EventBtnBox>
          </FooterBox>
        }
        onCancel={() => {
          setEditOpen(false);
          setSelectColumnTypes(columnTypes);
        }}
        centered={true}
        bodyStyle={{
          backgroundColor: `${({ theme }) => theme.colors.color_base_white}`,
        }}
        // width="75%"
        // window={height}
        destroyOnClose
      >
        <FieldSelect
          mode="multiple"
          dropdownMatchSelectWidth={false}
          value={selectColumnTypes}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
        >
          {allColumnTypes.map((columnType) => (
            <Option key={columnType} value={columnType}>
              {columnType}
            </Option>
          ))}
        </FieldSelect>
      </EditFieldsModal>
      <EditFieldsButton onClick={() => setEditOpen(true)}>
        {t("reporting.reportsTable.edit")}
      </EditFieldsButton>
      <MyTable
        data={data && data[0] ? data : []}
        title={""}
        columns={
          columnGenerator(columnTypes)
          // data && data[0]
          //   ? columnGenerator(
          //       Object.keys(data[0]).filter((key) => key !== "tableData")
          //     )
          //   : []
        }
        onColumnDragged={handleColumnDragged}
        options={{
          exportButton: { csv: false, pdf: true },
          exportPdf: (columns, data) => {
            handleExport(attachRef, data, columnTypes);
          },
          headerStyle: {
            // backgroundColor: "#DEF3FA",
            // color: "Black",
            position: "sticky",
            top: 0,
            whiteSpace: "nowrap",
          },
          maxBodyHeight: height - 190,
          minBodyHeight: height - 190,
          paging: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 75, 100],
          overflowY: "scroll !important",
          // fixedColumns: {
          //   left: 0,
          //   right: 0,
          // },
          // tableLayout: "fixed",
        }}
        localization={{
          toolbar: {
            // exportCSVName: "Export CSV",
            exportPDFName: "Export Excel",
          },
        }}
        // icons={{ Search: () => <Icons type="fas" name="edit" /> }}
      ></MyTable>
    </TableComp>
  );
};

const MyTable = styled(MaterialTable)``;

const FieldSelect = styled(Select)`
  width: 100%;
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const EventBtnBox = styled.div`
  display: flex;
  margin: 5px 0;
`;

const CancelButton = styled(ClearButton)`
  width: 70px;
`;

const ModalTitle = styled.h2`
  ${({ theme }) => theme.fontSizes.txt_h2};
`;

const EditFieldsModal = styled(CloseModal)``;

const EditFieldsButton = styled(SecondaryButton)`
  position: absolute;
  /* right: ; */
  z-index: 1;
  right: 358px;
  top: 9px;
`;

const TableComp = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignRight {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignRight:active {
    z-index: 100;
  }
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft:active {
    z-index: 100;
  }
  .MuiTableHead-root {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
    position: sticky;
  }
  .MuiTableBody-root {
    height: ${({ height }) => height - 250}px;
    overflow-y: scroll !important;
  }
  .MuiTable-root {
    font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  }
  .MuiInputBase-root {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.color_base_regular};
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  th.MuiTableCell-root.MuiTableCell-head {
    font-size: 12px;
  }
  button.MuiButtonBase-root.MuiIconButton-root {
    padding: 3px;
    margin-right: 5px;
  }
  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    margin-left: 10px;
  }
  .MuiInputAdornment-positionStart {
    margin-left: 8px;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    border: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  thead.MuiTableHead-root {
    z-index: 0;
    position: relative;
  }
  * {
    ::-webkit-scrollbar {
      background: ${({ theme }) => theme.colors.color_base_white};
      width: 12px;
      height: 12px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.color_base_white};
      border-radius: 0px;
      margin-left: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_dark};
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.color_base_darker};
      border-radius: 5px;
    }
  }
  .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    border-radius: 12px;
  }
  .MuiTablePagination-root:last-child {
    border-bottom: none;
  }
  button.MuiButtonBase-root.MuiIconButton-root {
    border: none;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .MuiTableCell-root {
    padding: 4px 6px;
    border-color: ${({ theme }) => theme.colors.color_base_dark};
    user-select: text;
    div {
      user-select: text;
    }
  }
  .MuiInputAdornment-root {
    height: auto;
  }
  .MTableToolbar-searchField-10 {
    padding-right: 8px;
  }
  td.MuiTableCell-root.MuiTableCell-body {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      font-size: 12px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 50px;
    }
  }
`;
