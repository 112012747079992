import { companyAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useAllTeamMembers = () => {
  async function getAllTeamMembers() {
    const { data } = await companyAPIs.getAllTeamMembersAxios();
    // return { ...data, loaded: true };
    return data;
  }
  return useQuery({
    queryKey: "get/team/profile/?fields=members",
    queryFn: getAllTeamMembers,
    placeholderData: { members: [] },
  });
};
