import React, { useState } from "react";
import styled from "styled-components";
import DealCardDetailView from "pages/DealCardDetailView/DealCardDetailView";
import { MemberIcon, Link, PageModal } from "ui";
import { useTranslation, withTranslation } from "react-i18next";

const DealsListItem = ({ item, handleDataUpdate }) => {
  const [visible, setVisible] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [preventClose, setPreventClose] = useState(false);

  const { id, name, card_list, amount, currency, user, archived_at } = item;

  const { t } = useTranslation();

  return (
    <>
      <TableListItem>
        <ItemLinkBox>
          <ItemLink
            onClick={() => {
              setCardId(id);
              setVisible(true);
            }}
          >
            {name}
          </ItemLink>
        </ItemLinkBox>

        <ItemText>{card_list}</ItemText>
        <ItemText>
          {currency === null
            ? "-"
            : `${currency} ${Number(amount).toLocaleString()}`}
        </ItemText>
        <AccountOwnerBox>
          <AccountOwnerText>
            <MemberIcon
              member={{
                user_name: user.user_name,
                user_email: user.user_email,
                user_profile_image: user.user_profile_image,
              }}
            ></MemberIcon>
          </AccountOwnerText>
          <AccountOwnerText>{user.user_name}</AccountOwnerText>
        </AccountOwnerBox>
        <ItemText>
          {archived_at === null
            ? t(
                "contacts.contactsDetailView.contactsDealsList.DealsListItem.active"
              )
            : `${t(
                "contacts.contactsDetailView.contactsDealsList.DealsListItem.archivedOn"
              )} ${archived_at}`}
        </ItemText>
        <PageModal
          visible={visible}
          onCancel={() => {
            !preventClose && setVisible(false);
            handleDataUpdate();
          }}
          footer={null}
          width="100%"
          centered={true}
          destroyOnClose
        >
          <DealCardDetailView
            setPreventClose={setPreventClose}
            id={cardId}
            setVisible={setVisible}
            visible={visible}
          />
        </PageModal>
      </TableListItem>
    </>
  );
};

export default withTranslation()(DealsListItem);

const TableListItem = styled.li`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.color_base_dark};
  height: 45px;
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const ItemText = styled.div`
  width: 12.5%;
  padding-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-height: 100%;
  display: inline-block;
  :nth-child(5) {
    width: 17.5%;
  }
`;

const ItemLinkBox = styled.div`
  width: 40%;
  padding-right: 8px;
`;

const ItemLink = styled(Link)`
  padding: 5px 0;
  display: inline;
  word-break: break-all;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
`;

const AccountOwnerBox = styled(ItemText)`
  width: 17.5%;
  display: flex;
  align-items: center;
`;

const AccountOwnerText = styled.div`
  margin: 0 5px 0 0;
`;
