import { globalAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useDealCardViewInfo = (id, string) => {
  async function getTeamMetaInfo() {
    const { data } = await globalAPIs.getCardViewPartialInfoAxios(id, string);
    return data.can_connect;
  }
  return useQuery({
    queryKey: "get/user/team/meta_info/",
    queryFn: getTeamMetaInfo,
    // placeholderData: {},
  });
};
