import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { notification } from "ui";
import GlobalStyle from "./style/GlobalStyle";
import loadable from "@loadable/component";
import { setToken } from "modules/Global/token";
// import { TRACKING_ID } from "./Config";
import { axios } from "common/ApiAxios";
// import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import RouteChangeTracker from "./analytics/RouteChangeTracker";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "utils/ErrorFallback";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// import createHistory from "history/createBrowserHistory";
import { IntercomProvider } from "react-use-intercom";
const StructureOuter = loadable(() =>
  import("pages/StructureOuter/StructureOuter")
);
const StructureInner = loadable(() =>
  import("pages/StructureInner/StructureInner")
);
const queryClient = new QueryClient();
const NotFound = loadable(() => import("./pages/Errors/NotFound"));
const Routes = () => {
  const token = useSelector((state) => state.token);
  const { t } = useTranslation();
  useEffect(() => {
    try {
      localStorage.getItem("token") &&
        dispatch(setToken(localStorage.getItem("token")));
    } catch (error) {
      notification(
        "warning",
        t("global.cookie.errorMessage"),
        "",
        "topRight",
        12
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  axios.interceptors.request.use(
    function (config) {
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      // 요청 에러 직전 호출됩니다.
      return Promise.reject(error);
    }
  );

  const dispatch = useDispatch();
  // useEffect(() => {
  //   //GA
  //   ReactGA.initialize(TRACKING_ID, { debug: false });
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <Router>
        <IntercomProvider
          autoBoot={window.location.host.includes("tradir.io")}
          appId={"e32c5nle"}
        >
          <RouteChangeTracker />
          <GlobalStyle />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => {
                  return <Redirect to={`/home/${props.location.search}`} />;
                }}
              />
              <Route path="/home" component={StructureOuter} />
              <Route path="/" component={StructureInner} />
              <Route exact path="/404" component={NotFound} />
              <Route render={() => <Redirect to="/home" />} />
              {/* <Redirect to="/404" /> */}
            </Switch>
          </ErrorBoundary>
        </IntercomProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default Routes;
