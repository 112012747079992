import { handleActions, createAction } from "redux-actions";

const StartingPlan = "startingPlan/UPDATE";

export const updateStartingPlan = createAction(StartingPlan);

const initialState = 2;

const startingPlan = handleActions(
  {
    [StartingPlan]: (state, { payload: info }) => info,
  },
  initialState
);

export default startingPlan;
