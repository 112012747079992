/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MemberIcon, Skeleton, Spin } from "ui";
import moment from "moment";
import * as comps from "../CardComponents";
import CommentAdd from "./CommentAdd";
import CommentList from "./CommentList";
import useViewport from "hooks/useViewPort";
import {
  postCommentAxios,
  getCommentByPageAxios,
  updateAllNotificationsAxios,
  getCardViewNotificationAxios,
} from "../../../../common/ApiAxios";
import { updateCardNotification } from "modules/DealCardDetailView/cardNotification";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sendNotification } from "modules/Global/notificationSocket";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { resetFile } from "modules/DealCardDetailView/attachFileInfo";
import usePrevious from "hooks/usePrevious";
import useCheckPath from "hooks/useCheckPath";
import {
  currentNum,
  initializeCurrentNum,
} from "modules/Contacts/paginationLists";
const CommentPage = ({
  id,
  match,
  viewType,
  cardOpen,
  setPreventClose,
  refresh,
}) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.addTeamMetaInfo.user);
  const notifications = useSelector((state) => state.notifications);
  const newFileLink = useSelector((state) => state.attachFileInfo);
  const currentCard = useSelector((state) => state.currentCard);
  const [commentHeight, setCommentHeight] = useState(32);
  const prevCommentHeight = usePrevious(commentHeight);
  const { height } = useViewport();
  const [updateHeight, setUpdateHeight] = useState(true);
  const [start, setStart] = useState(true);
  const [initialize, setInitialize] = useState(false);
  const [progress, setProgress] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [editCommentAdd, setEditCommentAdd] = useState(false);
  const [scrollTop, setScrollTop] = useState(100);

  // Mobile webview 에서 comment edit 할 때 하단 input 숨김
  const [isEditing, setIsEditing] = useState(false);
  const newComments = useSelector(
    (state) => state.cardNotification.new_comments
  );

  const containerRef = useRef();
  const currentPageInfo = useSelector((state) => state.paginationLists);
  const checkMobile = useCheckPath("mobile");

  useEffect(() => {
    cardOpen &&
      newComments.noti_ids.length > 0 &&
      updateAllNotificationsAxios({
        action: "read",
        id_list: newComments.noti_ids,
      });
    !cardOpen &&
      newComments.noti_ids.length > 0 &&
      getCardViewNotificationAxios(id.slice(5)).then((res) => {
        dispatch(updateCardNotification(res.data));
      });
    return () => {
      newComments.noti_ids.length > 0 &&
        getCardViewNotificationAxios(id.slice(5)).then((res) => {
          dispatch(updateCardNotification(res.data));
        });
    };
  }, [newComments, cardOpen]);

  useEffect(() => {
    start &&
      data.length > 0 &&
      setTimeout(() => {
        containerRef.current &&
          containerRef.current._osInstance.scroll({
            y: containerRef.current._osInstance.scroll().max.y,
          });
        setStart(false);
      }, 5);
  }, [data]);
  useEffect(() => {
    if (value !== "") {
      containerRef.current &&
        containerRef.current._osInstance.scroll({
          y:
            containerRef.current._osInstance.scroll().max.y +
            (commentHeight - prevCommentHeight),
        });
    } else {
      containerRef.current &&
        containerRef.current._osInstance.scroll({
          y: containerRef.current._osInstance.scroll().max.y,
        });
    }
  }, [commentHeight, id, prevCommentHeight, value]);

  useEffect(() => {
    newFileLink.url !== "" &&
      id === currentCard &&
      (value !== ""
        ? setValue(
            value +
              "|^|" +
              newFileLink.url +
              "|||" +
              newFileLink.content_type +
              "|||" +
              newFileLink.name +
              "|^|\n"
          )
        : setValue(
            "|^|" +
              newFileLink.url +
              "|||" +
              newFileLink.content_type +
              "|||" +
              newFileLink.name +
              "|^|\n"
          ));
    newFileLink.url !== "" &&
      id === currentCard &&
      setUpdateHeight(!updateHeight);
    dispatch(resetFile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFileLink]);

  useEffect(() => {
    notifications.msg_type === "updateChat" &&
      notifications.card_id === id.slice(5) &&
      notifications.user_id !== user.id &&
      getCommentByPageAxios(
        id.slice(5),
        `/comment/?card_id=${id.slice(5)}&limit=${data.length + 1}&offset=0`
      ).then((res) => {
        const receivingComment = res.data.results.map((comment) => {
          return {
            ...comment,
            datetime: comment.local_created_at,
            key: comment.id,
          };
        });
        setData(receivingComment.reverse());
        setProgress(false);
        dispatch(
          currentNum({
            ...currentPageInfo,
            next: res.data.next,
            previous: res.data.previous,
            total: res.data.count,
          })
        );
      });
  }, [id, notifications, user.id]);

  useEffect(() => {
    id !== null &&
      refresh &&
      getCommentByPageAxios(
        id.slice(5),
        `/comment/?card_id=${id.slice(5)}&limit=${
          data.length === 0 ? 10 : data.length
        }&offset=0`
      ).then((res) => {
        const receivingComment = res.data.results.map((comment) => {
          return {
            ...comment,
            datetime: comment.local_created_at,
            key: comment.id,
          };
        });
        setData(receivingComment.reverse());
        setIsFetching(false);
        setProgress(false);
        dispatch(
          currentNum({
            ...currentPageInfo,
            next: res.data.next,
            previous: res.data.previous,
            total: res.data.count,
          })
        );
        !initialize && setInitialize(true);
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(`commentLoad`);
      });
  }, [id, loading, refresh]);

  useEffect(() => {
    id !== null &&
      editCommentAdd &&
      getCommentByPageAxios(
        id.slice(5),
        `/comment/?card_id=${id.slice(5)}&limit=${data.length}&offset=0`
      ).then((res) => {
        const receivingComment = res.data.results.map((comment) => {
          return {
            ...comment,
            datetime: comment.local_created_at,
            key: comment.id,
          };
        });
        setData(receivingComment.reverse());
        setProgress(false);
        setEditCommentAdd(false);
        dispatch(
          currentNum({
            ...currentPageInfo,
            next: res.data.next,
            previous: res.data.previous,
            total: res.data.count,
          })
        );
        !initialize && setInitialize(true);
      });
  }, [id, editCommentAdd]);

  const handleSubmit = (mention_ids) => {
    if (!value) {
      return;
    }
    postCommentAxios({
      card: id.slice(5),
      content: value,
      mention_ids: mention_ids,
    }).then((res) => {
      const newComment = {
        id: res.data.id,
        content: res.data.content,
        user: { ...user },
        datetime: moment().fromNow(),
      };
      dispatch(
        sendNotification({
          board_id: match.params.category,
          card_id: id.slice(5),
          msg_type: "updateChat",
          team_id: user.team_id,
          type: "sendMessage",
          user_id: user.id,
        })
      );
      setData([...data, newComment]);
      setCommentCount((prevState) => prevState + 1);
      setValue("");
      setCommentHeight(32);
      containerRef.current._osInstance.scroll({
        y: containerRef.current._osInstance.scroll().max.y,
      });
    });
  };

  useEffect(() => {
    if (
      scrollTop === 0 &&
      currentPageInfo &&
      currentPageInfo.next !== "" &&
      currentPageInfo.next !== null
    ) {
      setIsFetching(true);
    }
  }, [scrollTop]);

  useEffect(() => {
    if (isFetching) {
      fetchMoreListItems();
    }
  }, [isFetching]);

  const fetchMoreListItems = () => {
    if (currentPageInfo && currentPageInfo.next !== "") {
      setProgress(true);
      id !== null &&
        currentPageInfo &&
        currentPageInfo.next !== "" &&
        currentPageInfo.next !== null &&
        getCommentByPageAxios(
          id.slice(5),
          currentPageInfo.next.split("/api")[1]
        ).then((res) => {
          const receivingComment = res.data.results.map((comment) => {
            return {
              ...comment,
              datetime: comment.local_created_at,
              key: comment.id,
            };
          });
          setData((prevState) => [
            ...receivingComment.slice(commentCount, 10).reverse(),
            ...prevState,
          ]);
          setCommentCount(0);
          const prevHeight = containerRef.current._osInstance.scroll().max.y;
          setTimeout(() => {
            containerRef.current._osInstance.scroll({
              y: containerRef.current._osInstance.scroll().max.y - prevHeight,
            });
          }, 100);
          dispatch(
            currentNum({
              ...currentPageInfo,
              next: res.data.next !== null ? res.data.next : "",
              previous: res.data.previous,
              total: res.data.count,
            })
          );
          setProgress(false);
          setIsFetching(false);
          !initialize && setInitialize(true);
        });
    }
  };

  // redux initialize check
  useEffect(() => {
    return () => {
      dispatch(initializeCurrentNum());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const handleChange = (value) => {
    setValue(value);
  };
  return (
    <CommentDiv window={height} isMobile={checkMobile}>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "leave" },
          overflowBehavior: {
            x: "hidden",
            y: "scroll",
          },
        }}
        ref={containerRef}
        onScroll={(e) => setScrollTop(e.target.scrollTop)}
      >
        <CommentContainer
          id={`commentContainertmp${id}`}
          window={height}
          commentHeight={commentHeight}
          isMobile={checkMobile}
          viewType={viewType === 3 ? 20 : 0}
          isEditing={isEditing}
        >
          <div style={{ marginTop: "auto" }}></div>
          {progress && (
            <SpinContainer>
              <Spin />
            </SpinContainer>
          )}
          {initialize ? (
            <CommentList
              key="Fadsfadsf"
              comments={data}
              id={id}
              user={user}
              setLoading={setLoading}
              setEditCommentAdd={setEditCommentAdd}
              setPreventClose={setPreventClose}
              loading={loading}
              setIsEditing={setIsEditing}
            />
          ) : (
            <Skeleton active />
          )}
        </CommentContainer>
      </OverlayScrollbarsComponent>
      {checkMobile && !isEditing ? (
        <CommentAddBox commentHeight={commentHeight}>
          <MemberIcon member={user}></MemberIcon>
          <CommentAdd
            updateHeight={updateHeight}
            id={id}
            onChange={handleChange}
            onSubmit={handleSubmit}
            commentHeight={commentHeight}
            value={value}
            setCommentHeight={setCommentHeight}
          />
        </CommentAddBox>
      ) : !checkMobile ? (
        <CommentAddBox commentHeight={commentHeight}>
          <MemberIcon member={user}></MemberIcon>
          <CommentAdd
            updateHeight={updateHeight}
            id={id}
            onChange={handleChange}
            onSubmit={handleSubmit}
            commentHeight={commentHeight}
            value={value}
            setCommentHeight={setCommentHeight}
          />
        </CommentAddBox>
      ) : (
        <></>
      )}
    </CommentDiv>
  );
};

export default withRouter(CommentPage);

const CommentAddBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 12px;
  right: 16px;
  display: flex;
`;

const CommentDiv = styled(comps.CardViewInnerSectionStyle)`
  position: relative;
  width: 100%;
  height: ${(props) =>
    props.isMobile ? props.window - 60 : props.window - 130}px;
  padding: 0 12px;
  ${(props) =>
    props.isMobile ? "border: none;" : "border: 1px solid #d6dee5;"}
`;

const CommentContainer = styled.div`
  height: ${(props) =>
    props.isMobile && !props.isEditing
      ? `${props.window - 100 - props.commentHeight + props.viewType}px`
      : props.isMobile && props.isEditing
      ? `${props.window - 70 - props.commentHeight + props.viewType}px`
      : `${props.window - 208 - props.commentHeight + props.viewType}px`};
`;
const SpinContainer = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
`;
