import { handleActions, createAction } from "redux-actions";

const TOGGLE = "toolBarOpen/TOGGLE";

export const changeToolBarOpen = createAction(TOGGLE);

const initialState = false;

const toolBarOpen = handleActions(
  {
    [TOGGLE]: (state, { payload: bool }) => bool,
  },
  initialState
);

export default toolBarOpen;
