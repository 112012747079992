// import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// export const CardViewInnerSection = () => {
//   return <CardViewInnerSectionStyle></CardViewInnerSectionStyle>;
// };

export const CardViewInnerSectionStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.color_base_white};
  /* box-shadow: ${({ theme }) => theme.elevations.elevation_light}; */
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.color_base_darker};
  /* line-height: 1.3em; */
  /* font-size: 15px; */
`;
export const CardViewInnerSectionTitleStyle = styled.div`
  font-weight: 600;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_darker};
`;
export const CardViewInnerSectionBodyStyle = styled.div`
  padding: 15px;
  @media ${({ theme }) => theme.screenSizes.tablet} {
    padding: 8px;
  }
`;
