/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MemberIcon, FormInput, PrimaryButton, Mentions } from "ui";
import Icons from "assets/Icons";
import useResponsiveHeight from "hooks/useResponsiveHeight";
import useViewport from "hooks/useViewPort";
import useCheckPath from "hooks/useCheckPath";
import { useTranslation, withTranslation } from "react-i18next";
import { getCardViewPartialInfoAxios } from "common/ApiAxios";
// import { faUserAstronaut } from "@fortawesome/pro-solid-svg-icons";
const CommentAdd = ({
  onChange,
  onSubmit,
  value,
  onClick,
  setCommentHeight,
  commentHeight,
  updateHeight,
  id,
}) => {
  const { Option } = Mentions;
  const inputEl = useRef(null);
  const { width } = useViewport();
  const textAreaHeight = useResponsiveHeight(
    document.getElementById(`AddComment${id}`)
  );
  const [memberData, setMemberData] = useState([]);
  const { t } = useTranslation();
  const [mentionList, setMentionList] = useState([]);

  const checkMobile = useCheckPath("mobile");

  useEffect(() => {
    getCardViewPartialInfoAxios(id.slice(5), "?fields=members").then((res) => {
      setMemberData(res.data.members.filter((member) => member.has_access));
    });
  }, []);

  useEffect(() => {
    value.includes("|||") && inputEl.current.focus();
    // setCommentHeight(inputEl.current.offsetHeight + 52);
  }, [textAreaHeight, value]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     inputEl.current &&
  //       setCommentHeight(
  //         inputEl.current.resizableTextArea.state.textareaStyles.height
  //       );
  //   }, 1);
  // }, [updateHeight]);
  useEffect(() => {
    inputEl.current && setCommentHeight(inputEl.current.textarea.offsetHeight);
  }, [value]);

  const optionList = memberData.map((member) => (
    <Option value={member.user_name} id={member.id} key={member.id}>
      <OptionItem>
        <MemberIcon member={member}></MemberIcon>
        <OptionText>
          {member.user_name}
          <br></br>
          {member.user_email}
        </OptionText>
      </OptionItem>
    </Option>
  ));

  return (
    <CommentAddBox width={width} isMobile={checkMobile}>
      <FormInput.Item>
        <Mentions
          autoSize={{ minRows: 1, maxRows: !checkMobile ? 20 : 3 }}
          id={`AddComment${id}`}
          ref={inputEl}
          onChange={onChange}
          onClick={onClick}
          value={value}
          placement="top"
          onResize={(height) => {
            // setCommentHeight(
            //   // inputEl.current.resizableTextArea.state.textareaStyles.height
            //   height
            // );
          }}
          placeholder={t("cardView.commentPage.commentAdd.placeholder")}
          onSelect={(option) =>
            setMentionList([
              ...mentionList,
              { id: option.id, value: option.value },
            ])
          }
        >
          {optionList}
          {/* <Option value="afc163">afc163</Option>
          <Option value="zombieJ">zombieJ</Option>
          <Option value="yesmeck">yesmeck</Option> */}
        </Mentions>
        {/* <CommentTextArea
          id={`AddComment${id}`}
          ref={inputEl}
          onChange={onChange}
          onClick={onClick}
          value={value}
          autoSize={{ minRows: 1, maxRows: 20 }}
          onResize={({ height }) => {
            setCommentHeight(
              inputEl.current.resizableTextArea.state.textareaStyles.height
            );
          }}
          onKeyDown={(e) => {
            const { key } = e;
            const start = e.target.selectionStart;
            if (key === "Enter" && e.target.offsetHeight < 450) {
              setCommentHeight(e.target.offsetHeight + 22);
            } else if (
              (key === "Backspace" || key === "Delete") &&
              inputEl.current.resizableTextArea.props.value[start - 1] === "\n"
            ) {
              setCommentHeight(e.target.offsetHeight - 22);
            }
            setTimeout(() => {
              if (
                key === "Enter" &&
                inputEl.current.resizableTextArea.state.textareaStyles.height <
                  450
              ) {
                setCommentHeight(
                  inputEl.current.resizableTextArea.state.textareaStyles.height
                );
              } else {
                setCommentHeight(
                  inputEl.current.resizableTextArea.state.textareaStyles.height
                );
              }
            }, 1);
            // if (key === "Enter" && e.target.offsetHeight < 450) {
            //   setCommentHeight(e.target.offsetHeight);
            // } else {
            //   setCommentHeight(e.target.offsetHeight);
            // }
          }}
          // onKeyPress={(e) => {
          //   const { key } = e;
          //   if (key === "Enter" && e.target.offsetHeight < 450) {
          //     setCommentHeight(e.target.offsetHeight);
          //   } else {
          //     setCommentHeight(e.target.offsetHeight);
          //   }
          // }}
          onKeyUp={(e) => {
            const { key } = e;
            if (key === "Enter" && e.target.offsetHeight < 450) {
              setCommentHeight(e.target.offsetHeight);
            } else {
              setCommentHeight(e.target.offsetHeight);
            }
          }}
        /> */}
      </FormInput.Item>
      {!checkMobile ? (
        <BtnBox>
          <PrimaryButton
            className="addCommentBtn"
            htmlType="submit"
            onClick={() => {
              const mention_ids = [];
              const tmp = mentionList.filter((mention) =>
                value.includes(`@${mention.value}`)
              );
              tmp.map(
                (mention) =>
                  !mention_ids.includes(mention.id) &&
                  mention_ids.push(mention.id)
              );
              onSubmit(mention_ids);
            }}
            type="primary"
          >
            {t("cardView.commentPage.commentAdd.send")}
          </PrimaryButton>
        </BtnBox>
      ) : (
        <MobileBtn>
          <SendBtn
            className="addCommentBtn"
            htmlType="submit"
            onClick={() => {
              const mention_ids = [];
              const tmp = mentionList.filter((mention) =>
                value.includes(`@${mention.value}`)
              );
              tmp.map(
                (mention) =>
                  !mention_ids.includes(mention.id) &&
                  mention_ids.push(mention.id)
              );
              onSubmit(mention_ids);
            }}
            type="primary"
          >
            <Icons type="fa" name="paper-plane" size="lg" />
          </SendBtn>
        </MobileBtn>
      )}
    </CommentAddBox>
  );
};
export default withTranslation()(CommentAdd);

const OptionText = styled.div`
  margin-left: 10px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
`;

const CommentAddBox = styled.div`
  margin-left: 8px;
  width: ${(props) => (props.isMobile ? "75%" : "-webkit-fill-available")};
  /* width: -webkit-fill-available; */
  /* position: absolute;
  right: 0;
  left: 30px;
  bottom: 0; */
  /* display: flex; */
  ${(props) => props.isMobile && "margin-right: 38px;"}
  position: relative;
`;

const BtnBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: -15px;
`;

const MobileBtn = styled.div`
  width: 50px;
  position: absolute;
  top: 0;
  right: -55px;
`;

const SendBtn = styled(PrimaryButton)`
  padding: 4px 7px;
  @media ${({ theme }) => theme.screenSizes.mobile} {
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.color_primary_regular} !important;
      border-color: ${({ theme }) =>
        theme.colors.color_primary_regular} !important;
      color: white !important;
    }
    &:active {
      background-color: ${({ theme }) =>
        theme.colors.color_primary_darker} !important;
      border-color: ${({ theme }) =>
        theme.colors.color_primary_darker} !important;
      color: white !important;
    }
  }
`;
