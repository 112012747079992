import { userAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useUserSettings = (queryString, fields = []) => {
  async function getUserSettingsData() {
    const { data } = await userAPIs.getUserSettingsAxios(queryString);
    console.log("data", data);
    // return { ...data, loaded: true };
    return data;
  }
  return useQuery({
    queryKey: ["get/user/settings/", ...fields],
    queryFn: getUserSettingsData,
    placeholderData: {
      card_type: "full",
      table_fields: [],
      view_type: "table",
      loading: true,
    },
  });
};
