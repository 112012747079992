import { handleActions, createAction } from "redux-actions";

const CURRENTNUM = "paginationLists/CURRENTNUM";
const INITIALIZE = "paginationLists/INITIALIZE";

export const currentNum = createAction(CURRENTNUM);
export const initializeCurrentNum = createAction(INITIALIZE);

const initialState = {
  current: 0,
  pageSize: 10,
  next: "",
  previous: "",
  total: 0,
};

const paginationLists = handleActions(
  {
    [CURRENTNUM]: (state, { payload: info }) => {
      return info;
    },
    [INITIALIZE]: (state, { payload: info }) => {
      return initialState;
    },
  },
  initialState
);

export default paginationLists;
