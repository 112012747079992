import { useTeamMetaInfo } from "api-cache";
const auth = (staff, agent) => {
  return {
    staff: staff,
    agent: agent,
  };
};

const checker = {
  DealBoard: {
    manageAccessButton: auth(true, false),
    changeManager: auth(false, false),
    dealSettings: auth(false, false),
  },
  DealBoardKanbanView: {
    listSettings: auth(false, false),
    addList: auth(true, false),
    deleteCard: auth(false, false),
  },
  Contacts: {
    deleteContact: auth(false, false),
  },
  SettingsMyCompany: {
    editProfileInfo: auth(false, false),
  },
  SettingsMembers: {
    editMember: auth(false, false),
  },
  Tutorial: {
    AdminTutorial: auth(false, false),
  },
  Nav: {
    Reports: auth(true, false),
  },
  Email: {
    MultiConnect: auth(false, false),
  },
  Settings: {
    Workspace: auth(true, false),
  },
  StructureInner: {
    Reports: auth(true, false),
  },
};
const useCheckAuth = (page, element, id) => {
  const {
    data: { user },
  } = useTeamMetaInfo();
  if (
    user.auth === "admin" ||
    (["DealBoard", "DealBoardKanbanView", "TableView", "ArchivedView"].includes(
      page
    ) &&
      user.manager_boards.includes(id)) ||
    (["Contacts"].includes(page) && user.id === id)
  ) {
    return true;
  }
  return checker[page][element][user.auth];
};

export default useCheckAuth;
