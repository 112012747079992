import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import { getMeetingNote, createMeetingNote } from "common/ApiAxios";
import { ClearButton } from "ui";
import Icons from "assets/Icons";
// import AddMeetingNote from "./AddMeetingNote";
import MeetingListItem from "./MeetingListItem";
import { useTranslation, withTranslation } from "react-i18next";

import loadable from "@loadable/component";
const AddMeetingNote = loadable(() => import("./AddMeetingNote"));
const MeetingNote = memo(({ type, typeId }) => {
  const [meetingNoteData, setMeetingNoteData] = useState([]);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const meetingNoteText = t("contacts.contactsDetailView.meetingNote", {
    returnObjects: true,
  });

  useEffect(() => {
    getMeetingNote(type, typeId).then((res) => {
      setMeetingNoteData(res.data);
    });
  }, [type, typeId]);

  const showModal = () => {
    setVisible(!visible);
  };

  const handleAddNote = (title, body) => {
    const addData = {
      title: title,
      body: body,
      account_object_id: typeId,
      account_content_type: type,
    };

    createMeetingNote(addData).then((res) => {
      setMeetingNoteData([...meetingNoteData, res.data]);
    });
  };

  return (
    <MeetingNoteComponent>
      <Title>{meetingNoteText.title}</Title>
      <Table>
        <TableTitleBox>
          <TableTitle>{meetingNoteText.tableTitle}</TableTitle>
          <TableTitle>{meetingNoteText.author}</TableTitle>
          <TableTitle>{meetingNoteText.createdDate}</TableTitle>
          {/* <TableTitle>REMOVE</TableTitle> */}
        </TableTitleBox>
        <MeetingList>
          {meetingNoteData &&
            meetingNoteData.map((item) => {
              return (
                <MeetingListItem
                  item={item}
                  key={item.id}
                  id={item.id}
                  type={type}
                  typeId={typeId}
                  meetingNoteData={meetingNoteData}
                  setMeetingNoteData={setMeetingNoteData}
                />
              );
            })}
        </MeetingList>
      </Table>
      <AddBtnBox>
        <AddButton onClick={() => showModal("AddNote")}>
          <AddIcon>
            <Icons type="fas" name="plus" />
          </AddIcon>
          {meetingNoteText.addNote}
        </AddButton>
      </AddBtnBox>
      <AddMeetingNote
        visible={visible}
        showModal={showModal}
        handleAddNote={handleAddNote}
      />
    </MeetingNoteComponent>
  );
});

export default withTranslation()(MeetingNote);

const MeetingNoteComponent = styled.section`
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h3};
  margin: 0px 0 10px 0;
`;

const Table = styled.div`
  /* border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc; */
`;

const TableTitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.color_base_dark};
`;

const TableTitle = styled.div`
  width: 25%;
  /* width: 33.3%; */
  /* text-align: center; */
  padding: 5px 0;
  ${({ theme }) => theme.fontSizes.txt_h5};

  :nth-child(1) {
    width: 50%;
  }
`;

const MeetingList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
`;

const AddBtnBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: 10px;
`;

const AddButton = styled(ClearButton)``;

const AddIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
