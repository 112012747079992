import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const center = {
//   lat: 33.51814,
//   lng: 126.52324,
// };

const TrackingMap = ({ lat, lon }) => {
  // const [map, setMap] = React.useState(null);
  //   const [data, setData] = useState({});
  const [latData, setLatData] = useState(lat);
  const [lonData, setLonData] = useState(lon);

  useEffect(() => {
    setLatData(lat);
    setLonData(lon);
  }, [lat, lon]);

  // fetch(`${API}/room/detail/${props.match.params.id}`)

  //   useEffect(() => {
  //     fetch(`${API}/room/detail/${props.match.params.id}`)
  //       .then((res) => res.json())
  //       .then((res) => {
  //         setData(res.room_info);
  //       });
  //   }, []);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyDhrc6j7ciPExCm5FrSNzGN-W9UbT3fBzY">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: Number(latData), lng: Number(lonData) }}
          zoom={7}
          onError={() => {
            setLatData(lat);
            setLonData(lon);
          }}
          //   onLoad={onLoad}
          // onUnmount={onUnmount}
        >
          <Marker
            position={{
              lat: Number(latData),
              lng: Number(lonData),
            }}
          />
          {/* Child components, such as markers, info windows, etc. */}
          <></>
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default TrackingMap;

const containerStyle = {
  width: "100%",
  height: "500px",
};
