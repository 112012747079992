import { emailAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
export const useEmailThreads = (pageSize, page) => {
  let history = useHistory();
  async function getEmailThreads() {
    const { data } = await emailAPIs.getEmailThreadsAxios(
      `?folder=${history.location.hash.slice(1)}&limit=${pageSize}&offset=${
        page * pageSize
      }`
    );
    return data;
  }
  return useQuery({
    enabled:
      history.location.hash.length > 0 &&
      !history.location.hash.includes("searching::"),
    queryKey: ["get/email/threads/", history.location.hash, pageSize, page],
    queryFn: getEmailThreads,
    keepPreviousData: true,
    placeholderData: { threads: [], loading: true },
  });
};
