import { handleActions, createAction } from "redux-actions";

const UPDATE = "tutorialProgress/UPDATE";

export const updateTutorial = createAction(UPDATE);

const initialState = {
  create_board: true,
  invite_user: true,
  customize_stages: true,
  fill_profile: true,
  sync_email: true,
};

const tutorialProgress = handleActions(
  {
    [UPDATE]: (state, { payload: info }) => {
      return info;
    },
  },
  initialState
);

export default tutorialProgress;
