import { emailAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailAccountEdit = () => {
  async function editEmailAcount(editData) {
    const { data } = await emailAPIs.putSettingEmailAxios(editData);
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(editEmailAcount, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/account/");
    },
  });
};
