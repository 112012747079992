import { handleActions, createAction } from "redux-actions";

const CURRENTCARD = "currentCard/CURRENTCARD";

export const updateCurrentCard = createAction(CURRENTCARD);

const initialState = "";

const currentCard = handleActions(
  {
    [CURRENTCARD]: (state, { payload: info }) => info,
  },
  initialState
);

export default currentCard;
