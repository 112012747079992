import { emailAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";
import { notification } from "ui";
import { useTranslation } from "react-i18next";

export const useEmailTokenSend = (pageSize, page) => {
  const { t } = useTranslation();
  async function disconnectEmail(code) {
    const { data } = await emailAPIs.sendEmailTokenAxios({ code: code });
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(disconnectEmail, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/user/team/meta_info/");
      queryClient.invalidateQueries("get/email/account/");
      queryClient.invalidateQueries("get/email/folders/");
      notification(
        "success",
        `${t("email.emailAccountIsConnected")}`,
        "",
        "bottomRight"
      );
    },
  });
};
