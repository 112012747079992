import React from "react";
import styled from "styled-components";
import { Tag as antTag } from "antd";
import Icons from "assets/Icons";
export const Tag = (props) => (
  <Tmp
    {...props}
    closeIcon={<Icons type="fas" name="times-circle" size="1x" />}
  >
    {props.children}
  </Tmp>
);

const Tmp = styled(antTag)`
  background-color: ${({ theme }) =>
    theme.colors.color_primary_lighter}!important;
  border: 1px solid ${({ theme }) =>
    theme.colors.color_primary_lighter} !important;
  color: ${({ theme }) => theme.colors.color_primary_darker} !important;
  .ant-tag-close-icon{
    color: ${({ theme }) => theme.colors.color_primary_darker} !important;
    margin-left: 6px;
  }
  /* span {
    color: ${({ theme }) => theme.colors.color_text_titles} !important;

     .anticon {
          .anticon-close {
            .ant-tag-close-icon {
                color: ${({ theme }) =>
                  theme.colors.color_text_titles} !important;
            }
        } 
    }  
  } 
  */

  &:hover {
    /* border: 1px solid ${({ theme }) =>
      theme.colors.color_primary_darker} !important; */
    /* color: ${({ theme }) => theme.colors.color_primary_darker}!important; */
  }
  &:focus {
    border: 1px solid ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    color: ${({ theme }) => theme.colors.color_primary_darker} !important;
    box-shadow: none !important;
  }
`;
