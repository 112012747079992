/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, memo } from "react";
import styled from "styled-components";
import {
  Input,
  Upload,
  Popconfirm,
  notification,
  label_green,
  label_grey,
  label_red,
  Link,
  SecondaryButton,
  Skeleton,
} from "ui";
import * as comps from "../CardComponents";
import { updateCardNotification } from "modules/DealCardDetailView/cardNotification";
// import { Draggable } from "react-beautiful-dnd";
import useViewport from "hooks/useViewPort";
import {
  getSharedAttachmentAxios,
  postSharedAttachmentAxios,
  deleteSharedAttachmentAxios,
  patchSharedAttachmentAxios,
  getMyAttachmentAxios,
  deleteMyAttachmentAxios,
  patchMyAttachmentAxios,
  postMyAttachmentAxios,
  updateAllNotificationsAxios,
  getCardViewNotificationAxios,
} from "common/ApiAxios";
// import PreviewModal from "Components/Global/PreviewModal";
import { useSelector, useDispatch } from "react-redux";
import { sendNotification } from "modules/Global/notificationSocket";
import { toComment } from "modules/DealCardDetailView/attachFileInfo";
import { toEmail } from "modules/DealCardDetailView/emailFileInfo";
import { useTranslation, withTranslation } from "react-i18next";
import { imageTypes } from "constants/ImageTypes";
import useCheckPath from "hooks/useCheckPath";
import loadable from "@loadable/component";
const PreviewModal = loadable(() =>
  import("ui/Organisms/PreviewModal/PreviewModal")
);
const CardViewAttachments = ({
  id,
  board_id,
  setUpdate,
  refreshAttachment,
  setPreventClose,
  refresh,
}) => {
  const [heightTrue, setHeightTrue] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [data, setData] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [type, setType] = useState("");
  const [idCompare, setIdCompare] = useState(0);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.addTeamMetaInfo.user);
  const composeCardID = useSelector((state) => state.composeCardID);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileUploadTime, setFileUploadTime] = useState("");
  const { height } = useViewport();
  const newAttachments = useSelector(
    (state) => state.cardNotification.new_attachments
  );
  const [uploaderName, setUploaderName] = useState("");
  const notifications = useSelector((state) => state.notifications);
  const bodyRef = useRef(null);
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const fileSize = useSelector(
    (state) => state.addTeamMetaInfo.file_upload_limit
  );

  const checkMobile = useCheckPath("mobile");
  useEffect(() => {
    setPreventClose &&
      (previewVisible
        ? setPreventClose(previewVisible)
        : setTimeout(() => {
            setPreventClose && setPreventClose(previewVisible);
          }, 500));
  }, [previewVisible]);

  useEffect(() => {
    newAttachments.noti_ids.length > 0 &&
      updateAllNotificationsAxios({
        action: "read",
        id_list: newAttachments.noti_ids,
      });
    return () => {
      newAttachments.noti_ids.length > 0 &&
        getCardViewNotificationAxios(id).then((res) => {
          dispatch(updateCardNotification(res.data));
        });
    };
  }, []);

  useEffect(() => {
    if (
      notifications.card_id &&
      notifications.card_id.toString().localeCompare(id.toString()) === 0
    ) {
      if (
        notifications.msg_type.localeCompare("newAttachment") === 0 &&
        notifications.user_id.toString().localeCompare(user.id.toString()) !== 0
      ) {
        isPrivate
          ? getMyAttachmentAxios(id).then((res) => {
              const receivingFile = res.data.map((filelists) => {
                return {
                  ...filelists,
                  uid: filelists.id,
                  url: filelists.link,
                  name: filelists.name,
                  type: filelists.content_type,
                  status: "done",
                };
              });
              setData(receivingFile);
            })
          : getSharedAttachmentAxios(id).then((res) => {
              const receivingFile = res.data.map((filelists) => {
                return {
                  ...filelists,
                  uid: filelists.id,
                  url: filelists.link,
                  name: filelists.name,
                  type: filelists.content_type,
                  status: "done",
                };
              });
              setData(receivingFile);
            });
      }
      if (
        notifications.msg_type.localeCompare("moveAttachmentPrivate") === 0 &&
        isPrivate
      ) {
        getMyAttachmentAxios(id).then((res) => {
          const receivingFile = res.data.map((filelists) => {
            return {
              ...filelists,
              uid: filelists.id,
              url: filelists.link,
              name: filelists.name,
              type: filelists.content_type,
              status: "done",
            };
          });
          setData(receivingFile);
        });
      } else if (
        notifications.msg_type.localeCompare("moveAttachmentShared") === 0 &&
        !isPrivate
      ) {
        getSharedAttachmentAxios(id).then((res) => {
          const receivingFile = res.data.map((filelists) => {
            return {
              ...filelists,
              uid: filelists.id,
              url: filelists.link,
              name: filelists.name,
              type: filelists.content_type,
              status: "done",
            };
          });
          setData(receivingFile);
        });
      }
    }
  }, [id, notifications, user.id]);
  // const curr = useRef(data);
  useEffect(() => {
    id !== "" &&
      refresh &&
      (isPrivate
        ? getMyAttachmentAxios(id).then((res) => {
            const receivingFile = res.data.map((filelists) => {
              return {
                ...filelists,
                uid: filelists.id,
                url: filelists.link,
                name: filelists.name,
                type: filelists.content_type,
                status: "done",
              };
            });
            setData(receivingFile);
          })
        : getSharedAttachmentAxios(id).then((res) => {
            const receivingFile = res.data.map((filelists) => {
              return {
                ...filelists,
                uid: filelists.id,
                url: filelists.link,
                name: filelists.name,
                type: filelists.content_type,
                status: "done",
              };
            });
            setData(receivingFile);
            !initialized && setInitialized(true);
          }));
  }, [id, isPrivate, refreshAttachment, refresh]);
  const handlePreview = async (file) => {
    setPreviewUrl(file.url);
    setFileName(file.name);
    setType(file.type);
    setFileUploadTime(file.local_uploaded_at);
    setUploaderName(file.uploader_name);
    if (checkMobile) {
      if (file.url.includes("/blob")) {
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(`${file.url}+${file.name}`);
      } else {
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(file.url);
      }
    }
  };

  const handleEditFileName = (file) => {
    setEdit(!edit);
    setIdCompare(file.id);
    setFileName(file.name);
  };

  const handleEmailLink = (file) => {
    dispatch(toEmail({ ...file, is_shared: !isPrivate }));
  };

  const handleFileInfo = (file) => {
    dispatch(toComment(file));
  };

  const handleRemoval = (info) => {
    const newData = data.filter((item) => item.id !== info.id);
    setData(newData);
    isPrivate
      ? deleteMyAttachmentAxios(info.id, id)
      : deleteSharedAttachmentAxios(info.id, id);
  };

  const handleEnterKey = (e) => {
    if (e.charCode === 13) {
      setFileName(e.target.value);
      isPrivate
        ? patchMyAttachmentAxios(idCompare, id, {
            name: e.target.value,
          }).then(() => {
            getMyAttachmentAxios(id).then((res) => {
              const receivingFile = res.data.map((filelists) => {
                return {
                  ...filelists,
                  uid: filelists.id,
                  url: filelists.link,
                  name: filelists.name,
                  type: filelists.content_type,
                  status: "done",
                };
              });
              setData(receivingFile);
            });
          })
        : patchSharedAttachmentAxios(idCompare, id, {
            name: e.target.value,
          }).then(() => {
            getSharedAttachmentAxios(id).then((res) => {
              const receivingFile = res.data.map((filelists) => {
                return {
                  ...filelists,
                  uid: filelists.id,
                  url: filelists.link,
                  name: filelists.name,
                  type: filelists.content_type,
                  status: "done",
                };
              });
              setData(receivingFile);
            });
          });
      setEdit(!edit);
    }
  };

  return (
    // <Draggable
    //   isDragDisabled={true}
    //   draggableId={String("OrderInformation")}
    //   index={4}
    // >
    //   {(provided) => (
    <OutsideWrapper
      isMobile={checkMobile}
      // ref={provided.innerRef}
      // {...provided.draggableProps}
      // {...provided.dragHandleProps}
    >
      <TitleBox>
        <SharedAttachmentTitle
          onClick={() => {
            setHeightTrue(!heightTrue);
          }}
          style={{ cursor: "pointer" }}
        >
          <div>{t("cardView.attachments.title")}</div>
        </SharedAttachmentTitle>
        <LableTextBox>
          <SharedLabel onClick={() => setIsPrivate(false)}>
            {!isPrivate ? (
              <LabelGreenText>
                {t("cardView.attachments.shared")}
              </LabelGreenText>
            ) : (
              <LabelGrayText>{t("cardView.attachments.shared")}</LabelGrayText>
            )}
          </SharedLabel>
          <PrivateLabel onClick={() => setIsPrivate(true)}>
            {!isPrivate ? (
              <LabelGrayText>{t("cardView.attachments.private")}</LabelGrayText>
            ) : (
              <LabelRedText>{t("cardView.attachments.private")}</LabelRedText>
            )}
          </PrivateLabel>
        </LableTextBox>
      </TitleBox>
      {!initialized ? (
        <Skeleton active />
      ) : (
        <AttachmentContainer height={checkMobile ? height + 30 : height}>
          <ContainerWrap>
            <BodyContainer ref={bodyRef}>
              <MyUpload
                customRequest={(info) => {
                  if (fileSize.max_bytes < info.file.size) {
                    notification(
                      "error",
                      `${t("notifications.cardViewAttachmentSize.front")} (${
                        fileSize.max_mbs
                      }) ${t("notifications.cardViewAttachmentSize.back")}`,
                      "",
                      "bottomRight"
                    );
                  } else {
                    const newFile = new FormData();
                    newFile.append("file", info.file);
                    newFile.append("card", id);
                    newFile.append("name", info.file.name);
                    const tmp = info.file.name.split(".");
                    newFile.append("content_type", tmp[tmp.length - 1]);
                    isPrivate
                      ? postMyAttachmentAxios(newFile)
                          .catch((err) => {
                            if (
                              err.response.data.file !== "" &&
                              err.response.status === 400
                            ) {
                              notification(
                                "error",
                                err.response.data.file,
                                "",
                                "bottomRight"
                              );
                            }
                          })
                          .then(() => {
                            getMyAttachmentAxios(id).then((res) => {
                              const receivingFile = res.data.map(
                                (filelists) => {
                                  return {
                                    ...filelists,
                                    uid: filelists.id,
                                    url: filelists.link,
                                    name: filelists.name,
                                    type: filelists.content_type,
                                    status: "done",
                                  };
                                }
                              );
                              setData(receivingFile);
                            });
                            setUpdate("my_attachment_set");
                          })
                      : postSharedAttachmentAxios(newFile)
                          .catch((err) => {
                            if (
                              err.response.data.file !== "" &&
                              err.response.status === 400
                            ) {
                              notification(
                                "error",
                                err.response.data.file,
                                "",
                                "bottomRight"
                              );
                            }
                          })
                          .then(() => {
                            getSharedAttachmentAxios(id).then((res) => {
                              const receivingFile = res.data.map(
                                (filelists) => {
                                  return {
                                    ...filelists,
                                    uid: filelists.id,
                                    url: filelists.link,
                                    name: filelists.name,
                                    type: filelists.content_type,
                                    status: "done",
                                  };
                                }
                              );
                              setData(receivingFile);
                            });
                            dispatch(
                              sendNotification({
                                board_id: board_id,
                                card_id: id,
                                msg_type: "newAttachment",
                                team_id: user.team_id,
                                type: "sendMessage",
                                user_id: user.id,
                              })
                            );
                          });
                  }
                }}
                fileList={data}
                onRemove={(info) => {
                  handleRemoval(info);
                }}
                onPreview={handlePreview}
                showUploadList={{
                  showDownloadIcon: true,
                  downloadIcon: (
                    <DownLoadText>
                      {t("cardView.attachments.downLoad")}
                    </DownLoadText>
                  ),
                  showRemoveIcon: false,
                  showPreviewIcon: true,
                }}
                multiple={true}
                iconRender={(file, listType) => {
                  return (
                    <>
                      {imageTypes.includes(file.content_type) ? (
                        <ImageIcon
                          onClick={() => {
                            if (!checkMobile) {
                              setPreviewVisible(true);
                              handlePreview(file);
                            } else if (checkMobile) {
                              // window.ReactNativeWebView.postMessage(previewUrl);
                              handlePreview(file);
                            }
                          }}
                        >
                          <img src={file.url} alt="previewimage" />
                        </ImageIcon>
                      ) : (
                        <LetterIcon
                          onClick={() => {
                            if (!checkMobile) {
                              setPreviewVisible(true);
                              handlePreview(file);
                            } else if (checkMobile) {
                              // window.ReactNativeWebView.postMessage(previewUrl);
                              handlePreview(file);
                            }
                          }}
                          fileType={
                            {
                              xlsx: "#188038",
                              xls: "#188038",
                              XLS: "#188038",
                              pdf: "#ff0000",
                              docx: "#00a2ed",
                              doc: "#00a2ed",
                              pptx: "#d04423",
                              ppt: "#d04423",
                            }[file.content_type] || "black"
                          }
                        >
                          <div>{file.content_type}</div>
                        </LetterIcon>
                      )}
                      {edit && idCompare === file.id && (
                        <MyInput
                          value={fileName}
                          autoFocus
                          onChange={(e) => setFileName(e.target.value)}
                          onKeyPress={handleEnterKey}
                          onBlur={(e) => {
                            setFileName(e.target.value);
                            isPrivate
                              ? patchMyAttachmentAxios(idCompare, id, {
                                  name: e.target.value,
                                }).then(() => {
                                  getMyAttachmentAxios(id).then((res) => {
                                    const receivingFile = res.data.map(
                                      (filelists) => {
                                        return {
                                          ...filelists,
                                          uid: filelists.id,
                                          url: filelists.link,
                                          name: filelists.name,
                                          type: filelists.content_type,
                                          status: "done",
                                        };
                                      }
                                    );
                                    setData(receivingFile);
                                  });
                                  setUpdate("my_attachment_set");
                                })
                              : patchSharedAttachmentAxios(idCompare, id, {
                                  name: e.target.value,
                                }).then(() => {
                                  getSharedAttachmentAxios(id).then((res) => {
                                    const receivingFile = res.data.map(
                                      (filelists) => {
                                        return {
                                          ...filelists,
                                          uid: filelists.id,
                                          url: filelists.link,
                                          name: filelists.name,
                                          type: filelists.content_type,
                                          status: "done",
                                        };
                                      }
                                    );
                                    setData(receivingFile);
                                  });
                                  dispatch(
                                    sendNotification({
                                      board_id: board_id,
                                      card_id: id,
                                      msg_type: "newAttachment",
                                      team_id: user.team_id,
                                      type: "sendMessage",
                                      user_id: user.id,
                                    })
                                  );
                                });
                            setEdit(!edit);
                          }}
                        ></MyInput>
                      )}

                      <AttachTime>
                        Added {file.local_uploaded_at}
                        {file.uploader_name ? ` by ${file.uploader_name}` : ""}
                      </AttachTime>
                      {!checkMobile && (
                        <>
                          {composeCardID && composeCardID.slice(5) === id && (
                            <SendtoEmail onClick={() => handleEmailLink(file)}>
                              {t("cardView.attachments.emailLink")}
                            </SendtoEmail>
                          )}
                          <EditAttachName
                            onClick={() => handleEditFileName(file)}
                          >
                            {t("cardView.attachments.editAttachName")}
                          </EditAttachName>
                          <CommentLink onClick={() => handleFileInfo(file)}>
                            {t("cardView.attachments.commentLink")}
                          </CommentLink>
                        </>
                      )}
                      <DeleteFile>
                        <Popconfirm
                          title={
                            <RemoveTitle>
                              {t("cardView.attachments.confirmText")}
                            </RemoveTitle>
                          }
                          destroyTooltipOnHide={true}
                          onConfirm={() => handleRemoval(file)}
                          okText={t("cardView.commentPage.commentListItem.yes")}
                          cancelText={t(
                            "cardView.commentPage.commentListItem.no"
                          )}
                          trigger="click"
                        >
                          <div>{t("cardView.attachments.delete")}</div>
                        </Popconfirm>
                      </DeleteFile>
                    </>
                  );
                }}
              >
                <AttachButton>
                  {!checkMobile ? (
                    <>
                      <AttachButtonText>
                        {t("cardView.attachments.attachButtonText")}
                      </AttachButtonText>
                      <AttachButtonText>
                        {t("cardView.attachments.or")}
                      </AttachButtonText>
                      <FileFindButton>
                        {t("cardView.attachments.browseHere")}
                      </FileFindButton>
                    </>
                  ) : (
                    <>
                      <AttachButtonText>+</AttachButtonText>
                      <AttachButtonText isMobile={checkMobile}>
                        {t("cardView.attachments.uploadFile")}
                      </AttachButtonText>
                    </>
                  )}
                </AttachButton>
              </MyUpload>
              {!checkMobile && (
                <PreviewModal
                  setType={setType}
                  type={type}
                  fileUploadTime={fileUploadTime}
                  fileName={fileName}
                  uploaderName={uploaderName}
                  previewUrl={previewUrl}
                  setPreviewVisible={setPreviewVisible}
                  previewVisible={previewVisible}
                  imageTypes={imageTypes}
                />
              )}
            </BodyContainer>
          </ContainerWrap>
        </AttachmentContainer>
      )}
    </OutsideWrapper>
    //   )}
    // </Draggable>
  );
};

export default memo(withTranslation()(CardViewAttachments));

const FileFindButton = styled.div`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-color: ${({ theme }) => theme.colors.color_primary_regular};
  background-color: ${({ theme }) => theme.colors.color_primary_regular};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.color_base_white};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    border-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    color: white !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    border-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    color: white !important;
  }
`;

const RemoveTitle = styled.div`
  white-space: nowrap;
`;

const SharedLabel = styled.div``;
const PrivateLabel = styled.div`
  margin-left: 8px;
`;

// const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)``;

const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)`
  ${(props) => props.isMobile && "border: none;"}
`;

const MyUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
  .ant-upload-list {
    width: 100%;
    .ant-upload-list-item {
      cursor: pointer;
      height: 50px;
      .ant-upload-list-item-name {
        top: 2px;
        left: 55px;
        position: absolute;
        color: ${({ theme }) => theme.colors.color_text_body} !important;
        font-size: ${({ theme }) => theme.fontSizes.txt_smaller} !important;
      }
      .ant-upload-list-item-card-actions {
        padding-left: 40px;
        button {
          opacity: 1;
          padding-top: 27px;
          padding-right: 20px;
          opacity: 0.7;
          right: 60px; // 다운로드 버튼 위치 조정
          bottom: 22px;
          position: absolute;
          span {
            svg {
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }
`;

const ContainerWrap = styled.div`
  height: ${(props) => props.heightVal};
  transition: all 1s ease-out;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  margin-bottom: 0;
`;

const AttachmentContainer = styled(comps.CardViewInnerSectionBodyStyle)`
  height: ${(props) => `${props.height - 172}px`};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.color_base_darker};
    border-radius: 5px;
  }
`;

const ImageIcon = styled.div`
  position: absolute;
  left: 5px;
  top: 4px;
  img {
    width: 43px;
    height: 43px;
    border-radius: 4px;
  }
`;

const MyInput = styled(Input)`
  position: absolute;
  left: 60px;
  border: none;
  background-color: ${({ theme }) =>
    theme.colors.color_base_regular} !important;
  font-size: 14px !important;
  font-weight: 400;
  top: 0px;
  z-index: 10;
  padding: 0;
  max-width: 92%;
`;

const LetterIcon = styled.div`
  border-radius: 4px;
  position: absolute;
  left: 5px;
  top: 4px;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-size: 16px;
    color: white;
    font-weight: 400;
  }
  background-color: ${(props) => props.fileType};
`;

const AttachTime = styled.div`
  position: absolute;
  top: 16px;
  left: 63px;
  right: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  font-size: ${({ theme }) => theme.fontSizes.txt_smallest};
  color: ${({ theme }) => theme.colors.color_text_placeholder};
`;

const SendtoEmail = styled(Link)`
  position: absolute;
  top: 27px;
  right: 355px;
  opacity: 0.7;
`;

const EditAttachName = styled(Link)`
  position: absolute;
  top: 27px;
  right: 262px;
  opacity: 0.7;
`;

const DownLoadText = styled(Link)`
  position: absolute;
  top: 27px;
  right: 18px;
  color: ${({ theme }) => theme.colors.color_base_black};
  font-size: 12px !important;
  @media ${({ theme }) => theme.screenSizes.tablet} {
    right: -11px;
  }
`;

const CommentLink = styled(Link)`
  position: absolute;
  top: 27px;
  right: 150px;
  opacity: 0.7;
`;

const DeleteFile = styled(Link)`
  position: absolute;
  top: 27px;
  right: 30px;
  opacity: 0.7;
  @media ${({ theme }) => theme.screenSizes.tablet} {
    right: 2px;
  }
`;

const TitleBox = styled(comps.CardViewInnerSectionTitleStyle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SharedAttachmentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const LableTextBox = styled.div`
  display: flex;
`;

const LabelGreenText = styled(label_green)`
  margin-right: 8px;
  cursor: pointer;
`;
const LabelGrayText = styled(label_grey)`
  margin-right: 8px;
  cursor: pointer;
`;
const LabelRedText = styled(label_red)`
  margin-right: 8px;
  cursor: pointer;
`;

const AttachButton = styled(SecondaryButton)`
  width: 100%;
  min-height: 110px;
  color: ${({ theme }) => theme.colors.color_text_placeholder} !important;
  ${({ theme }) => theme.fontSizes.txt_h6};
  background-color: ${({ theme }) =>
    theme.colors.color_base_regular} !important;
  border: none !important;
  margin-bottom: 4px;
  padding: 8px 0;
  flex-direction: column;
`;

const AttachButtonText = styled.div`
  margin-bottom: 8px;
  :nth-child(1) {
    ${({ theme }) => theme.fontSizes.txt_h2};
    color: ${({ theme }) => theme.colors.color_text_light};
  }

  :nth-child(2) {
    ${({ theme, isMobile }) =>
      isMobile ? theme.fontSizes.txt_h3 : theme.fontSizes.txt_h6};
    color: ${({ theme, isMobile }) =>
      isMobile
        ? theme.colors.color_text_light
        : theme.colors.color_text_placeholder};
    font-weight: normal;
  }

  :nth-child(3) {
    font-size: ${({ theme }) => theme.fontSizes.txt_standard};
    color: ${({ theme }) => theme.colors.color_text_placeholder};
    text-decoration: underline;
  }
`;
