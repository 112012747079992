/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import { useTeamMetaInfo } from "api-cache";
import { TextArea, Input, Select, Option } from "ui";
import Icons from "assets/Icons";
import { useTranslation, withTranslation } from "react-i18next";

const ContactsInformation = memo(
  ({ detailViewData, handleEditInfo, saveDetail, type }) => {
    const [name, setName] = useState("");
    const [countryName, setCountryName] = useState("");
    const [address, setAddress] = useState("");
    const [zip_code, setZip_code] = useState("");
    const [telephone, setTelephone] = useState("");
    const [fax, setFax] = useState("");
    const [tax_number, setTax_number] = useState("");
    const [website, setWebsite] = useState("");
    const [description, setDescription] = useState("");
    const [bank_name, setBank_name] = useState("");
    const [swift_code, setSwift_code] = useState("");
    const [bank_address, setBank_address] = useState("");
    const [account_number, setAccount_number] = useState(
      detailViewData.account_number
    );
    const [account_name, setAccount_name] = useState(
      detailViewData.account_name
    );
    const [consignee, setConsignee] = useState(detailViewData.consignee);
    const [notify, setNotify] = useState(detailViewData.notify);
    const [edit, setEdit] = useState("");

    const { t } = useTranslation();

    const onChangeEditInfo = (countryId) => {
      handleEditInfo({
        name: name,
        country: parseInt(countryId),
        address: address,
        zip_code: zip_code,
        telephone: telephone,
        fax: fax,
        tax_number: tax_number,
        website: website,
        description: description,
        bank_name: bank_name,
        swift_code: swift_code,
        bank_address: bank_address,
        account_number: account_number,
        consignee: consignee,
        notify: notify,
      });
      setEdit("");
    };

    useEffect(() => {
      setName(detailViewData.name === null ? "" : detailViewData.name);
      // setCountry(detailViewData.country === null ? "" : detailViewData.country);
      setCountryName(
        detailViewData.country_name === null ? "" : detailViewData.country_name
      );
      setAddress(detailViewData.address === null ? "" : detailViewData.address);
      setZip_code(
        detailViewData.zip_code === null ? "" : detailViewData.zip_code
      );
      setTelephone(
        detailViewData.telephone === null ? "" : detailViewData.telephone
      );
      setFax(detailViewData.fax === null ? "" : detailViewData.fax);
      setTax_number(
        detailViewData.tax_number === null ? "" : detailViewData.tax_number
      );
      setWebsite(detailViewData.website === null ? "" : detailViewData.website);
      setDescription(
        detailViewData.description === null ? "" : detailViewData.description
      );
      setBank_name(
        detailViewData.bank_name === null ? "" : detailViewData.bank_name
      );
      setSwift_code(
        detailViewData.swift_code === null ? "" : detailViewData.swift_code
      );
      setBank_address(
        detailViewData.bank_address === null ? "" : detailViewData.bank_address
      );
      setAccount_number(
        detailViewData.account_number === null
          ? ""
          : detailViewData.account_number
      );
      setAccount_name(
        detailViewData.account_name === null ? "" : detailViewData.account_name
      );
      setConsignee(
        detailViewData.consignee === null ? "" : detailViewData.consignee
      );
      setNotify(detailViewData.notify === null ? "" : detailViewData.notify);
    }, [detailViewData]);

    const {
      data: { all_countries: allCountries },
    } = useTeamMetaInfo();
    const countries = allCountries.map((country, i) => (
      <Option key={country.id} value={country.name}>
        <div
          onClick={() => {
            setCountryName(country.name);
            // setCountry(country.id);
          }}
        >
          {country.name}
        </div>
      </Option>
    ));

    return (
      <InformationComponent>
        {/*  GeneralInformation */}
        <GeneralInformation>
          <TitleBox>
            <SubTitle>
              {t(
                "contacts.contactsDetailView.contactsInformation.generalInformation"
              )}
            </SubTitle>
          </TitleBox>
          <Container>
            {/* columns 1 */}
            <ContainerColumn>
              <ContainerItem>
                <Required>
                  {t("contacts.contactsDetailView.contactsInformation.name")}
                </Required>
                {edit === "name" ? (
                  <ContainerInput
                    name="name"
                    // placeholder="Enter the company name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    onPressEnter={(e) => {
                      e.target.blur();
                    }}
                    onBlur={onChangeEditInfo}
                    autoFocus
                  ></ContainerInput>
                ) : (
                  <EditTextBox>
                    <ContainerText onClick={() => setEdit("name")}>
                      {name}
                    </ContainerText>
                    <EditIcon className="hidden">
                      <Icons type="fas" name="edit" />
                    </EditIcon>
                  </EditTextBox>
                )}
              </ContainerItem>
            </ContainerColumn>
            <ContainerColumn>
              <ContainerItem>
                <ContainerBoldText>
                  {t("contacts.contactsDetailView.contactsInformation.country")}
                </ContainerBoldText>
                {edit === "country" ? (
                  <ContainerSelect
                    // placeholder="Enter the company name"
                    value={countryName}
                    showSearch
                    defaultOpen={true}
                    autoFocus={true}
                    onSelect={(_, option) => {
                      setCountryName(option.value);
                      // setCountry(option.key);
                      onChangeEditInfo(option.key);
                    }}
                  >
                    {countries}
                  </ContainerSelect>
                ) : (
                  <EditTextBox>
                    <ContainerText onClick={() => setEdit("country")}>
                      {countryName}
                    </ContainerText>
                    <EditIcon className="hidden">
                      <Icons type="fas" name="edit" />
                    </EditIcon>
                  </EditTextBox>
                )}
              </ContainerItem>
            </ContainerColumn>
            {/* columns 2 */}
            <ContainerColumn>
              {/* <ContainerItem> */}
              <ColumnBoldText>
                {t("contacts.contactsDetailView.contactsInformation.address")}
              </ColumnBoldText>
              {edit === "address" ? (
                <ColumnInput
                  name="address"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  onPressEnter={(e) => {
                    e.target.blur();
                  }}
                  onBlur={onChangeEditInfo}
                  autoFocus
                ></ColumnInput>
              ) : (
                <EditTextBox>
                  <ContainerText onClick={() => setEdit("address")}>
                    {address}
                  </ContainerText>
                  <EditIcon className="hidden">
                    <Icons type="fas" name="edit" />
                  </EditIcon>
                </EditTextBox>
              )}
            </ContainerColumn>
            {/* columns 3 */}
            <ContainerColumn>
              <ContainerItem>
                <ContainerBoldText>
                  {t("contacts.contactsDetailView.contactsInformation.zip")}
                </ContainerBoldText>
                {edit === "zip_code" ? (
                  <ContainerInput
                    name="zip_code"
                    onChange={(e) => setZip_code(e.target.value)}
                    value={zip_code}
                    onPressEnter={(e) => {
                      e.target.blur();
                    }}
                    onBlur={onChangeEditInfo}
                    autoFocus
                  ></ContainerInput>
                ) : (
                  <EditTextBox>
                    <ContainerText onClick={() => setEdit("zip_code")}>
                      {zip_code}
                    </ContainerText>
                    <EditIcon className="hidden">
                      <Icons type="fas" name="edit" />
                    </EditIcon>
                  </EditTextBox>
                )}
              </ContainerItem>
            </ContainerColumn>
            <ContainerColumn>
              <ContainerItem>
                <ContainerBoldText>
                  {t("contacts.contactsDetailView.contactsInformation.phone")}
                </ContainerBoldText>
                {edit === "telephone" ? (
                  <ContainerInput
                    name="telephone"
                    onChange={(e) => setTelephone(e.target.value)}
                    value={telephone}
                    onPressEnter={(e) => {
                      e.target.blur();
                    }}
                    onBlur={onChangeEditInfo}
                    autoFocus
                  ></ContainerInput>
                ) : (
                  <EditTextBox>
                    <ContainerText onClick={() => setEdit("telephone")}>
                      {telephone}
                    </ContainerText>
                    <EditIcon className="hidden">
                      <Icons type="fas" name="edit" />
                    </EditIcon>
                  </EditTextBox>
                )}
              </ContainerItem>
            </ContainerColumn>

            {/* columns 5 */}
            <ContainerColumn>
              <ColumnBoldText>
                {t(
                  "contacts.contactsDetailView.contactsInformation.description"
                )}
              </ColumnBoldText>
              {edit === "description" ? (
                <ColumnInput
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  onPressEnter={(e) => {
                    e.target.blur();
                  }}
                  onBlur={onChangeEditInfo}
                  autoFocus
                ></ColumnInput>
              ) : (
                <EditTextBox>
                  <ContainerText onClick={() => setEdit("description")}>
                    {description}
                  </ContainerText>
                  <EditIcon className="hidden">
                    <Icons type="fas" name="edit" />
                  </EditIcon>
                </EditTextBox>
              )}
            </ContainerColumn>
          </Container>
        </GeneralInformation>

        {/* BankInformation */}
        {type === "supplier" ? (
          <BankInformation>
            <SubTitle>
              {t(
                "contacts.contactsDetailView.contactsInformation.bankInformation"
              )}
            </SubTitle>
            <Container>
              {/* columns 1 */}
              <ContainerColumn>
                <ContainerItem>
                  <ContainerBoldText>
                    {t(
                      "contacts.contactsDetailView.contactsInformation.backName"
                    )}
                  </ContainerBoldText>
                  {edit === "bank_name" ? (
                    <ContainerInput
                      name="bank_name"
                      onChange={(e) => setBank_name(e.target.value)}
                      value={bank_name}
                      onPressEnter={(e) => {
                        e.target.blur();
                      }}
                      onBlur={onChangeEditInfo}
                      autoFocus
                    ></ContainerInput>
                  ) : (
                    <EditTextBox>
                      <ContainerText onClick={() => setEdit("bank_name")}>
                        {bank_name}
                      </ContainerText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                  )}
                </ContainerItem>
              </ContainerColumn>
              <ContainerColumn>
                <ContainerItem>
                  <ContainerBoldText>
                    {t(
                      "contacts.contactsDetailView.contactsInformation.swiftCode"
                    )}
                  </ContainerBoldText>
                  {edit === "swift_code" ? (
                    <ContainerInput
                      name="swift_code"
                      onChange={(e) => setSwift_code(e.target.value)}
                      value={swift_code}
                      onPressEnter={(e) => {
                        e.target.blur();
                      }}
                      onBlur={onChangeEditInfo}
                      autoFocus
                    ></ContainerInput>
                  ) : (
                    <EditTextBox>
                      <ContainerText onClick={() => setEdit("swift_code")}>
                        {swift_code}
                      </ContainerText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                  )}
                </ContainerItem>
              </ContainerColumn>
              {/* columns 2 */}
              <ContainerColumn>
                {/* <ContainerItem> */}
                <ColumnBoldText>
                  {t(
                    "contacts.contactsDetailView.contactsInformation.bankAddress"
                  )}
                </ColumnBoldText>
                {edit === "bank_address" ? (
                  <ColumnInput
                    name="bank_address"
                    onChange={(e) => setBank_address(e.target.value)}
                    value={bank_address}
                    onPressEnter={(e) => {
                      e.target.blur();
                    }}
                    onBlur={onChangeEditInfo}
                    autoFocus
                  ></ColumnInput>
                ) : (
                  <EditTextBox>
                    <ContainerText onClick={() => setEdit("bank_address")}>
                      {bank_address}
                    </ContainerText>
                    <EditIcon className="hidden">
                      <Icons type="fas" name="edit" />
                    </EditIcon>
                  </EditTextBox>
                )}
              </ContainerColumn>
              {/* columns 3 */}
              <ContainerColumn>
                <ContainerItem>
                  <ContainerBoldText>
                    {t(
                      "contacts.contactsDetailView.contactsInformation.accountNumber"
                    )}
                  </ContainerBoldText>
                  {edit === "account_number" ? (
                    <ContainerInput
                      name="account_number"
                      onChange={(e) => setAccount_number(e.target.value)}
                      value={account_number}
                      onPressEnter={(e) => {
                        e.target.blur();
                      }}
                      onBlur={onChangeEditInfo}
                      autoFocus
                    ></ContainerInput>
                  ) : (
                    <EditTextBox>
                      <ContainerText onClick={() => setEdit("account_number")}>
                        {account_number}
                      </ContainerText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                  )}
                </ContainerItem>
              </ContainerColumn>
              <ContainerColumn>
                <ContainerItem>
                  <ContainerBoldText>
                    {t(
                      "contacts.contactsDetailView.contactsInformation.accountName"
                    )}
                  </ContainerBoldText>
                  {edit === "account_name" ? (
                    <ContainerInput
                      name="account_name"
                      onChange={(e) => setAccount_name(e.target.value)}
                      value={account_name}
                      onPressEnter={(e) => {
                        e.target.blur();
                      }}
                      onBlur={onChangeEditInfo}
                      autoFocus
                    ></ContainerInput>
                  ) : (
                    <EditTextBox>
                      <ContainerText onClick={() => setEdit("account_name")}>
                        {account_name}
                      </ContainerText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                  )}
                </ContainerItem>
              </ContainerColumn>
            </Container>
          </BankInformation>
        ) : null}
        {/*  ReferenceInformation */}
        <ReferenceInformation>
          <SubTitle>
            {t(
              "contacts.contactsDetailView.contactsInformation.referenceInformation"
            )}
          </SubTitle>
          <Container>
            <ContainerColumn>
              <TextAreaItem>
                <TextAreaBoldText>
                  {t(
                    "contacts.contactsDetailView.contactsInformation.consignee"
                  )}
                </TextAreaBoldText>
                <ContainerTextArea
                  name="consignee"
                  rows={4}
                  edit={"consignee"}
                  onChange={(e) => setConsignee(e.target.value)}
                  value={consignee}
                  // disabled={edit === "consignee" ? true : false}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  onPressEnter={(e) => {
                    // e.target.blur();
                  }}
                  onBlur={onChangeEditInfo}
                ></ContainerTextArea>
              </TextAreaItem>
            </ContainerColumn>
            <ContainerColumn>
              <TextAreaItem>
                <TextAreaBoldText>
                  {t("contacts.contactsDetailView.contactsInformation.notify")}
                </TextAreaBoldText>
                <ContainerTextArea
                  name="notify"
                  rows={4}
                  edit={edit.toString()}
                  onChange={(e) => setNotify(e.target.value)}
                  value={notify}
                  // disabled={!edit}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  onPressEnter={(e) => {
                    // e.target.blur();
                  }}
                  onBlur={onChangeEditInfo}
                ></ContainerTextArea>
              </TextAreaItem>
            </ContainerColumn>
          </Container>
        </ReferenceInformation>
      </InformationComponent>
    );
  }
);

export default withTranslation()(ContactsInformation);

const InformationComponent = styled.section`
  width: 100%;
  padding: 12px;
`;

const GeneralInformation = styled.section`
  width: 100%;
`;

const BankInformation = styled.section`
  width: 100%;
  margin-top: 40px;
`;

const ReferenceInformation = styled.section`
  width: 100%;
  margin-top: 40px;
`;

const TitleBox = styled.div`
  background-color: ${({ edit }) => (edit === true ? null : "#fff")};
  color: ${({ edit }) => (edit === true ? "#fff" : null)};
  /* align-items: center;

  :hover {
    cursor: pointer;
  }  */
`;

const SubTitle = styled.div`
  display: inline-block;
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const Container = styled.div`
  width: 100%;
`;

const ContainerColumn = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  /* margin: 25px 0; */
`;

const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const EditIcon = styled.div`
  display: none;
  padding-bottom: 4px;
`;

const EditTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  &:hover .hidden {
    display: inline-block;
  }
`;

const ContainerText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_text_body};
  margin-bottom: 0px;
  width: 100%;
  height: 32px;
  letter-spacing: 0.4px;
  padding: 5px 0;
  cursor: pointer;

  :nth-child(2) {
    padding: 5px 11px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
`;

const ContainerBoldText = styled.div`
  width: 110px;
  padding: 4px 11px;
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const ColumnBoldText = styled(ContainerBoldText)``;

const Required = styled(ContainerBoldText)`
  &:after {
    content: " *";
    color: red;
  }
`;

// const LinkText = styled.a`
//   padding: 6px 11px 0px;
//   width: 65%;
//   letter-spacing: 0.4px;
//   color: #3498db;
//   text-decoration: underline;
// `;

const TextAreaItem = styled(ContainerItem)`
  flex-direction: column;
`;

const TextAreaBoldText = styled.div`
  width: 100%;
  padding: 4px 11px;
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const ContainerTextArea = styled(TextArea)`
  width: 100%;
  /* border: ${({ edit }) =>
    edit === "true" ? "1px solid #5f6367 !important" : null}; */
  
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display:none;
  } 

  cursor: auto !important;
`;

const ContainerInput = styled(Input)`
  width: 100%;
  padding: 5px 0;
  margin-bottom: -1px;
`;

const ColumnInput = styled(Input)`
  /* width: 85%; */
  padding: 5px 0;
  margin-bottom: -1px;
`;

const ContainerSelect = styled(Select)`
  width: 100%;
`;
