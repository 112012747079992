import { emailAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailThreadsEdit = () => {
  async function editThreads(editData) {
    console.log(editData);
    const { data } = await emailAPIs.editThreadsAxios(editData);
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(editThreads, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/");
      queryClient.invalidateQueries("get/email/threads/");
    },
  });
};
