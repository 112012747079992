import { AxiosPromise } from "axios";
import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Select, Option } from "ui";
import { Divider } from "antd";
import * as api from "common/ApiAxios";
import { PlusOutlined } from "@ant-design/icons";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
type SetState<S> = Dispatch<SetStateAction<S>>;
interface Props {
  placeholder1: string;
  placeholder2: string;
  selectAxios?: (id?: string, data?: object) => AxiosPromise;
  id?: string;
  itemList: Array<any>;
  width?: string;
  saga: Function;
  defaultValue: string;
  keyValue: string;
  autoFocus?: boolean;
  defaultOpen?: boolean;
  onBlur?: Function;
  setter?: Function;
  idsetter?: Function;
  onChange?: Function;
}
interface AddNewProps {
  addNewCount?: number;
}

export const SelectAddNew: React.FC<Props> = ({
  placeholder1,
  placeholder2,
  selectAxios,
  id,
  itemList,
  width,
  saga,
  defaultValue,
  keyValue,
  onBlur,
  setter,
  idsetter,
  defaultOpen,
  autoFocus,
  onChange,
}) => {
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const [newValue, setNewValue] = useState<string | undefined>(undefined);
  const [addNewCount, setAddNewCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const ac = new AbortController();
    setValue(defaultValue);
    return () => ac.abort(); // Abort both fetches on unmount
  }, [defaultValue]);

  const items = itemList.map((list, i) => (
    <Option key={list.id} value={`${list.name}${list.id}`} val={list.name}>
      <div
      // onClick={() => {
      //   if (selectAxios !== undefined) {
      //     selectAxios(id, { [keyValue + "_id"]: list.id }).then(() => {
      //       idsetter && idsetter(list.id);
      //       setter && setter(list.name);
      //     });
      //   } else {
      //     idsetter && idsetter(list.id);
      //     setter && setter(list.name);
      //   }
      //   setValue(list.name);
      // }}
      >
        {list.name}
      </div>
    </Option>
  ));

  const addNewValue = () => {
    if (newValue !== undefined && newValue !== "") {
      onChange && onChange(newValue);
      api
        .createContactTypeAxios(keyValue, { name: newValue })
        .then(async function (res) {
          await dispatch(saga());
          if (selectAxios !== undefined) {
            selectAxios &&
              selectAxios(id, { [keyValue + "_id"]: res.data.id }).then(() => {
                onBlur && onBlur();
                setter && setter(newValue);
                idsetter && idsetter(res.data.id);
              });
          } else {
            setter && (await setter(newValue));
            idsetter && (await idsetter(res.data.id));
            onBlur && (await onBlur());
          }
          // selectAxios && selectAxios(id, { [keyValue + "_id"]: res.data.id });
          await setValue(newValue);
          // setter && (await setter(newValue));
          // idsetter && (await idsetter(res.data.id));
          // onChange && (await onChange(newValue));
          // onBlur && (await onBlur());
        });
    }
    setNewValue(undefined);
  };

  const handleEnter = (e) => {
    console.log(e.key, addNewCount);
    if (e.keyCode !== 13 && addNewCount !== 5) {
      setAddNewCount(0);
    }
    if (e.key === "ArrowDown") {
      setAddNewCount(5);
    }
    if (e.keyCode === 13 && addNewCount === 0) {
      e.preventDefault();
      e.stopPropagation();
      console.log("here");
      setAddNewCount(1);
    }
    if (e.keyCode === 13 && addNewCount === 1) {
      e.preventDefault();
      e.stopPropagation();
      addNewValue();
      setAddNewCount(0);
    }
  };
  const handleBlur = () => {
    setAddNewCount(0);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        onBlur && onBlur();
      }}
    >
      <SAddNew
        autoFocus={autoFocus && autoFocus}
        defaultOpen={defaultOpen && defaultOpen}
        style={{
          width: width ? width : "100%",
          height: "30px",
          textAlign: "left",
          color: "black",
          display: "flex",
          alignItems: "center",
        }}
        placeholder={placeholder1}
        showSearch
        filterOption={(input, option) =>
          option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        // onBlur={async () => {
        //   // onChange && (await onChange());
        //   onBlur && (await onBlur());
        // }}
        // onDropdownVisibleChange={(bool) => {
        //   onBlur && !bool && onBlur();
        // }}
        // onChange={onChange && onChange()}
        onBlur={handleBlur}
        onInputKeyDown={handleEnter}
        onSearch={(val) => setNewValue(val)}
        defaultActiveFirstOption={false}
        onSelect={async (value, option) => {
          setValue(option.val);
          if (selectAxios !== undefined) {
            selectAxios &&
              (option.key === "null"
                ? selectAxios(id, { [keyValue + "_id"]: null }).then(() => {
                    setter && setter(null);
                    idsetter && idsetter(null);
                    onChange && onChange(null);
                    onBlur && onBlur();
                  })
                : selectAxios(id, { [keyValue + "_id"]: option.key }).then(
                    (res) => {
                      setter && setter(option.val);
                      idsetter && idsetter(option.key);
                      onChange && onChange(res.data);
                      onBlur && onBlur();
                    }
                  ));
          } else {
            setter && setter(option.key === "null" ? null : option.val);
            idsetter && idsetter(option.key === "null" ? null : option.key);
            onBlur && onBlur();
          }
          // selectAxios &&
          //   (option.key === "null"
          //     ? selectAxios(id, { [keyValue + "_id"]: null })
          //     : selectAxios(id, { [keyValue + "_id"]: option.key }));
          // setValue(value.toString());
        }}
        value={value}
        // notFoundContent={<div>Not Found</div>}
        dropdownRender={(menu) => {
          if (menu.props.options?.length === 0 && addNewCount === 5) {
            setAddNewCount(0);
            console.log("here");
          }
          return (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <AddNew addNewCount={addNewCount} onClick={addNewValue}>
                <PlusOutlined />
                <Info>
                  Add "{newValue}" as new {keyValue}
                </Info>
                {/*  <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  alignItems: "center",
                }}
              >
                 <Input
                style={{
                  marginRight: 5,
                  marginLeft: 5,
                  width: "93%",
                }}
                value={newValue}
                onChange={(e) => {
                  setNewValue(e.target.value);
                }}
                onPressEnter={async () => {
                  addNewValue();
                }}
                // onKeyDown={}
                // onBlur={() => setNewValue("")}
                placeholder={placeholder2}
              /> */}
                {/* <PrimaryButton
                style={{
                  border: "none",
                  outline: "none",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  lineHeight: 25,
                  justifyContent: "center",
                  padding: 0,
                  height: 25,
                  width: 25,
                  borderRadius: 100,
                }}
              >
                <PlusOutlined />
              </PrimaryButton> */}
                {/* </div> */}
              </AddNew>
            </div>
          );
        }}
      >
        <Option key={"null"} value={""}>
          <div
            onClick={() => {
              selectAxios && selectAxios(id, { [keyValue + "_id"]: null });
              setValue("");
              idsetter && idsetter(null);
              setter && setter(null);
            }}
          >
            {""}
          </div>
        </Option>
        {items}
      </SAddNew>
    </ClickAwayListener>
  );
};

const AddNew = styled.div<AddNewProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: all;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.color_state_notification_dark};
  cursor: pointer;
  background-color: ${({ theme, addNewCount }) =>
    addNewCount === 1 && theme.colors.color_base_dark};
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
  }
`;

const Info = styled.div`
  padding-left: 10px;
  line-height: 25px;
  word-break: break-all;
`;

const SAddNew = styled(Select)`
  padding-left: 2px;
  .ant-select-selector {
    padding: 0 !important;
    /* padding-left: 10px !important; */
  }
  .ant-select-selection-search-input {
    /* padding-left: 10px !important; */
  }

  .ant-select-selection-search {
    left: 0 !important;
  }

  .ant-select-selection-item {
    div {
      margin: 0;
    }
  }
`;
