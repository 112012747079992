import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
export const useEmailThreadDetail = () => {
  let history = useHistory();
  async function getEmailThreadDetail() {
    const { data } = await emailThreadAPIs.getThreadDetailAxios(
      window.location.hash.split("::")[2]
    );
    console.log("", data);
    return data;
  }
  return useQuery({
    enabled:
      history.location.hash.length > 0 &&
      history.location.hash.includes("detail::"),
    queryKey: ["get/email/threads/detail/", history.location.hash],
    queryFn: getEmailThreadDetail,
    placeholderData: { messageSnippets: [], thread: {}, loading: true },
  });
};
