const axios = require("axios").default;
export { axios };
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
axios.defaults.headers.post["Content-Type"] = "application/json";
export const emptyAxios = axios;
export const authAxios = axios;
export const authAxios2 = axios;
authAxios2.defaults.headers.post["Content-Type"] = "multipart/form-data";
export const authAxiosDownload = require("axios").default;
// authAxiosDownload.defaults.responseType = "arraybuffer";

// export const emptyAxios = axios.create({
//   baseURL: apiUrl,
//   headers: {
//     "Content-Type": "application/json",
//     // crossdomain: true,
//   },
// });

// export const authAxios = axios.create({
//   baseURL: apiUrl,
//   headers: {
//     Authorization: `Bearer ${accessToken}`,
//     "Content-Type": "application/json",
//     // crossdomain: true,
//   },
// });

// export const authAxios2 = axios.create({
//   baseURL: apiUrl,
//   headers: {
//     Authorization: `Bearer ${accessToken}`,
//     "Content-Type": "multipart/form-data",
//     // crossdomain: true,
//   },
// });

//Lists

export const listsAPI = {
  getCardListAxios(id) {
    return authAxios.get(`/cardlist/?board_id=${id}&fields=id,name`);
  },
  boardInfoAxios(id) {
    return authAxios.get(`/cardlist/?board_id=${id}`);
  },
  createCardListAxios(info) {
    authAxios.post("/cardlist/", info);
  },
  removeCardListAxios(id) {
    authAxios.delete(`/cardlist/${id}/`);
  },
  changeCardListAxios(id, data) {
    authAxios.put(`/cardlist/${id}/`, data);
  },
};

export const boardInfoAxios = (id) =>
  authAxios.get(`/cardlist/?board_id=${id}`);

export const createCardListAxios = (info) => authAxios.post("/cardlist/", info);

export const removeCardListAxios = (id) => authAxios.delete(`/cardlist/${id}/`);

export const changeCardListAxios = (id, data) =>
  authAxios.put(`/cardlist/${id}/`, data);

//TableView

export const getAllBoardTableColumnsAxios = () =>
  authAxios.get("/board_table_columns/all_columns/?board_status=active");

export const getBoardTableColumnsAxios = () =>
  authAxios.get("/user/settings/?field=columns&board_status=active");

export const editBoardTableColumnsAxios = (data) =>
  authAxios.patch("/user/settings/?field=columns&board_status=active", data);

//ArchivedView

export const getAllArchivedColumnsAxios = () =>
  authAxios.get("/board_table_columns/all_columns/?board_status=archive");

export const getArchivedColumnsAxios = () =>
  authAxios.get("/board_table_columns/?board_status=archive");

export const editArchivedColumnsAxios = (data) =>
  authAxios.patch("/board_table_columns/?board_status=archive", data);

//Cards

export const createCardAxios = (info) => authAxios.post("/card/", info);
export const removeCardAxios = (id) => authAxios.delete(`/card/${id}/`);
export const tableViewAxios = (id) => authAxios.get(`/card/board_table/${id}/`);
export const getArchivedViewAxios = (id) =>
  authAxios.get(`/card/board_archived/${id}/`);

// Cards / Multi Edit
export const archiveCardsAxios = (list) =>
  authAxios.post("/cards/archive/", list);
export const cardsOwnerUpdateAxios = (data) =>
  authAxios.post("/cards/owner/update/", data);
export const cardsMembersUpdateAxios = (data) =>
  authAxios.post("/cards/members/update/", data);
export const updateCardProductAxios = (data) =>
  authAxios.patch("/cards/product/update/", data);

//Boards

export const getAccountBoardsAxios = () =>
  authAxios.get("/board/?fields=access_granted,has_access,id,name");

export const createBoardAxios = (name) => authAxios.post("/board/", name);

export const deleteBoardAxios = (id) => authAxios.delete(`/board/${id}/`);

export const editBoardAxios = (id, data) =>
  authAxios.patch(`/board/${id}/`, data);

export const getNavBoardsAxios = () =>
  authAxios.get("/board/?fields=id,name,has_access");

// export const changeBoardListAxios = (id, data) =>
//   authAxios.put(`/board/detail/${id}/`, data);

export const getBoardDetailAxios = (id) => authAxios.get(`/board/${id}`);

export const getBoardAccessAxios = (id, query) =>
  authAxios.get(`/board/access/${id}/${query ? query : ""}`);

export const patchBoardAccessAxios = (id, data) =>
  authAxios.patch(`/board/access/${id}/`, data);

//Team Info

export const getTeamMetaInfoAxios = () =>
  authAxios.get("/user/team/meta_info/");

export const getTeamContactsInfoAxios = () =>
  authAxios.get("/user/team/contact_info/");

// Settings - My Account
export const getMyProfileAxios = () => authAxios.get("/user/profile/");

export const putMyProfileAxios = (data) =>
  authAxios.put("/user/profile/", data);

export const putMyProfileImageAxios = (data) =>
  authAxios2.put("/user/profile/", data);

export const postChangePasswordAxios = (data) =>
  authAxios.post("/user/password_change/", data);

// Wrapper - Nav - Tutorial progress update
export const patchTutorialProgress = (data) =>
  authAxios.patch("/user/profile/", data);

// Settings - Company Profile

export const getMyCompanyAxios = () =>
  authAxios.get(
    "/team/profile/?fields=created_at,company_name,logo_image,address,contact,email,company_type,company_size"
  );

export const putMyCompanyAxios = (data) =>
  authAxios.put("/team/profile/", data);

export const putMyCompanyImageAxios = (data) =>
  authAxios2.put("/team/profile/", data);

// Settings - Email
export const getSettingEmailAxios = () => authAxios.get("/email/account/");
export const putSettingEmailAxios = (data) =>
  authAxios.put("/email/account/", data);

export const deleteSettingEmailAxios = () =>
  authAxios.delete("/email/account/");

// Settings - Subscription/Payment

export const getSubscriptionAxios = () => authAxios.get("/subscription/");

export const getPromoInfoAxios = () =>
  authAxios.get("/subscription/?fields=local_on_promotion_until,promotion_set");

export const cancelSubscriptionAxios = () =>
  authAxios.patch("/subscription/", { is_active: false });

export const updateSubscriptionAxios = (data) =>
  authAxios.patch("/subscription/", data);

export const startSubscriptionAxios = (data) =>
  authAxios.post("/subscription/", data);

export const getBillingListAxios = () => authAxios.get("/billing/");

export const getBillInfoAxios = (id) => authAxios.get(`/billing/${id}/`);

export const addPromotionCodeAxios = (code) =>
  authAxios.post("/promotion/", code);

//Contacts Page

export const getAllTeamMembersAxios = () =>
  authAxios.get("/team/profile/?fields=members");
export const getOwnedContactsAxios = (type) =>
  authAxios.get(`/contacts/owned/?account_type=${type}`);
export const deleteMultipleContactsAxios = (type, ids) =>
  authAxios.post("/contacts/delete/", { account_type: type, account_ids: ids });
export const removeMultipleContactsMembersAxios = (type, ids, user_ids) =>
  authAxios.post("/contacts/members/update/", {
    account_type: type,
    account_ids: ids,
    user_remove_ids: user_ids,
  });
export const addMultipleContactsMembersAxios = (type, ids, user_ids) =>
  authAxios.post("/contacts/members/update/", {
    account_type: type,
    account_ids: ids,
    user_add_ids: user_ids,
  });
export const changeMultipleContactsOwnerAxios = (type, ids, user_id, bool) =>
  authAxios.post("/contacts/owner/update/", {
    account_type: type,
    account_ids: ids,
    new_owner_id: user_id,
    keep_access: bool,
  });

// Contacts Pagination
export const getContactsByPageAxios = (type, pageSize, targetNum) =>
  authAxios.get(`/${type}/list/?limit=${pageSize}&offset=${targetNum}`);
export const getContactsByPageSearchAxios = (
  type,
  string,
  pageSize,
  targetNum
) =>
  authAxios.get(
    `/${type}/list/?search=${string}&limit=${pageSize}&offset=${targetNum}`
  );

export const getContactTypeAxios = (type, string) =>
  authAxios.get(`/${type}/list/${string}`);
export const createContactTypeAxios = (type, data) =>
  authAxios.post(`/${type}/`, data);
export const getContactTypeDetailAxios = (type, id) =>
  authAxios.get(`/${type}/${id}/`);
export const updateContactTypeDetailAxios = (type, id, data) =>
  authAxios.put(`/${type}/${id}/`, data);
export const deleteContactTypeDetailAxios = (type, id) =>
  authAxios.delete(`/${type}/${id}/`);

export const createContactAxios = (data) => authAxios.post("/contact/", data);
export const createBulkContactAxios = (data) =>
  authAxios.post("/contacts/create/bulk/", data);
export const getContactAxios = (type, typeId) =>
  authAxios.get(
    `/contact/?account_content_type=${type}&account_object_id=${typeId}`
  );
export const updateContactAxios = (id, type, typeId, data) =>
  authAxios.put(
    `/contact/${id}/?account_content_type=${type}&account_object_id=${typeId}`,
    data
  );
export const deleteContactAxios = (id, type, typeId) =>
  authAxios.delete(
    `/contact/${id}/?account_content_type=${type}&account_object_id=${typeId}`
  );
export const deleteContactsAxios = (data) =>
  authAxios.post(`/contacts/multiple/`, data);

export const createMeetingNote = (data) =>
  authAxios.post("/meeting_note/", data);

export const getMeetingNote = (type, typeId) =>
  authAxios.get(
    `/meeting_note/?account_content_type=${type}&account_object_id=${typeId}`
  );

export const getDetailMeetingNote = (id, type, typeId) =>
  authAxios.get(
    `/meeting_note/${id}/?account_content_type=${type}&account_object_id=${typeId}`
  );

export const updateMeetingNote = (id, type, typeId, data) =>
  authAxios.put(
    `/meeting_note/${id}/?account_content_type=${type}&account_object_id=${typeId}`,
    data
  );

export const deleteMeetingNote = (id, type, typeId) =>
  authAxios.delete(
    `/meeting_note/${id}/?account_content_type=${type}&account_object_id=${typeId}`
  );

export const createContactAttachmentAxios = (data) =>
  authAxios.post(`/account_attachment/`, data);

// export const createContactAttachmentAxios = (type, typeId, data) =>
//   authAxios.post(
//     `/account_attachment/?account_content_type=${type}&account_object_id=${typeId}`,
//     data
//   );

export const getContactAttachmentAxios = (type, typeId) =>
  authAxios.get(
    `/account_attachment/?account_content_type=${type}&account_object_id=${typeId}`
  );

export const getDetailAttachmentAxios = (id, type, typeId) =>
  authAxios.get(
    `/account_attachment/${id}/?account_content_type=${type}&account_object_id=${typeId}`
  );

export const updateContactAttachmentAxios = (id, type, typeId, data) =>
  authAxios.put(
    `/account_attachment/${id}/?account_content_type=${type}&account_object_id=${typeId}`,
    data
  );

export const deleteContactAttachmentAxios = (id, type, typeId) =>
  authAxios.delete(
    `/account_attachment/${id}/?account_content_type=${type}&account_object_id=${typeId}`
  );

// DealCardDetailView 데이터통신 부분 시작
export const getCardViewInfoAxios = (id) => authAxios.get(`/card/${id}/`);

export const getCardViewPartialInfoAxios = (id, string) =>
  authAxios.get(`/card/${id}/${string}`);

export const getDealDescriptionAxios = (id, data) =>
  authAxios.get(`/card/${id}/`, data);

export const getCardViewNotificationAxios = (id) =>
  authAxios.get(`/card_notification/?card_id=${id}`);

// Archived Card Archived, Unarchived 버튼 동작
export const putCardViewAxios = (id, data) =>
  authAxios.put(`/card/${id}/`, data);

// MyNote 텍스트 전송

export const postMyNoteAxios = (data) => authAxios.post(`/mynote/`, data);

export const putMyNoteAxios = (id, data) =>
  authAxios.put(`/mynote/${id}/`, data);

// 멤버 권한
export const getCardAccessAxios = (id) => authAxios.get(`/card/access/${id}/`);

export const putCardAccessAxios = (id, data) =>
  authAxios.put(`/card/access/${id}/`, data);

export const putOfferResultAxios = (id, data) =>
  authAxios.put(`/card/${id}/`, data);

export const patchDealDescriptionAxios = (id, value) =>
  authAxios.patch(`/card/${id}/`, value);

// export const deleteCardAxios = (id) => authAxios.delete(`/card/${id}/`);
export const putAddArchivedCardAxios = (id, data) =>
  authAxios.put(`/card/${id}/`, data);

// DealCardDetailView -> Shared Attachment
export const getSharedAttachmentAxios = (id) =>
  authAxios.get(`/attachment/?card_id=${id}`);

export const postSharedAttachmentAxios = (data) =>
  authAxios2.post(`/attachment/`, data);

export const patchSharedAttachmentAxios = (uid, id, data) =>
  authAxios2.patch(`/attachment/${uid}/?card_id=${id}`, data);

export const deleteSharedAttachmentAxios = (uid, id) =>
  authAxios2.delete(`/attachment/${uid}/?card_id=${id}`);

export const editSpreadSheetAxios = (id, data) =>
  authAxios.patch(`/spreadsheet/${id}/`, data);

export const createSpreadSheetAxios = (data) =>
  authAxios.post(`/spreadsheet/`, data);

// DealCardDetailView -> Private Attachment
export const getMyAttachmentAxios = (id) =>
  authAxios.get(`/myattachment/?card_id=${id}`);

export const postMyAttachmentAxios = (data) =>
  authAxios2.post(`/myattachment/`, data);

export const patchMyAttachmentAxios = (uid, id, data) =>
  authAxios2.patch(`/myattachment/${uid}/?card_id=${id}`, data);

export const deleteMyAttachmentAxios = (uid, id) =>
  authAxios2.delete(`/myattachment/${uid}/?card_id=${id}`);

export const editMySpreadSheetAxios = (id, data) =>
  authAxios.patch(`/my_spreadsheet/${id}/`, data);

export const createMySpreadSheetAxios = (data) =>
  authAxios.post(`/my_spreadsheet/`, data);

// DealCardDetailView => Order Information

export const getOrderInfoAxios = (id) => authAxios.get(`/order/${id}/`);

export const postOrderInfoAxios = (data) => authAxios.post(`/order/`, data);

export const putOrderInfoAxios = (id, data) =>
  authAxios.put(`/order/${id}/`, data);

export const getExportContractAxios = (id) =>
  authAxios.get(`/deal/export_contract/?card_id=${id}`);

// DealCardDetailView => Finance & Payment

export const getInvoiceRowsAxios = (id) =>
  authAxios.get(`/invoice/?card_id=${id}`);

export const createInvoiceRowAxios = (data) =>
  authAxios.post("/invoice/", data);

export const editInvoiceRowAxios = (id, data) =>
  authAxios.patch(`/invoice/${id}/`, data);

export const deleteInvoiceRowAxios = (id) =>
  authAxios.delete(`/invoice/${id}/`);

export const getPaymentRowsAxios = (id) =>
  authAxios.get(`/payment/?card_id=${id}`);

export const createPaymentAxios = (data) => authAxios.post("/payment/", data);

export const editPaymentRowAxios = (id, data) =>
  authAxios.patch(`/payment/${id}/`, data);

export const deletePaymentRowAxios = (id) =>
  authAxios.delete(`/payment/${id}/`);

// DealCardDetailView => Comment Page

// Comment Pagination

export const getCommentByPageAxios = (id, target) =>
  authAxios.get(`${target === "" ? `/comment/?card_id=${id}` : target}`);

export const getCommentAxios = (id) => authAxios.get(`/comment/?card_id=${id}`);

export const postCommentAxios = (data) => authAxios.post(`/comment/`, data);

export const putCommentAxios = (uid, id, data) =>
  authAxios.put(`/comment/${uid}/?card_id=${id}`, data);

export const deleteCommentAxios = (uid, id) =>
  authAxios.delete(`/comment/${uid}/?card_id=${id}`);

// DealCardDetailView => Tracking Shipment

export const getInitialTrackingShipmentAxios = (id) =>
  authAxios.get(`/shipment/?card_id=${id}`);

export const deleteVesselInfoAxios = (id) =>
  authAxios.delete(`/shipment/?card_id=${id}`);

// export const getTrackingShipmentAxios = (id, data) =>
//   authAxios.get(
//     `/deal/shipment/?vessel_name=${data}&card_id=${id}&update=true`
//   );

export const getVesselListAxios = (data) =>
  authAxios.get(`/search_vessel/?input_name=${data}`);

export const getVesselInfoAxios = (id, data) =>
  authAxios.get(`/shipment/?card_id=${id}&vessel_id=${data}`);

// DealCardDetailView => Deal Lost Reason
export const getDealLostReasonAxios = () => authAxios.get(`/deal_lost_reason/`);

export const postDealLostReasonAxios = (data) =>
  authAxios.post(`/deal_lost_reason/`, data);

export const deleteDealLostReasonAxios = (id) =>
  authAxios.delete(`/deal_lost_reason/${id}/`);

export const cardsLostReasonUpdateAxios = (data) =>
  authAxios.post("/merge_lost_reasons/", data);

//Account Members

export const getTeamMembersAxios = () => authAxios.get("/team_members/");

export const editMemberAuthAxios = (id, data) =>
  authAxios.patch(`/team_members/${id}/`, data);

export const deleteTeamMemberAxios = (id) =>
  authAxios.delete(`/team_members/${id}/`);

export const inviteTeamMemberAxios = (data) =>
  authAxios.post("/user/invitation/", data);

//Sign Up

export const getMemberSignUpAxios = (id, verification) =>
  emptyAxios.get(`/user/signup/staff/?id=${id}&verification=${verification}`);

export const getCountriesAxios = () => emptyAxios.get("/country/list/");

export const memberSignUpAxios = (data) =>
  emptyAxios.post("/user/signup/staff/", data);

export const getAdminSignUpAxios = () => emptyAxios.get("/user/signup/admin/");

export const adminSignUpAxios = (data) =>
  emptyAxios.post("/user/signup/admin/", data);

//Login

export const loginAxios = (data) =>
  authAxios.post("/user/login/", data, { withCredentials: true });

//Logout

export const logoutAxios = () => authAxios.post("/user/logout/");

//Reset token

export const resetTokenAxios = () => authAxios.get("/user/refresh/jwtcookie/");
// export const cookieSetAxios = () => authAxios.get("user/jwtcookieset/");

// Password Reset
export const postPasswordResetAxios = (data) =>
  authAxios.post("/user/request-reset-email/", data);

export const getPasswordResetAxios = (uid, token) =>
  authAxios.get(`/user/password-reset/${uid}/${token}/`);

export const updatePasswordResetAxios = (data) =>
  authAxios.patch(`/user/password-reset-complete/`, data);

//Recaptcha

export const reCaptchaAxios = (token) => authAxios.post(``);

//ToDo List

//(CARD VIEW)
export const getToDoItemAxios = (id) =>
  authAxios.get(`/todoitem/?card_id=${id}`);

export const postToDoItemAxios = (data) => authAxios.post(`/todoitem/`, data);

export const deleteToDoItemAxios = (todo, id) =>
  authAxios.delete(`/todoitem/${todo}/?card_id=${id}`);

export const editToDoItemAxios = (todo, id, data) =>
  authAxios.patch(`/todoitem/${todo}/?card_id=${id}`, data);

//(ToDo List Page)
export const getToDoListAxios = () => authAxios.get("/todoitem_list/");

export const getDoneToDoListAxios = () =>
  authAxios.get("/todoitem_list/?done=False");

export const getToDoCountAxios = () =>
  authAxios.get("/todoitem/?view=count&done=False");
export const editToDoListAxios = (id, data) =>
  authAxios.patch(`/todoitem_list/${id}/`, data);

export const deleteToDoListAxios = (id, data) =>
  authAxios.delete(`/todoitem_list/${id}/`, data);

//Notifications

export const getAllNotificationsAxios = () => authAxios.get("/notification/");

export const getUnreadNotificationsAxios = () =>
  authAxios.get("/notification/?type=unread");

export const getNotificationsCountAxios = () =>
  authAxios.get("/notification/?type=unread&view=count");

export const getAllMentionsAxios = () =>
  authAxios.get("/notification/?mention=true");

export const getUnreadMentionsAxios = () =>
  authAxios.get("/notification/?mention=true&type=unread");

export const getMentionsCountAxios = () =>
  authAxios.get("/notification/?mention=true&type=unread&view=count");

export const editNotificationAxios = (data) =>
  authAxios.post(`/notifications/status/`, data);

export const deleteNotificationAxios = (id) =>
  authAxios.patch(`/notification/${id}/`);

export const updateAllNotificationsAxios = (data) =>
  authAxios.post(`/notifications/action/`, data);

//Email

export const sendEmailAxios = (data) =>
  authAxios.post("/email/direct_send/", data);

export const resendEmailAxios = (data) =>
  authAxios.put("/email/direct_send/", data);

//connecting email
export const getEmailConnectLinkAxios = () => authAxios.get("/email/auth/");
export const sendEmailTokenAxios = (data) =>
  authAxios.post("/email/auth/", data);

//multiple threads
export const getEmailAxios = (querystring) =>
  authAxios.get(`/email/threads/${querystring}`);
export const getSendMailAxios = (querystring) =>
  authAxios.get(`/email/threads/sent/${querystring}`);
export const getTrashMailAxios = (querystring) =>
  authAxios.get(`/email/threads/trash/${querystring}`);
export const searchEmailAxios = (string, querystring) =>
  authAxios.get(`/email/threads/search/${string}/${querystring}`);

// authAxios.get(`/email/threads/search/${string}/`);
export const editThreadsAxios = (data) =>
  authAxios.post("/email/threads/", data);

//singlethread
export const editThreadDetailAxios = (id, data) =>
  authAxios.put(`/email/threads/detail/${id}/`, data);
export const getThreadDetailAxios = (id) =>
  authAxios.get(`/email/threads/detail/${id}/`);
export const getCheckEmailAxios = (string) =>
  authAxios.get(`/contacts/check_email/${string}`);
export const getContactSuggestionsAxios = () =>
  authAxios.get("/contacts/suggest/account/?search=");
//email compose
export const getEmailSuggestionsAxios = () =>
  authAxios.get("/contacts/suggest/accountcontact/?search=");
export const getDraftsAxios = (querystring) =>
  authAxios.get(`/email/drafts/${querystring}`);
export const getDraftDetailAxios = (id) =>
  authAxios.get(`/email/drafts/detail/${id}/`);
export const createDraftAxios = (data) =>
  authAxios.post("/email/drafts/create/", data);
export const editDraftAxios = (id, data) =>
  authAxios.put(`/email/drafts/detail/${id}/`, data);
export const deleteDraftAxios = (id) =>
  authAxios.delete(`/email/drafts/detail/${id}/`);
export const deleteDraftsAxios = (data) =>
  authAxios.post("/email/drafts/delete/", data);
export const postDraftAxios = (data) =>
  authAxios.post("/email/drafts/send/", data);
export const postEmailFileAxios = (data) =>
  authAxios2.post("/email/file/upload/", data);
export const deleteEmailFileAxios = (id) =>
  authAxios.delete(`/email/file/detail/${id}/`);
// export const downloadEmailFileAxios = (id) =>
//   authAxiosDownload.get(`/email/file/download/${id}/`);
export const downloadEmailFileAxios = (id) =>
  authAxios.get(`/email/file/download/${id}/`, {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
  });

//single message
export const getMessageBodyAxios = (id) =>
  authAxios.get(`/email/messages/${id}/`);
export const editMessageDetailAxios = (id, data) =>
  authAxios.put(`/email/messages/${id}/`, data);

//Thread - Card Snippet Connect
export const getConnectingCardListAxios = () =>
  authAxios.get(`/email/threads/saving/`);
export const getConnectedCards = (id) =>
  authAxios.get(`/email/connected_cards/?thread_id=${id}`);
export const searchCardSnippetsAxios = (input) =>
  authAxios.get(`/email/threads/sync/cards/?search=${input}`);
export const searchCardSnippetAxios = (id) =>
  authAxios.get(`/email/threads/sync/cards/${id}/`);
export const connectCardToEmailAxios = (data) =>
  authAxios.post("/email/threads/sync/", data);
export const getConnectedThreadsAxios = (id, string) =>
  authAxios.get(`/email/card_threads/?card_id=${id}${string}`);
export const getThreadMessagesAxios = (id) =>
  authAxios.get(`/email/thread_messages/?thread_id=${id}`);
export const getThreadMainAxios = (messageID, threadID) =>
  authAxios.get(
    `/email/thread_messages/${messageID}/?thread_id=${threadID}&full_body=true`
  );
export const getMoreThreadMessagesAxios = (string) =>
  authAxios.get(`/email/thread_messages/${string}`);
export const editThreadSharedAxios = (id, card_id, data) =>
  authAxios.patch(`/email/card_threads/${id}/?card_id=${card_id}`, data);
export const disconnectThreadAxios = (data) =>
  authAxios.post("/email/threads/unsync/", data);
export const emailToAttachmentsAxios = (data) =>
  authAxios.post("/email/file/to_attachment/", data);

//REPORTS
export const getOrderTrendDataAxios = (string) =>
  authAxios.get(`/report/order_trend/?${string}`);
export const getOrderByDataAxios = (string) =>
  authAxios.get(`/report/order_by_category/?${string}`);
export const getRevenueTrendAxios = (string) =>
  authAxios.get(`/report/revenue_trend/?${string}`);
export const getDealLostReasonReportAxios = (string) =>
  authAxios.get(`/report/deal/lost_reasons/?${string}`);
export const getDealSuccessRateAxios = (string) =>
  authAxios.get(`/report/deal/success_rate/?${string}`);
export const getReportColumnTypesAxios = (type) =>
  authAxios.get(`/report_table/${type}/`);
export const updateReportColumnTypesAxios = (type, data) =>
  authAxios.patch(`/report_table/${type}/`, data);
export const getAllReportColumnTypesAxios = () =>
  authAxios.get("/report_table/AllTableFields/");

//SCHEDULE
export const createScheduleAxios = (data) => authAxios.post("/schedule/", data);
export const updateScheduleAxios = (id, cardID, data) =>
  authAxios.patch(`/schedule/${id}/?card_id=${cardID}`, data);
export const deleteScheduleAxios = (id, cardID) =>
  authAxios.delete(`/schedule/${id}/?card_id=${cardID}`);
export const getSchedulesAxios = (string) =>
  authAxios.get(`/schedule/?${string}`);
export const getScheduleListAxios = (string) =>
  authAxios.get(`/calendar/${string}`);

//Version Check

export const versionCheckAxios = () =>
  authAxios.get("/version/check/?platform=web");

//Product

export const createProductAxios = (data) => authAxios.post("/product/", data);

export const getCardProductAxios = (id) =>
  authAxios.get(`/product/?card_id=${id}`);

export const getProductListAxios = () => authAxios.get("/product/");

export const updateProductAxios = (id, data) =>
  authAxios.patch(`/product/${id}/`, data);

export const deleteProductAxios = (id) => authAxios.delete(`/product/${id}/`);

export const updateCardProductAxiosOld = (data) =>
  authAxios.patch("/card/product/update/", data);
