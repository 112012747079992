import { dealBoardAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useAccountBoards = () => {
  async function getUserSettingsData() {
    const { data } = await dealBoardAPIs.getAccountBoardsAxios();
    return data;
  }
  return useQuery({
    queryKey: ["get/board/", "?fields=access_granted,has_access,id,name/"],
    queryFn: getUserSettingsData,
    placeholderData: [],
  });
};
