import { dealBoardAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useBoardRemove = () => {
  async function removeBoard(id) {
    const { data } = await dealBoardAPIs.deleteBoardAxios(id);
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(removeBoard, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/board/");
    },
  });
};
