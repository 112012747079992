import React from "react";
import styled from "styled-components";
import { Input } from "ui";
import Icons from "assets/Icons";

const InputBlock = ({
  edit,
  setEdit,
  value,
  setValue,
  axios,
  invoiceID,
  type,
  width,
  inputType,
  resetData,
}) => {
  return (
    <RowBlock width={width}>
      {edit ? (
        <Inputs
          type={inputType ? inputType : ""}
          autoFocus
          placeholder={""}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={(e) => {
            e.target.blur();
          }}
          onBlur={(e) => {
            axios(invoiceID, {
              [type]: resetData
                ? e.target.value === ""
                  ? 0
                  : e.target.value
                : e.target.value,
            }).then(() => resetData && resetData());
            setEdit(false);
          }}
        />
      ) : (
        <EditTextBox onClick={() => setEdit(true)}>
          <EditText>
            {value ? (
              inputType ? (
                // `${parseInt(value).toLocaleString("us", {
                //   maximumFractionDigits: 2,
                // })}`
                Number(value).toLocaleString("us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              ) : (
                value
              )
            ) : (
              <>&nbsp;&nbsp;&nbsp;&nbsp;-</>
            )}
          </EditText>
          <EditIcon className="hidden">
            <Icons type="fas" name="edit" />
          </EditIcon>
        </EditTextBox>
        // <ValueBox onClick={() => setEdit(true)}>
        // {value ? (
        //   resetData ? (
        //     // `${parseInt(value).toLocaleString("us", {
        //     //   maximumFractionDigits: 2,
        //     // })}`
        //     value
        //   ) : (
        //     value
        //   )
        // ) : (
        //   <>&nbsp;&nbsp;&nbsp;&nbsp;-</>
        // )}
        // </ValueBox>
      )}
    </RowBlock>
  );
};

export default InputBlock;

const Inputs = styled(Input)`
  padding-left: 4px;
`;

const EditIcon = styled.div`
  display: none;
  padding-bottom: 4px;
  padding-right: 4px;
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const EditTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  &:hover .hidden {
    display: inline-block;
    margin-top: 3px;
    margin-bottom: auto;
  }
`;

const EditText = styled.div`
  min-height: 31px;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  line-height: 31px;
  padding-left: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const RowBlock = styled.div`
  width: ${({ width }) => width}%;
  padding: 3px 2px;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;
