import { handleActions, createAction } from "redux-actions";

const TOGGLE = "webViewNotiToggle/TOGGLE";

export const toggleNoti = createAction(TOGGLE);

const initialState = true;

const notiUpdate = handleActions(
  {
    [TOGGLE]: (state, { payload: bool }) => bool,
  },
  initialState
);

export default notiUpdate;
