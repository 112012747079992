import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailToCardConnect = () => {
  async function connectCardToEmail(connectData) {
    const { data } = await emailThreadAPIs.connectCardToEmailAxios(connectData);
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(connectCardToEmail, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/threads/");
      queryClient.invalidateQueries("get/email/connected_cards/");
    },
  });
};
