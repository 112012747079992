import { handleActions, createAction } from "redux-actions";

const UPDATE = "emailFileInfo/UPDATE";
const RESET = "emailFileInfo/RESET";
export const toEmail = createAction(UPDATE);
export const resetEmail = createAction(RESET);

const initialState = {
  local_uploaded_at: "",
  card: "",
  content_type: "",
  id: "",
  link: "",
  name: "",
  status: "",
  type: "",
  uid: "",
  url: "",
};

const emailFileInfo = handleActions(
  {
    [UPDATE]: (state, { payload: info }) => {
      return info;
    },
    [RESET]: (state, { payload: info }) => {
      return initialState;
    },
  },
  initialState
);

export default emailFileInfo;
