import { saveAs } from "file-saver";
import * as spreadExcel from "@grapecity/spread-excelio";
import * as GC from "@grapecity/spread-sheets";
import "@grapecity/spread-sheets-designer-resources-en";
import "@grapecity/spread-sheets-designer";
if (window.location.origin.includes("testing.tradir.io")) {
  GC.Spread.Sheets.LicenseKey =
    "E499651173151541#B0vouHalFTUFx4UK5WQwtCeIN7S5Z5QhVnTQtSbCRXWkhmZURWW9xUMw3SWxQnMrM5N8o6QW5UMTF4d8JmYCZVZUVEeHVWOwdFNKR7RUZXTvoETsV6dIhDWqREdEJXOVRUN5AXUxdDTvREcPZjRvZVMD3icyFVeN5kah3WSIJXdoZlYDV4LzlmQud6LtFkRsNUWzg7SVp7Z0ZWcWFjcwVjYKlHbMJETD96Y7UDe9IWRZRXbhpXNi9mMZNWNmBjdPh6SSJ7RBVHV82ydUplSyEFeCFmSVBlaLlzRzIlVihkMpZkUxF4Lk9WZ8l5ZiojITJCLiU4N9IkNygzNiojIIJCLxQDM5IjMwcTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI7IDMycDMgMTMxATMyAjMiojI4J7QiwiIzEDNwEjMwIjI0ICc8VkIsISkwqegYyuI0ISYONkIsUWdyRnOiwmdFJCLiEDN5ETNxMzNxETN6kTO4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBZ5SCFndlVWWYB7Z7pHMhJWUENkSld6Ul5URUBnN8E4aUFkRwlDNqBlbWdzS7ZDWKd4YLpXbut4QUlnQahDVIBzYK3UYKRDRxN7VhFWeTFzSjJ4Z5k5ZDJjbvFDWVlDRy34QzE4SBVFvmzl";
} else {
  GC.Spread.Sheets.LicenseKey =
    "tradir.io,261274735935899#B0Emnzo5LkRDNyF6d4F5RJNVcJNnMzhVZ9VFaNdldzBXZyJ6czpFWPdEaTFVRPlkTIlXcVV6bMdGNxBDW4gUcKJFTUBHUrUVO42GS9gWNvBjQStidsJFZrh4NZ3CSJp5Rap7b5dTNxU4c52WNkhVSQtmYLBVbYNXeaRHSXZXNZN4Q7MGRrNXNPFWdjpXbk5EMX3Ca5Z6Q7YEMndXbNRGU8U6VUpWSnhDS0NDRTJDRzIVSQF6bxU6Y9RTNzMjdBVTYQh6LZRUVaR7c4kmMzBlNxJVW8NGTOJ4SplXQuhmUDpWMaZkTPpkI0IyUiwiIwIDNGVkRDRjI0ICSiwiM7ETO9UDN5gTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIwUDOxQDMggTMxATMyAjMiojI4J7QiwiIvlmLylGZhJHdiojIz5GRiwiIRCr0BiJ1iojIh94QiwiI9kDO5MTO5MzN4cjMxYjMiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUD5GUqNDe4FVeLh6c45URPVWVvcXdxVVdvhTMxtidtdUUX56KZNnYzV4aNF5aoNnR85WOpVnayEkU7YjRUFGdyo6KOh6TFpnVlhGe7oWUBN7ZpdkSydUQXd7Nq3EOJNmb0p7M6gFNK3SWroVMQDE20";
  spreadExcel.LicenseKey =
    "tradir.io,261274735935899#B0Emnzo5LkRDNyF6d4F5RJNVcJNnMzhVZ9VFaNdldzBXZyJ6czpFWPdEaTFVRPlkTIlXcVV6bMdGNxBDW4gUcKJFTUBHUrUVO42GS9gWNvBjQStidsJFZrh4NZ3CSJp5Rap7b5dTNxU4c52WNkhVSQtmYLBVbYNXeaRHSXZXNZN4Q7MGRrNXNPFWdjpXbk5EMX3Ca5Z6Q7YEMndXbNRGU8U6VUpWSnhDS0NDRTJDRzIVSQF6bxU6Y9RTNzMjdBVTYQh6LZRUVaR7c4kmMzBlNxJVW8NGTOJ4SplXQuhmUDpWMaZkTPpkI0IyUiwiIwIDNGVkRDRjI0ICSiwiM7ETO9UDN5gTM0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIwUDOxQDMggTMxATMyAjMiojI4J7QiwiIvlmLylGZhJHdiojIz5GRiwiIRCr0BiJ1iojIh94QiwiI9kDO5MTO5MzN4cjMxYjMiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TUD5GUqNDe4FVeLh6c45URPVWVvcXdxVVdvhTMxtidtdUUX56KZNnYzV4aNF5aoNnR85WOpVnayEkU7YjRUFGdyo6KOh6TFpnVlhGe7oWUBN7ZpdkSydUQXd7Nq3EOJNmb0p7M6gFNK3SWroVMQDE20";
}
GC.Spread.Sheets.Designer.LicenseKey =
  "tradir.io,423825181336266#B0rzsXOz4keP9mYkhlVW3COPVXQYVXeHRWevIGVp36b9UTMWJzQhlWbWFnYZNHVDVjNC9GTsp7anpXSC3yKLx4dIJUMrUXNpJXVI9WZkZDNroHOKpUTxg4YFBDMiVEeoJUWlZEVKFXNFZmQygVOux4R7cjRyUTc9R6Lu5mekhnRwkWbUdTOHFmb5VleyhXOkhmYNJFc9Z4LnhlVrRXVuh7SOZFTNJzUzUGZvAVNGFFdDF5QZ3CR5IGbWFFZxBncCl6dZlGcvJUN5xmQ4R6YORDUNhVTLpVUpB7Lr3iZNBDW4YEWBJiOiMlIsISREJERwIEN7IiOigkIscTO7ETOyAzM0IicfJye&Qf35VfiklNFdjI0IyQiwiI4EjL6BibvRGZB5icl96ZpNXZE5yUKRWYlJHcTJiOi8kI1tlOiQmcQJCLiMTN8EDNwACOxEDMxIDMyIiOiQncDJCLi2WauIXakFmc4JiOiMXbEJCLiEJsqHImsLiOiEmTDJCLiYjNyYzMzEDOxUjM8MjM4IiOiQWSisnOiQkIsISP3EUSWZEcuhXUNdWbLp4YmhmbOBjQChUWzk5V52iVVNHVBJ6ctFmW4EGbadXNoZkWKtUTrc5ZLRER75mRlZWdwtyYGlTTwM7Krw6QvE7cMpHeIRnMhZFe8QDdyskcU3GWoBzY7REe7Q6boB5TFhkbUFDLZuj";

const handleExport = (attachRef, data, keys) => {
  let columnList = [];
  const columnInfo = {
    card: {
      name: "card.name",
      displayName: "Card Name",
      size: 300,
    },
    board: {
      name: "board.name",
      displayName: "Board",
      size: 160,
    },
    created_at: {
      name: "created_at",
      displayName: "Created At",
      size: 160,
    },
    stage: {
      name: "stage",
      displayName: "Stage",
      size: 160,
    },
    card_list: {
      name: "card_list",
      displayName: "Stage",
      size: 160,
    },
    owner: {
      name: "owner.user_name",
      displayName: "Owner",
      size: 160,
    },
    user: {
      name: "user.user_name",
      displayName: "Owner",
      size: 160,
    },
    quantity: {
      name: "quantity",
      displayName: "Quantity",
      size: 80,
    },
    quantity_unit: {
      name: "quantity_unit",
      displayName: "Unit",
      size: 50,
    },
    currency: {
      name: "currency",
      displayName: "Currency",
      size: 50,
    },
    amount: {
      name: "amount",
      displayName: "Amount",
      size: 80,
    },
    customer: {
      name: "customer.name",
      displayName: "Customer Name",
      size: 170,
    },
    supplier: {
      name: "supplier.name",
      displayName: "Supplier Name",
      size: 170,
    },
    result: {
      name: "result",
      displayName: "Result",
      size: 160,
    },
    lost_reason: {
      name: "lost_reason",
      displayName: "Lost Reason",
      size: 160,
    },
    lost_description: {
      name: "lost_description",
      displayName: "Lost Description",
      size: 160,
    },
    local_result_updated_at: {
      name: "local_result_updated_at",
      displayName: "Result Updated At",
      size: 160,
    },
    invoice_total: {
      name: "invoice_total",
      displayName: "Invoice Total",
      size: 160,
    },
    payment_total: {
      name: "payment_total",
      displayName: "Payment Total",
      size: 160,
    },
    balance: {
      name: "balance",
      displayName: "Balance",
      size: 160,
    },
    country: {
      name: "country",
      displayName: "Country",
      size: 90,
    },
    order_number: {
      name: "order_number",
      displayName: "Order Number",
      size: 160,
    },
    local_ordered_date: {
      name: "local_ordered_date",
      displayName: "Order Date",
      size: 160,
    },
    product: {
      name: "product",
      displayName: "Product",
      size: 160,
    },
    products: {
      name: "products",
      displayName: "Products",
      size: 160,
      value: (data) =>
        data &&
        data.products &&
        data.products.map((product) => product.name).join(", "),
    },
    incoterms: {
      name: "incoterms",
      displayName: "Incoterms",
      size: 160,
    },
    local_delivery_deadline: {
      name: "local_delivery_deadline",
      displayName: "Delivery Deadline",
      size: 160,
    },
    destination_port: {
      name: "destination_port",
      displayName: "Destination Port",
      size: 160,
    },
    loading_port: {
      name: "loading_port",
      displayName: "Loading Port",
      size: 160,
    },
    payment_terms: {
      name: "payment_terms",
      displayName: "Payment Terms",
      size: 160,
    },
    other_terms: {
      name: "other_terms",
      displayName: "Other Terms",
      size: 160,
    },
  };
  for (let i = 0; i < keys.length; i++) {
    columnList.push(columnInfo[keys[i]]);
  }
  columnList = [
    ...columnList,
    {
      name: keys.includes("owner") ? "owner.user_email" : "user.user_email",
      displayName: "Owner Email",
      size: 240,
    },
  ];
  attachRef.current.spread.sheets[0].autoGenerateColumns = true;
  attachRef.current.spread.sheets[0].setDataSource(data);
  for (let i = 0; i < columnList.length; i++) {
    attachRef.current.spread.sheets[0].bindColumn(i, columnList[i]);
  }
  // attachRef.current.spread.sheets[0].bindColumn(columnList.length, null);
  // attachRef.current.spread.sheets[0].bindColumn(columnList.length + 1, null);
  attachRef.current.spread.sheets[0].addRows(0, 1);
  for (let i = 0; i < columnList.length; i++) {
    attachRef.current.spread.sheets[0].setText(0, i, columnList[i].displayName);
  }
  let excelIo = new spreadExcel.IO();

  let fileName = "Table Data";
  if (fileName.substr(-5, 5) !== ".xlsx") {
    fileName += ".xlsx";
  }

  let json = attachRef.current.spread.toJSON({
    includeBindingSource: true,
  });

  // // here is excel IO API
  excelIo.save(
    json,
    function (blob) {
      saveAs(blob, fileName);
    },
    function (e) {},
    { password: "" }
  );
};

export default handleExport;
