import React, { useState } from "react";
import { imageTypes } from "constants/ImageTypes";
import { emailToAttachmentsAxios } from "common/ApiAxios";
import { sendNotification } from "modules/Global/notificationSocket";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link, SecondaryButton, Popover } from "ui";
import Icons from "assets/Icons";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";

const EmailThreadMessageAttachment = ({
  file,
  setPreviewUrl,
  setFileName,
  setType,
  data,
  content_type,
  typeConverter,
  id,
  match,
}) => {
  const dispatch = useDispatch();
  const checkMobile = useCheckPath("mobile");
  const [popoverVisible, setPopoverVisible] = useState();
  const user = useSelector((state) => state.addTeamMetaInfo.user);
  const { t } = useTranslation();
  const popoverContent = (att_id) => (
    <div>
      <PopoverButton
        onClick={(e) => {
          emailToAttachmentsAxios({
            email_attachment_id: att_id,
            card_id: id.slice(5),
            shared: true,
          }).then(() =>
            dispatch(
              sendNotification({
                board_id: match.params.category,
                card_id: id.slice(5),
                msg_type: "moveAttachmentShared",
                team_id: user.team_id,
                type: "sendMessage",
                user_id: user.id,
              })
            )
          );
          setPopoverVisible(false);
        }}
      >
        {t(
          "cardView.emailThread.emailThreadMessageAttachment.sendToSharedAttachments"
        )}
      </PopoverButton>
      <PopoverButton
        onClick={(e) => {
          emailToAttachmentsAxios({
            email_attachment_id: att_id,
            card_id: id.slice(5),
            shared: false,
          }).then(() =>
            dispatch(
              sendNotification({
                board_id: match.params.category,
                card_id: id.slice(5),
                msg_type: "moveAttachmentPrivate",
                team_id: user.team_id,
                type: "sendMessage",
                user_id: user.id,
              })
            )
          );
          setPopoverVisible(false);
        }}
      >
        {t(
          "cardView.emailThread.emailThreadMessageAttachment.sendToPrivateAttachments"
        )}
      </PopoverButton>
    </div>
  );
  return (
    <Attachment
      key={file.file_id}
      onClick={() => {
        setPreviewUrl(file.link);
        setFileName(file.filename);
        setType(content_type);
        if (checkMobile) {
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(file.link);
        }
      }}
    >
      {imageTypes.includes(typeConverter(file.content_type)) ? (
        <ImageIcon>
          <img src={file.link} alt="previewimage" />
        </ImageIcon>
      ) : (
        <LetterIcon
          fileType={
            {
              xlsx: "#188038",
              xls: "#188038",
              pdf: "#ff0000",
              docx: "#00a2ed",
              doc: "#00a2ed",
              pptx: "#d04423",
              ppt: "#d04423",
            }[file.content_type] || "black"
          }
        >
          <div>{typeConverter(file.content_type)}</div>
        </LetterIcon>
      )}
      <AttachmentInfoBox>
        <FileName className="nameText">{file.filename}</FileName>
        <ToolsSec>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popover
              visible={popoverVisible}
              trigger="click"
              content={popoverContent(file.id)}
              onVisibleChange={() => setPopoverVisible((prev) => !prev)}
            >
              <SendToAttachmentLink>
                <Icons type="fas" name="file-export" />
                &nbsp;&nbsp;
                {t(
                  "cardView.emailThread.emailThreadMessageAttachment.sendToAttachments"
                )}
              </SendToAttachmentLink>
            </Popover>
          </div>
          <DownloadIcon
            data-download="download"
            href={file.link}
            download={file.filename}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {t("cardView.emailThread.emailThreadMessageAttachment.download")}
          </DownloadIcon>
        </ToolsSec>
      </AttachmentInfoBox>
      {/* <LetterIcon
    data-download="download"
    fileType={
      {
        xlsx: "#188038",
        xls: "#188038",
        pdf: "#ff0000",
        docx: "#00a2ed",
        doc: "#00a2ed",
        pptx: "#d04423",
        ppt: "#d04423",
        jpg: "#27B99A",
        png: "#E61B23",
        zip: "#E9BE10",
      }[content_type] ||
      `${({ theme }) => theme.colors.color_base_white}`
    }
  >
    {content_type}
  </LetterIcon> */}

      {/* <AttachmentName
    data-download="download"
    onClick={(e) => {
      e.stopPropagation();
      api
        .downloadEmailFileAxios(file.file_id)
        .then((response) => {
          const url = window.URL.createObjectURL(
            // new Blob([response.data])
            response.data
          );
          const link = document.createElement("a");
          link.href = url;
          link.download = file.filename;
          // link.setAttribute("download", info.filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }}
  >
    <div className="nameText">{file.filename}</div>
    <i className="far fa-arrow-to-bottom"></i>
  </AttachmentName>*/}
    </Attachment>
  );
};

export default withTranslation()(EmailThreadMessageAttachment);

const ToolsSec = styled.div`
  padding-bottom: 2px;
  padding-right: 6px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const PopoverButton = styled(SecondaryButton)`
  height: 30px;
  margin: 5px;
`;

const SendToAttachmentLink = styled(Link)`
  opacity: 0.7;
  margin-right: 8px;
`;

const DownloadIcon = styled(Link)`
  opacity: 0.7;
`;

const ImageIcon = styled.div`
  img {
    width: 35px;
    height: 35px;
    border-radius: 4px;
  }
`;

const LetterIcon = styled.div`
  border-radius: 4px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.fileType} !important;
  div {
    font-size: 12px;
    color: white;
    background-color: transparent !important;
    font-weight: 400;
  }
`;

const FileName = styled.div`
  color: ${({ theme }) => theme.colors.color_text_body};
  text-decoration: underline;
  text-align: start;
  /* line-height: 14px; */
`;

const AttachmentInfoBox = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  padding-left: 20px;
`;

const Attachment = styled.div`
  cursor: pointer;
  width: 100%;
  margin: 2px 0;
  /* width: 150px;
  height: 70px; */
  display: flex;
  align-items: center;
  border-radius: 3px;
  /* z-index: 3; */

  .fa-arrow-to-bottom {
    margin-left: 3px;
  }
  .nameText {
    max-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
  }
`;
