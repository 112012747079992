/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
// import "antd/dist/antd.css";
import {
  Input,
  PrimaryButton,
  notification,
  Upload,
  Modal,
  Link,
  SecondaryButton,
  Popconfirm,
} from "ui";
// import { UploadOutlined } from "@ant-design/icons";
// import AddIcon from "@material-ui/icons/Add";
// import * as comps from "../../DealCardDetailView/CardComponents";
import {
  createContactAttachmentAxios,
  getContactAttachmentAxios,
  updateContactAttachmentAxios,
  deleteContactAttachmentAxios,
} from "common/ApiAxios";
// import PreviewModal from "Components/Global/PreviewModal";
import { useSelector, useDispatch } from "react-redux";
import { sendNotification } from "modules/Global/notificationSocket";
// import { toComment } from "Modules/DealCardDetailView/attachFileInfo";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import InsertLinkRoundedIcon from "@material-ui/icons/InsertLinkRounded";
import { imageTypes } from "constants/ImageTypes";
import loadable from "@loadable/component";
import { useTranslation, withTranslation } from "react-i18next";
import { useTeamMetaInfo } from "api-cache";
const PreviewModal = loadable(() =>
  import("ui/Organisms/PreviewModal/PreviewModal")
);

const ContactsAttachment = memo(({ id, match, attachmentType, typeId }) => {
  const [heightType, setHeightType] = useState("default");
  const [stage, setStage] = useState("");
  const [data, setData] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [type, setType] = useState("");
  const [idCompare, setIdCompare] = useState(0);
  const [edit, setEdit] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [copyLinkVisible, setCopyLinkVisivle] = useState(false);
  const [copyLinkValue, setCopyLinkValue] = useState("");
  // const imageTypes = [
  //   "img",
  //   "jpg",
  //   "png",
  //   "gif",
  //   "PNG",
  //   "IMG",
  //   "JPG",
  //   "GIF",
  //   "jpeg",
  //   "JPEG",
  // ];
  const {
    data: { user, file_upload_limit: fileSize },
  } = useTeamMetaInfo();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // const curr = useRef(data);

  // 리스트 받아오기
  useEffect(() => {
    getContactAttachmentAxios(attachmentType, typeId).then((res) => {
      const receivingFile = res.data.map((filelists) => {
        return {
          ...filelists,
          uid: filelists.id,
          url: filelists.link,
          name: filelists.name,
          type: filelists.content_type,
          status: "done",
        };
      });
      setData(receivingFile);
    });
  }, [attachmentType, typeId]);

  const handlePreview = async (file) => {
    setPreviewUrl(file.url);
    setType(file.type);
  };

  useEffect(() => {
    type !== "" && setPreviewVisible(true);
  }, [type]);

  useEffect(() => {
    data.length > 4 ? setStage("over") : setStage("below");

    //"over"은 첨부파일 수가 4개 이상, "below"는 4개 이하
  }, [data]);
  //첨부 파일 4개 이상 되면 stage가 true가 되면서 view all attachments 나옴

  const handleEditFileName = (file) => {
    setEdit(!edit);
    setIdCompare(file.id);
    setFileName(file.name);
  };

  // const handleFileInfo = (file) => {
  //   dispatch(toComment(file));
  // };

  const handleRemove = (info) => {
    const newData = data.filter((item) => item.id !== info.id);
    setData(newData);
    deleteContactAttachmentAxios(info.id, attachmentType, typeId);
  };

  const handleEnterKey = (e) => {
    if (e.charCode === 13) {
      setFileName(e.target.value);
      updateContactAttachmentAxios(idCompare, attachmentType, typeId, {
        name: e.target.value,
        content_type: typeId,
        account_content_type: attachmentType,
      }).then(() => {
        getContactAttachmentAxios(attachmentType, typeId).then((res) => {
          const receivingFile = res.data.map((filelists) => {
            return {
              ...filelists,
              uid: filelists.id,
              url: filelists.link,
              name: filelists.name,
              type: filelists.content_type,
              status: "done",
            };
          });
          setData(receivingFile);
        });
      });
      setEdit(!edit);
    }
  };

  const showModal = () => {
    setCopyLinkVisivle(!copyLinkVisible);
  };

  const handleShowCopyLink = (file) => {
    setCopyLinkValue(
      "|^|" + file.url + "|||" + file.content_type + "|||" + file.name + "|^|"
    );
    showModal();
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      notification(
        "success",
        `${t("contacts.contactsDetailView.contactsAttachment.copy")}!`,
        "",
        "bottomRight"
      );
      showModal();
    } catch (err) {}
  };

  return (
    <AttachmentComponent>
      <Title>{t("contacts.contactsDetailView.contactsAttachment.title")}</Title>
      <OutsideWrapper>
        {/* <BodyContainer height={heightTrue.toString()} stage={stage}> */}
        <BodyContainer
          height={
            {
              default: "380px",
              extended: "100%",
              // false: "0px",
              // true: "",
            }[heightType]
          }
          // heightTure={heightTrue}
        >
          {/* <UploadContainer> */}
          <MyUpload
            customRequest={(info) => {
              const newFile = new FormData();
              newFile.append("file", info.file);
              newFile.append("name", info.file.name);
              const tmp = info.file.name.split(".");
              newFile.append("content_type", tmp[tmp.length - 1]);
              newFile.append("account_object_id", typeId);
              newFile.append("account_content_type", attachmentType);
              if (fileSize.max_bits < info.file.size) {
                notification(
                  "error",
                  `File size should not be greater than ${fileSize.max_mbs}`,
                  "",
                  "bottomRight"
                );
              } else {
                createContactAttachmentAxios(newFile)
                  .catch((err) => {
                    if (
                      err.response.data.file !== "" &&
                      err.response.status === 400
                    ) {
                      notification(
                        "error",
                        err.response.data.file,
                        "",
                        "bottomRight"
                      );
                    }
                  })

                  .then(() => {
                    // AccountAttachment - List get API
                    getContactAttachmentAxios(attachmentType, typeId).then(
                      (res) => {
                        const receivingFile = res.data.map((filelists) => {
                          return {
                            ...filelists,
                            uid: filelists.id,
                            url: filelists.link,
                            name: filelists.name,
                            type: filelists.content_type,
                            status: "done",
                          };
                        });
                        setData(receivingFile);
                      }
                    );
                  });
              }
              // AccountAttachment - Create API 붙히기
              // .then(async function (res) {
              //   await setTimeout(() => {
              //   }, 1000);
              //   return res;
              // })
              // .then(() =>
              //   //AccountAttachment - List get API
              //   getSharedAttachmentAxios(id).then((res) => {
              //     const receivingFile = res.data.map((filelists) => {
              //       return {
              //         ...filelists,
              //         uid: filelists.id,
              //         url: filelists.link,
              //         name: filelists.name,
              //         type: filelists.content_type,
              //         status: "done",
              //       };
              //     });
              //     setData(receivingFile);
              //   })
              // );
              dispatch(
                sendNotification({
                  board_id: match.params.category,
                  card_id: id,
                  msg_type: "newAttachment",
                  team_id: user.team_id,
                  type: "sendMessage",
                  user_id: user.id,
                })
              );
            }}
            fileList={data}
            // onChange={(info) => {
            //   const newFile = info.fileList[info.fileList.length - 1];
            //   info.fileList.length > data.length &&
            //     setData([
            //       {
            //         ...newFile,
            //         uid: newFile.id,
            //         url: newFile.link,
            //         thumbRul: newFile.link,
            //         type: newFile.content_type,
            //         content_type: newFile.content_type,
            //         status: "done", //에러 처리 필요함
            //         // status: "done",
            //       },
            //       ...data,
            //     ]);
            // }}
            onRemove={(info) => {
              handleRemove(info);
            }}
            onPreview={handlePreview}
            showUploadList={{
              showDownloadIcon: true,
              // downloadIcon: "Download",
              downloadIcon: (
                <DownLoadText>
                  {t("contacts.contactsDetailView.contactsAttachment.download")}
                </DownLoadText>
              ),
              showRemoveIcon: false,
              showPreviewIcon: true,
            }}
            multiple={true}
            iconRender={(file, listType) => (
              <>
                {imageTypes.includes(file.content_type) ? (
                  <FileIcon
                    onClick={() => {
                      setPreviewVisible(true);
                      handlePreview(file);
                    }}
                  >
                    <img src={file.url} alt="previewimage" />
                  </FileIcon>
                ) : (
                  <LetterIcon
                    onClick={() => {
                      setPreviewVisible(true);
                      handlePreview(file);
                    }}
                    fileType={
                      {
                        xlsx: "#188038",
                        xls: "#188038",
                        XLS: "#188038",
                        pdf: "#ff0000",
                        docx: "#00a2ed",
                        doc: "#00a2ed",
                        pptx: "#d04423",
                        ppt: "#d04423",
                      }[file.content_type] || "black"
                    }
                  >
                    <div>{file.content_type}</div>
                  </LetterIcon>
                )}
                {edit && idCompare === file.id && (
                  <MyInput
                    value={fileName}
                    autoFocus
                    onChange={(e) => setFileName(e.target.value)}
                    onKeyPress={handleEnterKey}
                    onBlur={(e) => {
                      setFileName(e.target.value);
                      updateContactAttachmentAxios(
                        idCompare,
                        attachmentType,
                        typeId,
                        {
                          name: e.target.value,
                          content_type: typeId,
                          account_content_type: attachmentType,
                        }
                      ).then(() => {
                        getContactAttachmentAxios(attachmentType, typeId).then(
                          (res) => {
                            const receivingFile = res.data.map((filelists) => {
                              return {
                                ...filelists,
                                uid: filelists.id,
                                url: filelists.link,
                                name: filelists.name,
                                type: filelists.content_type,
                                status: "done",
                              };
                            });
                            setData(receivingFile);
                          }
                        );
                      });
                      setEdit(!edit);
                    }}
                  ></MyInput>
                )}
                <AttachTime>
                  {t("contacts.contactsDetailView.contactsAttachment.added")}{" "}
                  {file.local_uploaded_at}
                  {file.uploader.name ? ` by ${file.uploader.name}` : ""}
                </AttachTime>
                <EditAttachName onClick={() => handleEditFileName(file)}>
                  {t(
                    "contacts.contactsDetailView.contactsAttachment.editFileName"
                  )}
                </EditAttachName>
                <CommentLink onClick={() => handleShowCopyLink(file)}>
                  {t("contacts.contactsDetailView.contactsAttachment.copyLink")}
                </CommentLink>
                <DeleteFile>
                  <Popconfirm
                    // title="Delete this attachment?"
                    title={
                      <RemoveTitle>
                        {t("cardView.attachments.confirmText")}
                        {/* 선택하신 첨부파일을 정말 삭제하시겠습니까? */}
                      </RemoveTitle>
                      // "선택하신 첨부파일을 정말 삭제하시겠습니까?"
                    }
                    destroyTooltipOnHide={true}
                    // getPopupContainer={(trigger) => trigger.parentElement}
                    onConfirm={() => handleRemove(file)}
                    okText={t("cardView.commentPage.commentListItem.yes")}
                    cancelText={t("cardView.commentPage.commentListItem.no")}
                    // okText="네"
                    // cancelText="아니요"
                    trigger="click"
                  >
                    <div>{t("cardView.attachments.delete")}</div>
                  </Popconfirm>
                </DeleteFile>
              </>
            )}
            // iconRender={(file, listype) => {
            //   return <div>fdsfdsf</div>;
            // }}
          >
            {/* <AttachButton>
              <AttachmentTitle>Drag and drop your file here</AttachmentTitle>
              <AttachmentTitle>or</AttachmentTitle>
              <PrimaryButton>
                <i className="fas fa-upload"></i> &nbsp; Select file
              </PrimaryButton>
            </AttachButton> */}

            {/* <AttachButton icon={<UploadOutlined /> }>
              <AttachButtonText>Drag and Drop your files here</AttachButtonText>
              <AttachButtonText>Or</AttachButtonText>
              <AttachButtonText>Upload</AttachButtonText>
            </AttachButton> */}
            <AttachButton>
              <AttachButtonText>
                {t("cardView.attachments.attachButtonText")}
              </AttachButtonText>
              <AttachButtonText>
                {t("cardView.attachments.or")}
              </AttachButtonText>
              <FileFindButton>
                {t("cardView.attachments.browseHere")}
              </FileFindButton>
            </AttachButton>
            {/* <AttachActiveBtnBox>
              <AttachButtonText>
                {t(
                  "contacts.contactsDetailView.contactsAttachment.dragAndDropYourFilesHere"
                )}
              </AttachButtonText>
              <AttachButtonText>
                {t("contacts.contactsDetailView.contactsAttachment.or")}
              </AttachButtonText>
              <AttachActiveBtn>
                {t("contacts.contactsDetailView.contactsAttachment.upload")}
              </AttachActiveBtn>
            </AttachActiveBtnBox> */}
          </MyUpload>
          <PreviewModal
            setType={setType}
            type={type}
            fileName={fileName}
            previewUrl={previewUrl}
            setPreviewVisible={setPreviewVisible}
            previewVisible={previewVisible}
            imageTypes={imageTypes}
          />
          {/* <MyModal
            window={height}
            wrapClassName="previewModal"
            visible={previewVisible}
            footer={null}
            centered={true}
            onCancel={() => {
              setPreviewVisible(false);
              setLoaded(false);
              setType("");
            }}
            maskClosable={true}
            width="90%"
            destroyOnClose={true}
          >
            {!loaded && !imageTypes.includes(type) && <MySpin size="large" />}
            {imageTypes.includes(type) ? (
              <img
                height={`${height - 200}px`}
                src={previewUrl}
                alt="previewimage"
              />
            ) : (
              <iframe
                key="1"
                ref={frame}
                title="preview"
                className={type}
                width="100%"
                height="750"
                frameBorder="0"
                onLoad={() => setLoaded(true)}
                src={`https://docs.google.com/viewer?url=${previewUrl}&embedded=true`}
              ></iframe>
            )}
          </MyModal> */}
          <CopyLinkModal
            title={
              <CopyTitle>
                {t("contacts.contactsDetailView.contactsAttachment.copyLink")}
              </CopyTitle>
            }
            visible={copyLinkVisible}
            onCancel={showModal}
            centered={true}
            footer={null}
            width="40%"
          >
            <CopyTitle>
              {t("contacts.contactsDetailView.contactsAttachment.linkUrl")}
            </CopyTitle>
            <CopyLinkBox>
              <InsertLinkRoundedIcon />
              <CopyInput
                value={copyLinkValue}
                onChange={({ target: { value } }) => {
                  setCopyLinkValue(value);
                }}
                // autofocus
              />
              <CopyButton onClick={() => copyToClipBoard(copyLinkValue)}>
                {t("contacts.contactsDetailView.contactsAttachment.copy")}
              </CopyButton>
              {/* <CopyToClipboard
                text={copyLinkValue}
                onCopy={(text, result) => {
                  handleCopyOk(text);
                  return result;
                }}
              >
                <CopyButton>Copy</CopyButton>
              </CopyToClipboard> */}
            </CopyLinkBox>
          </CopyLinkModal>
          {/* </UploadContainer> */}
        </BodyContainer>
        {
          {
            default: stage === "over" && (
              <ViewAll onClick={() => setHeightType("extended")}>
                {t(
                  "contacts.contactsDetailView.contactsAttachment.viewAllAttachments"
                )}{" "}
                ({data.length - 4} hidden)
              </ViewAll>
            ),
            extended: (
              <ViewAll onClick={() => setHeightType("default")}>
                {t(
                  "contacts.contactsDetailView.contactsAttachment.showFewerAttachments"
                )}
              </ViewAll>
            ),
          }[heightType]
        }
      </OutsideWrapper>
      {/* <AddBtnBox>
        <AddIcon size="small" />
        <AddButton>Add attachment</AddButton>
      </AddBtnBox> */}
    </AttachmentComponent>
  );
});

export default withRouter(withTranslation()(ContactsAttachment));

const AttachButtonText = styled.div`
  margin-bottom: 8px;
  :nth-child(1) {
    ${({ theme }) => theme.fontSizes.txt_h2};
    color: ${({ theme }) => theme.colors.color_text_light};
  }

  :nth-child(2) {
    ${({ theme, isMobile }) =>
      isMobile ? theme.fontSizes.txt_h3 : theme.fontSizes.txt_h6};
    color: ${({ theme, isMobile }) =>
      isMobile
        ? theme.colors.color_text_light
        : theme.colors.color_text_placeholder};
    font-weight: normal;
  }

  :nth-child(3) {
    font-size: ${({ theme }) => theme.fontSizes.txt_standard};
    color: ${({ theme }) => theme.colors.color_text_placeholder};
    text-decoration: underline;
  }
`;

const AttachButton = styled(SecondaryButton)`
  width: 100%;
  min-height: 110px;
  color: ${({ theme }) => theme.colors.color_text_placeholder} !important;
  ${({ theme }) => theme.fontSizes.txt_h6};
  background-color: ${({ theme }) =>
    theme.colors.color_base_regular} !important;
  border: none !important;
  margin-bottom: 4px;
  padding: 8px 0;
  flex-direction: column;
`;

const FileFindButton = styled.div`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-color: ${({ theme }) => theme.colors.color_primary_regular};
  background-color: ${({ theme }) => theme.colors.color_primary_regular};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.color_base_white};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    border-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    color: white !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    border-color: ${({ theme }) =>
      theme.colors.color_primary_darker} !important;
    color: white !important;
  }
`;

const RemoveTitle = styled.div`
  white-space: nowrap;
`;

const AttachmentComponent = styled.div`
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h3};
  /* font-weight: bold;
  text-decoration: underline; */
`;

const OutsideWrapper = styled.div`
  border-radius: 3px;
  margin-bottom: 13px;
  line-height: 1.3em;
  font-size: 15px;
`;

const MyUpload = styled(Upload)`
  .ant-upload {
    width: 100%;
  }
  .ant-upload-list {
    width: 100%;
    /* border: 1px solid red; */
    .ant-upload-list-item {
      /* position: relative; */
      cursor: pointer;
      height: 50px;
      .ant-upload-list-item-name {
        top: 2px;
        left: 55px;
        position: absolute;
        color: ${({ theme }) => theme.colors.color_text_body} !important;
        font-size: ${({ theme }) => theme.fontSizes.txt_smaller} !important;
      }
      /* .ant-upload-list-item-info { */
      .ant-upload-list-item-card-actions {
        padding-left: 40px;
        button {
          opacity: 1;
          padding-top: 27px;
          padding-right: 20px;
          opacity: 0.7;
          right: 60px; // 다운로드 버튼 위치 조정
          bottom: 22px;
          position: absolute;
          span {
            svg {
              margin-bottom: 7px;
            }
            &:hover {
              /* text-decoration: underline;
              color: #007bff;
              font-weight: 600; */
            }
          }
        }
      }
      /* .fa-ellipsis-v {
        position: absolute;
        width: 16px;
        height: 16px;
        right: 50px;
      } */
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  max-height: ${(props) => props.height};
/* 
  bottom: ${(props) => ({ 1: 0, 2: "15%" }[props.screen])};
  right: ${(props) => ({ 1: "90px", 2: "10%" }[props.screen])};
  height: ${(props) => ({ 1: "608px", 2: "80%" }[props.screen])};
  width: ${(props) => ({ 1: "600px", 2: "80%" }[props.screen])}; */

  overflow: hidden;
  position: relative;
  min-height: 140px;
  
`;

const FileIcon = styled.div`
  position: absolute;
  left: 5px;
  top: 4px;
  img {
    width: 43px;
    height: 43px;
    border-radius: 4px;
  }
`;

// const MyInput = styled(Input)`
//   position: absolute;
//   border: none;
//   left: 60px;
//   background-color: ${({ theme }) => theme.colors.base_regular};
//   font-weight: 600;
//   padding: 0;
//   width: auto;
// `;

const MyInput = styled(Input)`
  position: absolute;
  left: 60px;
  border: none;
  background-color: ${({ theme }) =>
    theme.colors.color_base_regular} !important;
  font-size: 14px !important;
  font-weight: 400;
  padding: 0;
  /* margin-right: 50px; */
  max-width: 92%;
`;

const LetterIcon = styled.div`
  border-radius: 4px;
  position: absolute;
  left: 5px;
  top: 4px;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-size: 16px;
    color: white;
    font-weight: 400;
  }
  background-color: ${(props) => props.fileType};
`;

const AttachTime = styled.div`
  position: absolute;
  top: 24px;
  left: 64px;
  opacity: 0.7;
  /* right: 350px; */
`;

// const EditAttachName = styled.div`
//   position: absolute;
//   top: 24px;
//   right: 130px;
//   opacity: 0.7;
//   &:hover {
//     text-decoration: underline;
//     /* color: #007bff; */
//     font-weight: 600;
//   }
// `;

const DeleteFile = styled(Link)`
  position: absolute;
  top: 27px;
  right: 30px;
  opacity: 0.7;
  /* z-index: 1000; */
`;

const EditAttachName = styled(Link)`
  position: absolute;
  top: 27px;
  right: 220px;
  opacity: 0.7;
`;

const DownLoadText = styled(Link)`
  position: absolute;
  top: 26.5px;
  right: 18px;
  color: ${({ theme }) => theme.colors.color_base_black};
  font-size: 12px !important;
  /* opacity: 0.7; */
`;

// const CommentLink = styled.div`
//   position: absolute;
//   top: 24px;
//   right: 235px;
//   opacity: 0.7;
//   &:hover {
//     text-decoration: underline;
//     /* color: #007bff; */
//     font-weight: 600;
//   }
// `;

const CommentLink = styled(Link)`
  position: absolute;
  top: 27px;
  right: 150px;
  opacity: 0.7;
`;

// const AttachButton = styled.div`
//   width: 100%;
//   min-height: 130px;
//   position: absolute;
//   top: 10px;
//   border: 2px dashed #e5e5e5;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// `;
// const AttachButtonText = styled.div`
//   :nth-child(1) {
//     color: ${({ theme }) => theme.colors.color_text_placeholder};
//   }

//   :nth-child(2) {
//     color: ${({ theme }) => theme.colors.color_text_light};
//     margin: 0 8px;
//   }

//   :nth-child(3) {
//     font-size: ${({ theme }) => theme.fontSizes.txt_small};
//     color: ${({ theme }) => theme.colors.color_text_body};
//     text-decoration: underline;
//   }
// `;

const AttachActiveBtnBox = styled.div`
  width: 100% !important;
  min-height: 130px; //BODY CONTAINER 높이만큼 자동으로 늘릴 수 있는 방법??
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.color_text_placeholder} !important;
  ${({ theme }) => theme.fontSizes.txt_h6};
  border: none !important;
  margin: 24px 0;
  padding: 8px 0;
  position: absolute;
  top: 10px;
`;

const AttachActiveBtn = styled(SecondaryButton)``;

const ViewAll = styled.div`
  text-decoration: underline;
  margin: 10px 0 10px 5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #007bff;
    font-weight: 600;
  }
`;

const CopyLinkModal = styled(Modal)``;

const CopyTitle = styled.h5`
  margin: 0;

  :nth-child(2) {
    font-size: 15px;
    font-weight: bold;
  }
`;

const CopyLinkBox = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0px 5px 0px;
`;

const CopyInput = styled.input`
  width: 100%;
  border: none;
  padding: 5px 8px;
`;

const CopyButton = styled(PrimaryButton)`
  /* padding: 4px 15px;
  border-radius: 3px;
  margin: 0;
  font-weight: bold;
  padding: 10px 30px;

  :hover {
    cursor: pointer;
  } */
`;
