import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
// import DeleteOutlineTwoToneIcon from "@material-ui/icons/DeleteOutlineTwoTone";
import { ClearButton } from "ui";
import Icons from "assets/Icons";
import {
  getContactAxios,
  createContactAxios,
  deleteContactAxios,
} from "common/ApiAxios";
import ContactsListItem from "./ContactsListItem";
import { useTranslation, withTranslation } from "react-i18next";

const ContactsList = memo(({ type, typeId }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getContactAxios(type, typeId).then((res) => {
      setData(res.data);
    });
  }, [type, typeId]);

  const handleAddContact = () => {
    const addData = {
      name: "",
      title: "",
      email: "",
      phone: "",
      mobile: "",
      fax: "",
      account_object_id: typeId,
      account_content_type: type,
    };

    createContactAxios(addData).then((res) => {
      setData([...data, res.data]);
    });
  };

  const handleDeleteContact = (id) => {
    deleteContactAxios(id, type, typeId);
    const deleteData = data.filter((item) => {
      return item.id !== id;
    });
    setData(deleteData);
  };

  return (
    <ContactsListComponent>
      <Title>{t("contacts.contactsDetailView.contactsList.title")}</Title>
      <Table>
        <TableTitleBox>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsList.name")}
          </TableTitle>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsList.jobTitle")}
          </TableTitle>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsList.email")}
          </TableTitle>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsList.phone")}
          </TableTitle>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsList.mobile")}
          </TableTitle>
          {/* <TableTitle>FAX</TableTitle> */}
          {/* <TableTitle width={10}>REMOVE</TableTitle> */}
        </TableTitleBox>
        <TableList>
          {data.map((item) => {
            return (
              <ContactsListItem
                key={item.id}
                item={item}
                id={item.id}
                type={type}
                typeId={typeId}
                handleDeleteContact={handleDeleteContact}
              />
            );
          })}
        </TableList>
      </Table>
      <AddBtnBox onClick={handleAddContact}>
        <AddButton>
          <AddIcon>
            <Icons type="fas" name="plus" />
          </AddIcon>
          {t("contacts.contactsDetailView.contactsList.addContact")}
        </AddButton>
      </AddBtnBox>
    </ContactsListComponent>
  );
});

export default withTranslation()(ContactsList);

const ContactsListComponent = styled.section`
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h3};
  margin: 0 0 10px 0px;
`;

const Table = styled.div`
  /* border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc; */
`;

const TableTitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.color_base_dark};
`;

const TableTitle = styled.div`
width: 16.6%;
  /* width: ${(props) => (props.width ? `${props.width}%` : "16.6%")}; */
  padding: 5px 0;
  margin: 0;
  ${({ theme }) => theme.fontSizes.txt_h5};

  :nth-child(2) {
    width: 30%;
  }

  :nth-child(3) {
    width: 20%;
  }
`;

const TableList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const AddBtnBox = styled.div`
  display: inline-block;
  margin-top: 15px;
`;

const AddButton = styled(ClearButton)`
  :hover {
    cursor: pointer;
  }
`;

const AddIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

// const DeleteButton = styled.div`
//   width: 16.6%;
//   text-align: center;
//   padding: 5px 0;
//   font-weight: bold;
//   margin: 0;
//   :hover {
//     cursor: pointer;
//   }
// `;
