import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailThreadDetailEdit = () => {
  async function editThreadDetail({ id, editData }) {
    console.log({ id, editData });
    const { data } = await emailThreadAPIs.editThreadDetailAxios(id, editData);
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(editThreadDetail, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/account/");
      queryClient.invalidateQueries("get/email/threads/");
    },
  });
};
