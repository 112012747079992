/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SecondaryButton } from "ui";
import Icons from "assets/Icons";
import {
  createInvoiceRowAxios,
  createPaymentAxios,
  getInvoiceRowsAxios,
  getPaymentRowsAxios,
} from "common/ApiAxios";
import * as comps from "../CardComponents";
import InvoiceItem from "pages/DealCardDetailView/components/Finance/InvoiceItem";
import PaymentItem from "pages/DealCardDetailView/components/Finance/PaymentItem";
import AnimatedNumber from "animated-number-react";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";

const Finance = ({ id, result }) => {
  const [height, setHeight] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [toggle, setToggle] = useState(true);

  const checkMobile = useCheckPath("mobile");

  const resetData = () => setToggle(!toggle);
  const { t } = useTranslation();

  useEffect(() => {
    getInvoiceRowsAxios(id).then((res) => setInvoiceList(res.data));
    getPaymentRowsAxios(id).then((res) => setPaymentList(res.data));
  }, [id, toggle]);

  useEffect(() => {
    result === "won" ? setHeight(true) : setHeight(false);
  }, [result]);
  const formatValue = (value) =>
    `${Number(value).toLocaleString("us", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;

  return (
    <>
      {checkMobile && <Divider />}
      <OutsideWrapper pathname={checkMobile} height={height.toString()}>
        <Title height={height.toString()}>
          <div>{t("cardView.finance.title")}</div>
          {/* <UpOutlined onClick={() => setHeight(!height)} /> */}

          {height ? (
            <div />
          ) : (
            <InfoText pathname={checkMobile}>
              {t("cardView.finance.infoText")}
            </InfoText>
          )}
        </Title>
        <BodyContainer height={height.toString()}>
          <SubTop>
            <SubTitle>{t("cardView.finance.invoices.title")}</SubTitle>
          </SubTop>
          <SectionRow>
            <RowBlock width={20}>
              {t("cardView.finance.invoices.invoiceNumber")}
            </RowBlock>
            <RowBlock width={18}>
              {t("cardView.finance.invoices.amount")}
            </RowBlock>
            <RowBlock width={18}>
              {t("cardView.finance.invoices.invoicingDate")}
            </RowBlock>
            <RowBlock width={18}>
              {t("cardView.finance.invoices.DueDate")}
            </RowBlock>
            <RowBlock width={21}>
              {t("cardView.finance.invoices.remark")}
            </RowBlock>
            <RowBlock width={5}></RowBlock>
          </SectionRow>
          {invoiceList.map((invoice) => (
            <InvoiceItem
              resetData={resetData}
              key={invoice.id}
              item={invoice}
            />
          ))}
          <DataRow addButton>
            <AddButton
              onClick={() =>
                createInvoiceRowAxios({ card: id }).then((res) =>
                  setInvoiceList([...invoiceList, res.data])
                )
              }
            >
              <Icons type="far" name="plus" size="lg" />
              &nbsp;&nbsp;&nbsp;
              {t("cardView.finance.invoices.buttonText")}
            </AddButton>
          </DataRow>
          <DataRow>
            <TotalBox>{t("cardView.finance.invoices.totalInvoice")}</TotalBox>
            <TotalBox>
              <AnimatedNumber
                value={invoiceList.reduce(
                  (sum, item) =>
                    sum +
                    (isNaN(Number(item.amount)) ? 0 : Number(item.amount)),
                  0
                )}
                formatValue={formatValue}
                duration={1000}
              />
            </TotalBox>
          </DataRow>
          <SubTop>
            <SubTitle>{t("cardView.finance.payment.title")}</SubTitle>
          </SubTop>
          <SectionRow>
            <RowBlock width={30}>
              {t("cardView.finance.payment.paymentName")}
            </RowBlock>
            <RowBlock width={18}>
              {t("cardView.finance.payment.amount")}
            </RowBlock>
            <RowBlock width={18}>
              {t("cardView.finance.payment.paymentDate")}
            </RowBlock>
            <RowBlock width={29}>
              {t("cardView.finance.payment.remark")}
            </RowBlock>
            <RowBlock width={5}></RowBlock>
          </SectionRow>
          {paymentList.map((payment) => (
            <PaymentItem
              resetData={resetData}
              key={payment.id}
              item={payment}
            />
          ))}
          <DataRow addButton>
            <AddButton
              onClick={() =>
                createPaymentAxios({ card: id }).then((res) =>
                  setPaymentList([...paymentList, res.data])
                )
              }
            >
              <Icons type="far" name="plus" size="lg" />
              &nbsp;&nbsp;&nbsp;{t("cardView.finance.payment.buttonText")}
            </AddButton>
          </DataRow>
          <DataRow>
            <TotalBox>{t("cardView.finance.payment.totalPayment")}</TotalBox>
            <TotalBox>
              <AnimatedNumber
                value={paymentList.reduce(
                  (sum, item) =>
                    sum +
                    (isNaN(Number(item.amount)) ? 0 : Number(item.amount)),
                  0
                )}
                formatValue={formatValue}
                duration={1000}
              />
            </TotalBox>
          </DataRow>
          <DataRow balance>
            <TotalBox>{t("cardView.finance.payment.balanceAmount")}</TotalBox>
            <TotalBox>
              <AnimatedNumber
                value={
                  invoiceList.reduce(
                    (sum, item) =>
                      sum +
                      (isNaN(Number(item.amount)) ? 0 : Number(item.amount)),
                    0
                  ) -
                  paymentList.reduce(
                    (sum, item) =>
                      sum +
                      (isNaN(Number(item.amount)) ? 0 : Number(item.amount)),
                    0
                  )
                }
                formatValue={formatValue}
                duration={1000}
              />
            </TotalBox>
          </DataRow>
        </BodyContainer>
      </OutsideWrapper>
    </>
  );
};

export default withTranslation()(Finance);

const DataRow = styled.div`
  display: flex;
  width: 100%;
  padding: 11px 2px;
  
  text-align: center;
  cursor: pointer;
  background-color: ${({ balance, theme }) =>
    balance && theme.colors.color_base_dark};
  ${({ addButton, theme }) =>
    addButton && `border-bottom: 1px solid ${theme.colors.color_base_darker}`};
  /* border-bottom: 1px solid ${({ theme }) =>
    theme.colors.color_base_darker}; */
`;

const TotalBox = styled.div`
  height: 36px;
  line-height: 36px;
  width: 18%;
  /* background-color: ${({ theme }) => theme.colors.color_base_dark}; */
  margin-right: 12px;
  text-align: center;
`;

const RowBlock = styled.div`
  width: ${({ width }) => width}%;
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_darker};
`;

const SectionRow = styled.div`
  display: flex;
`;

/* const DataRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
`; */

const AddButton = styled(SecondaryButton)`
  height: 36px !important;
`;

const SubTop = styled.div`
  margin: 12px 0;
`;

const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_standard};
  font-weight: bold;
`;

const InfoText = styled.div`
  font-size: ${({ pathname, theme }) =>
    pathname ? "12px" : theme.fontSizes.txt_small};
  font-weight: normal;
`;

const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)`
  margin-top: ${(props) => (props.pathname ? "0" : "12px")};
  ${(props) =>
    props.pathname ? "border: none;" : "border: 1px solid #d6dee5;"}
  ${(props) => props.height === "false" && "border: 1px solid #d6dee5;"};
`;

const Divider = styled.div`
  background-color: #f4f4f4;
  height: 10px;
`;

const BodyContainer = styled.div`
  ${(props) => (props.height === "false" ? "display: none" : "")};
  transition: all 1s ease-in-out;
  overflow: hidden;
  padding: 15px;
`;

const Title = styled(comps.CardViewInnerSectionTitleStyle)`
  display: flex;
  justify-content: space-between;
  ${(props) => (props.height === "false" ? "border-bottom: none" : "")};
  background-color: ${({ height, theme }) =>
    height === "false" && theme.colors.color_base_dark};
  ${({ height }) => height === "false" && "opacity: 0.5"};
  ${({ height }) => height === "false" && "cursor: not-allowed !important"};
  width: 100%;
`;
