import { handleActions, createAction } from "redux-actions";

const TOGGLE = "listVisible/TOGGLE";

export const toggleList = createAction(TOGGLE);

const initialState = false;

const listVisible = handleActions(
  {
    [TOGGLE]: (state, { payload: bool }) => bool,
  },
  initialState
);

export default listVisible;
