import React, { useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import {
  DropdownClear,
  Popconfirm,
  // SecondaryButton,
  Menu,
  label_green,
  label_red,
  label_grey_inverted,
} from "ui";
import * as api from "common/ApiAxios";
import MoonLoader from "react-spinners/MoonLoader";
import { useSelector } from "react-redux";
import Icons from "assets/Icons";
import { useTranslation, withTranslation } from "react-i18next";

const EmailThreadListItem = ({
  thread,
  id,
  i,
  threadList,
  setThreadList,
  setCurrentThreadID,
  noti,
}) => {
  const theme = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const threadMenu = (
    <Menu>
      {visible && (
        <Menu.Item onClick={() => {}} key="100">
          <EmailMenu
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MyPopconfirm
              title={
                <RemoveTitle>
                  {t("cardView.emailThread.emailThreadListItem.confirmTitle")}
                </RemoveTitle>
              }
              onConfirm={() => {
                api
                  .disconnectThreadAxios({
                    card_id: id.slice(5),
                    thread_ids: [thread.thread_id],
                  })
                  .then(() => {
                    for (let i = 0; i < threadList.length; i++) {
                      if (threadList[i].thread_id === thread.thread_id) {
                        const tmp = [...threadList];
                        tmp.splice(i, 1);
                        setThreadList(tmp);
                        break;
                      }
                    }
                  });
                setVisible(false);
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
              okText="Yes"
              cancelText="No"
              placement="leftTop"
              trigger="click"
            >
              <RemoveBtn>
                <div>
                  <Icons type="fas" name="trash" size="sm" />
                </div>
                &nbsp;
                <p>
                  {t("cardView.emailThread.emailThreadListItem.removeThread")}
                </p>
              </RemoveBtn>
            </MyPopconfirm>
          </EmailMenu>
        </Menu.Item>
      )}
    </Menu>
  );
  const synced_emails = useSelector(
    (state) => state.addTeamMetaInfo.synced_emails
  );
  const UpdateThread = (i, bool, thread_id) => {
    api
      .editThreadSharedAxios(thread_id, id.slice(5), { is_shared: bool })
      .then(() => {
        api
          .getCardViewPartialInfoAxios(id.slice(5), "?fields=synced_threads")
          .then((res) => {
            setThreadList(res.data.synced_threads);
          });
      });
  };
  return (
    <EmailThreadBox
      owner={synced_emails.includes(thread.synced_with_email) ? "me" : "other"}
      onClick={() => {
        setCurrentThreadID(thread.thread_id);
      }}
    >
      <EmailThreadTopSec>
        <EmailThreadEditLeftSec>
          {thread.saving && (
            <LoadingBox>
              <MyLoading
                color={theme.colors.color_state_warning_regular}
                css={"margin-top: 8px; margin-right: 5px;"}
                size={15}
              />
              {t("cardView.emailThread.emailThreadListItem.connecting")}
            </LoadingBox>
          )}
          {noti && <NewNoti>new</NewNoti>}
          <Title>{thread.subject === "" ? "No Title" : thread.subject}</Title>
        </EmailThreadEditLeftSec>
        <EmailThreadEditRightSec
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* <MyPopconfirm
            title={
              <RemoveTitle>
                Do you really want to remove this thread?
              </RemoveTitle>
            }
            onConfirm={() => {
              api
                .disconnectThreadAxios({
                  card_id: id.slice(5),
                  thread_ids: [thread.thread_id],
                })
                .then(() => {
                  for (let i = 0; i < threadList.length; i++) {
                    if (threadList[i].thread_id === thread.thread_id) {
                      const tmp = [...threadList];
                      tmp.splice(i, 1);
                      setThreadList(tmp);
                      break;
                    }
                  }
                });
            }}
            okText="Yes"
            cancelText="No"
            placement="leftTop"
            trigger="click"
          >
            <RemoveThread />
          </MyPopconfirm> */}
          <TimeStamp>{thread.local_last_message_timestamp}</TimeStamp>
          <DropdownClear
            placement={"bottomRight"}
            overlay={threadMenu}
            trigger={["click"]}
            onVisibleChange={(bool) => setVisible(bool)}
          >
            <EditButton className="MessageItems">
              <i className="fas fa-ellipsis-v" size="5px"></i>
            </EditButton>
          </DropdownClear>
        </EmailThreadEditRightSec>
      </EmailThreadTopSec>
      <EmailThreadBodySec>
        <EmailThreadBodyTopSec>
          <EmailThreadBodyTopLeftSec>
            <ParticipantBox
            // onClick={(e) => {
            //   e.stopPropagation();
            // }}
            >
              <ParticipantList>
                {thread.participants.map((participant, i) =>
                  participant.name !== ""
                    ? `${participant.name}${
                        thread.participants.length - 1 !== i ? ", " : ""
                      }`
                    : `${participant.email}${
                        thread.participants.length - 1 !== i ? ", " : ""
                      }`
                )}
              </ParticipantList>
              {thread.message_count > 1 && (
                <div>&nbsp;&nbsp;{thread.message_count}&nbsp;</div>
              )}
            </ParticipantBox>
          </EmailThreadBodyTopLeftSec>
          <EmailThreadBodyTopRightSec>
            <ThreadType
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SharedLabel
                onClick={() =>
                  !thread.is_shared && UpdateThread(i, true, thread.id)
                }
              >
                {thread.is_shared ? (
                  <LabelGreenText>
                    <LabelText>
                      {t("cardView.emailThread.emailThreadListItem.shared")}

                      {!synced_emails.includes(thread.synced_with_email) &&
                        ` by ${thread.synced_with_email}`}
                    </LabelText>
                  </LabelGreenText>
                ) : (
                  <LabelGreyText data-download={"Make shared"}>
                    {t("cardView.emailThread.emailThreadListItem.shared")}
                  </LabelGreyText>
                )}
              </SharedLabel>
              {(synced_emails.includes(thread.synced_with_email) ||
                !thread.is_shared) && (
                <PrivateLabel
                  onClick={() =>
                    thread.is_shared && UpdateThread(i, false, thread.id)
                  }
                >
                  {thread.is_shared ? (
                    <LabelGreyText data-download={"Make private"}>
                      {t("cardView.emailThread.emailThreadListItem.private")}
                    </LabelGreyText>
                  ) : (
                    <LabelRedText>
                      {t("cardView.emailThread.emailThreadListItem.private")}
                    </LabelRedText>
                  )}
                </PrivateLabel>
              )}
            </ThreadType>
          </EmailThreadBodyTopRightSec>
        </EmailThreadBodyTopSec>
        <Snippet>{thread.snippet === "" ? "" : thread.snippet}</Snippet>
      </EmailThreadBodySec>
    </EmailThreadBox>
  );
};

export default withTranslation()(EmailThreadListItem);

const LoadingBox = styled.div`
  display: flex;
  min-width: 100px;
  margin-right: 8px;
  justify-self: flex-end;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.color_state_warning_regular};
`;

const MyLoading = styled(MoonLoader)``;

const EmailMenu = styled.div`
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const EditButton = styled.div`
  width: 20px;
  text-align: center;
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.color_base_regular} !important;
  }
`;

const ParticipantList = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  max-width: 300px;
  padding-left: 4px;
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const TimeStamp = styled.div`
  margin-right: 8px;
  line-height: 22px;
  min-width: 54px;
  text-align: end;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const ParticipantBox = styled.div`
  display: flex;
  padding: 3px 0;
`;

const EmailThreadBodyTopLeftSec = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
`;
const EmailThreadBodyTopRightSec = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
`;

const EmailThreadBodyTopSec = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3px 0;

  @media ${({ theme }) => theme.screenSizes.mobile} {
    flex-direction: column-reverse;
    margin-bottom: 5px;
  }
`;

const EmailThreadBodySec = styled.div`
  padding: 4px 8px;
  /* background-color: #f8f9fa; */
  /* background-color: ${({ owner, theme }) =>
    owner !== "me" ? theme.colors.color_base_dark : "#D3EEF1"}; */
`;

const ThreadType = styled.div`
  display: flex;
  margin-right: 8px;
  @media ${({ theme }) => theme.screenSizes.mobile} {
    margin-bottom: 5px;
  }
`;

const EmailThreadEditLeftSec = styled.div`
  display: flex;
  align-items: center;
`;

const EmailThreadEditRightSec = styled.div`
  display: flex;
`;
const Snippet = styled.div`
  padding: 4px;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
  word-break: break-all;
`;

const NewNoti = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h4}
  color: ${({ theme }) => theme.colors.color_base_white};
  background-color: ${({ theme }) => theme.colors.color_state_warning_regular};
  margin-right: 8px;
  padding: 0 6px;
  border-radius: 3px;
  padding-bottom: 1px;
  line-height: 19px;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h4}
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const EmailThreadTopSec = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 6px 4px 12px;
  /* border-bottom: 3px solid
    ${({ owner, theme }) =>
      owner !== "me" ? theme.colors.color_base_dark : "#D3EEF1"}; */
  /* background-color: ${({ owner, theme }) =>
    owner !== "me" ? theme.colors.color_base_dark : "#D3EEF1"}; */
`;

const EmailThreadBox = styled.div`
  border: 3px solid
    ${({ owner, theme }) =>
      owner !== "me" ? theme.colors.color_base_dark : "#D3EEF1"};
  border-radius: 7px;
  margin-bottom: 12px;
  background-color: ${({ owner, theme }) =>
    owner !== "me" ? theme.colors.color_base_dark : "#D3EEF1"};
  /* &:hover ${EmailThreadBodySec} {
    background-color: ${({ owner, theme }) =>
      owner !== "me" ? theme.colors.color_base_dark : "#D3EEF1"};
  } */
  &:hover {
    box-shadow: ${({ theme }) => theme.elevations.elevation_light};
    cursor: pointer;
  }
`;

const SharedLabel = styled.div``;
const PrivateLabel = styled.div`
  margin-left: 8px;
`;

const LabelGreyText = styled(label_grey_inverted)`
  cursor: pointer;
`;

const LabelRedText = styled(label_red)`
  cursor: pointer;
`;

const LabelGreenText = styled(label_green)`
  cursor: pointer;
`;

const LabelText = styled.div``;

const RemoveTitle = styled.div`
  min-width: 300px;
`;

const MyPopconfirm = styled(Popconfirm)``;

const RemoveBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
