import React from "react";
import styled from "styled-components";
import Icons from "assets/Icons";
import { Dropdown, SecondaryButton, PrimaryButton } from "ui";
import { useTranslation, withTranslation } from "react-i18next";
// import { UnorderedListOutlined } from "@ant-design/icons";

const MultiCheckButton = ({
  checkItem,
  defaultItem,
  listMenu,
  checkNum,
  clickBtnFn,
  clearButtonFn,
  actionFn,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!checkItem ? (
        <EditBtn>
          <View overlay={listMenu} trigger={["click"]}>
            <ViewButton>
              <Icons type="fas" name="pen" size="lg" />
              <ViewButtonText>{defaultItem}</ViewButtonText>
              {/* <Icons type="fas" name="caret-down" size="lg" /> */}
            </ViewButton>
          </View>
        </EditBtn>
      ) : (
        <>
          {checkNum && checkNum > 0 ? (
            <SelectCount>{`${checkNum && checkNum} ${t(
              "global.multiCheckButton.card"
            )}${
              checkNum && checkNum > 1 ? t("global.multiCheckButton.s") : ""
            } ${t("global.multiCheckButton.selected")}`}</SelectCount>
          ) : (
            <></>
          )}
          <EditBtnContainer onClick={checkNum > 0 && actionFn}>
            <EditText>
              {t(`contacts.contactsEdit.contactsEditButton.${checkItem}`)}
            </EditText>
          </EditBtnContainer>
          <EditClearBtn onClick={clearButtonFn}>
            <Icons type="fas" name="times-circle" size="1x" />
            &nbsp;&nbsp; {t("global.multiCheckButton.cancel")}
          </EditClearBtn>
        </>
      )}
    </>
  );
};

export default withTranslation()(MultiCheckButton);

const EditBtn = styled.div`
  /* margin: 0 8px; */
  margin-left: 8px;
`;

const SelectCount = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.color_base_regular};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.color_base_regular};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  box-shadow: none;
  flex-direction: row;
  padding: 8px 14px;
  margin-right: 8px;
  width: max-content;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  font-weight: bold;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.color_text_titles};
  margin: 0 4px;
`;

const EditBtnContainer = styled(PrimaryButton)`
  margin: 0 4px;
  cursor: pointer;
`;

const EditText = styled.div`
  padding: 0 5px;
`;

const EditClearBtn = styled(SecondaryButton)`
  cursor: pointer;
  margin: 0 4px;
`;

const View = styled(Dropdown)`
  /* height: 36px;
  width: 30px;
  margin-left: 5px; */
`;

const ViewButton = styled(SecondaryButton)`
  /* display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold; */
  /* height: 30px;
  width: 125px; */
  /* width: 125px; */
`;

const ViewButtonText = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
  font-weight: normal;
  /* margin-right: 8px; */
  margin-left: 8px;
`;
