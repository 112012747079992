/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  PrimaryButton,
  DeleteButton,
  CloseModal,
  Input,
  Select,
  Option,
} from "ui";
// import "antd/dist/antd.css";
import {
  putCardViewAxios,
  putOfferResultAxios,
  getDealLostReasonAxios,
  patchDealDescriptionAxios,
} from "common/ApiAxios";
import { useSelector, useDispatch } from "react-redux";
import { sendNotification } from "modules/Global/notificationSocket";
import { toggleOfferResult } from "modules/DealCardDetailView/toggleOfferResult";
import useViewport from "hooks/useViewPort";
import useCheckPath from "hooks/useCheckPath";
import * as comps from "../CardComponents";
import LostReasonSetting from "./LostReasonSetting";
// import OrderInformation from "Components/DealCardDetailView/OrderInformation/OrderInformation";
import { useTranslation, withTranslation } from "react-i18next";

const Offer = ({
  id,
  result,
  board_id,
  lostReason,
  setLostReason,
  lostDescription,
  setLostDescription,
  lostTime,
}) => {
  const [lostReasonData, setLostReasonData] = useState([]);
  const [edit, setEdit] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.addTeamMetaInfo.user);
  const { width } = useViewport();
  const checkMobile = useCheckPath("mobile");

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getDealLostReasonAxios().then((res) => {
      setLostReasonData(res.data);
    });
  }, [lostReason]);

  const toggleModal = () => {
    setVisible(!visible);
  };

  const changeCardViewData = (type, datas, msg_type) => {
    putCardViewAxios(id, { [type]: datas }).then(() => {
      dispatch(toggleOfferResult());
      dispatch(
        sendNotification({
          board_id: board_id,
          card_id: id,
          msg_type: msg_type ? msg_type : type,
          team_id: user.team_id,
          type: "sendMessage",
          user_id: user.id,
        })
      );
    });
  };

  const handleChangeOfferResult = (result) => {
    switch (result) {
      case "won":
        changeCardViewData("result", 1);
        break;
      case "lost":
        changeCardViewData("result", 2);
        break;
      default:
        return null;
    }
  };

  const updateLostReason = (reason) => {
    setEdit("");
    putOfferResultAxios(id, { lost_reason: reason.id }).then(() => {
      setLostReason({ ...lostReason, name: reason.name });
    });
  };

  const updateLostDescription = () => {
    setEdit("");
    patchDealDescriptionAxios(id, {
      lost_description: lostDescription,
    });
  };

  const lostReasonList = lostReasonData.map((reason) => (
    <Option key={reason.id} value={reason.name}>
      <div onClick={() => updateLostReason(reason)}>{reason.name}</div>
    </Option>
  ));

  return (
    <>
      {checkMobile && <Divider />}
      <OutsideWrapper pathname={checkMobile}>
        {/* <DealSummaryTitle>OFFER RESULT</DealSummaryTitle> */}
        <BodyContainer>
          {result === "in progress" && (
            <>
              <OfferTitle>{t("cardView.dealSummary.offer.title")}</OfferTitle>
              {!checkMobile && <br />}
              <ButtonWrapper pathname={checkMobile}>
                <WonButton
                  onClick={() => {
                    handleChangeOfferResult("won");
                  }}
                  width={width}
                  pathname={checkMobile.toString()}
                >
                  {t("cardView.dealSummary.offer.won")}
                </WonButton>
                <LostButton
                  onClick={toggleModal}
                  width={width}
                  pathname={checkMobile.toString()}
                >
                  {t("cardView.dealSummary.offer.lost")}
                </LostButton>
              </ButtonWrapper>
              <OfferSubText>
                * {t("cardView.dealSummary.offer.subText")}
              </OfferSubText>
            </>
          )}

          {result === "won" && (
            <>
              <div>{t("cardView.dealSummary.offer.won")}</div>
              {/* <OrderInformation /> */}
            </>
          )}
          {result === "lost" && (
            <LostContainer>
              <Row pathname={checkMobile}>
                <LostTitle pathname={checkMobile}>
                  <p>{t("cardView.dealSummary.offer.lostTime")}:</p>
                </LostTitle>
                <LostText>
                  <p>{lostTime && lostTime}</p>
                </LostText>
              </Row>
              {checkMobile && <DividerLine />}
              <Row pathname={checkMobile}>
                <LostTitle pathname={checkMobile}>
                  <p>{t("cardView.dealSummary.offer.lostReason")}:</p>
                </LostTitle>
                {edit === "reason" ? (
                  <EditSelect
                    // placeholder="Select a lost reason"
                    value={lostReason && lostReason.name}
                    defaultOpen={true}
                    autoFocus={true}
                    onBlur={() => setEdit("")}
                  >
                    {lostReasonList}
                  </EditSelect>
                ) : (
                  <LostText onClick={() => setEdit("reason")}>
                    <p>{lostReason && lostReason.name}</p>
                  </LostText>
                )}
              </Row>
              {checkMobile && <DividerLine />}
              <Row pathname={checkMobile}>
                <LostTitle pathname={checkMobile}>
                  <p>{t("cardView.dealSummary.offer.comments")}:</p>
                </LostTitle>
                {edit === "comments" ? (
                  <EditInput
                    value={lostDescription}
                    onChange={(e) => setLostDescription(e.target.value)}
                    autoFocus
                    onBlur={updateLostDescription}
                  />
                ) : (
                  <LostText onClick={() => setEdit("comments")}>
                    <p>{lostDescription && lostDescription}</p>
                  </LostText>
                )}
              </Row>
            </LostContainer>
          )}
          <LostSettingModal
            title={
              <ModalTitle>
                {t("cardView.dealSummary.offer.modalTitle")}
              </ModalTitle>
            }
            visible={visible}
            onCancel={toggleModal}
            footer={null}
            width={checkMobile ? "90%" : "40%"}
            destroyOnClose
          >
            <LostReasonSetting
              id={id}
              toggleModal={toggleModal}
              handleChangeOfferResult={handleChangeOfferResult}
              lostReasonData={lostReasonData}
            />
          </LostSettingModal>
        </BodyContainer>
      </OutsideWrapper>
    </>
  );
};

export default withTranslation()(Offer);

// const DealSummaryTitle = styled(comps.CardViewInnerSectionTitleStyle)``;

const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)`
  margin-top: ${(props) => (props.pathname ? "0" : "12px")};
  ${(props) =>
    props.pathname
      ? "border: 1px solid #ffffff;"
      : "border: 1px solid #d6dee5;"}
`;

const Divider = styled.div`
  background-color: #f4f4f4;
  height: 10px;
`;

const OfferTitle = styled.h3`
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const OfferSubText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
  margin-top: 15px;
`;

const BodyContainer = styled(comps.CardViewInnerSectionBodyStyle)`
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.pathname && "margin-top: 20px"}
`;

const WonButton = styled(PrimaryButton)`
  width: ${(props) => (props.pathname === "true" ? props.width / 2 : 100)}px;
  /* width: 100px; */
`;

const LostButton = styled(DeleteButton)`
  width: ${(props) => (props.pathname === "true" ? props.width / 2 : 100)}px;
  /* width: 100px; */
  margin-left: ${(props) => (props.pathname ? 17 : 8)}px;
`;

const LostContainer = styled.div``;

const LostSettingModal = styled(CloseModal)``;

const ModalTitle = styled.h2`
  ${({ theme }) => theme.fontSizes.txt_h2};
`;

const Row = styled.div`
  display: flex;
  ${(props) =>
    props.pathname && "justify-content: flex-start; align-items: flex-start;"}
  height: 32px;
  margin: ${(props) => (props.pathname ? "20px 0" : "32px 0")};
  :nth-child(n + 2) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.color_base_regular};
    }
  }
`;

const DividerLine = styled.div`
  background-color: #e6e6e6;
  height: 1px;
`;

const LostTitle = styled.div`
  width: ${(props) => (props.pathname ? "40%" : "20%")};
  ${({ theme }) => theme.fontSizes.txt_h5};
  padding-right: 12px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.color_base_white};
`;

const LostText = styled.div`
  width: 80%;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  display: flex;
  align-items: center;
  cursor: auto;
`;

const EditInput = styled(Input)`
  width: 80%;
  padding: 0 !important;
  &:focus {
    background-color: ${({ theme }) =>
      theme.colors.color_base_white} !important;
  }
`;

const EditSelect = styled(Select)`
  width: 80%;
  &:focus {
    background-color: ${({ theme }) =>
      theme.colors.color_base_white} !important;
  }

  .ant-select-selector {
    padding: 0 !important;
  }
`;
