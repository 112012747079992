import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Select,
  Option,
  Input,
  DatePicker,
  MemberIcon,
  TimePicker,
  badge_default,
  Checkbox,
} from "ui";
import styled from "styled-components";
import moment from "moment";
import { Modal } from "ui";
import * as api from "common/ApiAxios";
import { useTranslation } from "react-i18next";
const AddSchedule = ({
  addScheduleOpen,
  setAddScheduleOpen,
  endVal,
  startVal,
  calendarRef,
  resetData,
  id,
  noCard,
  addSchedule,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [start, setStart] = useState(startVal ? startVal : "");
  const [startTime, setStartTime] = useState("00:00:00");
  const [allDay, setAllDay] = useState(true);
  const [end, setEnd] = useState(endVal ? endVal : "");
  const [endTime, setEndTime] = useState("23:59:59");
  const [snippetList, setSnippetList] = useState([]);
  const [cardSearchVal, setCardSearchVal] = useState(null);
  const [cardID, setCardID] = useState(id ? id : "");
  // const [cardName, setCardName] = useState("");
  const selectRef = useRef();
  const cardOptions = () =>
    snippetList.map((snippet, i) => (
      <Option key={snippet.id} title={snippet.name}>
        <RelationalPosition>
          {snippet.board.name} {">"} {snippet.card_list}
        </RelationalPosition>
        <MyCard hoverable={true}>
          <CardName>{snippet.name}</CardName>
          <InputRow style={{ paddingTop: 5 }} customer={snippet.customer}>
            {snippet.customer && (
              <CustomerName>{snippet.customer}</CustomerName>
            )}
            <InputTitle width={140}>{snippet.supplier}</InputTitle>
          </InputRow>
          <UserInfoRow>
            {snippet.user && (
              <MemberIcon info={true} member={snippet.user}></MemberIcon>
            )}
          </UserInfoRow>
        </MyCard>
      </Option>
    ));
  function handleSearch(value) {
    value && value.length > 0 && selectRef.current
      ? api.searchCardSnippetsAxios(value).then((res) => {
          selectRef.current &&
            res.config.url.split("/?search=")[1] ===
              selectRef.current.props.value &&
            setSnippetList(res.data);
        })
      : setSnippetList([]);
  }
  useEffect(() => {
    id && setCardID(id);
  }, [id]);
  useEffect(() => {
    startVal && setStart(startVal);
  }, [startVal]);
  useEffect(() => {
    // let tmp = moment(endVal, "YYYY-MM-DD");
    // setEnd(
    //   moment(endVal, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD")
    // );
    endVal && setEnd(endVal);
  }, [endVal]);
  const timeString = (date, time) => {
    if (date === "") {
      return "";
    } else {
      if (time === "") {
        return date;
      } else {
        return `${date}T${time}`;
      }
    }
  };
  const resetter = () => {
    setTitle("");
    setStart(startVal ? startVal : "");
    setStartTime("00:00:00");
    setEnd(endVal ? endVal : "");
    setEndTime("23:59:59");
    noCard && setCardID("");
    // noCard && setCardName("");
    noCard && setCardSearchVal(null);
  };
  const handleOk = () => {
    if (start !== "" && title !== "" && cardID !== "") {
      api
        .createScheduleAxios({
          card_id: cardID,
          start_date: timeString(start, allDay ? "00:00:00" : startTime),
          deadline: timeString(end, allDay ? "00:00:00" : endTime),
          is_done: false,
          allDay,
          title,
        })
        .then((res) => {
          calendarRef &&
            calendarRef.current._calendarApi.view.calendar.addEvent(
              res.data
              //   {
              //   id: res.data.id,
              //   title: res.data.title,
              //   start: res.data.start,
              //   end: res.data.end,
              //   allDay: res.data.allDay,
              //   card: res.data.card,
              //   board: res.data.board,
              // }
            );
          addSchedule &&
            addSchedule(
              res.data
              // {
              //is_done: false,
              // id: res.data.id,
              // card: res.data.card,
              // board: res.data.board,
              // start: res.data.start,
              // end: res.data.end,
              // title: res.data.title,
              // allDay: res.data.allDay,
              // }
            );
          resetter();
          resetData && resetData();
        });

      setAddScheduleOpen(false);
    }
  };
  const handleCancel = () => {
    resetter();
    resetData && resetData();
    setAddScheduleOpen(false);
  };
  const handleAllDay = () => {
    allDay
      ? setEnd(
          moment(end, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD")
        )
      : setEnd(moment(end, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"));
    setAllDay(!allDay);
  };
  return (
    <AddScheduleModal
      onCancel={handleCancel}
      onOk={handleOk}
      visible={addScheduleOpen}
      destroyOnClose
      title={<Title>Add Schedule</Title>}
    >
      <AddScheduleContainer>
        <InputRow>
          <Required>Schedule</Required>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </InputRow>
        {noCard && (
          <InputRow>
            <Required>Select Card</Required>
            <SnippetSelect
              onClick={(e) => e.stopPropagation()}
              placeholder={t(
                "email.emailConnect.emailConnectExist.selectPlaceholder"
              )}
              showSearch
              ref={selectRef}
              // showSearch={cardSearchVal && cardSearchVal.length > 2}
              value={cardSearchVal}
              // onChange={(value) => {
              // }}
              onSelect={(val, option) => {
                setCardID(val.slice(5));
                // setCardName(option.title);
                setCardSearchVal(option.title);
              }}
              onSearch={(value) => {
                setCardSearchVal(value);
                value && value.length > 0
                  ? api.searchCardSnippetsAxios(value).then((res) => {
                      handleSearch(value);
                    })
                  : setSnippetList([]);
              }}
              defaultActiveFirstOption={false}
              filterOption={false}
              optionLabelProp="title"
              listHeight={400}
            >
              {cardOptions()}
            </SnippetSelect>
          </InputRow>
        )}
        <TimeContainer allDay={allDay}>
          <TimeRow>
            <TimeBlock>
              <Required>Start</Required>
              <DatePicker
                allowClear={false}
                value={start !== "" && moment(start, "YYYY-MM-DD")}
                format="YYYY-MM-DD"
                onChange={(date, dateString) => {
                  setStart(dateString);
                }}
              />
            </TimeBlock>
            {!allDay && (
              <TimeBlock>
                <InputTitle>Start Time</InputTitle>
                <TimePicker
                  // defaultValue
                  allowClear={false}
                  defaultValue={
                    startTime !== "" && moment(startTime, "HH:mm:ss")
                  }
                  defaultOpenValue={moment("11:00:00", "HH:mm:ss")}
                  onChange={(time, timeString) => setStartTime(timeString)}
                />
              </TimeBlock>
            )}
          </TimeRow>
          <TimeRow>
            <TimeBlock>
              <Required>End</Required>
              <DatePicker
                allowClear={false}
                value={
                  allDay
                    ? end !== "" &&
                      moment(end, "YYYY-MM-DD").subtract(1, "days")
                    : end !== "" && moment(end, "YYYY-MM-DD")
                  // .subtract(1, "days")
                }
                format="YYYY-MM-DD"
                onChange={(date, dateString) => {
                  setEnd(
                    allDay
                      ? moment(dateString, "YYYY-MM-DD")
                          .add(1, "days")
                          .format("YYYY-MM-DD")
                      : moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD")
                  );
                }}
              />
            </TimeBlock>
            {!allDay && (
              <TimeBlock>
                <InputTitle> End Time</InputTitle>
                <TimePicker
                  allowClear={false}
                  defaultValue={endTime !== "" && moment(endTime, "HH:mm:ss")}
                  defaultOpenValue={moment("11:00:00", "HH:mm:ss")}
                  onChange={(time, timeString) => {
                    setEndTime(timeString);
                  }}
                />
              </TimeBlock>
            )}
          </TimeRow>
        </TimeContainer>
        <AllDayChecker checked={!allDay} onClick={handleAllDay}>
          Select Time
        </AllDayChecker>
      </AddScheduleContainer>
    </AddScheduleModal>
  );
};

export default AddSchedule;

const AllDayChecker = styled(Checkbox)``;

const TimeContainer = styled.div`
  display: ${({ allDay }) => allDay && "flex"};
`;

const CustomerName = styled(badge_default)``;

const UserInfoRow = styled.div`
  margin-top: 8px;
  text-align: right;
`;

const SnippetSelect = styled(Select)`
  /* width: 400px; */
  width: 100%;
`;

const RelationalPosition = styled.div`
  word-break: break-all;
  font-size: 10px;
  padding-bottom: 3px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.color_text_placeholder};
  line-height: 15px;
`;

const CardName = styled.div`
  word-break: break-all;
  padding: 3px 0;
  font-size: ${({ theme }) => theme.fontSizes.txt_h4};
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_dark};
`;

const MyCard = styled(Card)`
 margin: 0;
  background-color: ${({ theme }) => theme.colors.color_base_white};
  /* padding-top: 0; */
  /* border: 1px solid ${({ theme }) => theme.colors.color_base_dark}; */
  .ant-card-body {
    padding: 5px 15px;
  }
  border-radius: 4px;
  /* box-shadow: ${({ theme }) => theme.elevations.elevation_light},
    ${({ theme }) => theme.elevations.elevation_heavy}; */
`;

const InputTitle = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
  width: 100%;
  margin-bottom: 4px;
`;

const Required = styled(InputTitle)`
  &:after {
    content: " *";
    color: red;
  }
`;

const InputRow = styled.div`
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
  flex-direction: column;
  /* margin: 15px 5px; */
  margin-bottom: 24px;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h2};
  margin: 5px;
`;

const TimeRow = styled.div`
  width: 100%;
  display: flex;
  margin: 12px 0;
`;

const TimeBlock = styled.div`
  width: 100%;
`;

const AddScheduleModal = styled(Modal)``;

const AddScheduleContainer = styled.div`
  margin-top: 32px;
`;
