import styled from "styled-components";
import { Menu as antMenu } from "antd";

export const Menu = styled(antMenu)`
  /* border: 1px solid ${({ theme }) =>
    theme.colors.color_base_regular} !important;
  &:hover {
    border: 1px solid ${({ theme }) =>
      theme.colors.color_base_regular}!important;
  }
  &:focus {
    border-color: ${({ theme }) =>
      theme.colors.color_primary_regular} !important;
    box-shadow: none !important;
  } */
`;
