import { handleActions, createAction } from "redux-actions";

export const SEND_ACTION = "notificationSocket/SEND";
const RECIEVE_ACTION = "notificationSocket/RECIEVE";

export const sendNotification = createAction(SEND_ACTION);
export const recieveNotification = createAction(RECIEVE_ACTION);

const initialState = {
  user_id: undefined,
  team_id: undefined,
  board_id: undefined,
  card_id: undefined,
  msg_type: undefined,
  type: undefined,
  multi_edit: undefined,
};

const notifications = handleActions(
  {
    [RECIEVE_ACTION]: (state, { payload: info, type }) => {
      return {
        ...info,
        type: type,
      };
    },
    [SEND_ACTION]: (state, { payload: info, type }) => {
      return {
        ...info,
        type: type,
      };
    },
  },
  initialState
);

export default notifications;
