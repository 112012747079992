import { emailAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useConnectingEmailThreads = () => {
  async function getConnectingCardList() {
    const { data } = await emailAPIs.getConnectingCardListAxios();
    return data;
  }
  return useQuery({
    queryKey: "get/email/threads/saving/",
    queryFn: getConnectingCardList,
    placeholderData: [],
  });
};
