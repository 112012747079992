import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "fast-text-encoding/text";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "Routes";
import { ThemeProvider } from "styled-components";
import theme from "style/theme";
import { Provider } from "react-redux";
import { MixpanelContext } from "analytics/MixPanelContext";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "modules";
import { rootSaga } from "modules/index";
import "bootstrap/dist/css/bootstrap.css";
// import { axios } from "common/ApiAxios";
import "overlayscrollbars/css/OverlayScrollbars.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "i18n"; // 다국어 지원 파일
import "antd/dist/antd.css";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga";
import { TRACKING_ID } from "./Config";

ReactGA.initialize(TRACKING_ID);
mixpanel.init("e0c6233ce9a4c94e12305ebd9e464db0");
if (
  process.env.production !== undefined &&
  process.env.production.REACT_APP_API_URL !== "https://app.tradir.io/api/"
) {
  mixpanel.disable();
}
Sentry.init({
  dsn:
    "https://f4ffe713f4bb42ad81c75fbbb58d1ff7@o422131.ingest.sentry.io/5569159",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "Non-Error promise rejection captured",
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
// const token = useSelector((state) => state.token);

// axios.interceptors.request.use(
//   function (config) {
//     if (localStorage.getItem("token")) {
//       config.headers["Authorization"] = `Bearer ${localStorage.getItem(
//         "token"
//       )}`;
//     }
//     return config;
//   },
//   function (error) {
//     // 요청 에러 직전 호출됩니다.
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   (res) => res,
//   (error) => {
//     // alert("Error happened");
//     // this.setState({ error });
//   }
// );

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(sagaMiddleware)
    // ,
    // (window as any).__REDUX_DEVTOOLS_EXTENSION__
    //   ? composeWithDevTools()
    //   : (f) => f
  )
);

sagaMiddleware.run(rootSaga);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <MixpanelContext.Provider value={mixpanel}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    </MixpanelContext.Provider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <MixpanelContext.Provider value={mixpanel}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </Provider>
    </MixpanelContext.Provider>,
    rootElement
  );
}

// ReactDOM.render(
//   <MixpanelContext.Provider value={mixpanel}>
//     <Provider store={store}>
//       <ThemeProvider theme={theme}>
//         <Routes />
//       </ThemeProvider>
//     </Provider>
//   </MixpanelContext.Provider>,
//   document.getElementById("root")
// );
