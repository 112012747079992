import React from "react";
import styled from "styled-components";

const PageNavRow = ({ focused, children, onClick }) => {
  return (
    <PageNaveRowContainer onClick={onClick} focused={focused}>
      <PageNaveRowInner focused={focused}>{children}</PageNaveRowInner>
    </PageNaveRowContainer>
  );
};

export default PageNavRow;

const PageNaveRowInner = styled.div`
  border-radius: 8px;
  width: 100%;
  background-color: ${({ focused, theme }) =>
    focused && theme.colors.color_base_dark};
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
    color: ${({ theme }) => theme.colors.color_text_body};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
    color: ${({ theme }) => theme.colors.color_text_body};
  }
  padding: 8px 16px;
  line-height: 20px;
`;

const PageNaveRowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  font-weight: ${({ focused }) => focused && "bold"};
  padding: 0 16px;
  /* border-bottom-right-radius: 10px;
  border-top-right-radius: 10px; */
  color: ${(props) =>
    props.tab === props.type && props.theme.colors.color_text_body};
  cursor: pointer;
`;
