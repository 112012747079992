import { globalAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useTeamMetaInfo = () => {
  async function getTeamMetaInfo(props) {
    const { data } = await globalAPIs.getTeamMetaInfoAxios();
    return data;
  }
  return useQuery({
    queryKey: "get/user/team/meta_info/",
    queryFn: getTeamMetaInfo,
    placeholderData: {
      loading: true,
      all_currencies: [],
      all_quantity_units: [],
      all_countries: [],
      local_trial_end_at: "",
      auth_types: [],
      offer_results: [],
      synced_state: false,
      user: {
        id: 0,
        team_id: 0,
        team_name: "",
        user_email: "",
        user_name: "",
        auth: "",
        user_profile_image: "",
        email_account: null,
        manager_boards: [],
        language: "",
        tutorial_progress: {
          create_board: false,
          invite_user: false,
          customize_stages: false,
          fill_profile: false,
          sync_email: false,
        },
      },
      syncedEmails: [],
      file_upload_limit: {
        max_mbs: "10MB",
        max_bits: "10485760",
      },
      has_paid_plan: false,
      subscription: {
        is_trial: false,
        is_active: true,
        plan: "",
        local_trial_end_at: "Mar 30, 4000",
      },
    },
  });
};
