import { handleActions } from "redux-actions";

const UPDATE = "currentBoardsSaga/UPDATE_SUCCESS";

const initialState = [{ id: "", name: "" }];

const currentBoards = handleActions(
  {
    [UPDATE]: (state, { payload: info }) => info,
  },
  initialState
);

export default currentBoards;
