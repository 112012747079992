import React, { useState } from "react";
import styled, { css } from "styled-components";
import { editPaymentRowAxios, deletePaymentRowAxios } from "common/ApiAxios";
import Icons from "assets/Icons";
import InputBlock from "pages/DealCardDetailView/components/Finance/InputBlock";
import DateBlock from "pages/DealCardDetailView/components/Finance/DateBlock";
import useCheckPath from "hooks/useCheckPath";

const InvoiceItem = ({ item, resetData }) => {
  const [paymentName, setPaymentName] = useState(item.name);
  const [paymentNameEdit, setPaymentNameEdit] = useState(false);
  const [amount, setAmount] = useState(item.amount);
  const [amountEdit, setAmountEdit] = useState(false);
  const [paymentDate, setPaymentDate] = useState(item.payment_date_read);
  const [remark, setRemark] = useState(item.remark);
  const [remarkEdit, setRemarkEdit] = useState(false);

  const checkMobile = useCheckPath("mobile");

  return (
    <InvoiceRow isMobile={checkMobile}>
      <InputBlock
        edit={paymentNameEdit}
        setEdit={setPaymentNameEdit}
        value={paymentName}
        setValue={setPaymentName}
        axios={editPaymentRowAxios}
        invoiceID={item.id}
        type={"name"}
        width={!checkMobile ? 30 : 100}
      />
      <InputBlock
        edit={amountEdit}
        setEdit={setAmountEdit}
        value={amount}
        setValue={setAmount}
        axios={editPaymentRowAxios}
        invoiceID={item.id}
        type={"amount"}
        width={!checkMobile ? 18 : 100}
        inputType={"number"}
        resetData={resetData}
      />
      <DateBlock
        value={paymentDate}
        setValue={setPaymentDate}
        axios={editPaymentRowAxios}
        invoiceID={item.id}
        type={"payment_date"}
        width={!checkMobile ? 18 : 100}
      />
      <InputBlock
        edit={remarkEdit}
        setEdit={setRemarkEdit}
        value={remark}
        setValue={setRemark}
        axios={editPaymentRowAxios}
        invoiceID={item.id}
        type={"remark"}
        width={!checkMobile ? 29 : 100}
      />
      {!checkMobile && (
        <RowBlock
          width={5}
          onClick={() => deletePaymentRowAxios(item.id).then(() => resetData())}
          className="delete"
        >
          <Icons type="fas" name="trash" />
        </RowBlock>
      )}
    </InvoiceRow>
  );
};

export default InvoiceItem;

const RowBlock = styled.div`
  width: ${({ width }) => width}%;
  padding: 3px 2px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InvoiceRow = styled.div`
  display: flex;
  width: 100%;
  ${(props) => !props.isMobile && "padding: 8px 0;"}
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_darker};
  .delete {
    display: none;
    opacity: 0.7;
  }
  &:hover .delete {
    display: flex;
  }
  ${(props) =>
    props.isMobile &&
    css`
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    `}
`;
