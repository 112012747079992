const axios = require("axios").default;
export { axios };
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
axios.defaults.headers.post["Content-Type"] = "application/json";
export const emptyAxios = axios;
export const authAxios = axios;
export const authAxios2 = axios;
authAxios2.defaults.headers.post["Content-Type"] = "multipart/form-data";
export const authAxiosDownload = require("axios").default;

//Boards
export const dealBoardAPIs = {
  getAccountBoardsAxios() {
    return authAxios.get("/board/?fields=access_granted,has_access,id,name");
  },
  createBoardAxios(name) {
    return authAxios.post("/board/", name);
  },
  deleteBoardAxios(id) {
    return authAxios.delete(`/board/${id}/`);
  },
  editBoardAxios(id, data) {
    return authAxios.patch(`/board/${id}/`, data);
  },
  getNavBoardsAxios() {
    return authAxios.get("/board/?fields=id,name,has_access");
  },
  getBoardDetailAxios(id) {
    return authAxios.get(`/board/${id}/`);
  },
  getBoardAccessAxios(id, query) {
    return authAxios.get(`/board/access/${id}/${query ? query : ""}`);
  },
  patchBoardAccessAxios(id, data) {
    return authAxios.patch(`/board/access/${id}/`, data);
  },
};

//TableView
export const dealBoardTableViewAPIs = {
  getAllBoardTableColumnsAxios() {
    return authAxios.get(
      "/board_table_columns/all_columns/?board_status=active"
    );
  },
  getBoardTableColumnsAxios() {
    return authAxios.get("/user/settings/?field=columns&board_status=active");
  },
  editBoardTableColumnsAxios(data) {
    return authAxios.patch(
      "/user/settings/?field=columns&board_status=active",
      data
    );
  },
  tableViewAxios(id) {
    return authAxios.get(`/card/board_table/${id}/`);
  },
};

//ArchivedView
export const dealBoardArchivedViewAPIs = {
  getAllArchivedColumnsAxios() {
    return authAxios.get(
      "/board_table_columns/all_columns/?board_status=archive"
    );
  },
  getArchivedColumnsAxios() {
    return authAxios.get("/board_table_columns/?board_status=archive");
  },
  editArchivedColumnsAxios(data) {
    return authAxios.patch("/board_table_columns/?board_status=archive", data);
  },
  getArchivedViewAxios(id) {
    return authAxios.get(`/card/board_archived/${id}/`);
  },
};

//Lists
export const listsAPIs = {
  getCardListAxios(id) {
    return authAxios.get(`/cardlist/?board_id=${id}&fields=id,name`);
  },
  boardInfoAxios(id) {
    return authAxios.get(`/cardlist/?board_id=${id}`);
  },
  createCardListAxios(info) {
    return authAxios.post("/cardlist/", info);
  },
  removeCardListAxios(id) {
    return authAxios.delete(`/cardlist/${id}/`);
  },
  changeCardListAxios(id, data) {
    return authAxios.put(`/cardlist/${id}/`, data);
  },
};

//Deal Card
export const dealCardAPIs = {
  createCardAxios(info) {
    return authAxios.post("/card/", info);
  },
  removeCardAxios(id) {
    return authAxios.delete(`/card/${id}/`);
  },
  archiveCardsAxios(list) {
    return authAxios.post("/cards/archive/", list);
  },
  cardsOwnerUpdateAxios(data) {
    return authAxios.post("/cards/owner/update/", data);
  },
  cardsMembersUpdateAxios(data) {
    return authAxios.post("/cards/members/update/", data);
  },
  updateCardProductAxios(data) {
    return authAxios.patch("/cards/product/update/", data);
  },
  getCardViewInfoAxios(id) {
    return authAxios.get(`/card/${id}/`);
  },
  getCardViewPartialInfoAxios(id, string) {
    return authAxios.get(`/card/${id}/${string}`);
  },
  getDealDescriptionAxios(id, data) {
    return authAxios.get(`/card/${id}/`, data);
  },
  getCardViewNotificationAxios(id) {
    return authAxios.get(`/card_notification/?card_id=${id}`);
  },
  // Archived Card Archived, Unarchived 버튼 동작
  putCardViewAxios(id, data) {
    return authAxios.put(`/card/${id}/`, data);
  },
  // 멤버 권한
  getCardAccessAxios(id) {
    return authAxios.get(`/card/access/${id}/`);
  },
  putCardAccessAxios(id, data) {
    return authAxios.put(`/card/access/${id}/`, data);
  },
  putOfferResultAxios(id, data) {
    return authAxios.put(`/card/${id}/`, data);
  },
  patchDealDescriptionAxios(id, value) {
    return authAxios.patch(`/card/${id}/`, value);
  },
  putAddArchivedCardAxios(id, data) {
    return authAxios.put(`/card/${id}/`, data);
  },
};

// MyNote 텍스트 전송
export const dealCardMyNoteAPIs = {
  postMyNoteAxios(data) {
    return authAxios.post(`/mynote/`, data);
  },
  putMyNoteAxios(id, data) {
    return authAxios.put(`/mynote/${id}/`, data);
  },
};

// DealCardDetailView -> Shared Attachment

export const dealCardSharedAttachmentsAPIs = {
  getSharedAttachmentAxios(id) {
    return authAxios.get(`/attachment/?card_id=${id}`);
  },
  postSharedAttachmentAxios(data) {
    authAxios2.post(`/attachment/`, data);
  },
  patchSharedAttachmentAxios(uid, id, data) {
    authAxios2.patch(`/attachment/${uid}/?card_id=${id}`, data);
  },
  deleteSharedAttachmentAxios(uid, id) {
    authAxios2.delete(`/attachment/${uid}/?card_id=${id}`);
  },
  editSpreadSheetAxios(id, data) {
    return authAxios.patch(`/spreadsheet/${id}/`, data);
  },
  createSpreadSheetAxios(data) {
    return authAxios.post(`/spreadsheet/`, data);
  },
};

// DealCardDetailView -> Private Attachment
export const dealCardMyAttachmentsAPIs = {
  getMyAttachmentAxios(id) {
    return authAxios.get(`/myattachment/?card_id=${id}`);
  },
  postMyAttachmentAxios(data) {
    authAxios2.post(`/myattachment/`, data);
  },
  patchMyAttachmentAxios(uid, id, data) {
    authAxios2.patch(`/myattachment/${uid}/?card_id=${id}`, data);
  },
  deleteMyAttachmentAxios(uid, id) {
    authAxios2.delete(`/myattachment/${uid}/?card_id=${id}`);
  },
  editMySpreadSheetAxios(id, data) {
    return authAxios.patch(`/my_spreadsheet/${id}/`, data);
  },
  createMySpreadSheetAxios(data) {
    return authAxios.post(`/my_spreadsheet/`, data);
  },
};

// DealCardDetailView => Order Information
export const dealCardOrderInformationAPIs = {
  getOrderInfoAxios(id) {
    return authAxios.get(`/order/${id}/`);
  },
  postOrderInfoAxios(data) {
    return authAxios.post(`/order/`, data);
  },
  putOrderInfoAxios(id, data) {
    return authAxios.put(`/order/${id}/`, data);
  },
};

// DealCardDetailView => Invoice
export const dealCardInvoiceAPIs = {
  getInvoiceRowsAxios(id) {
    return authAxios.get(`/invoice/?card_id=${id}`);
  },
  createInvoiceRowAxios(data) {
    return authAxios.post("/invoice/", data);
  },
  editInvoiceRowAxios(id, data) {
    return authAxios.patch(`/invoice/${id}/`, data);
  },
  deleteInvoiceRowAxios(id) {
    return authAxios.delete(`/invoice/${id}/`);
  },
};

// DealCardDetailView => Payment
export const dealCardPaymentAPIs = {
  getPaymentRowsAxios(id) {
    return authAxios.get(`/payment/?card_id=${id}`);
  },
  createPaymentAxios(data) {
    return authAxios.post("/payment/", data);
  },
  editPaymentRowAxios(id, data) {
    return authAxios.patch(`/payment/${id}/`, data);
  },
  deletePaymentRowAxios(id) {
    return authAxios.delete(`/payment/${id}/`);
  },
};

// DealCardDetailView => Comment Page
export const dealCardCommentAPIs = {
  // Comment Pagination
  getCommentByPageAxios(id, target) {
    return authAxios.get(
      `${target === "" ? `/comment/?card_id=${id}` : target}`
    );
  },
  getCommentAxios(id) {
    return authAxios.get(`/comment/?card_id=${id}`);
  },
  postCommentAxios(data) {
    return authAxios.post(`/comment/`, data);
  },
  putCommentAxios(uid, id, data) {
    return authAxios.put(`/comment/${uid}/?card_id=${id}`, data);
  },
  deleteCommentAxios(uid, id) {
    return authAxios.delete(`/comment/${uid}/?card_id=${id}`);
  },
};

// DealCardDetailView => Tracking Shipment
export const dealCardShipmentAPIs = {
  getInitialTrackingShipmentAxios(id) {
    return authAxios.get(`/shipment/?card_id=${id}`);
  },
  deleteVesselInfoAxios(id) {
    return authAxios.delete(`/shipment/?card_id=${id}`);
  },
  getVesselListAxios(data) {
    return authAxios.get(`/search_vessel/?input_name=${data}`);
  },
  getVesselInfoAxios(id, data) {
    return authAxios.get(`/shipment/?card_id=${id}&vessel_id=${data}`);
  },
};

// DealCardDetailView => Deal Lost Reason
export const dealCardLostReasonAPIs = {
  getDealLostReasonAxios() {
    return authAxios.get(`/deal_lost_reason/`);
  },
  postDealLostReasonAxios(data) {
    return authAxios.post(`/deal_lost_reason/`, data);
  },
  deleteDealLostReasonAxios(id) {
    return authAxios.delete(`/deal_lost_reason/${id}/`);
  },
  cardsLostReasonUpdateAxios(data) {
    return authAxios.post("/merge_lost_reasons/", data);
  },
};

// DealCardDetailView => Deal ToDo List
export const dealCardTodoListAPIs = {
  getToDoItemAxios(id) {
    return authAxios.get(`/todoitem/?card_id=${id}`);
  },
  postToDoItemAxios(data) {
    return authAxios.post(`/todoitem/`, data);
  },
  deleteToDoItemAxios(todo, id) {
    return authAxios.delete(`/todoitem/${todo}/?card_id=${id}`);
  },
  editToDoItemAxios(todo, id, data) {
    return authAxios.patch(`/todoitem/${todo}/?card_id=${id}`, data);
  },
};

//(ToDo List Page)
export const todoListAPIs = {
  getToDoListAxios() {
    return authAxios.get("/todoitem_list/");
  },
  getDoneToDoListAxios() {
    return authAxios.get("/todoitem_list/?done=False");
  },
  getToDoCountAxios() {
    return authAxios.get("/todoitem/?view=count&done=False");
  },
  editToDoListAxios(id, data) {
    return authAxios.patch(`/todoitem_list/${id}/`, data);
  },
  deleteToDoListAxios(id, data) {
    return authAxios.delete(`/todoitem_list/${id}/`, data);
  },
};

// Settings - My Account
export const userAPIs = {
  getMyProfileAxios() {
    return authAxios.get("/user/profile/");
  },
  putMyProfileAxios(data) {
    return authAxios.put("/user/profile/", data);
  },
  putMyProfileImageAxios(data) {
    authAxios2.put("/user/profile/", data);
  },
  postChangePasswordAxios(data) {
    return authAxios.post("/user/password_change/", data);
  },
  getUserSettingsAxios(queryString) {
    return authAxios.get(`/user/settings/${queryString}`);
  },
  editUserSettingsAxios(data, queryString) {
    return authAxios.patch(`/user/settings/${queryString}`, data);
  },
};

// Settings - Company Profile
export const companyAPIs = {
  getMyCompanyAxios() {
    return authAxios.get(
      "/team/profile/?fields=created_at,company_name,logo_image,address,contact,email,company_type,company_size"
    );
  },
  putMyCompanyAxios(data) {
    return authAxios.put("/team/profile/", data);
  },
  putMyCompanyImageAxios(data) {
    authAxios2.put("/team/profile/", data);
  },
  getAllTeamMembersAxios() {
    return authAxios.get("/team/profile/?fields=members");
  },
};

//Settings - Product
export const productAPIs = {
  createProductAxios(data) {
    return authAxios.post("/product/", data);
  },
  getCardProductAxios(id) {
    return authAxios.get(`/product/?card_id=${id}`);
  },
  getProductListAxios() {
    return authAxios.get("/product/");
  },
  updateProductAxios(id, data) {
    return authAxios.patch(`/product/${id}/`, data);
  },
  deleteProductAxios(id) {
    return authAxios.delete(`/product/${id}/`);
  },
};

// Settings - Subscription/Payment
export const billingAPIs = {
  getSubscriptionAxios() {
    return authAxios.get("/subscription/");
  },
  getPromoInfoAxios() {
    return authAxios.get(
      "/subscription/?fields=local_on_promotion_until,promotion_set"
    );
  },
  cancelSubscriptionAxios() {
    return authAxios.patch("/subscription/", { is_active: false });
  },
  updateSubscriptionAxios(data) {
    return authAxios.patch("/subscription/", data);
  },
  startSubscriptionAxios(data) {
    return authAxios.post("/subscription/", data);
  },
  getBillingListAxios() {
    return authAxios.get("/billing/");
  },
  getBillInfoAxios(id) {
    return authAxios.get(`/billing/${id}/`);
  },
  addPromotionCodeAxios(code) {
    return authAxios.post("/promotion/", code);
  },
};

//Settings - Account Members
export const membersAPIs = {
  getTeamMembersAxios() {
    return authAxios.get("/team_members/");
  },
  editMemberAuthAxios(id, data) {
    return authAxios.patch(`/team_members/${id}/`, data);
  },
  deleteTeamMemberAxios(id) {
    return authAxios.delete(`/team_members/${id}/`);
  },
  inviteTeamMemberAxios(data) {
    return authAxios.post("/user/invitation/", data);
  },
};

// Settings - Email
export const emailAPIs = {
  //get connected mail info
  getSettingEmailAxios() {
    return authAxios.get("/email/account/");
  },
  //edit email lists thread count per page
  putSettingEmailAxios(data) {
    return authAxios.put("/email/account/", data);
  },
  //disconnect connected email
  deleteSettingEmailAxios() {
    return authAxios.delete("/email/account/");
  },
  //connecting email
  getEmailConnectLinkAxios() {
    return authAxios.get("/email/auth/");
  },
  //send backend nylas token after connection
  sendEmailTokenAxios(data) {
    return authAxios.post("/email/auth/", data);
  },
  //get all the folders of the user's email
  getEmailFoldersAxios() {
    return authAxios.get("/email/folders/");
  },
  //apis to get email threads
  getEmailThreadsAxios(querystring) {
    return authAxios.get(`/email/threads/${querystring}`);
  },
  getEmailAxios(querystring) {
    return authAxios.get(`/email/threads/${querystring}`);
  },
  getSendMailAxios(querystring) {
    return authAxios.get(`/email/threads/sent/${querystring}`);
  },
  getTrashMailAxios(querystring) {
    return authAxios.get(`/email/threads/trash/${querystring}`);
  },
  getDraftsAxios(querystring) {
    return authAxios.get(`/email/drafts/${querystring}`);
  },
  //email search
  searchEmailAxios(querystring) {
    return authAxios.get(`/email/threads/search/${querystring}`);
  },
  //edit multiple threads mark as read/unread, send to trash
  editThreadsAxios(data) {
    return authAxios.post("/email/threads/", data);
  },
  //get list of currently connecting cards
  getConnectingCardListAxios() {
    return authAxios.get(`/email/threads/saving/`);
  },
};
//Single Email Thread
export const emailThreadAPIs = {
  //edit single email thread mark as read/unread, send to trash
  editThreadDetailAxios(id, data) {
    return authAxios.put(`/email/threads/detail/${id}/`, data);
  },
  //get single email thread details
  getThreadDetailAxios(id) {
    return authAxios.get(`/email/threads/detail/${id}/`);
  },
  //single message of a thread
  getMessageBodyAxios(id) {
    return authAxios.get(`/email/messages/${id}/`);
  },
  //edit single message of a thread
  editMessageDetailAxios(id, data) {
    return authAxios.put(`/email/messages/${id}/`, data);
  },
  //check if emails in the email thread exists in contact points
  getCheckEmailAxios(string) {
    return authAxios.get(`/contacts/check_email/${string}`);
  },
  //search both supplier and customer list to connect to new contact point
  getContactSuggestionsAxios() {
    return authAxios.get("/contacts/suggest/account/?search=");
  },
  //get cards connected to thread
  getConnectedCards(id) {
    return authAxios.get(`/email/connected_cards/?thread_id=${id}`);
  },
  //search for cards to sync
  searchCardSnippetsAxios(input) {
    return authAxios.get(`/email/threads/sync/cards/?search=${input}`);
  },
  //get single card info for redirected connecting from card
  searchCardSnippetAxios(id) {
    return authAxios.get(`/email/threads/sync/cards/${id}/`);
  },
  //connect card to email thread(s)
  connectCardToEmailAxios(data) {
    return authAxios.post("/email/threads/sync/", data);
  },
  connectNewCardToEmailAxios(data) {
    return authAxios.post("threads/sync/new_card/", data);
  },
  disconnectThreadAxios(data) {
    return authAxios.post("/email/threads/unsync/", data);
  },
};

//Email compose
export const emailComposeAPIs = {
  //send email
  sendEmailAxios(data) {
    return authAxios.post("/email/direct_send/", data);
  },
  //resend email on email send fail
  resendEmailAxios(data) {
    return authAxios.put("/email/direct_send/", data);
  },
  //get suggested email address from contact points
  getEmailSuggestionsAxios() {
    return authAxios.get("/contacts/suggest/accountcontact/?search=");
  },
  //add attachment to email compose
  postEmailFileAxios(data) {
    authAxios2.post("/email/file/upload/", data);
  },
  //remove attachment from email compose
  deleteEmailFileAxios(id) {
    return authAxios.delete(`/email/file/detail/${id}/`);
  },
  //download the uploaded attachment
  downloadEmailFileAxios(id) {
    return authAxios.get(`/email/file/download/${id}/`, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
//email draft
export const emailDraftAPIs = {
  getDraftDetailAxios(id) {
    return authAxios.get(`/email/drafts/detail/${id}/`);
  },
  createDraftAxios(data) {
    return authAxios.post("/email/drafts/create/", data);
  },
  editDraftAxios(id, data) {
    return authAxios.put(`/email/drafts/detail/${id}/`, data);
  },
  deleteDraftAxios(id) {
    return authAxios.delete(`/email/drafts/detail/${id}/`);
  },
  deleteDraftsAxios(data) {
    return authAxios.post("/email/drafts/delete/", data);
  },
  postDraftAxios(data) {
    return authAxios.post("/email/drafts/send/", data);
  },
};

export const dealCardEmailAPIs = {
  //get email threeads connected to dealcard
  getConnectedThreadsAxios(id, string) {
    return authAxios.get(`/email/card_threads/?card_id=${id}${string}`);
  },
  //get thread messages of a connected thread
  getThreadMessagesAxios(id) {
    return authAxios.get(`/email/thread_messages/?thread_id=${id}`);
  },
  //thread messages pagination
  getMoreThreadMessagesAxios(string) {
    return authAxios.get(`/email/thread_messages/${string}`);
  },
  //get entire thread message
  getThreadMainAxios(messageID, threadID) {
    return authAxios.get(
      `/email/thread_messages/${messageID}/?thread_id=${threadID}&full_body=true`
    );
  },
  editThreadSharedAxios(id, card_id, data) {
    return authAxios.patch(
      `/email/card_threads/${id}/?card_id=${card_id}`,
      data
    );
  },
  emailToAttachmentsAxios(data) {
    return authAxios.post("/email/file/to_attachment/", data);
  },
};

//Contacts Page
export const contactsAPIs = {
  getOwnedContactsAxios(type) {
    return authAxios.get(`/contacts/owned/?account_type=${type}`);
  },
  createBulkContactAxios(data) {
    return authAxios.post("/contacts/create/bulk/", data);
  },
  deleteMultipleContactsAxios(type, ids) {
    return authAxios.post("/contacts/delete/", {
      account_type: type,
      account_ids: ids,
    });
  },
  removeMultipleContactsMembersAxios(type, ids, user_ids) {
    return authAxios.post("/contacts/members/update/", {
      account_type: type,
      account_ids: ids,
      user_remove_ids: user_ids,
    });
  },
  addMultipleContactsMembersAxios(type, ids, user_ids) {
    return authAxios.post("/contacts/members/update/", {
      account_type: type,
      account_ids: ids,
      user_add_ids: user_ids,
    });
  },
  changeMultipleContactsOwnerAxios(type, ids, user_id, bool) {
    return authAxios.post("/contacts/owner/update/", {
      account_type: type,
      account_ids: ids,
      new_owner_id: user_id,
      keep_access: bool,
    });
  },
  getContactsByPageAxios(type, pageSize, targetNum) {
    return authAxios.get(
      `/${type}/list/?limit=${pageSize}&offset=${targetNum}`
    );
  },
  getContactsByPageSearchAxios(type, string, pageSize, targetNum) {
    return authAxios.get(
      `/${type}/list/?search=${string}&limit=${pageSize}&offset=${targetNum}`
    );
  },
};

// Contact Detail Page
export const contactDetailAPIs = {
  getContactTypeAxios(type, string) {
    return authAxios.get(`/${type}/list/${string}`);
  },
  createContactTypeAxios(type, data) {
    return authAxios.post(`/${type}/`, data);
  },
  getContactTypeDetailAxios(type, id) {
    return authAxios.get(`/${type}/${id}/`);
  },
  updateContactTypeDetailAxios(type, id, data) {
    return authAxios.put(`/${type}/${id}/`, data);
  },
  deleteContactTypeDetailAxios(type, id) {
    return authAxios.delete(`/${type}/${id}/`);
  },
  createContactAxios(data) {
    return authAxios.post("/contact/", data);
  },
  getContactAxios(type, typeId) {
    return authAxios.get(
      `/contact/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  updateContactAxios(id, type, typeId, data) {
    return authAxios.put(
      `/contact/${id}/?account_content_type=${type}&account_object_id=${typeId}`,
      data
    );
  },
  deleteContactAxios(id, type, typeId) {
    return authAxios.delete(
      `/contact/${id}/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  createMeetingNote(data) {
    return authAxios.post("/meeting_note/", data);
  },
  getMeetingNote(type, typeId) {
    return authAxios.get(
      `/meeting_note/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  getDetailMeetingNote(id, type, typeId) {
    return authAxios.get(
      `/meeting_note/${id}/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  updateMeetingNote(id, type, typeId, data) {
    return authAxios.put(
      `/meeting_note/${id}/?account_content_type=${type}&account_object_id=${typeId}`,
      data
    );
  },
  deleteMeetingNote(id, type, typeId) {
    return authAxios.delete(
      `/meeting_note/${id}/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  createContactAttachmentAxios(data) {
    return authAxios.post(`/account_attachment/`, data);
  },
  getContactAttachmentAxios(type, typeId) {
    return authAxios.get(
      `/account_attachment/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  getDetailAttachmentAxios(id, type, typeId) {
    return authAxios.get(
      `/account_attachment/${id}/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
  updateContactAttachmentAxios(id, type, typeId, data) {
    return authAxios.put(
      `/account_attachment/${id}/?account_content_type=${type}&account_object_id=${typeId}`,
      data
    );
  },
  deleteContactAttachmentAxios(id, type, typeId) {
    return authAxios.delete(
      `/account_attachment/${id}/?account_content_type=${type}&account_object_id=${typeId}`
    );
  },
};

//Global Team Info
export const globalAPIs = {
  //team meta info
  getTeamMetaInfoAxios() {
    return authAxios.get("/user/team/meta_info/");
  },
  //List of contacts the team has
  getTeamContactsInfoAxios() {
    return authAxios.get("/user/team/contact_info/");
  },
  //Version Check
  versionCheckAxios(platform) {
    return authAxios.get(`/version/check/?platform=${platform}`);
  },
  // Wrapper - Nav - Tutorial progress update
  patchTutorialProgress(data) {
    return authAxios.patch("/user/profile/", data);
  },
};

//Notifications
export const notificationsAPIs = {
  getAllNotificationsAxios() {
    return authAxios.get("/notification/");
  },
  getUnreadNotificationsAxios() {
    return authAxios.get("/notification/?type=unread");
  },
  getNotificationsCountAxios() {
    return authAxios.get("/notification/?type=unread&view=count");
  },
  getAllMentionsAxios() {
    return authAxios.get("/notification/?mention=true");
  },
  getUnreadMentionsAxios() {
    return authAxios.get("/notification/?mention=true&type=unread");
  },
  getMentionsCountAxios() {
    return authAxios.get("/notification/?mention=true&type=unread&view=count");
  },
  editNotificationAxios(data) {
    return authAxios.post(`/notifications/status/`, data);
  },
  deleteNotificationAxios(id) {
    return authAxios.patch(`/notification/${id}/`);
  },
  updateAllNotificationsAxios(data) {
    return authAxios.post(`/notifications/action/`, data);
  },
};

//Sign Up
export const signupAPIs = {
  getMemberSignUpAxios(id, verification) {
    emptyAxios.get(`/user/signup/staff/?id=${id}&verification=${verification}`);
  },
  getCountriesAxios() {
    emptyAxios.get("/country/list/");
  },
  memberSignUpAxios(data) {
    emptyAxios.post("/user/signup/staff/", data);
  },
  getAdminSignUpAxios() {
    emptyAxios.get("/user/signup/admin/");
  },
  adminSignUpAxios(data) {
    emptyAxios.post("/user/signup/admin/", data);
  },
};

//Login
export const loginAPIs = {
  loginAxios(data) {
    return authAxios.post("/user/login/", data, { withCredentials: true });
  },
  //Logout
  logoutAxios() {
    return authAxios.post("/user/logout/");
  },
  //Reset token
  resetTokenAxios() {
    return authAxios.get("/user/refresh/jwtcookie/");
  },
  //Recaptcha
  reCaptchaAxios(token) {
    return authAxios.post(``);
  },
};

// Password Reset
export const passwordResetAPIs = {
  postPasswordResetAxios(data) {
    return authAxios.post("/user/request-reset-email/", data);
  },
  getPasswordResetAxios(uid, token) {
    return authAxios.get(`/user/password-reset/${uid}/${token}/`);
  },
  updatePasswordResetAxios(data) {
    return authAxios.patch(`/user/password-reset-complete/`, data);
  },
};

//REPORTS
export const reportAPIs = {
  getOrderTrendDataAxios(string) {
    return authAxios.get(`/report/order_trend/?${string}`);
  },
  getOrderByDataAxios(string) {
    return authAxios.get(`/report/order_by_category/?${string}`);
  },
  getRevenueTrendAxios(string) {
    return authAxios.get(`/report/revenue_trend/?${string}`);
  },
  getDealLostReasonReportAxios(string) {
    return authAxios.get(`/report/deal/lost_reasons/?${string}`);
  },
  getDealSuccessRateAxios(string) {
    return authAxios.get(`/report/deal/success_rate/?${string}`);
  },
  getReportColumnTypesAxios(type) {
    return authAxios.get(`/report_table/${type}/`);
  },
  updateReportColumnTypesAxios(type, data) {
    return authAxios.patch(`/report_table/${type}/`, data);
  },
  getAllReportColumnTypesAxios() {
    return authAxios.get("/report_table/AllTableFields/");
  },
};

//SCHEDULE
export const calendarAPIs = {
  createScheduleAxios(data) {
    return authAxios.post("/schedule/", data);
  },
  updateScheduleAxios(id, cardID, data) {
    return authAxios.patch(`/schedule/${id}/?card_id=${cardID}`, data);
  },
  deleteScheduleAxios(id, cardID) {
    return authAxios.delete(`/schedule/${id}/?card_id=${cardID}`);
  },
  getSchedulesAxios(string) {
    return authAxios.get(`/schedule/?${string}`);
  },
  getScheduleListAxios(string) {
    return authAxios.get(`/calendar/${string}`);
  },
};

//not used
// export const getExportContractAxios = (id) =>
//   return authAxios.get(`/deal/export_contract/?card_id=${id}`);
// export const deleteCardAxios = (id) => return authAxios.delete(`/card/${id}/`);
// export const getTrackingShipmentAxios = (id, data) =>
//   return authAxios.get(
//     `/deal/shipment/?vessel_name=${data}&card_id=${id}&update=true`
//   );
// export const cookieSetAxios = () => return authAxios.get("user/jwtcookieset/");
