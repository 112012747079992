import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, PageModal } from "ui";
import ContactsDetailView from "pages/Contacts/components/ContactsDetailView/ContactsDetailView";
const ContactLink = ({ type, name, id }) => {
  const [customerID, setCustomerID] = useState(null);
  const [customerVisible, setCustomerVisible] = useState(false);
  const [supplierID, setSupplierID] = useState(null);
  const [supplierVisible, setSupplierVisible] = useState(false);

  useEffect(() => {
    customerID !== null && setCustomerVisible(true);
  }, [customerID]);
  useEffect(() => {
    customerVisible === false && setCustomerID(null);
  }, [customerVisible]);
  useEffect(() => {
    supplierID !== null && setSupplierVisible(true);
  }, [supplierID]);
  useEffect(() => {
    supplierVisible === false && setSupplierID(null);
  }, [supplierVisible]);
  return (
    <>
      <PageModal
        visible={customerVisible || supplierVisible}
        footer={null}
        onCancel={() => {
          customerVisible
            ? setCustomerVisible(false)
            : setSupplierVisible(false);
        }}
        centered={true}
        bodyStyle={{
          backgroundColor: `${({ theme }) => theme.colors.color_base_white}`,
        }}
        width="75%"
        destroyOnClose
      >
        <ContactsDetailView
          type={customerVisible ? "customer" : "supplier"}
          typeId={customerVisible ? customerID : supplierID}
          setDetailViewVisible={
            customerVisible ? setCustomerVisible : setSupplierVisible
          }
        />
      </PageModal>
      <LinkText
        onClick={(e) => {
          e.stopPropagation();
          type === "customer" ? setCustomerID(`${id}`) : setSupplierID(`${id}`);
        }}
      >
        {name}
      </LinkText>
    </>
  );
};

export default ContactLink;

const LinkText = styled(Link)`
  pointer-events: auto !important;
  text-decoration: none !important;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  &:hover {
    text-decoration: underline !important;
  }
`;
