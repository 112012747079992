import { userAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useUserSettingsEdit = (fields = []) => {
  async function editUserSettings({ editData, queryString = "" }) {
    const { data } = await userAPIs.editUserSettingsAxios(
      editData,
      queryString
    );
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(editUserSettings, {
    onMutate: async ({ id, editData }) => {
      console.log(editData, fields);
      await queryClient.cancelQueries(["get/user/settings/", ...fields]);

      const previousValue = queryClient.getQueryData([
        "get/user/settings/",
        ...fields,
      ]);
      queryClient.setQueryData(["get/user/settings/", ...fields], (old) => {
        return old.loading ? { ...editData, loading: true } : editData;
      });
      return previousValue;
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/user/settings/");
    },
  });
};
