import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ClearButton, label_green } from "ui";
import Icons from "assets/Icons";
import { getSchedulesAxios } from "common/ApiAxios";
import * as comps from "../CardComponents";
import ScheduleItem from "./ScheduleItem";
import MobileScheduleItem from "./MobileScheduleItem";
// import { Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import useCheckPath from "hooks/useCheckPath";
// import { sendNotification } from "Modules/Global/notificationSocket";
import { useTranslation, withTranslation } from "react-i18next";
import AddSchedule from "pages/Calendar/components/AddSchedule";
import moment from "moment";
const Schedules = ({ id, match, refresh }) => {
  const [scheduleList, setScheduleList] = useState([]);
  const [addScheduleOpen, setAddScheduleOpen] = useState(false);
  const handleClick = () => {
    setAddScheduleOpen(true);
  };
  useEffect(() => {
    id &&
      getSchedulesAxios(`card_id=${id}`).then((res) =>
        setScheduleList(res.data)
      );
  }, [id]);

  const addSchedule = (schedule) => {
    console.log(schedule);
    setScheduleList([schedule, ...scheduleList]);
  };
  const [height, setHeight] = useState(true);
  const checkMobile = useCheckPath("mobile");
  const { t } = useTranslation();
  return (
    <div>
      <SchedulesContainer isMobile={checkMobile}>
        <AddSchedule
          addScheduleOpen={addScheduleOpen}
          setAddScheduleOpen={setAddScheduleOpen}
          startVal={`${moment().format("YYYY-MM-DD")}`}
          endVal={`${moment().add(1, "days").format("YYYY-MM-DD")}`}
          addSchedule={addSchedule}
          id={id}
        />
        <TitleBox>
          <TitleSec
            onClick={() => setHeight(!height)}
            style={{ cursor: "pointer" }}
          >
            <div>{t("cardView.schedule.title")}</div>
          </TitleSec>
          <LabelGreenText>{t("cardView.schedule.shared")}</LabelGreenText>
        </TitleBox>
        <BodyContainer height={height.toString()}>
          <TableHeader isMobile={checkMobile}>
            <Checkbox isMobile={checkMobile}>
              {t("cardView.schedule.done")}
            </Checkbox>
            <Description isMobile={checkMobile}>
              {t("cardView.schedule.title")}
            </Description>
          </TableHeader>
          {!checkMobile ? (
            <div className="descriptionBox">
              {scheduleList.map((schedule, i) => (
                <ScheduleItem
                  id={id}
                  schedule={schedule}
                  key={schedule.id}
                  setScheduleList={setScheduleList}
                  scheduleList={scheduleList}
                />
              ))}
            </div>
          ) : (
            <div className="descriptionBox">
              {scheduleList.map((schedule, i) => (
                <MobileScheduleItem
                  id={id}
                  schedule={schedule}
                  key={schedule.id}
                  setScheduleList={setScheduleList}
                  scheduleList={scheduleList}
                />
              ))}
            </div>
          )}
          <AddButton onClick={() => handleClick()}>
            <Icon>
              <Icons type="fal" name="plus" />
            </Icon>
            {t("cardView.todos.buttonText")}
          </AddButton>
        </BodyContainer>
      </SchedulesContainer>
    </div>
  );
};

export default withRouter(withTranslation()(Schedules));

const AddButton = styled(ClearButton)`
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    height: 20px;
    font-weight: bold;
    /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
    /* border: 1px solid lightgray; */
    margin-top: 10px;
`;

const TitleSec = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const TitleBox = styled(comps.CardViewInnerSectionTitleStyle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.color_base_white};
`;

const LabelGreenText = styled(label_green)`
  cursor: pointer;
`;

const SchedulesContainer = styled(comps.CardViewInnerSectionStyle)`
  box-shadow: none;
  margin-bottom: 32px;
  ${(props) =>
    props.isMobile ? "border: none;" : "border: 1px solid #d6dee5;"}
`;

const TableHeader = styled.div`
  /* margin-top: 15px; */
  width: 100%;
  /* border: 1px solid #d9d9d9; */
  display: flex;
  justify-content: flex-start;
  padding-left: 8px;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  font-weight: bold;
  /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
  color: ${({ theme }) => theme.colors.color_text_titles};
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_dark};
  div {
    /* border: 0.5px solid #d9d9d9; */
    height: 36px;
    align-items: center;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
  }
  .descriptionBox{
    border: none;
  }
  /* .createdDate {
    width: 10%;
    border: 0.5px solid #d9d9d9;
    border-right: none;
  } */
  .remove {
    width: 8.5%;
    /* border: 0.5px solid #d9d9d9; */
    border-left: none;
  }
`;

const Checkbox = styled.div`
  width: ${(props) => (props.isMobile ? "15%" : "6.5%")};
  ${(props) => props.isMobile && "display: flex;"}
  ${(props) =>
    props.isMobile
      ? "justify-content: flex-start;"
      : "justify-content: center;"}
  /* border: 0.5px solid #d9d9d9; */
  border-right: none;
`;

const Description = styled.div`
  /* border: 0.5px solid #d9d9d9; */
  ${(props) => props.isMobile && "display: flex;"}
  ${(props) =>
    props.isMobile ? "justify-content: center;" : "justify-content: center;"}
  width: ${(props) => (props.isMobile ? "40%" : "65%")};
`;

const BodyContainer = styled(comps.CardViewInnerSectionBodyStyle)`
  ${(props) => (props.height === "false" ? "height:0px" : "")};
  transition: all 1s ease-in-out;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.color_base_white};
`;

const Icon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
