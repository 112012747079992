import { handleActions, createAction } from "redux-actions";

const TOGGLE = "toggleOfferResult/TOGGLE";

export const toggleOfferResult = createAction(TOGGLE);

const initialState = true;

const offerResult = handleActions(
  {
    [TOGGLE]: (state) => !state,
  },
  initialState
);

export default offerResult;
