/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo, useRef } from "react";
import UpperWrapper from "pages/DealCardDetailView/components/UpperWrapper/UpperWrapper";
import styled from "styled-components";
// import CardNote from "Components/DealCardDetailView/CardNote/CardNote";
import Todos from "pages/DealCardDetailView/components/Todos/Todos";
import Schedules from "pages/DealCardDetailView/components/Schedule/Schedules";
import CommentPage from "pages/DealCardDetailView/components/Commentpage/CommentPage";
import { useSelector } from "react-redux";
// import EmailThread from "Components/DealCardDetailView/EmailThread/EmailThread";
import EmailThreadList from "pages/DealCardDetailView/components/EmailThread/EmailThreadList";
import { updateCurrentCard } from "modules/DealCardDetailView/currentCard";
import {
  updateCardNotification,
  resetCardNotification,
} from "modules/DealCardDetailView/cardNotification";
// import OrderInformation from "Components/DealCardDetailView/OrderInformation/OrderInformation";
import TrackingShipment from "pages/DealCardDetailView/components/TrackingShipment/TrackingShipment";
import useViewport from "hooks/useViewPort";
import { useDispatch } from "react-redux";
import { CardViewTabs } from "ui";
import * as api from "common/ApiAxios";
import { toggleDeal } from "modules/DealCardDetailView/toggleDealfromCard";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import CardViewAttachments from "pages/DealCardDetailView/components/Attachment/CardViewAttachments";
import loadable from "@loadable/component";
// import CardViewSpreadsheet from "Components/DealCardDetailView/Spreadsheet/CardViewSpreadsheet";
import DealSummary from "pages/DealCardDetailView/components/DealSummary/DealSummary";
import { withRouter } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
// import { Element, Link as ScrollLink } from "react-scroll";
import { Element } from "react-scroll";
import { useTranslation, withTranslation } from "react-i18next";

const CardNote = loadable(() =>
  import("pages/DealCardDetailView/components/CardNote/CardNote")
);
const CardViewSpreadsheet = loadable(() =>
  import("pages/DealCardDetailView/components/Spreadsheet/CardViewSpreadsheet")
);
const DealCardDetailView = ({
  id,
  setVisible,
  visible,
  match,
  setPreventClose,
  cardname,
  customer,
  supplier,
  quantity,
  qunit,
  amount,
  aunit,
  owner,
  history,
}) => {
  const { height } = useViewport();
  const dispatch = useDispatch();
  const [viewType, setViewType] = useState(1);
  const [refreshAttachment, setRefreshAttachment] = useState(true);
  const [currentTab, setCurrentTab] = useState("Deal Info");
  const [communication, setCommunication] = useState("comments");
  const { t } = useTranslation();

  const cardNoti = useSelector((state) => state.cardNotification);
  const currentCardNoti = useRef();
  useEffect(() => {
    CardNote.load();
    CardViewSpreadsheet.load();
  }, []);
  useEffect(() => {
    currentCardNoti.current = cardNoti;
  }, [cardNoti]);
  useEffect(() => {
    visible && dispatch(updateCurrentCard(id));
    return () => {
      dispatch(updateCurrentCard(null));
    };
  }, [id, visible]);

  useEffect(() => {
    visible &&
      api.getCardViewNotificationAxios(id.slice(5)).then((res) => {
        dispatch(updateCardNotification(res.data));
      });
    visible && dispatch(updateCurrentCard(id));
    return () => {
      dispatch(resetCardNotification());
    };
  }, [visible]);

  const onDragEnd = (result) => {
    // const { destination, source, draggableId, type } = result;
    const { destination } = result;
    if (!destination) {
      return;
    }
  };

  const handleAddArchived = () => {
    api
      .putAddArchivedCardAxios(id.slice(5), { is_archived: true })
      .then(() => dispatch(toggleDeal()))
      .then(() => setVisible(false))
      .catch((error) => {});
  };

  const handleUnarchived = () => {
    api
      .putAddArchivedCardAxios(id.slice(5), { is_archived: false })
      .then(() => dispatch(toggleDeal()))
      .then(() => setVisible(false))
      .catch((error) => {});
  };

  const handleDeleteCard = () => {
    api
      .removeCardAxios(id.slice(5))
      .then(() => dispatch(toggleDeal()))
      .then(() => setVisible(false))
      .catch((error) => {});
  };
  return (
    <CardViewContainer id={id} key={id} window={height}>
      <UpperWrapper
        id={id.slice(5)}
        handleUnarchived={handleUnarchived}
        handleAddArchived={handleAddArchived}
        handleDeleteCard={handleDeleteCard}
        board_id={match.params.category}
        setViewType={setViewType}
        viewType={viewType}
        owner={owner}
        cardname={cardname}
      />
      <DownWrapper>
        {(viewType === 1 || viewType === 2) && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={String("LeftWrapper")}>
              {(provided) => (
                <LeftWrapper
                  window={height}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  viewType={viewType}
                >
                  <TabsBox>
                    <CardViewTabs
                      focused={currentTab === "Deal Info" ? "true" : "false"}
                      onClick={() => setCurrentTab("Deal Info")}
                      first="first"
                    >
                      {t("cardView.downWrapper.dealInfo")}
                    </CardViewTabs>
                    <CardViewTabs
                      focused={currentTab === "WorkStation" ? "true" : "false"}
                      onClick={() => setCurrentTab("WorkStation")}
                    >
                      {t("cardView.downWrapper.noteTodos")}
                    </CardViewTabs>
                    <CardViewTabs
                      focused={currentTab === "Spreadsheet" ? "true" : "false"}
                      onClick={() => setCurrentTab("Spreadsheet")}
                    >
                      {t("cardView.downWrapper.spreadsheet")}
                    </CardViewTabs>
                    <CardViewTabs
                      focused={currentTab === "Attachments" ? "true" : "false"}
                      onClick={() => setCurrentTab("Attachments")}
                    >
                      <NotiAlert
                        count={cardNoti.new_attachments.noti_ids.length}
                      >
                        {cardNoti.new_attachments.noti_ids.length}
                      </NotiAlert>
                      {t("cardView.downWrapper.attachments")}
                    </CardViewTabs>
                    <CardViewTabs
                      focused={currentTab === "Shipment" ? "true" : "false"}
                      onClick={() => setCurrentTab("Shipment")}
                    >
                      {t("cardView.downWrapper.shipment")}
                    </CardViewTabs>
                  </TabsBox>
                  <OverlayScrollbarsComponent
                    options={{ scrollbars: { autoHide: "leave" } }}
                  >
                    <LeftInner id={`leftInnertmp${id}`} window={height}>
                      <>
                        {currentTab === "Deal Info" && (
                          <DealSummary
                            refresh={visible}
                            id={id.slice(5)}
                            customer={customer}
                            supplier={supplier}
                            quantity={quantity}
                            qunit={qunit}
                            amount={amount}
                            aunit={aunit}
                            board_id={match.params.category}
                            cardVisible={visible}
                          />
                        )}

                        {currentTab === "WorkStation" && (
                          <>
                            <CardNote
                              id={id.slice(5)}
                              board_id={match.params.category}
                              refresh={visible}
                            />
                            <Schedules id={id.slice(5)} refresh={visible} />
                            <Todos id={id.slice(5)} refresh={visible} />
                          </>
                        )}
                        {currentTab === "Spreadsheet" && (
                          <CardViewSpreadsheet
                            refresh={visible}
                            board_id={match.params.category}
                            id={id.slice(5)}
                            viewType={viewType}
                            refreshAttachment={() =>
                              setRefreshAttachment(!refreshAttachment)
                            }
                            setPreventClose={setPreventClose}
                          />
                        )}
                        {currentTab === "Attachments" && (
                          <CardViewAttachments
                            refresh={visible}
                            id={id.slice(5)}
                            board_id={match.params.category}
                            setRefreshAttachment={setRefreshAttachment}
                            refreshAttachment={refreshAttachment}
                            setPreventClose={setPreventClose}
                          />
                        )}
                        {currentTab === "Shipment" && (
                          <TrackingShipment
                            refresh={visible}
                            id={id.slice(5)}
                            board_id={match.params.category}
                          />
                        )}
                      </>
                    </LeftInner>
                  </OverlayScrollbarsComponent>
                </LeftWrapper>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {(viewType === 1 || viewType === 3) && (
          <RightWrapper window={height} viewType={viewType}>
            {viewType === 1 && (
              <>
                <TabsBox>
                  <CardViewTabs
                    active={communication === "comments" ? "true" : "false"}
                    onClick={() => setCommunication("comments")}
                    first
                  >
                    <NotiAlert count={cardNoti.new_comments.noti_ids.length}>
                      {cardNoti.new_comments.noti_ids.length}
                    </NotiAlert>
                    {t("cardView.downWrapper.comments")}
                  </CardViewTabs>
                  <CardViewTabs
                    active={communication !== "comments" ? "true" : "false"}
                    onClick={() => setCommunication("email")}
                  >
                    <NotiAlert count={cardNoti.new_threads.noti_ids.length}>
                      {cardNoti.new_threads.noti_ids.length}
                    </NotiAlert>

                    {t("cardView.downWrapper.email")}
                  </CardViewTabs>
                </TabsBox>
                {communication === "comments" ? (
                  <CommentPage
                    cardOpen={visible}
                    id={id}
                    setPreventClose={setPreventClose}
                    refresh={visible}
                  />
                ) : (
                  <EmailThreadList
                    id={id}
                    cardOpen={visible}
                    setPreventClose={setPreventClose}
                    refresh={visible}
                  />
                )}
              </>
            )}
            {viewType === 3 && (
              <Communications>
                <div style={{ width: "50%" }}>
                  <CommentPage
                    id={id}
                    refresh={visible}
                    viewType={viewType}
                    setPreventClose={setPreventClose}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <EmailThreadList
                    id={id}
                    viewType={viewType}
                    refresh={visible}
                    setPreventClose={setPreventClose}
                  />
                </div>
              </Communications>
            )}
          </RightWrapper>
        )}
      </DownWrapper>
    </CardViewContainer>
  );
};

export default memo(withRouter(withTranslation()(DealCardDetailView)));

// const TabNotification = styled.div`

// `;

const NotiAlert = styled.div`
  ${({ count }) => count < 1 && "display: none;"}
  background: ${({ theme }) => theme.colors.color_state_warning_middle};
  min-height: 19px;
  min-width: 19px;
  text-align: center;
  border-radius: 12px;
  padding: 0 4px;
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 12px;
`;

const Communications = styled.div`
  display: flex;
  width: 100%;
`;

const CardViewContainer = styled.div`
  /* border-radius: 8px; */
  position: relative;
  width: 100%;
  height: ${({ window }) => window - 28}px;
  z-index: 6;
  @media ${({ theme }) => theme.screenSizes.tablet} {
    overflow-y: scroll;
  }
`;

const DownWrapper = styled.div`
  border-radius: 3px;
  display: flex;
  @media ${({ theme }) => theme.screenSizes.tablet} {
    flex-direction: column;
  }
`;

const LeftInner = styled(Element)`
  height: ${(props) => `${props.window - 130}px`};
  margin-right: 16px;
`;

const LeftWrapper = styled.div`
  border-radius: 3px;
  width: ${(props) => (props.viewType === 1 ? 50 : 100)}%;
  position: relative;
  height: ${(props) => `${props.window - 96}px`};
  /* background-color: black; */
  @media ${({ theme }) => theme.screenSizes.tablet} {
    width: 100%;
    margin: 0 auto;
    height: auto;
  }
  .os-scrollbar {
    width: 16px;
  }
  .os-scrollbar-handle {
    background: #999 !important;
    opacity: 0.5;
  }
`;

const RightWrapper = styled.div`
  width: ${(props) => (props.viewType === 1 ? 50 : 100)}%;
  margin-left: 15px;
  border-radius: 3px;
  position: relative;
  /* overflow: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.color_base_regular};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.color_base_darker};
    border-radius: 5px;
  } */
  height: ${(props) => `${props.window - 96}px`};
  /* background-color: black; */
  @media ${({ theme }) => theme.screenSizes.tablet} {
    width: 100%;
    margin: 0 auto;
  }
  .os-scrollbar {
    width: 16px;
  }
  .os-scrollbar-handle {
    background: #999 !important;
    opacity: 0.5;
  }
`;

const TabsBox = styled.div`
  display: flex;
  align-items: center;
  /* position: fixed; */
  /* background: ${({ theme }) => theme.colors.color_base_white}; */
`;
