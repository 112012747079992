import { handleActions, createAction } from "redux-actions";

const UPDATE = "syncCardName/UPDATE";
const RESET = "syncCardName/RESET";

export const updateSyncCardInfo = createAction(UPDATE);
export const resetSyncCardInfo = createAction(RESET);

const initialState = { card_id: null, board_id: null };

const syncCardInfo = handleActions(
  {
    [UPDATE]: (state, { payload: info }) => info,
    [RESET]: () => initialState,
  },
  initialState
);

export default syncCardInfo;
