import { handleActions, createAction } from "redux-actions";

const CURRENTCARD = "composeCardID/CURRENTCARD";

export const updateComposeID = createAction(CURRENTCARD);

const initialState = null;

const composeCardID = handleActions(
  {
    [CURRENTCARD]: (state, { payload: info }) => info,
  },
  initialState
);

export default composeCardID;
