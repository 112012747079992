import React from "react";
import styled from "styled-components";
import { Tag } from "ui";
import { Select as antSelect } from "antd";
export const tagRender = (props) => {
  const { label, closable, onClose } = props;

  return (
    <Tag
      color={`${({ theme }) => theme.colors.color_primary_light}`}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: "3", display: "flex", alignItems: "center" }}
    >
      {label}
    </Tag>
  );
};

export const Select = styled(antSelect)`

   .ant-select-dropdown-menu-item {
    padding: 8px;
}

  .ant-select-selector {
    /* border-top: none !important;
    border-left: none !important;
    border-right: none !important; */
    border-color: ${({ theme }) => theme.colors.color_base_dark} !important;
    border-radius: 4px !important;
    align-items: center;
    box-shadow: none !important;
    
    /* padding: 0 !important; */
  }
 


  .ant-select-selection-overflow-item{
    margin: 1.5px 0px;
    .ant-select-selection-search {
      margin: 0px !important;
    }
  }
  .ant-select-selection-overflow-item-suffix{
    margin: 0px;
  }

  .ant-select-selector:focus-within {
    color: ${({ theme }) => theme.colors.color_text_body};
    border-color: ${({ theme }) => theme.colors.color_base_darker} !important; 
  }

  .ant-select-selection-item {
    color: ${({ theme }) => theme.colors.color_text_body};
    /* background-color: ${({ theme }) =>
      theme.colors.color_base_regular} !important; */
  }
  

  .ant-select-item-option {
    color: ${({ theme }) => theme.colors.color_primary_dark} !important;
  }
  .ant-select-clear {
    margin-top: -8px;
  }
  .ant-select-dropdown {
    &::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.color_base_darker};
      border-radius: 5px;
    }
  }

  /* .ant-select-focused {
    border-color: ${({ theme }) => theme.colors.color_primary_dark}!important;
     box-shadow: none !important; 
  }  */

  /* border-color: #d9d9d9 !important;
  box-shadow: none !important;
  .ant-select-selector {
    border-color: #d9d9d9 !important;
  }
  .ant-select-focused {
    border-color: black !important;
    box-shadow: none !important;
  } */
`;
