export const imageTypes = [
  "img",
  "jpg",
  "png",
  "gif",
  "PNG",
  "IMG",
  "JPG",
  "GIF",
  "jpeg",
  "JPEG",
  "bmp",
  "BMP",
  "tiff",
  "svg",
  "SVG",
];
