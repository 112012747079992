import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MemberIcon } from "ui";
import EmailLabel from "./EmailLabel";
import isEmail from "utils/isEmail";
import Autosuggest from "react-autosuggest";
export const EmailAddressInput = ({
  contactList,
  setContactList,
  reference,
  placeholder,
  autofillList,
}) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState(autofillList);
  useEffect(() => {
    setSuggestions(autofillList);
  }, [autofillList]);
  const handleChange = (e, { newValue, method }) => {
    if (method === "type") {
      setValue(newValue);
    }
  };
  const handleEnter = (e) => {
    if (e.keyCode === 13 && isEmail(e.target.value)) {
      setContactList([...contactList, { email: e.target.value }]);
      setValue("");
    }
  };
  const handleBlur = (e, { highlightedSuggestion }) => {
    if (isEmail(value)) {
      setContactList([...contactList, { email: value }]);
      setValue("");
    }
  };
  const handleSelect = (e, { suggestion, suggestionValue }) => {
    setContactList([...contactList, suggestion]);
    setValue("");
  };
  const removeContact = (email) => {
    setContactList(contactList.filter((contact) => contact.email !== email));
  };

  const getSuggestions = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    setSuggestions(
      inputLength === 0
        ? []
        : autofillList.filter(
            (contact) =>
              contact.name.toLowerCase().slice(0, inputLength) === inputValue ||
              contact.email.toLowerCase().slice(0, inputLength) === inputValue
          )
    );
    return inputLength === 0
      ? []
      : autofillList.filter(
          (contact) =>
            contact.name.toLowerCase().slice(0, inputLength) === inputValue ||
            contact.email.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (contact) =>
    contact.name ? contact.name : contact.email;

  const renderSuggestion = (contact) => (
    <SuggestionContainer>
      <MemberIcon
        member={{ ...contact, user_name: contact.name, user_profile_image: "" }}
      ></MemberIcon>
      <div>
        <SuggestionName>
          {contact.name ? contact.name : contact.email.split("@")[0]}
        </SuggestionName>
        <SuggestionEmail>{contact.email}</SuggestionEmail>
      </div>
    </SuggestionContainer>
  );

  return (
    <EmailAddressInputContainer>
      {contactList &&
        contactList.map((contact) => (
          <EmailLabel
            reference={reference}
            removeContact={removeContact}
            setValue={setValue}
            contact={contact}
          />
        ))}
      <EmailInput
        inputProps={{
          ref: reference,
          value,
          onChange: handleChange,
          placeholder,
          onKeyDown: handleEnter,
          onBlur: handleBlur,
        }}
        // onChange={(e) => setValue(e.target.value)}
        // onPressEnter={handleEnter}
        // placeholder={placeholder}
        suggestions={suggestions}
        onSuggestionsFetchRequested={getSuggestions}
        onSuggestionSelected={handleSelect}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
      />
    </EmailAddressInputContainer>
  );
};

const SuggestionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SuggestionName = styled.div`
  margin-left: 12px;
  font-size: ${({ theme }) => theme.fontSizes.txt_standard} !important;
  font-family: "Spoqa Han Sans Neo", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Noto Color Emoji" !important;
`;

const SuggestionEmail = styled.div`
  margin-left: 12px;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller} !important;
  font-family: "Spoqa Han Sans Neo", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Noto Color Emoji" !important;
  color: ${({ theme }) => theme.colors.color_text_light};
`;

const EmailAddressInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.color_text_body};
  * {
    font-family: "Spoqa Han Sans Neo", -apple-system, BlinkMacSystemFont,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Noto Color Emoji" !important;
  }
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 240px;
    height: 30px;
    font-size: 14px;
    font-weight: normal;
    border: none;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.color_text_body};
    font-family: "Spoqa Han Sans Neo", -apple-system, BlinkMacSystemFont,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Noto Color Emoji";
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    max-height: 400px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: ${(props) => (props.isMobile ? "0px" : "15px")};
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.color_base_regular};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.color_base_darker};
      border-radius: 10px;
    }
    top: 30px;
    width: 280px;
    border: 1px solid ${({ theme }) => theme.colors.color_base_dark};
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.elevations.elevation_light};
    padding: 8px 0;
    z-index: 2;
    font-family: "Spoqa Han Sans Neo", -apple-system, BlinkMacSystemFont,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Noto Color Emoji";
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: ${({ theme }) => theme.colors.color_text_body};
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
  }
`;

const EmailInput = styled(Autosuggest)`
  width: auto;
`;
