import { handleActions, createAction } from "redux-actions";

const POSITION = "cardPosition/POSITION";

export const addPosition = createAction(POSITION);

const initialState = [null];

const position = handleActions(
  {
    [POSITION]: (state, { payload: vals }) => vals,
  },
  initialState
);

export default position;
