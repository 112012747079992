import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const RouteChangeTracker = ({ history }) => {
  // history.listen((location, action) => {
  useEffect(() => {
    ReactGA.set({ page: history.location.pathname });
    ReactGA.pageview(history.location.pathname);
  }, [history.location.pathname]);
  //ReactGA.modalview(location.pathname);
  // });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
