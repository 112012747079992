/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
// import "antd/dist/antd.css";
import {
  WhiteButtonKey,
  // SecondaryButton,
  ClearButton,
  Skeleton,
} from "ui";
import Icons from "assets/Icons";
import styled from "styled-components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSyncCardInfo } from "modules/Email/syncCardInfo";
import useViewport from "hooks/useViewPort";
import * as api from "common/ApiAxios";
import produce from "immer";
import EmailThreadListItem from "./EmailThreadListItem";
import EmailThreadDetail from "./EmailThreadDetail";
import {
  CardViewInnerSectionStyle,
  CardViewInnerSectionTitleStyle,
  CardViewInnerSectionBodyStyle,
} from "pages/DealCardDetailView/components/CardComponents";
import { useSelector } from "react-redux";
import { updateCardNotification } from "modules/DealCardDetailView/cardNotification";
// import { MenuItem } from "@material-ui/core";
// import Title from "antd/lib/skeleton/Title";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";

const EmailThreadList = ({
  id,
  history,
  viewType,
  cardOpen,
  setPreventClose,
  refresh,
}) => {
  const { height } = useViewport();
  // const [connected, setConnected] = useState(isConnected);
  // const [isNew, setIsNew] = useState(true);
  const dispatch = useDispatch();
  const bottomRef = useRef();
  const [loading, setLoading] = useState(true);
  const [threadList, setThreadList] = useState([]);
  const [connectingList, setConnectingList] = useState([]);
  const [isDetailView, setIsDetailView] = useState(false);
  const [currentThreadID, setCurrentThreadID] = useState("");
  const containerRef = useRef();
  const newThreads = useSelector((state) => state.cardNotification.new_threads);
  const maxThreads = useSelector(
    (state) => state.addTeamMetaInfo.max_connected_threads
  );
  const { t } = useTranslation();

  const checkMobile = useCheckPath("mobile");

  useEffect(() => {
    newThreads.noti_ids.length > 0 &&
      api.updateAllNotificationsAxios({
        action: "read",
        id_list: newThreads.noti_ids,
      });
    return () => {
      newThreads.noti_ids.length > 0 &&
        api.getCardViewNotificationAxios(id.slice(5)).then((res) => {
          dispatch(updateCardNotification(res.data));
        });
    };
  }, []);
  useEffect(() => {
    currentThreadID !== "" ? setIsDetailView(true) : setIsDetailView(false);
    currentThreadID === "" &&
      api
        .getCardViewPartialInfoAxios(id.slice(5), "?fields=synced_threads")
        .then((res) => {
          setThreadList(res.data.synced_threads);
        });
  }, [currentThreadID]);
  useEffect(() => {
    refresh &&
      api
        .getCardViewPartialInfoAxios(id.slice(5), "?fields=synced_threads")
        .then((res) => {
          setThreadList(res.data.synced_threads);
        })
        .then(() => {
          api
            .getConnectingCardListAxios()
            .then((res) => setConnectingList(res.data))
            .then(() => setLoading(false));
        });
  }, [id, refresh]);
  useEffect(() => {
    let connectingCount = 0;
    const newList = produce(threadList, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        draft[i].saving = connectingList.includes(draft[i].thread_id)
          ? true
          : false;
        connectingList.includes(draft[i].thread_id) && connectingCount++;
      }
    });
    setThreadList(newList);
    if (connectingList.length !== 0 && connectingCount > 0) {
      setTimeout(() => {
        api.getConnectingCardListAxios().then((res) => {
          setConnectingList(res.data);
        });
      }, 5000);
    }
  }, [connectingList]);
  // useEffect(() => {
  //   if (id !== null) {
  //     setLoaded(false);
  //     setLoading(true);
  //     api
  //       .getConnectedThreadsAxios(id.slice(5), `&search=${currentThreadID}`)
  //       .then((res) => {
  //         setData(res.data);
  //         setLoading(false);
  //       });
  //   }
  // }, [id, currentThreadID, reset]);

  // const UpdateThread = (i, bool, thread_id) => {
  //   api
  //     .editThreadSharedAxios(thread_id, id.slice(5), { is_shared: bool })
  //     .then(() => {
  //       api
  //         .getCardViewPartialInfoAxios(id.slice(5), "?fields=synced_threads")
  //         .then((res) => {
  //           setThreadList(res.data.synced_threads);
  //         });
  //     });
  //   const changeState = produce(data, (draft) => {
  //     draft[i].is_shared = bool;
  //   });
  //   setData(changeState);
  // };
  return isDetailView ? (
    <EmailThreadDetail
      currentThreadID={currentThreadID}
      id={id}
      setCurrentThreadID={setCurrentThreadID}
      setPreventClose={setPreventClose}
      connectingList={connectingList}
    ></EmailThreadDetail>
  ) : (
    <ThreadListContainerBox isMobile={checkMobile}>
      <ThreadListHeader>
        {/* {cardOpen && (
          <EmailThreadSelect
            threadList={threadList}
            setCurrentThread={setCurrentThread}
            currentThread={currentThread}
            setCurrentThreadID={setCurrentThreadID}
          />
        )} */}
        {t("cardView.emailThread.emailThreadList.title")}
        <ThreadBtn
          disabled={maxThreads !== 0 && threadList.length >= maxThreads}
        >
          <Icon>
            <Icons type="fal" name="plus" />
          </Icon>
          <BtnText
            onClick={() => {
              dispatch(
                updateSyncCardInfo({
                  card_id: id,
                  board_id: history.location.pathname.split("/")[2],
                })
              );
              history.push("/email/");
            }}
          >
            {t("cardView.emailThread.emailThreadList.buttonText")}
          </BtnText>
        </ThreadBtn>
      </ThreadListHeader>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "leave" },
          overflowBehavior: {
            x: "hidden",
            y: "scroll",
          },
        }}
      >
        <ThreadListContainer
          id={`threadContainertmp${id}`}
          viewType={viewType === 3 ? 20 : 0}
          ref={containerRef}
          window={height}
          isMobile={checkMobile}
        >
          {!loading ? (
            <>
              <VisWrap>
                {threadList.length === 0 ? (
                  <>
                    <ConnectThreadBox>
                      <ConnectThreadTitle>
                        {t(
                          "cardView.emailThread.emailThreadList.connectThreadTitle"
                        )}
                      </ConnectThreadTitle>
                      <ConnectThreadContents>
                        <Content isMobile={checkMobile}>
                          {t(
                            "cardView.emailThread.emailThreadList.connectThreadText"
                          )}
                          &nbsp;
                          {/* <MoreText>
                          {t("cardView.emailThread.emailThreadList.learnMore")}
                        </MoreText> */}
                        </Content>
                        {!checkMobile && (
                          <Content>
                            <GoTo
                              onClick={() => {
                                dispatch(
                                  updateSyncCardInfo({
                                    card_id: id,
                                    board_id: history.location.pathname.split(
                                      "/"
                                    )[2],
                                  })
                                );
                                history.push("/email/");
                              }}
                            >
                              <Icon>
                                <Icons type="fal" name="plus" />
                              </Icon>
                              {t(
                                "cardView.emailThread.emailThreadList.buttonText"
                              )}
                            </GoTo>
                          </Content>
                        )}
                      </ConnectThreadContents>
                    </ConnectThreadBox>
                    {checkMobile && (
                      <MobileThreadBtn>
                        <Icon>
                          <Icons type="fal" name="plus" />
                        </Icon>
                        <BtnText
                          onClick={() => {
                            if (checkMobile) {
                              window.ReactNativeWebView &&
                                window.ReactNativeWebView.postMessage(
                                  `goEmailTab`
                                );
                            } else {
                              history.push("/email/");
                            }
                          }}
                        >
                          {t("cardView.emailThread.emailThreadList.buttonText")}
                        </BtnText>
                      </MobileThreadBtn>
                    )}
                  </>
                ) : (
                  threadList.map((thread, i) => (
                    <EmailThreadListItem
                      thread={thread}
                      threadList={threadList}
                      setThreadList={setThreadList}
                      id={id}
                      key={i}
                      i={i}
                      setCurrentThreadID={setCurrentThreadID}
                      noti={newThreads.object_ids.includes(thread.id)}
                    ></EmailThreadListItem>
                  ))
                )}
              </VisWrap>
            </>
          ) : (
            <Skeleton active />
          )}
          <div ref={bottomRef}></div>
        </ThreadListContainer>
      </OverlayScrollbarsComponent>
    </ThreadListContainerBox>
  );
};

export default withRouter(withTranslation()(EmailThreadList));

const VisWrap = styled.div`
  padding-bottom: 4px;
`;

const ThreadBtn = styled(WhiteButtonKey)`
  @media ${({ theme }) => theme.screenSizes.mobile} {
    display: none !important;
  }
`;

const MobileThreadBtn = styled(WhiteButtonKey)`
  margin-top: 10px;
  &:hover {
    border-color: none !important;
  }
`;

const BtnText = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const Icon = styled.div`
  margin-right: 8px;
`;

const ConnectThreadBox = styled.div`
  padding: 24px;
  background-color: ${({ theme }) =>
    theme.colors.color_state_notification_light};
  border-radius: 8px;
`;

const ConnectThreadTitle = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h2};
`;

const ConnectThreadContents = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_body};
  margin-top: 8px;
  display: flex;
`;

const MoreText = styled.span`
  color: ${({ theme }) => theme.colors.color_state_notification_dark};
`;

const Content = styled.div`
  width: ${(props) => (!props.isMobile ? "60%" : "100%")};

  :nth-child(2) {
    width: 40%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const GoTo = styled(ClearButton)`
  /* position: relative;
  top: 30%; */
  /* font-size: 16px; */
  /* margin: auto; */
  color: ${({ theme }) =>
    theme.colors.color_state_notification_dark} !important;
`;

const ThreadListContainerBox = styled(CardViewInnerSectionStyle)`
  ${(props) => props.isMobile && "border: none;"}
  margin-bottom: 20px;
`;

const ThreadListHeader = styled(CardViewInnerSectionTitleStyle)`
  @media ${({ theme }) => theme.screenSizes.mobile} {
    font-size: ${({ theme }) => theme.fontSizes.txt_standard};
  }
`;

// const ConnectButton = styled(WhiteButtonKey)``;

const ThreadListContainer = styled(CardViewInnerSectionBodyStyle)`
  height: ${(props) =>
    props.isMobile
      ? `${props.window - 120 + props.viewType}px`
      : `${props.window - 170 + props.viewType}px`};
`;
