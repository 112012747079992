import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import {
  PrimaryButton,
  ClearButton,
  Divider,
  Progress,
  Select,
  Option,
  label_green,
} from "ui";
// import { UpOutlined, DownOutlined } from "@ant-design/icons";
import * as api from "common/ApiAxios";
import * as comps from "../CardComponents";
// import { Draggable } from "react-beautiful-dnd";
import useViewport from "hooks/useViewPort";
import useCheckPath from "hooks/useCheckPath";
import TrackingMap from "pages/DealCardDetailView/components/TrackingShipment/TrackingMap";
import { useTranslation, withTranslation } from "react-i18next";

const TrackingShipment = ({ id, refresh }) => {
  const [heightTrue, setHeightTrue] = useState(true);
  const { height } = useViewport();
  // const [height, setHeight] = useState(true);
  const [data, setData] = useState(null);
  const [vesselName, setVesselName] = useState(null);
  const [vesselId, setVesselId] = useState("");
  const [vesselList, setVesselList] = useState([]);
  const [shownVesselList, setShownVesselList] = useState([]);
  const [loadingCount, setLoadingCount] = useState(1);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState("");
  // const [totalVoyageDay, setTotalVoyageDay] = useState("");

  const checkMobile = useCheckPath("mobile");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const progressCalculator = useCallback(() => {
    const startDate = new Date(data.LAST_PORT_TIME);
    const endDate = new Date(data.ETA);
    const totalVoyageTime = Math.abs(endDate - startDate);
    const totalVoyageDays = Math.ceil(totalVoyageTime / (1000 * 60 * 60 * 24));
    // setTotalVoyageDay(totalVoyageDays);

    const today = new Date();
    const fromDepartTillNowTime = Math.abs(today - startDate);
    const fromDepartTillNowDays = Math.ceil(
      fromDepartTillNowTime / (1000 * 60 * 60 * 24)
    );
    const progressPercent = Math.round(
      (fromDepartTillNowDays / totalVoyageDays) * 100
    );
    setProgress(progressPercent);
  });

  const bodyRef = useRef(null);
  const [heightVal, setHeightVal] = useState("auto");

  const { t } = useTranslation();

  useEffect(() => {
    id &&
      refresh &&
      api.getInitialTrackingShipmentAxios(id).then((res) => {
        setData(res.data);
        setVesselId(res.data.VESSEL_ID);
        setVesselName(res.data.SHIPNAME);
      });
  }, [progress, id, refresh]);

  useEffect(() => {
    data && progressCalculator();
  }, [data, progressCalculator]);

  useEffect(() => {
    bodyRef.current &&
      (heightTrue
        ? setTimeout(function () {
            bodyRef.current &&
              setHeightVal(`${bodyRef.current.scrollHeight}px`);
          }, 100)
        : setHeightVal("0px"));
  }, [heightTrue, data]);

  const showVesselList = shownVesselList.map((unit, i) => {
    return (
      <Option
        key={unit.VESSEL_ID}
        value={`${unit.VESSEL_NAME} [${unit.FLAG}]`}
        name="vessel_name"
      >
        <OptionList>
          <MyPageSubtitle>{`${unit.VESSEL_NAME} [${unit.FLAG}]`}</MyPageSubtitle>
          <MyPageText>
            <Text>
              {t("cardView.trackingShipment.vesselType")} : {unit.TYPE_SPECIFIC}
            </Text>
            <Text>
              {t("cardView.trackingShipment.imo")} : {unit.IMO}
            </Text>
          </MyPageText>
        </OptionList>
      </Option>
    );
  });

  const handleVesselDelete = () => {
    api.deleteVesselInfoAxios(id).then((res) => {
      if (res.status === 204) {
        setVisible(false);
        setVesselName("");
        setData(null);
      }
    });

    // vesselName === "" ? setVisible(false) : id && setLoading(true);
    // getTrackingShipmentAxios(id, vesselName)
    //   .then(async function (res) {
    //     setVesselName(vesselName);
    //     setData(res.data);
    //     await progressCalculator();
    //   })
    //   .then(() => setLoading(false));
  };
  const containerRef = useRef(null);
  const handleScroll = () => {
    // containerRef.current.scrollTop =
    //   containerRef.current.scrollHeight - containerRef.current.clientHeight;

    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const onScroll = (event) => {
    let target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      shownVesselList.length < vesselList.length
    ) {
      setLoading(true);
      for (let i = loadingCount * 20; i < (loadingCount + 1) * 20; i++) {
        shownVesselList.push(vesselList[i]);
        // i === (loadingCount + 1) * 20 - 1 && setLoading(false);
      }
      setLoading(false);

      target.scrollTo(0, target.scrollHeight);
      setLoadingCount(loadingCount + 1);
    }
  };

  return (
    // <Draggable
    //   isDragDisabled={true}
    //   draggableId={String("OrderInformation")}
    //   index={4}
    // >
    //   {(provided) => (
    <OutsideWrapper
      isMobile={checkMobile}
      // ref={provided.innerRef}
      // {...provided.draggableProps}
      // {...provided.dragHandleProps}
    >
      <TrackingShipmentTitle
        onClick={() => setHeightTrue(!heightTrue)}
        style={{ cursor: "pointer" }}
      >
        <div>{t("cardView.trackingShipment.title")}</div>
        <LabelGreenText>{t("cardView.trackingShipment.shared")}</LabelGreenText>
        {/* {heightTrue ? <UpOutlined /> : <DownOutlined />} */}
      </TrackingShipmentTitle>
      <BodyContainer ref={bodyRef} heightVal={heightVal}>
        <TrackingShipmentContainer>
          <VesselName>
            <VesselNameArea>
              <VesselNameText>
                {t("cardView.trackingShipment.searchVesselName")}
              </VesselNameText>
              {/* {loading && <Spin size="small" />} */}

              <ContainerSelect
                checkMobile={checkMobile}
                dropdownMatchSelectWidth={false}
                placeholder={t("cardView.trackingShipment.placeholder")}
                showSearch
                value={vesselName}
                onChange={(value) => {
                  setVesselName(value);
                }}
                onSelect={(value, option) => {
                  api.getVesselInfoAxios(id, option.key).then((res) => {
                    setData(res.data);
                    handleScroll();
                    setVesselId(option.key);
                    setVisible(true);
                  });
                }}
                onSearch={(value) => {
                  if (value && value.length > 3) {
                    api.getVesselListAxios(value).then((res) => {
                      setVesselList(res.data);
                      let tmp = [];
                      for (
                        let i = 0;
                        i < (20 < res.data.length ? 20 : res.data.length);
                        i++
                      ) {
                        tmp.push(res.data[i]);
                      }
                      setShownVesselList(tmp);
                      // setVisible(true);
                    });
                  } else {
                    setVesselList([]);
                  }
                }}
                defaultActiveFirstOption={false}
                filterOption={false}
                optionLabelProp="Vessel Name"
                onPopupScroll={(e) => onScroll(e)}
              >
                {showVesselList}
                {loading && <Option key="loading">Loading...</Option>}
              </ContainerSelect>
            </VesselNameArea>
            {visible && data && (
              <VesselButtonArea>
                <VesselNameButton
                  onClick={() =>
                    api
                      .getVesselInfoAxios(id, vesselId)
                      .then((res) => setData(res.data))
                  }
                >
                  {t("cardView.trackingShipment.update")}
                </VesselNameButton>

                <VesselDeleteButton onClick={() => handleVesselDelete()}>
                  {t("cardView.trackingShipment.clear")}
                </VesselDeleteButton>
              </VesselButtonArea>
            )}
          </VesselName>
          {visible && data && (
            <VesselVoyageInfo>
              <VoyageInfo>
                <VoyageInfoTitle>
                  {t("cardView.trackingShipment.subTitle")}
                </VoyageInfoTitle>
                <Row>
                  <Info>
                    <InfoName>{t("cardView.trackingShipment.name")}</InfoName>
                    <InfoText>{data.SHIPNAME}</InfoText>
                  </Info>
                  <Info>
                    <InfoName>
                      {t("cardView.trackingShipment.vesselType")}
                    </InfoName>
                    <InfoText>{data.TYPE_SPECIFIC} </InfoText>
                  </Info>
                  <Info>
                    <InfoName>{t("cardView.trackingShipment.flag")}</InfoName>
                    <InfoText>{data.FLAG} </InfoText>
                  </Info>
                </Row>
                <Row>
                  <Info>
                    <InfoName>{t("cardView.trackingShipment.imo")}</InfoName>
                    <InfoText>{data.IMO} </InfoText>
                  </Info>
                  <Info>
                    <InfoName>{t("cardView.trackingShipment.dwt")}</InfoName>
                    <InfoText>{data.DWT} </InfoText>
                  </Info>
                  <Info>
                    <InfoName>
                      {t("cardView.trackingShipment.callSign")}
                    </InfoName>
                    <InfoText>{data.CALLSIGN} </InfoText>
                  </Info>
                </Row>
                <Row>
                  <Info>
                    <InfoName>{t("cardView.trackingShipment.mmsi")}</InfoName>
                    <InfoText>{data.MMSI} </InfoText>
                  </Info>
                  <Info>
                    <InfoName>
                      {t("cardView.trackingShipment.yearBuilt")}
                    </InfoName>
                    <InfoText>{data.YEAR_BUILT} </InfoText>
                  </Info>
                  <Info></Info>
                </Row>

                <Divider style={{ borderColor: "lightgray" }} />
                <VoyageInfoBody>
                  <UpperContainer>
                    <CountryPort>
                      {data.LAST_PORT_UNLOCODE && (
                        <Country>
                          {data.LAST_PORT_UNLOCODE.substring(0, 2)}
                          &nbsp;&nbsp;
                          {data.LAST_PORT_UNLOCODE.substring(2)}
                        </Country>
                      )}
                      {data.LAST_PORT_UNLOCODE && (
                        <Port>{data.LAST_PORT_NAME}</Port>
                      )}
                    </CountryPort>
                    {/* <div>Total Voyage : {totalVoyageDay} days</div> */}
                    <CountryPort>
                      {data.NEXT_PORT_UNLOCODE && (
                        <Country>
                          {data.NEXT_PORT_UNLOCODE.substring(0, 2)}
                          &nbsp;&nbsp;
                          {data.NEXT_PORT_UNLOCODE.substring(2)}
                        </Country>
                      )}
                      {data.NEXT_PORT_UNLOCODE && (
                        <Port>{data.NEXT_PORT_NAME}</Port>
                      )}
                    </CountryPort>
                  </UpperContainer>
                  <ProgressBox>
                    <ProgressDayText>
                      {data.TIME_TO_ARRIVAL !== "Arrived"
                        ? "Total Voyage :"
                        : ""}{" "}
                      {data.TIME_TO_ARRIVAL}
                    </ProgressDayText>
                    <Progress
                      percent={
                        data.TIME_TO_ARRIVAL !== "Arrived" ? data.PROGRESS : 100
                      }
                      status="active"
                      strokeColor={`${({ theme }) =>
                        theme.colors.color_state_notification_regular}`}
                      strokeLinecap="square"
                    />
                  </ProgressBox>

                  <BottomContainer>
                    <ATD>
                      <ETAName>{t("cardView.trackingShipment.atd")} : </ETAName>
                      <ETADate>{data.LAST_PORT_TIME}</ETADate>
                    </ATD>

                    <ETAarea>
                      <ETA>
                        <ETAName>
                          {t("cardView.trackingShipment.eta")} :
                        </ETAName>
                        <ETADate>{data.ETA && data.ETA}</ETADate>
                      </ETA>

                      {data.ETA_CALC && (
                        <ETA>
                          <ETAName>
                            {t("cardView.trackingShipment.calculatedEta")} :
                          </ETAName>
                          <ETADate>{data.ETA_CALC && data.ETA_CALC}</ETADate>
                        </ETA>
                      )}
                    </ETAarea>
                  </BottomContainer>

                  {data.LAT && data.LON && (
                    <TrackingMap lat={data.LAT} lon={data.LON} />
                  )}
                  <PositionText ref={containerRef} window={height}>
                    * {t("cardView.trackingShipment.subText")} :{" "}
                    {data.TIMESTAMP}
                  </PositionText>
                </VoyageInfoBody>
              </VoyageInfo>
            </VesselVoyageInfo>
          )}
        </TrackingShipmentContainer>
      </BodyContainer>
    </OutsideWrapper>
    //   )}
    // </Draggable>
  );
};

export default withTranslation()(TrackingShipment);

// const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)``;
const LabelGreenText = styled(label_green)`
  cursor: pointer;
`;

const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)`
  ${(props) =>
    props.isMobile ? "border: none;" : "border: 1px solid #d6dee5;"}
`;

const BodyContainer = styled(comps.CardViewInnerSectionBodyStyle)`
  height: auto;
  transition: all 1s ease-in-out;
  overflow: hidden;
`;

const VesselVoyageInfo = styled.div``;

// const TrackingShipmentTitle = styled(comps.CardViewInnerSectionTitleStyle)`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// `;

const TrackingShipmentTitle = styled(comps.CardViewInnerSectionTitleStyle)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const TrackingShipmentContainer = styled.div`
  padding-left: 15px;
`;

const OptionList = styled.div``;

const MyPageSubtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const MyPageText = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Text = styled.div`
  margin: 5px 10px 5px 0px;
`;

const ContainerSelect = styled(Select)`
  width: ${(props) => (props.checkMobile ? "95%" : "350px")};
  /* width: 350px; */
  margin-right: 30px;
  font-weight: 400;
  font-size: 16px;
  /* .ant-select-selector {
    border-radius: 5px !important;
  } */
`;

const VesselNameArea = styled.div`
  width: 100%;
`;

const VesselButtonArea = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 34px 10px 0 20px;
`;

const VesselName = styled.div`
  /* width: 90%; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 24px 0px 24px 0px;
  align-items: center;
`;

const VesselNameText = styled.div`
  /* width: 20%; */
  margin: 10px 0;
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const VesselNameButton = styled(PrimaryButton)`
  /* width: 25%; */
  width: 40%;
`;

const VesselDeleteButton = styled(ClearButton)`
  width: 40%;
`;

const VoyageInfo = styled.div`
  width: 100%;
`;
const VoyageInfoTitle = styled.div`
  /* font-size: 16px;
  font-weight: 600; */
  margin-top: 20px;
  margin-bottom: 15px;
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const VoyageInfoBody = styled.div`
  /* width: 90%; */
  width: 100%;
  /* margin-left: 10px; */
`;

const UpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`;

const CountryPort = styled.div`
  display: block;
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const Country = styled.div`
  color: ${({ theme }) => theme.colors.color_base_darker};
  margin-right: 10px;
  display: flex;
`;

const Port = styled.div`
  color: ${({ theme }) => theme.colors.color_base_black};
  font-weight: 600px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`;

const ATD = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

const ETA = styled.div`
  display: flex;
  text-align: right;
  .firstLine {
    margin-bottom: 10px;
  }
`;

const ETAarea = styled.div`
  display: block;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  /* width: 95%; */
  width: 100%;
  margin-top: 12px;

  /* :nth-child(3) {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  } */
`;

const Info = styled.div`
  /* width: 200px; */
  width: 33.3%;

  :nth-child(2) {
    margin: 0 12px;
  }
`;

const InfoName = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
`;
const InfoText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const ProgressBox = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressDayText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
`;

const ETAName = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  margin-right: 8px;
`;

const ETADate = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const PositionText = styled.div`
  color: ${({ theme }) => theme.colors.color_text_light};
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  margin-top: 4px;

  /* &:after {
    content: " *";
  } */
`;
