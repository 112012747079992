import { takeLatest } from "redux-saga/effects";
// import { startLoading, finishLoading } from "../Modules/loading";
import createRequestSaga from "../CreateSaga/createRequestSaga";
import { createAction } from "redux-actions";
import * as api from "../../common/ApiAxios";

const UPDATE = "currentBoardsSaga/UPDATE";

export const updateCurrentBoards = createAction(UPDATE);

const updateSaga = createRequestSaga(UPDATE, api.getNavBoardsAxios);

export default function* currentBoardsSaga() {
  yield takeLatest(UPDATE, updateSaga);
}
