/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Select,
  SelectAddNew,
  SelectAddNewCommon,
  Input,
  OptionListUnit,
  Link,
  Option,
  CloseModal,
  PageModal,
} from "ui";
// import { UpOutlined, DownOutlined, UploadOutlined } from "@ant-design/icons";
// import { Draggable } from "react-beautiful-dnd";
// import "antd/dist/antd.css";
import {
  putCardViewAxios,
  getCardViewPartialInfoAxios,
  getProductListAxios,
  getCardProductAxios,
  updateCardProductAxios,
  createProductAxios,
} from "common/ApiAxios";
import { useSelector, useDispatch } from "react-redux";
// import optionList from "Components/Global/OptionList";
import { withRouter } from "react-router-dom";
import { addTeamContactsInfo } from "modules/Contacts/addTeamContactsInfoSaga";
import { sendNotification } from "modules/Global/notificationSocket";
import usePrevious from "hooks/usePrevious";
import useCheckPath from "hooks/useCheckPath";
import * as comps from "../CardComponents";
import Offer from "./Offer";
import OrderInformation from "pages/DealCardDetailView/components/OrderInformation/OrderInformation";
import Finance from "pages/DealCardDetailView/components/Finance/Finance";
import MobileFinance from "pages/DealCardDetailView/components/Finance/MobileFinance";
import { useTranslation, withTranslation } from "react-i18next";
import Icons from "assets/Icons";
import ContactsDetailView from "pages/Contacts/components/ContactsDetailView/ContactsDetailView";

const DealSummary = ({
  id,
  board_id,
  customer,
  supplier,
  quantity,
  qunit,
  amount,
  aunit,
  setLoading,
  refresh,
  cardVisible,
}) => {
  const dispatch = useDispatch();

  const states = useSelector((state) => state.addTeamMetaInfo);
  const [quantityvalue, setQuantityvalue] = useState("");
  const [amountvalue, setAmountvalue] = useState("");
  const customers = useSelector((state) => state.addTeamContactsInfo);
  const [qunitID, setQunitID] = useState(null);
  const [qunitName, setQunitName] = useState(null);
  const [aunitID, setAunitID] = useState(null);
  const [aunitName, setAunitName] = useState(null);
  const [editQuantity, setEditQuantity] = useState(false);
  const [editAmount, setEditAmount] = useState(false);
  const [result, setResult] = useState("");
  const [editCustomer, setEditCustomer] = useState(false);
  const [editSupplier, setEditSupplier] = useState(false);
  const [editUnit, setEditUnit] = useState(false);
  const [editCurrency, setEditCurrency] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const prevCustomerName = usePrevious(customerName);
  const [currCustomerID, setCurrCustomerID] = useState(null);
  const [supplierName, setSupplierName] = useState("");
  const prevSupplierName = usePrevious(supplierName);
  const [currSupplierID, setCurrSupplierID] = useState(null);
  const [lostDescription, setLostDescription] = useState("");
  const [lostTime, setLostTime] = useState("");
  const [lostReason, setLostReason] = useState({});
  const [gotNoti, setGotNoti] = useState(false);
  const user = useSelector((state) => state.addTeamMetaInfo.user);
  const notifications = useSelector((state) => state.notifications);
  const toggle = useSelector((state) => state.toggleOfferResult);
  const currentCard = useSelector((state) => state.currentCard);
  const [start, setStart] = useState(true);
  const [customerVisible, setCustomerVisible] = useState(false);
  const [customerID, setCustomerID] = useState(null);
  const [supplierVisible, setSupplierVisible] = useState(false);
  const [supplierID, setSupplierID] = useState(null);
  const [products, setProducts] = useState([]);
  const [editProduct, setEditProduct] = useState("");
  const [productList, setProductList] = useState([]);

  const { t } = useTranslation();

  const checkMobile = useCheckPath("mobile");

  useEffect(() => {
    getCardProductAxios(id).then((res) => setProducts(res.data));
  }, [id]);

  useEffect(() => {
    getProductListAxios().then((res) => setProductList(res.data));
  }, []);

  useEffect(() => {
    supplierID !== null && setSupplierVisible(true);
  }, [supplierID]);

  useEffect(() => {
    supplierVisible === false && setSupplierID(null);
  }, [supplierVisible]);

  useEffect(() => {
    customerID !== null && setCustomerVisible(true);
  }, [customerID]);

  useEffect(() => {
    customerVisible === false && setCustomerID(null);
  }, [customerVisible]);
  useEffect(() => {
    const msgs = [
      "quantity",
      "quantity_unit",
      "currency",
      "amount",
      "result",
      "customer",
      "supplier",
    ];
    if (
      notifications.card_id &&
      id.localeCompare(notifications.card_id.toString()) === 0 &&
      user.id.toString().localeCompare(notifications.user_id.toString()) !==
        0 &&
      msgs.includes(notifications.msg_type)
    ) {
      setGotNoti(true);
      getCardViewPartialInfoAxios(
        id,
        "?fields=quantity,quantity_unit,amount,currency,result,customer,supplier"
      ).then((res) => {
        setQuantityvalue(res.data.quantity);
        setQunitName(res.data.quantity_unit);
        setAmountvalue(res.data.amount);
        setAunitName(res.data.currency);
        setResult(res.data.result);
        setCustomerName(res.data.customer && res.data.customer.name);
        setCurrCustomerID(res.data.customer && res.data.customer.id);
        setSupplierName(res.data.supplier && res.data.supplier.name);
        setCurrSupplierID(res.data.supplier && res.data.supplier.id);
      });
    }
  }, [notifications]);
  useEffect(() => {
    setQuantityvalue(quantity ? quantity : "");
    setQunitName(qunit ? qunit : "");
    setAmountvalue(amount ? amount : "");
    setAunitName(aunit ? aunit : "");
    setResult(result);
    setCustomerName(customer && customer.name);
    setSupplierName(supplier && supplier.name);
  }, []);

  useEffect(() => {
    (refresh || cardVisible) &&
      getCardViewPartialInfoAxios(
        id,
        "?fields=quantity,quantity_unit,amount,currency,result,customer,supplier,lost_description,lost_reason,result_updated_at"
      ).then((res) => {
        setQuantityvalue(res.data.quantity);
        setQunitName(res.data.quantity_unit);
        setAmountvalue(res.data.amount);
        setAunitName(res.data.currency);
        setResult(res.data.result);
        setCustomerName(res.data.customer && res.data.customer.name);
        setCurrCustomerID(res.data.customer && res.data.customer.id);
        setSupplierName(res.data.supplier && res.data.supplier.name);
        setCurrSupplierID(res.data.supplier && res.data.supplier.id);
        setLostDescription(
          res.data.lost_description && res.data.lost_description
        );
        setLostTime(res.data.result_updated_at && res.data.result_updated_at);
        setLostReason(res.data.lost_reason && res.data.lost_reason);
        setLoading && setLoading(true);
      });
  }, [id, refresh, cardVisible]);

  useEffect(() => {
    start && setStart(false);
    !start &&
      currentCard === `card_${id}` &&
      getCardViewPartialInfoAxios(
        id,
        "?fields=result,lost_description,lost_reason,result_updated_at"
      ).then((res) => {
        setResult(res.data.result);
        setLostDescription(res.data.lost_description);
        setLostReason(res.data.lost_reason);
        setLostTime(res.data.result_updated_at);
      });
  }, [toggle]);

  useEffect(() => {
    id !== "" &&
      prevCustomerName &&
      prevCustomerName.localeCompare(customerName) !== 0 &&
      !gotNoti &&
      dispatch(
        sendNotification({
          board_id: board_id,
          card_id: id,
          msg_type: "customer",
          team_id: user.team_id,
          type: "sendMessage",
          user_id: user.id,
        })
      );
    gotNoti && setGotNoti(false);
  }, [customerName]);

  useEffect(() => {
    id !== "" &&
      prevSupplierName &&
      prevSupplierName.localeCompare(supplierName) !== 0 &&
      !gotNoti &&
      dispatch(
        sendNotification({
          board_id: board_id,
          card_id: id,
          msg_type: "supplier",
          team_id: user.team_id,
          type: "sendMessage",
          user_id: user.id,
        })
      );
    gotNoti && setGotNoti(false);
  }, [supplierName]);

  const changeCardViewData = (type, datas, msg_type) => {
    putCardViewAxios(id, { [type]: datas }).then(() => {
      dispatch(
        sendNotification({
          board_id: board_id,
          card_id: id,
          msg_type: msg_type ? msg_type : type,
          team_id: user.team_id,
          type: "sendMessage",
          user_id: user.id,
        })
      );
    });
  };

  const handleProductAddNewValue = (e, newValue) => {
    e.stopPropagation();
    createProductAxios({ card_ids: [id], name: newValue }).then((res) => {
      setProductList([res.data, ...productList]);
      setProducts([
        ...products,
        {
          ...res.data,
          key: res.data.id,
          value: `${res.data.name}${res.data.id}`,
          val: res.data.name,
        },
      ]);
    });
  };

  const handleProductOptionClick = () => {};

  const handleProductSelect = (value, option) => {
    setProducts([...products, option]);
    updateCardProductAxios({ card_id: id, add_product_ids: [option.key] });
  };

  const handleProductDeselect = (value, option) => {
    setProducts(products.filter((product) => product.id !== option.id));
    updateCardProductAxios({
      card_id: id,
      remove_product_ids: [option.key],
    });
  };

  const handleProductClear = () => {
    updateCardProductAxios({
      card_id: id,
      remove_product_ids: products.map((product) => product.id),
    });
    setProducts([]);
  };

  return (
    <>
      <OutsideWrapper pathname={checkMobile}>
        <DealSummaryTitle
          pathname={checkMobile}
          // onClick={() => setHeightTrue(!heightTrue)}
          // style={{ cursor: "pointer" }}
        >
          <div>{t("cardView.dealSummary.title")}</div>
          {/* {heightTrue ? <UpOutlined /> : <DownOutlined />} */}
        </DealSummaryTitle>
        <BodyContainer /*height={heightTrue.toString()}*/>
          <LeftWrapper>
            <ContactsDetailModal
              visible={customerVisible || supplierVisible}
              // closable={false}
              footer={null}
              onCancel={() => {
                customerVisible
                  ? setCustomerVisible(false)
                  : setSupplierVisible(false);
              }}
              centered={true}
              bodyStyle={{
                backgroundColor: `${({ theme }) =>
                  theme.colors.color_base_white}`,
              }}
              width="75%"
              // window={height}
              destroyOnClose
            >
              <ContactsDetailView
                type={customerVisible ? "customer" : "supplier"}
                typeId={customerVisible ? customerID : supplierID}
                setDetailViewVisible={
                  customerVisible ? setCustomerVisible : setSupplierVisible
                }
              />
            </ContactsDetailModal>
            <InfoRow>
              <InfoBox>
                <InfoTitle>
                  {t("cardView.dealSummary.customer.label")}
                </InfoTitle>
                <Info>
                  {editCustomer ? (
                    <SelectAddNew
                      placeholder1={t(
                        "cardView.dealSummary.customer.placeholder1"
                      )}
                      placeholder2={t(
                        "cardView.dealSummary.customer.placeholder2"
                      )}
                      dropdownMatchSelectWidth={false}
                      selectAxios={putCardViewAxios}
                      id={id}
                      itemList={customers.all_customers}
                      saga={addTeamContactsInfo}
                      defaultValue={customerName}
                      keyValue="customer"
                      onBlur={() => setEditCustomer(false)}
                      setter={setCustomerName}
                      idsetter={setCurrCustomerID}
                      defaultOpen={true}
                      autoFocus={true}
                    />
                  ) : (
                    // <TextBox onClick={() => setEditCustomer(!editCustomer)}>
                    //   {customerName
                    //     ? customerName
                    //     : dealSummaryText.customer.text}
                    // </TextBox>
                    <EditTextBox onClick={() => setEditCustomer(!editCustomer)}>
                      {customers.all_customers
                        .map((customer) => customer.id)
                        .includes(currCustomerID) ? (
                        <LinkText
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomerID(currCustomerID);
                          }}
                        >
                          {customerName
                            ? customerName
                            : t("cardView.dealSummary.customer.text")}
                        </LinkText>
                      ) : (
                        <EditText>
                          {customerName
                            ? customerName
                            : t("cardView.dealSummary.customer.text")}
                        </EditText>
                      )}
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                  )}
                </Info>
              </InfoBox>

              <InfoBox>
                <InfoTitle>
                  {t("cardView.dealSummary.supplier.label")}
                </InfoTitle>
                <Info>
                  {editSupplier ? (
                    <SelectAddNew
                      placeholder1={t(
                        "cardView.dealSummary.supplier.placeholder1"
                      )}
                      dropdownMatchSelectWidth={false}
                      placeholder2={t(
                        "cardView.dealSummary.supplier.placeholder2"
                      )}
                      selectAxios={putCardViewAxios}
                      id={id}
                      itemList={customers.all_suppliers}
                      saga={addTeamContactsInfo}
                      defaultValue={supplierName}
                      keyValue="supplier"
                      onBlur={() => setEditSupplier(false)}
                      setter={setSupplierName}
                      idsetter={setCurrSupplierID}
                      defaultOpen={true}
                      autoFocus={true}
                    ></SelectAddNew>
                  ) : (
                    <EditTextBox onClick={() => setEditSupplier(!editSupplier)}>
                      {customers.all_suppliers
                        .map((supplier) => supplier.id)
                        .includes(currSupplierID) ? (
                        <LinkText
                          onClick={(e) => {
                            e.stopPropagation();
                            setSupplierID(currSupplierID);
                          }}
                        >
                          {supplierName
                            ? supplierName
                            : t("cardView.dealSummary.supplier.text")}
                        </LinkText>
                      ) : (
                        <EditText>
                          {supplierName
                            ? supplierName
                            : t("cardView.dealSummary.supplier.text")}
                        </EditText>
                      )}
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                    // <TextBox onClick={() => setEditSupplier(!editSupplier)}>
                    //   {supplierName
                    //     ? supplierName
                    //     : dealSummaryText.supplier.text}
                    // </TextBox>
                  )}
                </Info>
              </InfoBox>
            </InfoRow>
            <InfoRow pathname={checkMobile}>
              <InfoBox pathname={checkMobile}>
                <InfoTitle>
                  {t("cardView.dealSummary.quantity.label")}{" "}
                </InfoTitle>
                <Info>
                  {editQuantity ? (
                    <EditInput
                      type="number"
                      value={quantityvalue <= 0 ? "" : quantityvalue}
                      autoFocus
                      onChange={(e) => setQuantityvalue(e.target.value)}
                      onPressEnter={(e) => {
                        changeCardViewData(
                          "quantity",
                          e.target.value === "" ? 0 : e.target.value
                        );
                        setEditQuantity(!editQuantity);
                      }}
                      onBlur={(e) => {
                        changeCardViewData(
                          "quantity",
                          e.target.value === "" ? 0 : e.target.value
                        );
                        setEditQuantity(!editQuantity);
                      }}
                    />
                  ) : (
                    <EditTextBox onClick={() => setEditQuantity(!editQuantity)}>
                      <EditText>
                        {Number(quantityvalue).toLocaleString("us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </EditText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                    // <TextBox onClick={() => setEditQuantity(!editQuantity)}>
                    //   {Number(quantityvalue).toLocaleString()}
                    // </TextBox>
                  )}
                  {editUnit ? (
                    <SelectInput
                      width={100}
                      placeholder={t(
                        "cardView.dealSummary.quantity.placeholder"
                      )}
                      value={qunitName}
                      showSearch
                      dropdownMatchSelectWidth={false}
                      onSelect={(value, option) => {
                        putCardViewAxios(id, {
                          quantity_unit_id: option.key,
                        }).then(() => {
                          dispatch(
                            sendNotification({
                              board_id: board_id,
                              card_id: id,
                              msg_type: "quantity_unit",
                              team_id: user.team_id,
                              type: "sendMessage",
                              user_id: user.id,
                            })
                          );
                        });
                        setQunitName(option.code);
                        setQunitID(option.id);
                        setEditUnit(!editUnit);
                      }}
                      onBlur={() => setEditUnit(!editUnit)}
                      filterOption={(input, option) =>
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      defaultOpen
                      autoFocus
                    >
                      {OptionListUnit(
                        states.all_quantity_units.filter(
                          (unit) => unit.id !== qunitID
                        )
                      )}
                      {/* {quantityUnitList} */}
                    </SelectInput>
                  ) : (
                    <EditTextBox
                      style={{ width: "100.4px" }}
                      onClick={() => setEditUnit(!editUnit)}
                    >
                      <EditText>
                        {qunitName ? qunitName : <span>&nbsp;&nbsp;-</span>}
                      </EditText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                    // <TextBox
                    //   style={{ width: "100.4px" }}
                    //   onClick={() => setEditUnit(!editUnit)}
                    // >
                    //   {qunitName}
                    // </TextBox>
                  )}
                </Info>
              </InfoBox>
              <InfoBox>
                <InfoTitle>{t("cardView.dealSummary.amount.label")}</InfoTitle>
                <Info>
                  {editCurrency ? (
                    <SelectInput
                      width={100}
                      placeholder={t("cardView.dealSummary.amount.placeholder")}
                      value={aunitName}
                      showSearch
                      onSelect={(value, option) => {
                        putCardViewAxios(id, {
                          currency_id: option.key,
                        }).then(() => {
                          dispatch(
                            sendNotification({
                              board_id: board_id,
                              card_id: id,
                              msg_type: "currency",
                              team_id: user.team_id,
                              type: "sendMessage",
                              user_id: user.id,
                            })
                          );
                        });
                        setAunitName(option.code);
                        setAunitID(option.id);
                        setEditCurrency(!editCurrency);
                      }}
                      onBlur={() => setEditCurrency(!editCurrency)}
                      filterOption={(input, option) =>
                        option.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      defaultOpen
                      dropdownMatchSelectWidth={false}
                      autoFocus
                    >
                      {OptionListUnit(
                        states.all_currencies.filter(
                          (unit) => unit.id !== aunitID
                        )
                      )}
                    </SelectInput>
                  ) : (
                    <EditTextBox
                      style={{ width: "100.4px" }}
                      onClick={() => setEditCurrency(!editCurrency)}
                    >
                      <EditText>
                        {aunitName ? aunitName : <span>&nbsp;&nbsp;-</span>}
                      </EditText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                    // <TextBox
                    //   style={{ width: "100.4px" }}
                    //   onClick={() => setEditCurrency(!editCurrency)}
                    // >
                    //   {aunitName}
                    // </TextBox>
                  )}

                  {editAmount ? (
                    <EditInput
                      type="number"
                      value={amountvalue <= 0 ? "" : amountvalue}
                      autoFocus
                      onChange={(e) => setAmountvalue(e.target.value)}
                      onPressEnter={(e) => {
                        changeCardViewData(
                          "amount",
                          e.target.value === "" ? 0 : e.target.value
                        );
                        setEditAmount(!editAmount);
                      }}
                      onBlur={(e) => {
                        changeCardViewData(
                          "amount",
                          e.target.value === "" ? 0 : e.target.value
                        );
                        setEditAmount(!editAmount);
                      }}
                    />
                  ) : (
                    <EditTextBox onClick={() => setEditAmount(!editAmount)}>
                      <EditText>
                        {Number(amountvalue).toLocaleString()}
                      </EditText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                    // <TextBox onClick={() => setEditAmount(!editAmount)}>
                    //   {Number(amountvalue).toLocaleString()}
                    // </TextBox>
                  )}
                </Info>
              </InfoBox>
            </InfoRow>
            <InfoRow>
              <FullBox>
                <InfoTitle>{t("cardView.dealSummary.product.label")}</InfoTitle>
                <Info>
                  {editProduct ? (
                    <SelectAddNewCommon
                      placeholder1={t(
                        "cardView.dealSummary.product.placeholder1"
                      )}
                      dropdownMatchSelectWidth={false}
                      placeholder2={t(
                        "cardView.dealSummary.product.placeholder2"
                      )}
                      selectAxios={putCardViewAxios}
                      id={id}
                      itemList={productList.map((list, i) => (
                        <Option
                          id={list.id}
                          key={list.id}
                          value={`${list.name}${list.id}`}
                          val={list.name}
                          name={list.name}
                        >
                          <div>{list.name}</div>
                        </Option>
                      ))}
                      value={products.map(
                        (product) => `${product.name}${product.id}`
                      )}
                      itemType="product"
                      onBlur={() => setEditProduct(false)}
                      defaultOpen={true}
                      autoFocus={true}
                      addNewValue={handleProductAddNewValue}
                      onOptionClick={handleProductOptionClick}
                      onSelect={handleProductSelect}
                      onDeselect={handleProductDeselect}
                      onClear={handleProductClear}
                      // onChange={handleProductChange}
                      allowClear
                      mode="multiple"
                    ></SelectAddNewCommon>
                  ) : (
                    <EditTextBox onClick={() => setEditProduct(!editProduct)}>
                      <EditText>
                        {products.length > 0
                          ? products.map((product) => product.name).join(", ")
                          : t("cardView.dealSummary.product.text")}
                      </EditText>
                      <EditIcon className="hidden">
                        <Icons type="fas" name="edit" />
                      </EditIcon>
                    </EditTextBox>
                  )}
                </Info>
              </FullBox>
            </InfoRow>
          </LeftWrapper>
        </BodyContainer>
      </OutsideWrapper>
      {result !== "won" && (
        <Offer
          id={id}
          result={result}
          board_id={board_id}
          lostReason={lostReason}
          setLostReason={setLostReason}
          lostTime={lostTime}
          lostDescription={lostDescription}
          setLostDescription={setLostDescription}
        ></Offer>
      )}
      {result !== "lost" && (
        <OrderInformation id={id} result={result}></OrderInformation>
      )}
      {result !== "lost" && !checkMobile ? (
        <Finance id={id} result={result}></Finance>
      ) : (
        <MobileFinance id={id} result={result} />
      )}
    </>
  );
};

export default withRouter(withTranslation()(DealSummary));

const ContactsDetailModal = styled(PageModal)`
  /* margin: 20px 0;

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 0px;
    width: 90%;
    margin: 0 auto;
  }

  .ant-modal-close-x {
    width: 46px;
    height: 46px;
    font-size: 21px;
    line-height: 41px;
  }

  .ant-modal-footer {
    padding: 20px 40px;
    border-top: none;
  } */
`;

const LinkText = styled(Link)`
  min-height: 31px;
  line-height: 31px;
  padding-left: 2px;
  pointer-events: auto !important;
  text-decoration: none !important;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  &:hover {
    text-decoration: underline !important;
  }
`;

const EditIcon = styled.div`
  display: none;
  padding-bottom: 4px;
  padding-right: 4px;
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const EditTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  &:hover .hidden {
    display: inline-block;
  }
`;

const EditText = styled.div`
  min-height: 31px;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  line-height: 31px;
  padding-left: 2px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DealSummaryTitle = styled(comps.CardViewInnerSectionTitleStyle)`
  /* ${(props) =>
    props.pathname ? "border: none;" : "border: 1px solid #d6dee5;"} */
`;

const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)`
  ${(props) =>
    props.pathname
      ? "border: 1px solid #ffffff;"
      : "border: 1px solid #d6dee5;"}
`;

const Info = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  margin-top: 12px;
`;

const LeftWrapper = styled.div`
  /* display: flex;
  justify-content: space-between; */
  margin-top: 10px;
`;

const InfoRow = styled.div`
  display: flex;

  ${(props) => props.pathname && "flex-direction: column;"}
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const InfoBox = styled.div`
  /* width: 25%; */
  width: 50%;

  ${(props) => props.pathname && "margin-bottom: 10px;"}

  :nth-child(1) {
    margin-right: 8px;
  }

  /* :nth-child(5) {
    width: 25%;
  } */
  /* margin-right: 16px; */
`;

const FullBox = styled(InfoBox)`
  width: 100%;
`;

const InfoTitle = styled.div`
  /* height: 50%; */
  width: 100%;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fontSizes.txt_h5};
  /* margin-bottom: 12px; */

  /* padding: 0 11px; */
  /* margin-right: 16px; */
`;

// const UpperContainer = styled.div`
//   margin-top: 15px;
//   padding-bottom: 0;
//   width: 100%;
//   min-height: 70px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   font-size: 14px;
// `;

// const LeftWrapper = styled.div`
//   height: 64px;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   /* padding-right: 50px; */
//   width: 65%;
//   @media (max-width: 1500px) {
//     width: 100%;
//     padding-right: 0px;
//     margin: auto 0;
//   }
// `;

const SelectInput = styled(Select)`
  padding-left: 2px;
  width: ${(props) => `${props.width}px`} !important;
  .ant-select-selector {
    padding: 0 !important;
  }
  .ant-select-selection-search-input {
    padding-left: 0px !important;
  }

  .ant-select-selection-search {
    left: 0 !important;
  }

  .ant-select-selection-item {
    div {
      margin: 0;
    }
  }
  .ant-select-selector {
    /* width: ${(props) => `${props.width}px`} !important; */
    /* padding-left: 0px !important; */
  }
`;

const EditInput = styled(Input)`
  padding: 0 !important;
  padding-left: 2px;
`;

const BodyContainer = styled(comps.CardViewInnerSectionBodyStyle)`
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  font-size: 14px;
`;
