/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  MemberIcon,
  Modal,
  AvatarGroup,
  Popconfirm,
  Popover,
  PrimaryButton,
  DeleteButton,
  ClearButton,
  DropdownClear,
  Dropdown,
  Menu,
  CloseModal,
  ManageAccessOwner,
} from "ui";
import Icons from "assets/Icons";
import styled from "styled-components";
import {
  putCardViewAxios,
  putCardAccessAxios,
  getCardViewPartialInfoAxios,
  putOfferResultAxios,
  getDealLostReasonAxios,
} from "common/ApiAxios";
import useViewport from "hooks/useViewPort";
import * as comps from "../CardComponents";
import { sendNotification } from "modules/Global/notificationSocket";
import { toggleOfferResult } from "modules/DealCardDetailView/toggleOfferResult";
import LostReasonSetting from "pages/DealCardDetailView/components/DealSummary/LostReasonSetting";
import { useTranslation, withTranslation } from "react-i18next";

const UpperWrapper = ({
  id,
  board_id,
  handleUnarchived,
  handleAddArchived,
  handleDeleteCard,
  setViewType,
  viewType,
  owner,
  cardname,
}) => {
  const [namevalue, setNamevalue] = useState(cardname ? cardname : "");
  const [ownerData, setOwnerData] = useState(owner ? owner : "");
  const [memberData, setMemberData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [updateAuth, setUpdateAuth] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const currentCard = useSelector((state) => state.currentCard);
  const dispatch = useDispatch();
  const { height } = useViewport();
  const [start, setStart] = useState(true);
  const [offerResult, setOfferResult] = useState();
  const [lostReasonData, setLostReasonData] = useState([]);
  const user = useSelector((state) => state.addTeamMetaInfo.user);
  const toggle = useSelector((state) => state.toggleOfferResult);
  const notifications = useSelector((state) => state.notifications);
  const [lostModalVisible, setLostModalVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    start && setStart(false);
    !start &&
      currentCard === `card_${id}` &&
      getCardViewPartialInfoAxios(id, "?fields=result,lost_reason").then(
        (res) => {
          setOfferResult(res.data.result);
        }
      );
  }, [toggle]);

  useEffect(() => {
    getDealLostReasonAxios().then((res) => {
      setLostReasonData(res.data);
    });
  }, []);

  useEffect(() => {
    if (
      notifications.card_id &&
      id.localeCompare(notifications.card_id.toString()) === 0 &&
      user.id.toString().localeCompare(notifications.user_id.toString()) !==
        0 &&
      notifications.msg_type.localeCompare("name") === 0
    ) {
      getCardViewPartialInfoAxios(id, "?fields=name").then((res) => {
        setNamevalue(res.data.name);
        // setOwnerData(res.data.owner);
        // setMemberData(res.data.members);
        // setIsArchived(res.data.is_archived);
      });
    } else if (
      notifications.multi_edit &&
      notifications.multi_edit.includes(id)
    ) {
      getCardViewPartialInfoAxios(
        id,
        "?fields=name,owner,members,is_archived,result"
      ).then((res) => {
        setNamevalue(res.data.name);
        setOwnerData(res.data.owner);
        setMemberData(res.data.members);
        setIsArchived(res.data.is_archived);
        setOfferResult(res.data.result);
      });
    }
  }, [notifications]);
  useEffect(() => {
    getCardViewPartialInfoAxios(
      id,
      "?fields=name,owner,members,is_archived,result"
    ).then((res) => {
      setNamevalue(res.data.name);
      setOwnerData(res.data.owner);
      setMemberData(res.data.members);
      setIsArchived(res.data.is_archived);
      setOfferResult(res.data.result);
    });
  }, [id]);

  const showModal = () => {
    setVisible(true);
  };

  const handleVisibleChange = (flag) => {
    setMenuVisible(flag);
  };

  const handleOk = (e) => {
    setUpdateAuth(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const changeCardViewData = (type, datas, msg_type) => {
    putCardViewAxios(id, { [type]: datas }).then(() => {
      dispatch(
        sendNotification({
          board_id: board_id,
          card_id: id,
          msg_type: msg_type ? msg_type : type,
          team_id: user.team_id,
          type: "sendMessage",
          user_id: user.id,
        })
      );
    });
  };

  const handleEnterKey = (e) => {
    if (e.charCode === 13) {
      changeCardViewData("name", e.target.value);
      setEditTitle(!editTitle);
    }
  };

  const onConfirmToggle = () => {
    setConfirmVisible(!confirmVisible);
  };

  const handleChangeOfferResult = (result) => {
    setConfirmVisible(false);
    setLostModalVisible(false);

    putOfferResultAxios(id, {
      result: { won: 1, lost: 2, reset: 4 }[result],
    }).then(() => {
      dispatch(toggleOfferResult());
    });
  };

  const showLostModal = () => {
    setLostModalVisible(!lostModalVisible);
    setConfirmVisible(false);
  };

  const viewMenu = (
    <Menu>
      <Menu.Item
        style={{
          display: "flex",
        }}
        onClick={() => setViewType(1)}
      >
        <ItemBox>
          <Icons type="fas" name="exchange-alt" />
          &nbsp; &nbsp;
          <div>{t("cardView.upperWrapper.showBoth")}</div>
        </ItemBox>
      </Menu.Item>
      <Menu.Item
        style={{
          display: "flex",
        }}
        onClick={() => setViewType(2)}
      >
        <ItemBox>
          <Icons type="fas" name="arrow-to-right" />
          &nbsp;&nbsp; &nbsp;
          <div>{t("cardView.upperWrapper.hideMyThreads")}</div>
        </ItemBox>
      </Menu.Item>
      <Menu.Item
        style={{
          display: "flex",
        }}
        onClick={() => setViewType(3)}
      >
        <ItemBox>
          <Icons type="fas" name="arrow-to-left" />
          &nbsp;&nbsp; &nbsp;
          <div>{t("cardView.upperWrapper.hideMyTools")}</div>
        </ItemBox>
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Popconfirm
          getPopupContainer={(trigger) => trigger.parentElement}
          placement="bottomRight"
          autoAdjustOverflow={false}
          title={
            <ArchiveTitle>
              {isArchived === true
                ? t("cardView.upperWrapper.unArchive.confirmText")
                : t("cardView.upperWrapper.archive.confirmText")}
            </ArchiveTitle>
          }
          onConfirm={isArchived === true ? handleUnarchived : handleAddArchived}
          okText="Yes"
          cancelText="No"
        >
          <MenuItem>
            {isArchived === true
              ? t("cardView.upperWrapper.unArchive.name")
              : t("cardView.upperWrapper.archive.name")}
          </MenuItem>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key="delete">
        <Popconfirm
          getPopupContainer={(trigger) => trigger.parentElement}
          placement="bottomRight"
          arrowPointAtCenter
          autoAdjustOverflow={false}
          title={
            <ArchiveTitle>
              {t("cardView.upperWrapper.delete.confirmText")}
            </ArchiveTitle>
          }
          onConfirm={handleDeleteCard}
          okText="Yes"
          cancelText="No"
        >
          <MenuItem>{t("cardView.upperWrapper.delete.name")}</MenuItem>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <TopWrapper>
      <LeftWrapper>
        {editTitle ? (
          <TitleInput
            autoFocus
            value={namevalue}
            onChange={(e) => setNamevalue(e.target.value)}
            onKeyPress={handleEnterKey}
            onBlur={(e) => {
              changeCardViewData("name", e.target.value);
              setEditTitle(!editTitle);
            }}
          ></TitleInput>
        ) : (
          <Title
            onClick={() => {
              setEditTitle(!editTitle);
            }}
          >
            {namevalue}
          </Title>
        )}
      </LeftWrapper>

      <RightWrapper>
        <InfoContainer>
          <InfoBox>
            <View trigger="click" overlay={viewMenu}>
              <ViewButton>
                <ViewButtonText>
                  <ItemBox>
                    {viewType === 1 && (
                      <span>
                        <Icons type="fas" name="exchange-alt" />
                      </span>
                    )}
                    {viewType === 2 && (
                      <span>
                        <Icons type="fas" name="arrow-to-right" />
                      </span>
                    )}
                    {viewType === 3 && (
                      <span>
                        <Icons type="fas" name="arrow-to-left" />
                      </span>
                    )}
                    {
                      {
                        1: (
                          <div>
                            &nbsp; &nbsp;{t("cardView.upperWrapper.showBoth")}
                          </div>
                        ),
                        2: (
                          <div>
                            &nbsp;&nbsp; &nbsp;
                            {t("cardView.upperWrapper.hideMyThreads")}
                          </div>
                        ),
                        3: (
                          <div>
                            &nbsp;&nbsp; &nbsp;
                            {t("cardView.upperWrapper.hideMyTools")}
                          </div>
                        ),
                      }[viewType]
                    }
                  </ItemBox>
                </ViewButtonText>
                <Icons type="fas" name="caret-down" />
              </ViewButton>
            </View>
          </InfoBox>
          <InfoBox>
            <OwnerBox>
              <p>{ownerData && <MemberIcon member={ownerData}></MemberIcon>}</p>
              <OwnerIcon>
                <Icons type="fas" name="check-circle" />
              </OwnerIcon>
            </OwnerBox>
          </InfoBox>
          <InfoBox>
            <InfoPadding>
              <AvatarGroup
                maxPopoverPlacement="bottom"
                maxCount={3}
                maxStyle={{
                  fontSize: `${({ theme }) => theme.fontSizes.txt_smaller}`,
                  fontWeight: "bold",
                  lineHeight: "28px",
                  color: `${({ theme }) => theme.colors.color_text_titles}`,
                  backgroundColor: `${({ theme }) =>
                    theme.colors.color_base_dark}`,
                  border: `2px solid ${({ theme }) =>
                    theme.colors.color_base_darker}`,
                }}
              >
                {memberData &&
                  memberData
                    .filter((members) => members.id !== ownerData.id)
                    .filter((members) => members.has_access)
                    .map((member, i) => (
                      <MemberIcon
                        key={i}
                        info={true}
                        member={member}
                      ></MemberIcon>
                    ))}
              </AvatarGroup>

              <AddModal
                title={t("cardView.upperWrapper.addModal.title")}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                height={height}
                okText="Add Member(s)"
                footer={
                  <FooterBox>
                    <EventBtnBox>
                      <ClearButton onClick={() => setVisible(false)}>
                        {t("cardView.upperWrapper.addModal.cancel")}
                      </ClearButton>
                      <AddBtn onClick={handleOk}>
                        {t("cardView.upperWrapper.addModal.addMembers")}
                      </AddBtn>
                    </EventBtnBox>
                  </FooterBox>
                }
                centered
                destroyOnClose
              >
                {ownerData !== undefined && (
                  <ManageAccessOwner
                    ownerData={ownerData}
                    setOwnerData={setOwnerData}
                    memberData={memberData}
                    setMemberData={setMemberData}
                    setVisible={setVisible}
                    updateAuth={updateAuth}
                    setUpdateAuth={setUpdateAuth}
                    targetID={id}
                    editApi={putCardAccessAxios}
                  ></ManageAccessOwner>
                )}
              </AddModal>
              <AddMemberBtn onClick={() => showModal()}>
                <Icons type="far" name="plus" size="lg" />
              </AddMemberBtn>
            </InfoPadding>
          </InfoBox>

          <InfoBox>
            <Popover
              visible={confirmVisible}
              onVisibleChange={onConfirmToggle}
              trigger="click"
              placement="bottomLeft"
              arrowPointAtCenter
              content={
                <ConfirmContainer>
                  <ConfirmTitle>
                    <Icons type="fas" name="question-circle" />
                    &nbsp; {t("cardView.upperWrapper.confirmText")}
                  </ConfirmTitle>
                  <ConformFooter>
                    <BtnBox>
                      {offerResult === "won" ? (
                        <DeleteButton onClick={() => showLostModal()}>
                          {t("cardView.upperWrapper.lost")}
                        </DeleteButton>
                      ) : (
                        <PrimaryButton
                          onClick={() => handleChangeOfferResult("won")}
                        >
                          {t("cardView.upperWrapper.won")}
                        </PrimaryButton>
                      )}
                      &nbsp;
                      <ClearButton
                        onClick={() => handleChangeOfferResult("reset")}
                      >
                        {t("cardView.upperWrapper.reset")}
                      </ClearButton>
                    </BtnBox>
                  </ConformFooter>
                </ConfirmContainer>
              }
            >
              {offerResult === "lost" && (
                <DeleteButton>{t("cardView.upperWrapper.lost")}</DeleteButton>
              )}
              {offerResult === "won" && (
                <PrimaryButton>{t("cardView.upperWrapper.won")}</PrimaryButton>
              )}
            </Popover>

            <LostSettingModal
              title={
                <ModalTitle>{t("cardView.upperWrapper.modalTitle")}</ModalTitle>
              }
              visible={lostModalVisible}
              onCancel={showLostModal}
              footer={null}
              width="40%"
              destroyOnClose
            >
              <LostReasonSetting
                id={id}
                toggleModal={showLostModal}
                handleChangeOfferResult={handleChangeOfferResult}
                lostReasonData={lostReasonData}
              />
            </LostSettingModal>
          </InfoBox>
          <InfoBox>
            <DropdownBox
              overlay={menu}
              trigger={["click"]}
              onVisibleChange={handleVisibleChange}
              visible={menuVisible}
              placement="bottomRight"
            >
              <MenuIcon>
                <Icons type="fas" name="ellipsis-v" size="lg" />
              </MenuIcon>
            </DropdownBox>
          </InfoBox>
        </InfoContainer>
      </RightWrapper>
    </TopWrapper>
  );
};

export default withTranslation()(UpperWrapper);

const MenuItem = styled.div`
  width: 100%;
  height: 100%;
`;

const ArchiveTitle = styled.div`
  min-width: 264px;
  white-space: nowrap;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const InfoContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;

const View = styled(DropdownClear)`
  width: 100%;
  color: ${({ theme }) => theme.colors.color_text_titles} !important;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.color_text_titles} !important;
    background-color: inherit !important;
  }
`;

const ViewButton = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const ViewButtonText = styled.div`
  margin-right: 8px;
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const ItemBox = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerBox = styled.div`
  position: relative;
`;

const OwnerIcon = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  top: -2px;
  right: -2px;
  z-index: 999;
  color: ${({ theme }) => theme.colors.color_primary_light};
  background-color: ${({ theme }) => theme.colors.color_base_white};
  border: 1px solid ${({ theme }) => theme.colors.color_base_white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
`;

const DropdownBox = styled(Dropdown)`
  background-color: inherit;
  border: none;

  &:hover {
    background-color: inherit;
    border: none;
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
`;

const InfoPadding = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 11px;
`;

const LeftWrapper = styled.div`
  width: 60%;
`;

const Title = styled.div`
  padding: 4px 12px;
  width: 100%;
  ${({ theme }) => theme.fontSizes.txt_h2};
`;

const TitleInput = styled.input`
  width: 100%;
  ${({ theme }) => theme.fontSizes.txt_h2};
  background-color: inherit;
  border: none;
  padding: 0px 12px;
  padding-bottom: 1px;
`;

const TopWrapper = styled(comps.CardViewInnerSectionStyle)`
  width: 100%;
  margin: 16px 0 !important;
  display: flex;
  align-items: center;
  @media (max-width: 1500px) {
    margin: 0px auto;
  }
`;

const RightWrapper = styled.div`
  height: 44px;
  display: flex;
  justify-content: center;
  width: 40%;
  @media ${({ theme }) => theme.screenSizes.mobile} {
    display: none;
  }
`;

const AddModal = styled(Modal)`
  padding-top: 40px;
`;

const AddMemberBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.color_text_body};
  background-color: ${({ theme }) => theme.colors.color_base_dark};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.color_base_darker};
  cursor: pointer;
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const EventBtnBox = styled.div`
  display: flex;
  margin: 5px 0;
`;

const AddBtn = styled(PrimaryButton)`
  margin-left: 24px;
`;

const BtnBox = styled.div`
  display: flex;
  align-items: center;
`;

const ConfirmContainer = styled.div`
  min-width: 170px;
  padding: 10px;
`;

const ConfirmTitle = styled.div`
  padding: 5px 0;
`;

const ConformFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const LostSettingModal = styled(CloseModal)``;

const ModalTitle = styled.h2`
  ${({ theme }) => theme.fontSizes.txt_h2};
`;
