import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import DealsListItem from "./DealsListItem";
import { getContactTypeDetailAxios } from "common/ApiAxios";
import { useTranslation, withTranslation } from "react-i18next";
import { useUserSettings } from "api-cache";
const DealsList = memo(({ type, typeId }) => {
  const {
    data: { contact_deal_columns: columns },
  } = useUserSettings("?field=contact_deal_columns", ["contact_deal_columns"]);
  const [dealsListData, setDealsListData] = useState([]);
  const [dealListUpdate, setDealListUpdate] = useState(false);

  useEffect(() => {
    getContactTypeDetailAxios(type, typeId).then((res) => {
      setDealsListData(res.data.card_set);
    });
  }, [dealListUpdate, type, typeId]);

  const handleDataUpdate = () => {
    setDealListUpdate(!dealListUpdate);
  };

  const { t } = useTranslation();

  return (
    <DealsListComponent>
      <Title>{t("contacts.contactsDetailView.contactsDealsList.title")}</Title>
      <Table>
        <TableTitleBox>
          {/* <TableTitle>NO.</TableTitle> */}
          <TableTitle>
            {t("contacts.contactsDetailView.contactsDealsList.name")}
          </TableTitle>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsDealsList.stage")}
          </TableTitle>
          {/* <TableTitle>OFFER RESULT</TableTitle> */}
          <TableTitle>
            {t("contacts.contactsDetailView.contactsDealsList.amount")}
          </TableTitle>
          {/* <TableTitle>
            {type === "customer" ? "CUSTOMER" : "SUPPLIER"}
          </TableTitle> */}
          <TableTitle>
            {t("contacts.contactsDetailView.contactsDealsList.owner")}
          </TableTitle>
          <TableTitle>
            {t("contacts.contactsDetailView.contactsDealsList.archivedDate")}
          </TableTitle>
          {/* <TableTitle>CREATED_DATE</TableTitle>
          <TableTitle>ARCHIVED DATE</TableTitle> */}
        </TableTitleBox>
        <TableList>
          {dealsListData &&
            dealsListData.map((item) => {
              return (
                <DealsListItem
                  key={item.id}
                  item={item}
                  handleDataUpdate={handleDataUpdate}
                />
              );
            })}
        </TableList>
      </Table>
    </DealsListComponent>
  );
});

export default withTranslation()(DealsList);

const DealsListComponent = styled.div`
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h3};
  margin-bottom: 15px;
`;

const Table = styled.div`
  /* border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc; */
`;

const TableTitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_dark};
`;

const TableTitle = styled.div`
  width: 12.5%;
  padding: 5px 0;
  ${({ theme }) => theme.fontSizes.txt_h5};

  :nth-child(1) {
    width: 40%;
  }

  :nth-child(4) {
    width: 17.5%;
  }

  :nth-child(5) {
    width: 17.5%;
  }
`;

const TableList = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;
