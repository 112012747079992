/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
// import "antd/dist/antd.css";
import {
  Popconfirm,
  // SecondaryButton,
  label_green,
  label_red,
  label_grey,
  Skeleton,
} from "ui";
import Icons from "assets/Icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled, { ThemeContext } from "styled-components";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailThreadMessage from "./EmailThreadMessage";
import useViewport from "hooks/useViewPort";
import * as api from "common/ApiAxios";
import {
  CardViewInnerSectionStyle,
  CardViewInnerSectionTitleStyle,
  CardViewInnerSectionBodyStyle,
} from "pages/DealCardDetailView/components/CardComponents";
import MoonLoader from "react-spinners/MoonLoader";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";
// import { MenuItem } from "@material-ui/core";
// import Title from "antd/lib/skeleton/Title";

const EmailThreadDetail = ({
  id,
  currentThreadID,
  setCurrentThreadID,
  connectingList,
  setPreventClose,
}) => {
  const { height } = useViewport();
  const bottomRef = useRef();
  const [reset, setReset] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [threadData, setThreadData] = useState(null);
  const [threadMessages, setThreadMessages] = useState([]);
  const [scrollTop, setScrollTop] = useState(100);
  const [threadMessagesInfo, setThreadMessagesInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  const theme = useContext(ThemeContext);
  const synced_emails = useSelector(
    (state) => state.addTeamMetaInfo.synced_emails
  );
  const [start, setStart] = useState(true);
  const containerRef = useRef();
  const synced_email_current = useSelector(
    (state) => state.emailInfo.synced_email_current
  );
  const checkMobile = useCheckPath("mobile");

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (scrollTop === 0 && threadMessagesInfo.next && containerRef.current) {
      const prevHeight = containerRef.current._osInstance.scroll().max.y;
      setLoading(true);
      api
        .getMoreThreadMessagesAxios(threadMessagesInfo.next.split("/")[6])
        .then((res) => {
          setThreadMessages([...res.data.results.reverse(), ...threadMessages]);
          setThreadMessagesInfo(res.data);
          setTimeout(() => {
            containerRef.current._osInstance.scroll({
              y: containerRef.current._osInstance.scroll().max.y - prevHeight,
            });
          }, 100);
          setLoading(false);
        });
    }
  }, [scrollTop, containerRef]);
  useEffect(() => {
    api
      .getConnectedThreadsAxios(
        id.slice(5),
        `&search=${currentThreadID}&fields=id,is_shared,thread_id,subject,synced_with_email`
      )
      .then((res) => {
        setThreadData(res.data[0]);
      });
    api.getThreadMessagesAxios(currentThreadID).then((res) => {
      setThreadMessages(res.data.results.reverse());
      setThreadMessagesInfo(res.data);
    });
  }, [id]);

  // useEffect(() => {
  //   if (id !== null) {
  //     setLoaded(false);
  //     setStart(true);
  //     setLoading(true);
  //     api
  //       .getConnectedThreadsAxios(
  //         id.slice(5),
  //         currentThreadID === "All Connected Threads"
  //           ? ""
  //           : `&search=${currentThreadID}`
  //       )
  //       .then((res) => {
  //         setData(res.data);
  //         res.data.length === 0 && setStart(false);
  //         setLoading(false);
  //       });
  //   }
  // }, [id, currentThreadID, reset]);

  const UpdateThread = (bool, thread_id) => {
    api.editThreadSharedAxios(thread_id, id.slice(5), { is_shared: bool });
    setThreadData({ ...threadData, is_shared: bool });
  };
  useEffect(() => {
    loaded && setLoaded(false);
    loaded && start && bottomRef.current.scrollIntoView(true);
    loaded && start && setStart(false);
  }, [loaded]);
  return threadData === null ? (
    <Skeleton active />
  ) : (
    <ThreadContainerBox isMobile={checkMobile}>
      <ThreadHeader>
        <GoBack
          onClick={() => {
            setCurrentThreadID("");
          }}
        >
          <GoBackArrow className="ToolBarItems" />
        </GoBack>
        {connectingList.includes(threadData.thread_id) && (
          <LoadingBox>
            <MyLoading
              color={theme.colors.color_state_warning_regular}
              css={"margin-top: 8px; margin-right: 5px;"}
              size={15}
            />
            {}
            {t("cardView.emailThread.emailThreadDetail.connecting")}
          </LoadingBox>
        )}
        <TitleLimit>
          {threadData.subject === "" ? "No Title" : threadData.subject}
        </TitleLimit>
        <ThreadType>
          <SharedLabel
            onClick={() =>
              !threadData.is_shared && UpdateThread(true, threadData.id)
            }
          >
            {threadData.is_shared ? (
              <LabelGreenText>
                <LabelText>
                  {t("cardView.emailThread.emailThreadDetail.shared")}
                  {!synced_emails.includes(threadData.synced_with_email) &&
                    ` by ${threadData.synced_with_email.split("@")[0]}`}
                </LabelText>
              </LabelGreenText>
            ) : (
              <LabelGreyText>
                {t("cardView.emailThread.emailThreadDetail.shared")}
              </LabelGreyText>
            )}
          </SharedLabel>
          {(synced_emails.includes(threadData.synced_with_email) ||
            !threadData.is_shared) && (
            <PrivateLabel
              onClick={() =>
                threadData.is_shared && UpdateThread(false, threadData.id)
              }
            >
              {threadData.is_shared ? (
                <LabelGreyText>
                  {t("cardView.emailThread.emailThreadDetail.private")}
                </LabelGreyText>
              ) : (
                <LabelRedText>
                  {t("cardView.emailThread.emailThreadDetail.private")}
                </LabelRedText>
              )}
            </PrivateLabel>
          )}
        </ThreadType>
        <MyPopconfirm
          title={
            <RemoveTitle>
              {t("cardView.emailThread.emailThreadDetail.confirmText")}
            </RemoveTitle>
          }
          getPopupContainer={(trigger) => trigger.parentElement}
          onConfirm={() => {
            api
              .disconnectThreadAxios({
                card_id: id.slice(5),
                thread_ids: [threadData.thread_id],
              })
              .then(() => setCurrentThreadID(""));
          }}
          okText="Yes"
          cancelText="No"
          placement="leftTop"
          trigger="click"
        >
          <RemoveThread>
            <Icons type="far" name="unlink" size="xs" />
          </RemoveThread>
        </MyPopconfirm>
      </ThreadHeader>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "leave" },
          overflowBehavior: {
            x: "hidden",
            y: "scroll",
          },
        }}
        ref={containerRef}
        // callbacks={{
        //   onScrollStop: function (e) {
        //   },
        // }}
        // onScrollStop={(e) => e.target.scrollTop}
        onScroll={(e) => setScrollTop(e.target.scrollTop)}
      >
        <ThreadContainer window={height} isMobile={checkMobile}>
          {start && <Skeleton active />}
          <VisWrap start={start ? "true" : "false"}>
            {loading && (
              <LoadingSec>
                <MyLoading
                  color={theme.colors.color_primary_regular}
                  // css={"margin-top: 8px; margin-right: 5px;"}
                  size={25}
                />
              </LoadingSec>
            )}
            {threadMessages.map((message, index) => (
              <EmailThreadMessage
                setThreadMessages={setThreadMessages}
                threadMessages={threadMessages}
                setReset={setReset}
                reset={reset}
                message={message}
                key={message.id}
                threadID={threadData.thread_id}
                threadSubject={threadData.subject}
                setPreventClose={setPreventClose}
                setComponentLoaded={setLoaded}
                id={id}
                messageID={message.id}
                last={threadMessages.length - 1 === index ? true : false}
                mine={synced_email_current === threadData.synced_with_email}
              />
            ))}
            <div ref={bottomRef}></div>
          </VisWrap>
        </ThreadContainer>
      </OverlayScrollbarsComponent>
    </ThreadContainerBox>
  );
};

export default withRouter(withTranslation()(EmailThreadDetail));

const LoadingSec = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingBox = styled.div`
  display: flex;
  min-width: 131px;
  margin-left: 8px;
  padding-left: 4px;
  justify-self: flex-end;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.color_state_warning_regular};
`;

const MyLoading = styled(MoonLoader)``;

const GoBackArrow = styled(ArrowLeftOutlined)``;

const GoBack = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  line-height: 40px;
  cursor: pointer;
`;

const VisWrap = styled.div`
  padding-bottom: 4px;
  opacity: ${({ start }) => start === "true" && 0};
`;

const SharedLabel = styled.div``;
const PrivateLabel = styled.div`
  margin-left: 8px;
`;

const LabelGreyText = styled(label_grey)`
  cursor: pointer;
`;

const LabelRedText = styled(label_red)`
  cursor: pointer;
`;

const LabelGreenText = styled(label_green)`
  cursor: pointer;
`;

const LabelText = styled.div`
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

const RemoveTitle = styled.div`
  min-width: 300px;
`;

const MyPopconfirm = styled(Popconfirm)``;

// const CurrentThreads = styled(WhiteButtonKey)`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   font-weight: bold;
//   height: 30px;
//   width: 300px;
//   .text {
//     width: 280px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     word-break: break-all;
//   }
// `;

const ThreadType = styled.div`
  display: flex;
  position: absolute;
  right: 40px;
`;

const RemoveThread = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
`;

const TitleLimit = styled.div`
  margin: 0 140px 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  user-select: text !important;
`;

const ThreadContainerBox = styled(CardViewInnerSectionStyle)`
  ${(props) => props.isMobile && "border: none;"} 
  /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
  margin-bottom: 20px;
`;

const ThreadHeader = styled(CardViewInnerSectionTitleStyle)`
  justify-content: flex-start;
  /* width: 80%; */
  /* margin: 10px 10%; */
  /* text-align: center;
  height: 50px;
  line-height: 50px; */
  /* font-size: 15px; */
  /* background-color: ${({ theme }) => theme.colors.color_text_body}; */
  /* border-radius: 10px; */
  /* color: ${({ theme }) => theme.colors.color_base_regular}; */
  /* font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_text_body}; */
  /* background-color: ${({ theme }) => theme.colors.color_base_darker}; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; */
  /* border-radius: 4px; */
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_dark}; */
`;

// const ConnectButton = styled(WhiteButtonKey)``;
const ThreadContainer = styled(CardViewInnerSectionBodyStyle)`
  height: ${(props) =>
    props.isMobile ? `${props.window - 120}px` : `${props.window - 190}px`};
`;
