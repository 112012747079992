import { dealBoardAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useBoardDetail = (id) => {
  async function getBoardDetail() {
    const { data } = await dealBoardAPIs.getBoardDetailAxios(id);
    return data;
  }
  return useQuery({
    queryKey: ["get/board/", id],
    queryFn: getBoardDetail,
  });
};
