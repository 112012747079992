import { emailAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useEmailAccount = () => {
  async function getSettingEmailData() {
    const { data } = await emailAPIs.getSettingEmailAxios();
    return data;
  }
  return useQuery({
    queryKey: "get/email/account/",
    queryFn: getSettingEmailData,
    placeholderData: {
      synced_email_current: "",
      synced_emails: [],
      provider: "",
      synced_state: false,
      thread_count: 25,
      max_upload: 10000000,
      loading: true,
    },
  });
};
