import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ClearButton, label_red } from "ui";
import Icons from "assets/Icons";
import {
  postToDoItemAxios,
  getCardViewPartialInfoAxios,
} from "common/ApiAxios";
import * as comps from "../CardComponents";
import TodoItem from "./TodoItem";
// import { Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import useCheckPath from "hooks/useCheckPath";
// import { sendNotification } from "Modules/Global/notificationSocket";
import { useTranslation, withTranslation } from "react-i18next";

const Todos = ({ id, match, refresh }) => {
  const [todoList, setTodoList] = useState([]);
  useEffect(() => {
    refresh &&
      getCardViewPartialInfoAxios(id, "?fields=to_do_items").then((res) => {
        setTodoList(res.data.to_do_items);
      });
  }, [id, refresh]);
  const date = new Date();
  const handleClick = () => {
    postToDoItemAxios({
      card: id,
      deadline: date.toISOString().slice(0, 10),
      content: "",
      is_done: false,
    }).then((res) => {
      setTodoList([
        ...todoList,
        {
          id: res.data.id,
          card: id,
          deadline_read: date.toISOString().slice(0, 10),
          content: "",
          is_done: false,
        },
      ]);
    });
    // dispatch(
    //   sendNotification({
    //     board_id: match.params.category,
    //     card_id: id,
    //     msg_type: "todoItem",
    //     team_id: user.team_id,
    //     type: "sendMessage",
    //     user_id: user.id,
    //   })
    // );
  };

  const [height, setHeight] = useState(true);
  const checkMobile = useCheckPath("mobile");

  const { t } = useTranslation();

  return (
    <div>
      {/*  <Draggable isDragDisabled={true} draggableId={String("Todos")} index={3}>
       {(provided) => (
          <ThingsToDoContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
       > */}
      {/* <comps.CardViewInnerSectionTitleStyle> */}
      <ThingsToDoContainer isMobile={checkMobile}>
        <TitleBox>
          <TitleSec
            onClick={() => setHeight(!height)}
            style={{ cursor: "pointer" }}
          >
            <div>{t("cardView.todos.title")}</div>
            {/* {height ? <UpOutlined /> : <DownOutlined />} */}
          </TitleSec>
          <LabelRedText>{t("cardView.todos.tag")}</LabelRedText>
        </TitleBox>

        {/* </comps.CardViewInnerSectionTitleStyle> */}
        <BodyContainer height={height.toString()}>
          <TableHeader isMobile={checkMobile}>
            <Checkbox isMobile={checkMobile}>
              {t("cardView.todos.done")}
            </Checkbox>
            <Description isMobile={checkMobile}>
              {t("cardView.todos.toDoItem")}
            </Description>
            <Deadline isMobile={checkMobile}>
              {t("cardView.todos.deadline")}
            </Deadline>
            {!checkMobile && (
              <div className="remove">{t("cardView.todos.remove")}</div>
            )}
            {/* <div className="createdDate">Created</div> */}
          </TableHeader>
          <div className="descriptionBox">
            {todoList.map((todo, i) => (
              <TodoItem
                id={id}
                todo={todo}
                key={i}
                setTodoList={setTodoList}
                todoList={todoList}
              />
            ))}
          </div>
          <ClearButton onClick={() => handleClick()} className="addButton">
            <Icon>
              <Icons type="fal" name="plus" />
            </Icon>
            {t("cardView.todos.buttonText")}
          </ClearButton>
        </BodyContainer>
      </ThingsToDoContainer>
      {/*    )}
     </Draggable> */}
    </div>
  );
};

export default withRouter(withTranslation()(Todos));

const TitleSec = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.fontSizes.txt_h3};
`;

const TitleBox = styled(comps.CardViewInnerSectionTitleStyle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.color_base_white};
`;

const LabelRedText = styled(label_red)`
  /* margin-right: 8px; */
  cursor: pointer;
`;

const ThingsToDoContainer = styled(comps.CardViewInnerSectionStyle)`
box-shadow: none;
margin-bottom: 32px;
${(props) => (props.isMobile ? "border: none;" : "border: 1px solid #d6dee5;")}
  .textInput {
    width: 100%;
    height: 36px;
    font-size: 14px;
    padding-left: 10px;
    border: 0.5px solid #d9d9d9;
    margin-top: 10px;
  }

  .textBox {
    margin-left: 10px;
    font-size: 14px;
    text-align: start;
    width: 95%;
  }
  .deleteButton {
    flex: display;
    justify-content: center;
    border: none;
    box-shadow: none;
    align-items: center;
    /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
  }
  .addButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    height: 20px;
    font-weight: bold;
    /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
    /* border: 1px solid lightgray; */
    margin-top: 10px;
    button {
      /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
    }
  }
`;

const TableHeader = styled.div`
  /* margin-top: 15px; */
  width: 100%;
  /* border: 1px solid #d9d9d9; */
  display: flex;
  justify-content: ${(props) => (props.isMobile ? "flex-start" : "center")};
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  font-weight: bold;
  /* background-color: ${({ theme }) => theme.colors.color_base_regular}; */
  color: ${({ theme }) => theme.colors.color_text_titles};
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_dark};
  div {
    /* border: 0.5px solid #d9d9d9; */
    height: 36px;
    align-items: center;
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
  }
  .descriptionBox{
    border: none;
  }
  /* .createdDate {
    width: 10%;
    border: 0.5px solid #d9d9d9;
    border-right: none;
  } */
  .remove {
    width: 8.5%;
    /* border: 0.5px solid #d9d9d9; */
    border-left: none;
  }
`;

const Checkbox = styled.div`
  width: ${(props) => (props.isMobile ? "15%" : "6.5%")};
  ${(props) => props.isMobile && "display: flex;"}
  ${(props) =>
    props.isMobile
      ? "justify-content: flex-start;"
      : "justify-content: center;"}
  /* border: 0.5px solid #d9d9d9; */
  border-right: none;
`;

const Description = styled.div`
  /* border: 0.5px solid #d9d9d9; */
  ${(props) => props.isMobile && "display: flex;"}
  ${(props) =>
    props.isMobile ? "justify-content: center;" : "justify-content: center;"}
  width: ${(props) => (props.isMobile ? "40%" : "65%")};
`;

const Deadline = styled.div`
  width: ${(props) => (props.isMobile ? "40%" : "20%")};
  /* border: 0.5px solid #d9d9d9; */
  ${(props) => props.isMobile && "display: flex;"}
  ${(props) =>
    props.isMobile
      ? "justify-content: center;"
      : "justify-content: center;"}
  border-left: none;
`;

const BodyContainer = styled(comps.CardViewInnerSectionBodyStyle)`
  ${(props) => (props.height === "false" ? "height:0px" : "")};
  transition: all 1s ease-in-out;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.color_base_white};
`;

const Icon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
