import React, { useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Select, TextArea, Input, Option, ClearButton, DeleteButton } from "ui";
import {
  postDealLostReasonAxios,
  patchDealDescriptionAxios,
  putOfferResultAxios,
} from "common/ApiAxios";
import { useTranslation, withTranslation } from "react-i18next";
import MoonLoader from "react-spinners/MoonLoader";
const LostReasonSetting = ({
  id,
  toggleModal,
  handleChangeOfferResult,
  lostReasonData,
}) => {
  const [otherCheck, setOtherCheck] = useState(false);
  const [lostReasonValue, setLostReasonValue] = useState("");
  const [additionalInfoValue, setAdditionalInfoValue] = useState(null);
  const [errorCheck, setErrorCheck] = useState(false);
  const [selectLostReasonData, setSelectLostReasonData] = useState({});
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleChangeValue = (e) => {
    if (e.target.name === "lostReason") {
      setLostReasonValue(e.target.value);
    } else {
      setAdditionalInfoValue(e.target.value);
    }
  };

  const handleAddLostReason = () => {
    if (lostReasonValue.length === 0) {
      setErrorCheck(true);
    } else {
      setLoading(true);
      postDealLostReasonAxios({ name: lostReasonValue }).then((res) => {
        if (res.status === 201) {
          putOfferResultAxios(id, { lost_reason: res.data.id }).then((res) => {
            handleChangeOfferResult("lost");
            toggleModal();
          });
        }
      });
    }
  };

  const updateAdditionalInfo = () => {
    patchDealDescriptionAxios(id, {
      lost_description: additionalInfoValue,
    });
  };

  const updateLostReason = () => {
    if (Object.keys(selectLostReasonData).length === 0) {
      setErrorCheck(true);
    } else {
      setLoading(true);
      putOfferResultAxios(id, {
        lost_reason: selectLostReasonData.key,
      }).then((res) => {
        if (res.status === 200) {
          handleChangeOfferResult("lost");
          toggleModal();
        }
      });
    }
  };

  const lostReasonList = lostReasonData.map((reason) => (
    <Option key={reason.id} value={reason.name}>
      <div onClick={() => otherCheck && setOtherCheck(false)}>
        {reason.name}
      </div>
    </Option>
  ));

  return (
    <ModalContents>
      {lostReasonData.length === 0 && (
        <Row>
          <RowImportantTitle>
            {t("cardView.dealSummary.lostReasonSetting.lostReason.label")}
          </RowImportantTitle>
          <LostReasonInput
            name="lostReason"
            placeholder={t(
              "cardView.dealSummary.lostReasonSetting.lostReason.placeholder"
            )}
            value={lostReasonValue}
            onChange={handleChangeValue}
          />
          {errorCheck && (
            <ErrorMessage>
              {t("cardView.dealSummary.lostReasonSetting.enterErrorMessage")}
            </ErrorMessage>
          )}
        </Row>
      )}
      {lostReasonData.length !== 0 && (
        <Row>
          <RowImportantTitle>
            {t("cardView.dealSummary.lostReasonSetting.lostReason.label")}
          </RowImportantTitle>
          <LostReasonSelect
            placeholder={t(
              "cardView.dealSummary.lostReasonSetting.otherLostReason.placeholder"
            )}
            optionFilterProp="children"
            onSelect={(_, info) => {
              !otherCheck && setSelectLostReasonData(info);
            }}
          >
            {lostReasonList}
            <Option>
              <div onClick={() => setOtherCheck(true)}>
                {t("cardView.dealSummary.lostReasonSetting.otherText")}
              </div>
            </Option>
          </LostReasonSelect>
          {errorCheck && (
            <ErrorMessage>
              {t("cardView.dealSummary.lostReasonSetting.selectErrorMessage")}
            </ErrorMessage>
          )}
        </Row>
      )}
      {otherCheck && (
        <Row>
          <RowImportantTitle>
            {t("cardView.dealSummary.lostReasonSetting.otherLostReason.label")}
          </RowImportantTitle>
          <LostReasonInput
            name="lostReason"
            placeholder={t(
              "cardView.dealSummary.lostReasonSetting.otherLostReason.placeholder"
            )}
            value={lostReasonValue}
            onChange={handleChangeValue}
          />
        </Row>
      )}
      <Row>
        <RowTitle>
          {t("cardView.dealSummary.lostReasonSetting.additionalInfo.label")}
        </RowTitle>
        <DescriptionInput
          name="additionalInfo"
          value={additionalInfoValue}
          onChange={handleChangeValue}
          onBlur={updateAdditionalInfo}
        />
      </Row>
      <RowSubText>
        {t("cardView.dealSummary.lostReasonSetting.subText")}
        &nbsp;
        <CompanyRouteText to="/settings/companyprofile">
          {t("cardView.dealSummary.lostReasonSetting.companyRoute")}
        </CompanyRouteText>
      </RowSubText>
      <FooterBox>
        <EventBtnBox>
          <CancelButton onClick={toggleModal}>
            {t("cardView.dealSummary.lostReasonSetting.cancel")}
          </CancelButton>
          <LostButton
            onClick={() => {
              !selectLostReasonData || otherCheck || lostReasonData.length === 0
                ? handleAddLostReason()
                : updateLostReason();
            }}
          >
            {t("cardView.dealSummary.lostReasonSetting.lost")}
            {loading && (
              <MyLoading
                color={theme.colors.color_base_white}
                css={"margin-left: 12px;"}
                size={18}
              />
            )}
          </LostButton>
        </EventBtnBox>
      </FooterBox>
    </ModalContents>
  );
};

export default withTranslation()(LostReasonSetting);

const MyLoading = styled(MoonLoader)``;

const ModalContents = styled.div``;

const Row = styled.div`
  width: 100%;
  margin: 32px 0;
`;

const RowTitle = styled.h5`
  ${({ theme }) => theme.fontSizes.txt_h5};
`;

const RowImportantTitle = styled(RowTitle)`
  &:after {
    content: " *";
    color: red;
  }
`;

const LostReasonInput = styled(Input)`
  width: 60%;
`;

const ErrorMessage = styled.p`
  margin-top: 4px;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_state_danger_regular};
`;

const LostReasonSelect = styled(Select)`
  width: 60%;
`;

const DescriptionInput = styled(TextArea)`
  width: 60%;
  height: 100px !important;
  box-shadow: none;
  border: 1px solid ${({ theme }) => theme.colors.color_base_darker};
`;

const RowSubText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_text_light};
`;

const CompanyRouteText = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_primary_regular};

  &:hover {
    color: ${({ theme }) => theme.colors.color_primary_regular};
    text-decoration: none;
  }
`;

const FooterBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const EventBtnBox = styled.div`
  display: flex;
  margin: 5px 0;
`;

const CancelButton = styled(ClearButton)`
  width: 100px;
`;

const LostButton = styled(DeleteButton)`
  min-width: 100px;
  margin-left: 8px;
`;
