import React, { useState } from "react";
import styled from "styled-components";
// import Icons from "assets/Icons";
import { useTranslation, withTranslation } from "react-i18next";

type tooltipNoticeTextType = {
  thisNoteIsPublic: string;
  hide: string;
};

export const TooltipNotice = withTranslation()(() => {
  const [tipVisible, setTipVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const tooltipNoticeText: tooltipNoticeTextType = t("ui.tooltipNotice", {
    returnObjects: true,
  });

  const handleTipToggle = () => {
    setTipVisible(!tipVisible);
  };

  return (
    <>
      {tipVisible === false ? (
        <TooltipNoticeStyle tipVisible={tipVisible}>
          <IconBox>
            <Icon>
              {/* <Icons type="fas" name="engine-warning" /> */}
              <i className="fas fa-engine-warning"></i>
            </Icon>
            <Text>{tooltipNoticeText.thisNoteIsPublic}</Text>
          </IconBox>
          <HideText onClick={handleTipToggle}>
            {tooltipNoticeText.hide}
          </HideText>
        </TooltipNoticeStyle>
      ) : (
        <TooltipNoticeStyle tipVisible={tipVisible} onClick={handleTipToggle}>
          <TipShowBox>
            <IconBox>
              <Icon>
                <i className="far fa-exclamation-circle"></i>
              </Icon>
              <HideText>{tooltipNoticeText.hide}</HideText>
            </IconBox>
          </TipShowBox>
        </TooltipNoticeStyle>
      )}
    </>
  );
});

// export default TooltipNotice;

const TooltipNoticeStyle = styled.div<{ tipVisible: boolean }>`
  display: flex;
  justify-content: ${({ tipVisible }) =>
    !tipVisible ? "space-between" : "flex-end"};
  align-items: center;
  padding: ${({ tipVisible }) => !tipVisible && "8px 16px"};
  margin: 8px 0;
  background-color: ${({ tipVisible, theme }) =>
    !tipVisible && theme.colors.color_state_warning_light};
  border-radius: 4px;
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  color: ${({ theme }) => theme.colors.color_state_warning_regular};
  margin-right: 8px;
`;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_state_warning_dark};
`;

const HideText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_state_warning_dark};
  text-decoration: underline;
  cursor: pointer;
`;

const TipShowBox = styled.div`
  /* display: flex;
  flex-direction: row-reverse; */
  display: inline-block;
  padding: 4px 8px;
  /* margin: 8px 0; */
  background-color: ${({ theme }) => theme.colors.color_state_warning_light};
  border-radius: 4px;
  /* margin-top: 32px; */
`;
