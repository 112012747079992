/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, memo, useContext } from "react";
// import "antd/dist/antd.css";
import Icons from "assets/Icons";
import { DropdownClear, Menu, Switch, DeleteButtonRed } from "ui";
import styled, { ThemeContext } from "styled-components";
import { useSelector } from "react-redux";
import ReplyIcon from "@material-ui/icons/Reply";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";

import { getThreadMainAxios, resendEmailAxios } from "common/ApiAxios";
// import EmailCompose from "Components/Email/EmailCompose";
import PacmanLoader from "react-spinners/PacmanLoader";
import { withRouter } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";
import { imageTypes } from "constants/ImageTypes";
import EmailThreadMessageAttachment from "pages/DealCardDetailView/components/EmailThread/EmailThreadMessageAttachment";
import loadable from "@loadable/component";
const EmailCompose = loadable(() => import("pages/Email/components/EmailCompose"));
const PreviewModal = loadable(() => import("ui/Organisms/PreviewModal/PreviewModal"));
const EmailThreadMessage = ({
  message,
  setReset,
  reset,
  threadID,
  threadSubject,
  id,
  setThreadMessages,
  threadMessages,
  last,
  setComponentLoaded,
  history,
  mine,
  messageID,
  match,
  setPreventClose,
}) => {
  const [composeType, setComposeType] = useState(undefined);
  const [composing, setComposing] = useState(false);
  const synced_emails = useSelector(
    (state) => state.addTeamMetaInfo.synced_emails
  );
  const bodyRef = useRef();
  const [showLess, setShowLess] = useState(true);
  const [messageHeight, setMessageHeight] = useState(0);
  const [previewUrl, setPreviewUrl] = useState("");
  const [type, setType] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const rowRef = useRef();
  const [start, setStart] = useState(true);
  const composeRef = useRef();
  const [fileName, setFileName] = useState("");
  const [isParsed, setIsParsed] = useState(true);
  const [fullBody, setFullBody] = useState(null);
  const checkMobile = useCheckPath("mobile");
  // const loadRef = useRef(loaded);
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const [data, setData] = useState({
    id: 1547,
    message_id: "",
    subject: "",
    from_email: "",
    from_name: "",
    date: "",
    attachments: [],
    to: [],
    cc: [],
    bcc: [],
    main_body: "",
    status: null,
    save_id: "",
  });

  useEffect(() => {
    setData(message);
  }, [message]);

  useEffect(() => {
    bodyRef.current && setMessageHeight(bodyRef.current.scrollHeight);
  }, [fullBody]);
  useEffect(() => {
    fullBody === null &&
      (!isParsed || composing) &&
      getThreadMainAxios(messageID, threadID).then((res) => {
        setFullBody(res.data.body);
        setShowLess(false);
      });
  }, [isParsed, composing]);
  useEffect(() => {
    last && setComponentLoaded(true);
  }, [last]);
  useEffect(() => {
    type !== "" && setPreviewVisible(true);
  }, [type]);
  useEffect(() => {
    composeType !== undefined && setComposing(true);
  }, [composeType]);

  useEffect(() => {
    if (composing) {
      setTimeout(() => {
        composeRef.current && composeRef.current.scrollIntoView(true);
      }, 100);
    }
  }, [composing]);

  useEffect(() => {
    if (showLess === false) {
      setStart(false);
    }
    if (start !== true && showLess === true) {
      rowRef.current.scrollIntoView(true);
    }
  }, [showLess]);

  useEffect(() => {
    bodyRef.current && setMessageHeight(bodyRef.current.scrollHeight);
  }, [bodyRef.current]);
  // useEffect(() => {
  //   setPreventClose && setPreventClose(previewVisible);
  // }, [previewVisible]);
  useEffect(() => {
    setPreventClose &&
      (previewVisible
        ? setPreventClose(previewVisible)
        : setTimeout(() => {
            setPreventClose(previewVisible);
          }, 500));
  }, [previewVisible]);
  const menu = (
    <Menu>
      {data && (
        <>
          <ToDetailRow>
            <ToDetailRowLeft>
              {t("cardView.emailThread.emailThreadMessage.to")}:
            </ToDetailRowLeft>
            <ToDetailRowRight>
              {data.to.length > 0 &&
                data.to.map((email, i) =>
                  email.name === ""
                    ? `${email.email}${i + 1 < data.to.length ? ", " : ""}`
                    : `${email.name}<${email.email}>${
                        i + 1 < data.to.length ? ", " : ""
                      }`
                )}
            </ToDetailRowRight>
          </ToDetailRow>
          {data.cc.length > 0 && (
            <ToDetailRow>
              <ToDetailRowLeft>
                {t("cardView.emailThread.emailThreadMessage.cc")}:
              </ToDetailRowLeft>
              <ToDetailRowRight>
                {data.cc.map((email, i) =>
                  email.name === ""
                    ? `${email.email}${i + 1 < data.cc.length ? ", " : ""}`
                    : `${email.name}<${email.email}>${
                        i + 1 < data.cc.length ? ", " : ""
                      }`
                )}
              </ToDetailRowRight>
            </ToDetailRow>
          )}
          {data.bcc.length > 0 && (
            <ToDetailRow>
              <ToDetailRowLeft>
                {t("cardView.emailThread.emailThreadMessage.bcc")}:
              </ToDetailRowLeft>
              <ToDetailRowRight>
                {data.bcc.map((email, i) =>
                  email.name === ""
                    ? `${email.email}${i + 1 < data.bcc.length ? ", " : ""}`
                    : `${email.name}<${email.email}>${
                        i + 1 < data.bcc.length ? ", " : ""
                      }`
                )}
              </ToDetailRowRight>
            </ToDetailRow>
          )}
        </>
      )}
    </Menu>
  );
  const messageMenu = (
    <Menu>
      <Menu.Item
        onClick={(e) => {
          setComposeType("reply");
        }}
        key="25"
      >
        <EmailMenu>
          <ReplyIcon
            data-title={"Reply"}
            style={{ height: "0.9em", width: "0.9em" }}
          />
          &nbsp;&nbsp;{t("cardView.emailThread.emailThreadMessage.reply")}
        </EmailMenu>
      </Menu.Item>
      {data.cc.length > 0 && (
        <Menu.Item
          onClick={(e) => {
            setComposeType("replytoall");
          }}
          key="50"
        >
          <EmailMenu>
            <ReplyAllIcon
              data-title={"Reply to all"}
              className="MessageItems"
              style={{ height: "0.9em", width: "0.9em" }}
            />
            &nbsp;&nbsp;
            {t("cardView.emailThread.emailThreadMessage.replyToAll")}
          </EmailMenu>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={(e) => {
          history.push(`/email/#detail::inbox::${threadID}`);
        }}
        key="250"
      >
        <EmailMenu>
          &nbsp;
          <Icons type="fas" name="envelope" />
          &nbsp;&nbsp;&nbsp;
          {t("cardView.emailThread.emailThreadMessage.viewFullEmail")}
        </EmailMenu>
      </Menu.Item>
      {/* <Menu.Item onClick={() => {}} key="100">
        <EmailMenu>
          &nbsp;
          <DeleteFilled data-title={"Delete Message"} />
          &nbsp;&nbsp;&nbsp;
          {t("cardView.emailThread.emailThreadMessage.delete")}
        </EmailMenu>
      </Menu.Item> */}
    </Menu>
  );
  const typeConverter = (contentType) => {
    return (
      {
        "image/png": "png",
        "image/jpeg": "jpg",
        "image/gif": "gif",
        "image/bmp": "bmp",
        "image/tiff": "tiff",
        "image/svg+xml": "svg/xml",
        "text/plain": "txt",
        "text/rtf": "rtf",
        "audio/mpeg": "mpeg",
        "application/pdf": "pdf",
        "application/zip": "zip",
        "application/x-gzip": "gzip",
        "application/vnd.android.package-archive": "apk",
        "application/msword": "docx",
        "application/vnd.ms-excel": "xls",
        "application/vnd.ms-powerpoint": "ppt",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
        "application/vnd.openxmlformats-officedocument.presentationml.pesentation":
          "pptx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "xlsx",
        "video/mp4": "mp4",
        "video/quicktime": "mov",
        "video/x-ms-wmv": "wmv",
        "video/x-msvideo": "avi",
      }[contentType] || contentType
    );
  };

  const handleResend = () => {
    resendEmailAxios({ save_id: data.save_id }).then((res) =>
      setData(res.data.resending_message)
    );
  };

  const EmailParser = (email) => {
    try {
      return parse(email);
    } catch (error) {
      return parse(sanitizeHtml(email));
    }
  };

  return (
    <MessageContainer ref={rowRef}>
      <BodySec from={synced_emails.includes(data.from_email) ? "me" : "else"}>
        <BodyTopSec>
          <BodyTopTitle>
            <Owner>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <InfoSec>
                <InfoTopSec>
                  {`${t("cardView.emailThread.emailThreadMessage.from")} : ${
                    data.from_name
                  }`}
                  &nbsp;&nbsp;
                  <Email>{`<${data.from_email}>`}</Email>
                  <ToDetailCard
                    placement={"bottomRight"}
                    overlay={menu}
                    trigger={["click"]}
                  >
                    <div>
                      <Icons type="fas" name="caret-down" />
                    </div>
                  </ToDetailCard>
                </InfoTopSec>
              </InfoSec>
            </Owner>
          </BodyTopTitle>
          <EditMessage>
            <InfoBottomSec>
              <Email>{`${data.date}`}</Email>
            </InfoBottomSec>
            {mine && (
              <MessageInfoIcon
                placement={"bottomRight"}
                overlay={messageMenu}
                trigger={["click"]}
              >
                <EditButton className="MessageItems">
                  <i className="fas fa-ellipsis-v" size="5px"></i>
                </EditButton>
              </MessageInfoIcon>
            )}
          </EditMessage>
        </BodyTopSec>
        <MessageBody
          ref={bodyRef}
          from={synced_emails.includes(data.from_email) ? "me" : "else"}
          messageHeight={messageHeight}
          showLess={showLess ? "true" : "false"}
          over={messageHeight > 300 ? "true" : "false"}
          failed={data.status === "failed"}
        >
          <SwitchContainer>
            {data.status === "success" && (
              <BodySwitch
                checkedChildren={<Icons type="fas" name="check" />}
                unCheckedChildren={<Icons type="fas" name="times" />}
                onChange={(checked) => setIsParsed(checked)}
                defaultChecked
              />
            )}
            {data.status === "failed" && (
              <>
                <ResendText>
                  {t("cardView.emailThread.emailThreadMessage.resendText")}
                </ResendText>
                <ResendButton onClick={handleResend}>
                  {t("cardView.emailThread.emailThreadMessage.resendButton")}
                </ResendButton>
              </>
            )}
            {data.status === "sending" && (
              <>
                <SendingText>
                  {t("cardView.emailThread.emailThreadMessage.sendingEmail")}
                  <Loading>
                    <PacmanLoader
                      size={12}
                      color={theme.colors.color_primary_light}
                    />
                  </Loading>
                </SendingText>
              </>
            )}
          </SwitchContainer>
          {/* <ErrorBoundary
            FallbackComponent={
              <div>
                {isParsed ? (
                  data.main_body && parse(sanitizeHtml(data.main_body))
                ) : fullBody ? (
                  parse(sanitizeHtml(fullBody))
                ) : (
                  <LoadingContainer>
                    <PacmanLoader
                      size={20}
                      color={theme.colors.color_primary_light}
                    />
                  </LoadingContainer>
                )}
              </div>
            }
          >
            <div>
              {isParsed ? (
                data.main_body && EmailParser(data.main_body).then()
              ) : fullBody ? (
                EmailParser(fullBody)
              ) : (
                <LoadingContainer>
                  <PacmanLoader
                    size={20}
                    color={theme.colors.color_primary_light}
                  />
                </LoadingContainer>
              )}
            </div>
          </ErrorBoundary> */}
          {isParsed ? (
            data.main_body && EmailParser(data.main_body)
          ) : fullBody ? (
            EmailParser(fullBody)
          ) : (
            <LoadingContainer>
              <PacmanLoader
                size={20}
                color={theme.colors.color_primary_light}
              />
            </LoadingContainer>
          )}
        </MessageBody>
        <ShowMore
          failed={data.status === "failed"}
          from={synced_emails.includes(data.from_email) ? "me" : "else"}
        >
          {messageHeight > 300 && (
            <>
              {showLess && <Icons type="fas" name="ellipsis-h" />}
              <ShowMoreText onClick={() => setShowLess(!showLess)}>
                {showLess
                  ? t("cardView.emailThread.emailThreadMessage.viewMore")
                  : t("cardView.emailThread.emailThreadMessage.viewless")}
              </ShowMoreText>
            </>
          )}
          <Attachments>
            {data.attachments &&
              data.attachments.map((file) => {
                const content_type =
                  typeConverter(file.content_type) ||
                  // }["image/png"] ||
                  file.filename.split(".")[file.filename.split(".").length - 1];
                return (
                  <EmailThreadMessageAttachment
                    file={file}
                    setPreviewUrl={setPreviewUrl}
                    setFileName={setFileName}
                    setType={setType}
                    data={data}
                    content_type={content_type}
                    typeConverter={typeConverter}
                    id={id}
                    key={file.id}
                    match={match}
                  />
                );
              })}
          </Attachments>
        </ShowMore>
        {!checkMobile && (
          <PreviewModal
            setType={setType}
            type={type}
            fileName={fileName}
            previewUrl={previewUrl}
            setPreviewVisible={setPreviewVisible}
            previewVisible={previewVisible}
            imageTypes={imageTypes}
          />
        )}
        {/* <MyModal
          window={height}
          wrapClassName="previewModal"
          visible={previewVisible}
          footer={null}
          centered={true}
          onCancel={() => {
            setPreviewVisible(false);
            setLoaded(false);
            setType("");
          }}
          maskClosable={true}
          width="95%"
          destroyOnClose={true}
        >
          {!loaded && !imageTypes.includes(type) && <MySpin size="large" />}
          {imageTypes.includes(type) ? (
            <img
              height={`${height - 200}px`}
              src={previewUrl}
              alt="previewimage"
            />
          ) : (
            <iframe
              key="1"
              ref={frame}
              title="preview"
              className={type}
              width="100%"
              height="750"
              frameBorder="0"
              onLoad={() => setLoaded(true)}
              src={`https://docs.google.com/viewer?url=${previewUrl}&embedded=true`}
            ></iframe>
          )}
        </MyModal> */}
        <ComposePos ref={composeRef}></ComposePos>
      </BodySec>
      {composing && (
        <EmailCompose
          cardID={id}
          id={`composingEmail${threadID}${data.message_id}`}
          setMessageSnippets={setThreadMessages}
          messageSnippets={threadMessages}
          resetID={setComposeType}
          setComposing={setComposing}
          inlineCard
          threadID={threadID}
          toData={
            {
              reply: [{ email: data.from_email, name: data.name }],
              replytoall: [{ email: data.from_email, name: data.name }],
            }[composeType]
          }
          ccData={
            {
              replytoall: data.cc.map((data) => ({
                name: data.name,
                email: data.email,
              })),
            }[composeType]
          }
          bccData={
            {
              replytoall: data.bcc.map((data) => ({
                name: data.name,
                email: data.email,
              })),
            }[composeType]
          }
          mailTextData={
            data &&
            `
                <div class="gmail_quote"><br />       
                <blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">
                <div dir="ltr" class="gmail_attr">
                ${data.date} ${data.from_name} &lt;
                <a href="mailto:${data.from_email}">${data.from_email}</a>
                &gt; wrote:
                </div>
                ${fullBody}
                </blockquote></div>
              `
          }
          setReset={setReset}
          reset={reset}
          threadSubject={threadSubject}
          messageID={data.message_id}
        ></EmailCompose>
      )}
    </MessageContainer>
  );
};

export default memo(withRouter(withTranslation()(EmailThreadMessage)));

const SendingText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.color_primary_regular};
  line-height: 26px;
  margin: auto;
`;

const ResendText = styled.span`
  color: ${({ theme }) => theme.colors.color_state_danger_regular};
  line-height: 26px;
`;

const ResendButton = styled(DeleteButtonRed)`
  height: 26px;
  padding: 4px 9px;
  margin-left: 8px;
`;

const Loading = styled.div`
  display: inline-block;
  margin-left: 9px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const BodySwitch = styled(Switch)``;

const ComposePos = styled.div`
  position: relative;
  top: -50px;
`;

const EmailMenu = styled.div`
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const EditButton = styled.div`
  width: 20px;
  text-align: center;
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark} !important;
  }
`;

// const ReplyBox = styled.div`
//   display: flex;
// `;

const ShowMore = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  min-height: 7px;
  text-align: center;
  padding: 8px;
  /* background-color: ${(props) =>
    props.from !== "me" ? props.theme.colors.color_base_dark : "#D3EEF1"}; */
  /* border: 1px solid ${({ theme }) => theme.colors.color_base_regular}; */
  border: 3px solid ${({ from, theme, failed }) =>
    failed
      ? theme.colors.color_state_danger_light
      : from !== "me"
      ? theme.colors.color_base_dark
      : "#D3EEF1"};
  border-top: none;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  /* &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_darker};
  } */
`;

const ShowMoreText = styled.div`
  width: 20%;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
  padding-left: 20px;
  border-radius: 4px;
  padding: 6px;
  background-color: ${({ theme }) => theme.colors.color_base_white};
  border: 1px solid ${({ theme }) => theme.colors.color_text_placeholder};
  margin-bottom: 3px;

  @media ${({ theme }) => theme.screenSizes.mobile} {
    width: 100%;
  }
`;

const InfoSec = styled.div`
  display: inline-block;
  margin-left: 8px;

  @media ${({ theme }) => theme.screenSizes.mobile} {
    margin-left: 0;
  }
`;

const ToDetailRowRight = styled.div`
  max-width: 300px;
`;

const ToDetailRowLeft = styled.div`
  min-width: 36px;
  text-align: end;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.color_text_light};
`;

const ToDetailRow = styled.div`
  display: flex;
  margin: 4px 8px;
`;

const ToDetailCard = styled(DropdownClear)`
  height: 100%;
  /* width: 100%; */
  margin: 0px 8px 0 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
`;

const MessageInfoIcon = styled(DropdownClear)`
  @media ${({ theme }) => theme.screenSizes.mobile} {
    display: none !important;
  }
`;

// const ToDetail = styled.div`
//   user-select: none;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   display: inline-block;
//   margin-left: 4px;
//   height: 18px;
//   width: 18px;
//   position: relative;
//   &:hover {
//     border: none;
//     border-radius: 100px;
//     /* overflow: hidden; */
//     background-color: ${({ theme }) => theme.colors.color_base_dark};
//   }
// `;

// const InfoMiddleSec = styled.div`
//   ${(props) => props.side === "right" && "text-align: end;"};
//   font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
//   color: ${({ theme }) => theme.colors.color_text_light};
//   font-weight: normal;
//   user-select: text !important;
// `;

const InfoTopSec = styled.div`
  display: flex;
  align-items: center;
`;

const InfoBottomSec = styled.div`
  margin: 0 12px;
  ${(props) => props.side === "right" && "text-align: end;"};

  @media ${({ theme }) => theme.screenSizes.mobile} {
    margin: 0;
  }
`;

const Attachments = styled.div`
  width: 100%;
  /* border-top: 1.5px solid ${({ theme }) => theme.colors.color_base_dark}; */
`;

const MessageBody = styled.div`
  position: relative;
  ${(props) =>
    props.messageHeight > 300 &&
    `max-height: 
    ${props.showLess === "true" ? 300 : props.messageHeight}px;`}
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
  border: 3px solid ${({ from, theme, failed }) =>
    failed
      ? theme.colors.color_state_danger_light
      : from !== "me"
      ? theme.colors.color_base_dark
      : "#D3EEF1"};
  /* border-color: ${({ from, theme }) =>
    from !== "me" ? theme.colors.color_base_dark : "#D3EEF1"}; */

  border-radius: 7px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
  /* ${(props) => props.over === "true" && ""}
  ${(props) => props.over === "true" && ""}
  ${(props) => props.over === "true" && ""} */
  /* body {
    background-color: ${(props) =>
      props.from !== "me"
        ? props.theme.colors.color_base_dark
        : "#D3EEF1"} !important;
    padding: 0 !important;
    border-radius: 7px;
  }
  div {
    background-color: ${(props) =>
      props.from !== "me" ? props.theme.colors.color_base_dark : "#D3EEF1"};
    border-radius: 7px;
  } */
  * {
    user-select: text !important;
  }
`;

// const SyncedCount = styled.div`
//   /* width: 40px; */
// `;

const EditMessage = styled.div`
  display: flex;
  /* align-items: center; */
  /* width: 80px; */
  /* justify-content: space-between; */
  .MessageItems {
    min-height: 20px;
    cursor: pointer;
    margin: 0 4px;
  }

  @media ${({ theme }) => theme.screenSizes.mobile} {
    justify-content: space-between;
  }
`;

const Email = styled.div`
  display: inline;
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
  color: ${({ theme }) => theme.colors.color_text_light};
  font-weight: normal;
  user-select: text !important;
`;

const Owner = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const BodyTopSec = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.screenSizes.mobile} {
    flex-direction: column;
  }
`;

const BodyTopTitle = styled.div`
  position: relative;
  display: flex;
`;

const BodySec = styled.div`
  /* padding: 0 20px; */
  padding-left: ${(props) => props.from === "me" && "80px"};
  padding-right: ${(props) => props.from !== "me" && "80px"};
  padding-top: 10px;
  width: 100%;

  @media ${({ theme }) => theme.screenSizes.mobile} {
    padding-left: ${(props) => props.from === "me" && "0px"};
    padding-right: ${(props) => props.from !== "me" && "0px"};
  }
`;

const MessageContainer = styled.div`
  /* display: flex;
  align-items: center; */
  padding: 8px;
  width: 100%;

  .content{
    margin: 0 -14px !important;
  }

  .content-wrap {
    border: none !important;
  }
  /* background-color: ${({ theme }) => theme.colors.color_base_white}; */

  @media ${({ theme }) => theme.screenSizes.mobile} {
    .jnnIEn{
      padding: 0 !important;
    }  
  }

`;
