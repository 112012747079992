import React, { useState, memo /* useContext */ } from "react";
import styled /* ThemeContext */ from "styled-components";
import { Input, Popconfirm } from "ui";
import isEmail from "utils/isEmail";
import Icons from "assets/Icons";
// import { Menu } from "antd";
import { updateContactAxios } from "common/ApiAxios";
// import CreateIcon from "@material-ui/icons/Create";
import { useTranslation, withTranslation } from "react-i18next";

const ContactsListItem = memo(
  ({ id, item, type, typeId, handleDeleteContact }) => {
    const [listItemData, setListItemData] = useState({
      name: item.name,
      title: item.title,
      email: item.email,
      office_phone: item.office_phone,
      mobile: item.mobile,
      fax: item.fax,
      account_object_id: typeId,
      account_content_type: type,
    });

    const [division, setDivision] = useState("");
    const [edit, setEdit] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    // const theme = useContext(ThemeContext);

    const { name, title, email, office_phone, mobile } = listItemData;
    const { t } = useTranslation();

    const handleEnter = () => {
      if (
        division === "email" &&
        !isEmail(listItemData.email) &&
        listItemData.email.length > 0
      ) {
        setEmailCheck(true);
      } else {
        const divisions = ["name", "title", "email", "phone", "mobile"];
        setDivision(divisions[divisions.indexOf(division) + 1]);
        updateContactAxios(id, type, typeId, listItemData);
      }
    };

    const handleSaveText = () => {
      if (
        division === "email" &&
        !isEmail(listItemData.email) &&
        listItemData.email.length > 0
      ) {
        setEmailCheck(true);
      } else {
        setEdit(false);
        setDivision("");
        updateContactAxios(id, type, typeId, listItemData);
      }
    };

    const handleChangeValue = (event) => {
      if (event.target.name === "email") {
        if (
          event.target.value.includes("@") ||
          event.target.value.length === 0
        ) {
          setEmailCheck(false);
        } else {
          setEmailCheck(true);
        }
      }
      setListItemData({
        ...listItemData,
        [event.target.name]: event.target.value,
      });
    };

    const handleEditText = (category) => {
      setEdit(true);
      setDivision(category);
      category === "email" && setEmailCheck(false);
    };

    const showMenu = (bool) => {
      bool === true ? setMenuVisible(true) : setMenuVisible(false);
    };

    return (
      <TableListItem
        onMouseEnter={() => showMenu(true)}
        onMouseLeave={() => showMenu(false)}
      >
        <ItemBox>
          {edit === true && division === "name" ? (
            <ItemInput
              name="name"
              value={name}
              onChange={handleChangeValue}
              onPressEnter={handleEnter}
              onBlur={handleSaveText}
              autoFocus
            ></ItemInput>
          ) : (
            <ItemText onClick={() => handleEditText("name")}>{name}</ItemText>
          )}
        </ItemBox>
        <ItemBox>
          {edit === true && division === "title" ? (
            <ItemInput
              name="title"
              value={title}
              onChange={handleChangeValue}
              onPressEnter={handleEnter}
              onBlur={handleSaveText}
              autoFocus
            ></ItemInput>
          ) : (
            <ItemText onClick={() => handleEditText("title")}>{title}</ItemText>
          )}
        </ItemBox>
        <ItemBox>
          {edit === true && division === "email" ? (
            <ItemInput
              className="email"
              name="email"
              value={email}
              onChange={handleChangeValue}
              onPressEnter={handleEnter}
              onBlur={handleSaveText}
              autoFocus
              emailcheck={emailCheck.toString()}
            ></ItemInput>
          ) : (
            <ItemText className="email" onClick={() => handleEditText("email")}>
              {email}
            </ItemText>
          )}
        </ItemBox>
        <ItemBox>
          {edit === true && division === "phone" ? (
            <ItemInput
              name="office_phone"
              value={office_phone}
              onChange={handleChangeValue}
              onPressEnter={handleEnter}
              onBlur={handleSaveText}
              autoFocus
            ></ItemInput>
          ) : (
            <ItemText onClick={() => handleEditText("phone")}>
              {office_phone}
            </ItemText>
          )}
        </ItemBox>
        <ItemBox>
          {edit === true && division === "mobile" ? (
            <ItemInput
              name="mobile"
              value={mobile}
              onChange={handleChangeValue}
              onBlur={handleSaveText}
              autoFocus
            ></ItemInput>
          ) : (
            <ItemText onClick={() => handleEditText("mobile")}>
              {mobile}
            </ItemText>
          )}
        </ItemBox>
        {/* <ItemBox>
          {edit === true && division === "fax" ? (
            <ItemInput
              name="fax"
              value={fax}
              onChange={handleChangeValue}
              onBlur={handleSaveText}
              autoFocus
            ></ItemInput>
          ) : (
            <ItemText onClick={() => handleEditText("fax")}>{fax}</ItemText>
          )}
        </ItemBox> */}
        {id === item.id && menuVisible === true ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title={t(
              "contacts.contactsDetailView.contactsList.contactsListItem.confirmTitle"
            )}
            okText={t(
              "contacts.contactsDetailView.contactsList.contactsListItem.delete"
            )}
            cancelText={t(
              "contacts.contactsDetailView.contactsList.contactsListItem.cancel"
            )}
            okType="danger"
            onConfirm={() => handleDeleteContact(id)}
          >
            <DeleteButton>
              <Icons type="fas" name="trash" />
            </DeleteButton>
          </Popconfirm>
        ) : null}
      </TableListItem>
    );
  }
);

export default withTranslation()(ContactsListItem);

const ItemBox = styled.div`
  width: 16.6%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  color: ${({ theme }) => theme.colors.color_text_body};
  /* .email {
    color: #3498db;
    text-decoration: underline;
    border: ${(props) =>
      props.emailcheck === "true" && "1px solid #FA5858 !important"};
  } */
  
   :nth-child(2){
    width: 30%;
  }

  :nth-child(3){
    width: 20%;
  }

  /* :nth-child(6){
    width: 13.6%;
  } */
`;

const TableListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.color_base_dark};
  height: 45px;
`;

const ItemText = styled.div`
  /* text-align: center; */
  /* line-height: 30px; */
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
  }
`;

const ItemInput = styled(Input)`
  width: 100%;
  height: 30px;
  margin: 2px;
  border-color: ${({ theme, emailCheck }) =>
    // emailCheck === "false" &&
    theme.colors.color_state_danger_regular} !important;
`;

const DeleteButton = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_text_body};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  margin: auto;
  padding: 5px;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.color_state_danger_dark};
  }
`;
