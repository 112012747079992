import { globalAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";

export const useVersionInfo = (platform) => {
  async function getVersion() {
    const { data } = await globalAPIs.versionCheckAxios(platform);
    // return { ...data, loaded: true };
    return data.latest_version;
  }
  return useQuery({
    queryKey: ["get/version/check", platform],
    queryFn: getVersion,
  });
};
