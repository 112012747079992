/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Select, Option, MemberIcon, tagRender } from "ui";
import * as api from "common/ApiAxios";
import useCheckAuth from "hooks/useCheckAuth";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleDeal } from "modules/DealCardDetailView/toggleDealfromCard";
import { useTranslation, withTranslation } from "react-i18next";
import { initializeAccountBoards } from "modules/DealBoardList/accountBoardsSaga";
import { useBoardAccess } from "api-cache";
const ManageAccess = ({
  setData,
  setVisible,
  updateAuth,
  setUpdateAuth,
  boardID,
  socket,
  match,
}) => {
  const { data: accessData } = useBoardAccess(match.params.category);
  const [accessData1, setAccessData] = useState();
  const [managerIdList, setManagerIdList] = useState([]);
  const [membersIdList, setMembersIdList] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // useEffect(() => {
  //   api
  //     .getBoardAccessAxios(
  //       boardID,
  //       "?fields=cur_members,cur_managers,users,access_granted"
  //     )
  //     .then((res) => {
  //       setAccessData(res.data);
  //       setManagerIdList(
  //         res.data.cur_managers.map((manager) => manager.id.toString())
  //       );
  //       setMembersIdList(
  //         res.data.cur_members.map((member) => member.id.toString())
  //       );
  //     });
  // }, []);

  useEffect(() => {
    setManagerIdList(
      accessData.cur_managers.map((manager) => manager.id.toString())
    );
    setMembersIdList(
      accessData.cur_members.map((member) => member.id.toString())
    );
  }, [accessData]);

  useEffect(() => {
    if (updateAuth) {
      const origMan = accessData.cur_managers.map((manager) =>
        manager.id.toString()
      );
      const origMem = accessData.cur_members.map((member) =>
        member.id.toString()
      );
      const as_member = [];
      const as_manager = [];
      origMan.map((id) => {
        !managerIdList.includes(id) && as_manager.push(parseInt(id));
        return true;
      });
      managerIdList.map((id) => {
        !origMan.includes(id) && as_manager.push(parseInt(id));
        return true;
      });
      origMem.map((id) => {
        !membersIdList.includes(id) && as_member.push(parseInt(id));
        return true;
      });
      membersIdList.map((id) => {
        !origMem.includes(id) && as_member.push(parseInt(id));
        return true;
      });
      as_member.length + as_manager.length > 0 &&
        api
          .patchBoardAccessAxios(boardID, {
            as_member: membersIdList,
            as_manager: managerIdList,
          })
          .then((res) => {
            socket();
            setAccessData(res.data);
            dispatch(toggleDeal());
            dispatch(initializeAccountBoards());
            setData && setData(res.data);
          });
      //   dispatch(
      //     sendNotification({
      //       board_id: props.match.params.category,
      //       card_id: "",
      //       msg_type: "addBoardMember",
      //       team_id: user.team_id,
      //       type: "sendMessage",
      //       user_id: user.id,
      //     })
      //   );
      setVisible(false);
      setUpdateAuth(false);
    }
  }, [updateAuth]);

  const OptionList = (info = [], exception) =>
    info
      .filter((member) => member.auth_type !== exception)
      .map((member, i) => (
        <Option
          key={member.id}
          finder={`${member.user_name}${member.user_email}`}
          label={member.user_name}
        >
          <OptionItem>
            <MemberIcon member={member}></MemberIcon>
            <OptionText>
              {member.user_name}
              <br></br>
              {member.user_email}
            </OptionText>
          </OptionItem>
        </Option>
      ));

  return (
    <ManageContainer>
      {useCheckAuth("DealBoard", "changeManager", boardID) && (
        <Row>
          <Title>{t("global.manageAccess.boardManager.title")}</Title>
          <ManagerSelect
            width={170}
            placeholder={t("global.manageAccess.boardManager.placeholder")}
            mode="multiple"
            value={managerIdList}
            showSearch
            onChange={(value, option, test) => {
              setManagerIdList(value);
              // membersIdList.includes
            }}
            onSelect={(key) => {
              if (membersIdList.includes(key.toString())) {
                const tmp = [];
                for (let i = 0; i < membersIdList.length; i++) {
                  membersIdList[i] !== key.toString() &&
                    tmp.push(membersIdList[i]);
                }
                setMembersIdList(tmp);
              }
            }}
            filterOption={(input, option) => {
              console.log(option);
              return (
                option.finder.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            optionLabelProp="label"
            // autoFocus
            tagRender={tagRender}
          >
            {OptionList(accessData?.users, "agent")}
          </ManagerSelect>
        </Row>
      )}
      <Row>
        <Title>{t("global.manageAccess.boardMembers.title")}</Title>
        <MemberSelect
          width={170}
          placeholder={t("global.manageAccess.boardMembers.placeholder")}
          mode="multiple"
          value={membersIdList}
          showSearch
          onChange={(value, option) => {
            setMembersIdList(value);
          }}
          onSelect={(key) => {
            if (managerIdList.includes(key.toString())) {
              const tmp = [];
              for (let i = 0; i < managerIdList.length; i++) {
                managerIdList[i] !== key.toString() &&
                  tmp.push(managerIdList[i]);
              }
              setManagerIdList(tmp);
            }
          }}
          filterOption={(input, option) => {
            console.log(option);
            return (
              option.finder.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          optionLabelProp="label"
          tagRender={tagRender}
        >
          {OptionList(accessData?.users)}
        </MemberSelect>
      </Row>
    </ManageContainer>
  );
};

export default withRouter(withTranslation()(ManageAccess));

const OptionText = styled.div`
  margin-left: 10px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px;
`;

const ManagerSelect = styled(Select)`
  width: 100%;
`;

const MemberSelect = styled(Select)`
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h5};
  padding-left: 5px;
  margin-bottom: 5px;
  &:after {
    content: " *";
    color: ${({ theme }) => theme.colors.color_state_danger_regular};
  }
`;

// const Required = styled(Title)`
//   &:after {
//     content: " *";
//     color: #e44e48;
//   }
// `;

const Row = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const ManageContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;
