/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback, memo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { addTeamContactsInfo } from "modules/Contacts/addTeamContactsInfoSaga";
import {
  // SecondaryButton,
  // DeleteButton,
  Modal,
  MemberIcon,
  AvatarGroup,
  ClearButton,
  PrimaryButton,
  Menu,
  Popconfirm,
  Dropdown,
  ManageAccessOwner,
  CardViewTabs,
} from "ui";
import Icons from "assets/Icons";
import {
  getContactTypeDetailAxios,
  updateContactTypeDetailAxios,
  deleteContactTypeDetailAxios,
} from "common/ApiAxios";
import ContactsInformation from "./ContactsInformation";
import ContactsList from "./ContactsList/ContactsList";
import MeetingNote from "./MeetingNote/MeetingNote";
import ContactsAttachment from "./ContactsAttachment";
import DealsList from "./ContactsDealsList/DealsList";
import useViewport from "hooks/useViewPort";
import useCheckAuth from "hooks/useCheckAuth";
import { useTranslation, withTranslation } from "react-i18next";
import * as comps from "pages/DealCardDetailView/components/CardComponents";
import { useTeamMetaInfo } from "api-cache";
const ContactsDetailView = memo(
  ({ typeId, type, contactsData, setContactsData, setDetailViewVisible }) => {
    const [currentTab, setCurrentTab] = useState("Overview");
    const [detailViewData, setDetailViewData] = useState({});
    const [ownerData, setOwnerData] = useState(null);
    const [title, setTitle] = useState("");
    const [memberData, setMemberData] = useState();
    const [visible, setVisible] = useState();
    const { height, width } = useViewport();
    const [updateAuth, setUpdateAuth] = useState(false);
    const [saveDetail, setSaveDetail] = useState();
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleOk = (e) => {
      setUpdateAuth(true);
    };

    const showModal = () => {
      setVisible(true);
    };

    const {
      data: { all_countries },
    } = useTeamMetaInfo();
    useEffect(() => {
      typeId !== 0 &&
        getContactTypeDetailAxios(type, typeId).then((res) => {
          setOwnerData(res.data.owner);
          setMemberData(res.data.members);
          setTitle(res.data.name);
          setDetailViewData(res.data);
          // const countryId = all_countries.filter((country) => {
          //   return country.id === res.data.country;
          // });
          setDetailViewData({
            ...res.data,
            // country: countryId[0].name,
          });
        });
    }, [all_countries, type, typeId]);

    const handleDeleteCustomer = () => {
      setDeleteConfirmVisible(false);
      deleteContactTypeDetailAxios(type, typeId).then(() => {
        const deleteData =
          contactsData &&
          contactsData.filter((item) => {
            return item.id !== typeId;
          });
        setContactsData && setContactsData(deleteData);
        setDetailViewVisible(false);
        dispatch(addTeamContactsInfo());
      });
    };

    const handleEditInfo = useCallback(
      (editData) => {
        if (editData.name.length !== 0) {
          updateContactTypeDetailAxios(type, typeId, editData).then((res) => {
            const updateData =
              contactsData &&
              contactsData.map((item) => {
                return item.id !== res.data.id
                  ? item
                  : {
                      ...res.data,
                      country: res.data.country,
                      country_name: res.data.country_name,
                      key: res.data.id,
                    };
              });
            setContactsData && setContactsData(updateData);
            return;
          });
        }
        setSaveDetail(false);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contactsData && contactsData, type, typeId]
    );

    const handleVisibleChange = (flag) => {
      setDeleteConfirmVisible(flag);
    };

    const menu = (
      <Menu>
        <Menu.Item>
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            placement="left"
            autoAdjustOverflow={false}
            title={t("contacts.contactsDetailView.confirmTitle")}
            onConfirm={handleDeleteCustomer}
            okText="Yes"
            cancelText="No"
            // onCancle={handleVisibleChange}
          >
            <Icons type="fas" name="trash" />
            &nbsp; {t("contacts.contactsDetailView.delete")}
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <ContactsDetailViewComponent window={height}>
        <TitleBox>
          <Title>{title}</Title>
          <EventBtnBox>
            <Header>
              <InfoBox>
                <p>
                  {ownerData && <MemberIcon member={ownerData}></MemberIcon>}
                </p>
              </InfoBox>
              <InfoBox>
                <AvatarGroup
                  maxCount={3}
                  maxStyle={{
                    fontSize: `${({ theme }) => theme.fontSizes.txt_smaller}`,
                    fontWeight: "bold",
                    lineHeight: "28px",
                    color: `${({ theme }) => theme.colors.color_text_titles}`,
                    backgroundColor: `${({ theme }) =>
                      theme.colors.color_base_dark}`,
                    border: `2px solid ${({ theme }) =>
                      theme.colors.color_base_darker}`,
                    // display: "flex",
                    // alignItems: "center",
                  }}
                  // rowkey={"Group"}
                >
                  {memberData &&
                    memberData
                      // .filter((member) => member.user_name !== ownerName)
                      .filter((members) => members.id !== ownerData.id)
                      .filter((members) => members.has_access)
                      .map((member, i) => (
                        <MemberIcon
                          info={true}
                          member={member}
                          key={i}
                        ></MemberIcon>
                      ))}
                </AvatarGroup>
                <AddModal
                  title={
                    <Title>
                      {type === "customer"
                        ? t("contacts.contactsDetailView.selectCustomerMembers")
                        : t(
                            "contacts.contactsDetailView.selectSupplierMembers"
                          )}
                    </Title>
                  }
                  visible={visible}
                  // onOk={handleOk}
                  onCancel={() => setVisible(false)}
                  height={height}
                  // okText="Add Member(s)"
                  centered
                  destroyOnClose
                  footer={
                    <FooterBox>
                      <EventBtnBox>
                        <ClearButton onClick={() => setVisible(false)}>
                          {t("contacts.contactsDetailView.cancel")}
                        </ClearButton>
                        <AddBtn onClick={handleOk}>
                          {t("contacts.contactsDetailView.addMembers")}
                        </AddBtn>
                      </EventBtnBox>
                    </FooterBox>
                  }
                >
                  {ownerData !== undefined && (
                    <ManageAccessOwner
                      ownerData={ownerData}
                      setOwnerData={setOwnerData}
                      memberData={memberData}
                      setMemberData={setMemberData}
                      setVisible={setVisible}
                      updateAuth={updateAuth}
                      setUpdateAuth={setUpdateAuth}
                      targetID={typeId}
                      editApi={(contactId, editData) =>
                        updateContactTypeDetailAxios(type, contactId, editData)
                      }
                    ></ManageAccessOwner>
                  )}
                </AddModal>
                <AddMemberBtn onClick={() => showModal()}>
                  <Icons type="far" name="plus" size="lg" />
                </AddMemberBtn>
              </InfoBox>
            </Header>
            {useCheckAuth(
              "Contacts",
              "deleteContact",
              ownerData && ownerData.id
            ) && (
              <DropdownBox
                overlay={menu}
                trigger={["click"]}
                onVisibleChange={handleVisibleChange}
                visible={deleteConfirmVisible}
                placement="bottomLeft"
              >
                <MenuIcon>
                  <Icons type="fas" name="ellipsis-v" size="lg" />
                </MenuIcon>
              </DropdownBox>
            )}
          </EventBtnBox>
        </TitleBox>
        <DownWrapper>
          <LeftSection window={height}>
            <ContactsInformation
              saveDetail={saveDetail}
              detailViewData={detailViewData}
              handleEditInfo={handleEditInfo}
              // edit={edit}
              type={type}
              typeId={typeId}
            />
          </LeftSection>
          <RightSection window={height} width={width}>
            <TabsBox>
              <CardViewTabs
                focused={currentTab === "Overview" ? "true" : "false"}
                onClick={() => setCurrentTab("Overview")}
                first="first"
              >
                {t("cardView.downWrapper.dealInfo")}
              </CardViewTabs>
              <CardViewTabs
                focused={currentTab === "MeetingNote" ? "true" : "false"}
                onClick={() => setCurrentTab("MeetingNote")}
              >
                {t("contacts.contactsDetailView.meetingNote.title")}
              </CardViewTabs>
              <CardViewTabs
                focused={currentTab === "Attachments" ? "true" : "false"}
                onClick={() => setCurrentTab("Attachments")}
              >
                {t("cardView.downWrapper.attachments")}
              </CardViewTabs>
              <CardViewTabs
                focused={currentTab === "Contacts" ? "true" : "false"}
                onClick={() => setCurrentTab("Contacts")}
              >
                {t("contacts.contactsDetailView.contactsList.title")}
              </CardViewTabs>
            </TabsBox>
            {currentTab === "Overview" && (
              <TabSectionWrapper window={height}>
                <DealsList type={type} typeId={typeId} />
              </TabSectionWrapper>
            )}
            {currentTab === "Contacts" && (
              <TabSectionWrapper window={height}>
                <ContactsList type={type} typeId={typeId} />
              </TabSectionWrapper>
            )}
            {currentTab === "MeetingNote" && (
              <TabSectionWrapper window={height}>
                <MeetingNote type={type} typeId={typeId} />
              </TabSectionWrapper>
            )}
            {currentTab === "Attachments" && (
              <TabSectionWrapper window={height}>
                <ContactsAttachment attachmentType={type} typeId={typeId} />
              </TabSectionWrapper>
            )}
            {/* <DealsList type={type} typeId={typeId} /> */}
          </RightSection>
        </DownWrapper>
      </ContactsDetailViewComponent>
    );
  }
);

export default withTranslation()(ContactsDetailView);

const TabsBox = styled.div`
  display: flex;
  align-items: center;
  /* position: fixed; */
  /* background: ${({ theme }) => theme.colors.color_base_white}; */
`;

const DownWrapper = styled.div`
  border-radius: 3px;
  display: flex;
  @media ${({ theme }) => theme.screenSizes.tablet} {
    flex-direction: column;
  }
`;

const LeftSection = styled(comps.CardViewInnerSectionStyle)`
  width: 400px;
  margin-right: 12px;
  height: ${({ window }) => window - 80}px;
  overflow-y: scroll;
`;
const RightSection = styled.div`
  width: ${(props) => props.width - 362}px;
  height: ${({ window }) => window - 80}px;
`;

const TabSectionWrapper = styled(comps.CardViewInnerSectionStyle)`
  padding: 12px;
  height: ${({ window }) => window - 112}px;
  overflow-y: scroll;
`;

const AddModal = styled(Modal)`
  padding-top: 40px;
  .ant-modal-body {
    /* overflow-y: scroll;
    max-height: ${(props) => props.height - 300}px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f2f2f2;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 5px;
    } */
  }
`;

const InfoBox = styled.div`
  display: flex;
  margin-right: 12px;
`;

const AddMemberBtn = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.color_text_body};
  background-color: ${({ theme }) => theme.colors.color_base_dark};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.color_base_darker};
  cursor: pointer;
`;

const ContactsDetailViewComponent = styled.div`
  width: 100%;
  height: ${({ window }) => window - 12}px;
`;

const Header = styled.header`
  display: flex;
`;

// const HeaderItemBox = styled.div`
//   display: flex;
// `;

// const HeaderItem = styled.div`
//   margin-right: 10px;
// `;

const TitleBox = styled(comps.CardViewInnerSectionStyle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Title = styled.div`
  ${({ theme }) => theme.fontSizes.txt_h2};
  margin: 5px;
`;

// const EventBtnBox = styled.div`
//   display: flex;
//   margin-right: 50px;
// `;

const FooterBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const EventBtnBox = styled.div`
  display: flex;
  margin: 5px 0;
`;

const AddBtn = styled(PrimaryButton)`
  margin-left: 24px;
`;

const DropdownBox = styled(Dropdown)`
  background-color: inherit;
  border: none;
  margin-right: 10px;

  &:hover {
    background-color: inherit;
    border: none;
  }
`;

const MenuIcon = styled.div`
  cursor: pointer;
`;
