import React from "react";
import styled from "styled-components";
import Icons from "assets/Icons";
const EmailLabel = ({ contact, setValue, removeContact, reference }) => {
  const addressClick = () => {
    setValue(contact.email);
    reference.current.focus();
    removeContact(contact.email);
  };
  const handleRemove = () => {
    removeContact(contact.email);
  };
  return (
    <Label>
      <Address onClick={addressClick}>
        {contact.name ? contact.name : contact.email}
      </Address>
      <Icon onClick={handleRemove}>
        <Icons type="fal" name="times" />
      </Icon>
    </Label>
  );
};

export default EmailLabel;

const Icon = styled.div`
  margin: auto 7px;
  cursor: pointer;
`;

const Address = styled.span`
  font-weight: 600;
  margin-left: 0.833em;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: left;
`;

const Label = styled.div`
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 3px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
`;
