/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Table } from "ui";
import { useTranslation, withTranslation } from "react-i18next";

const AddMember = ({ exception, membersList, accessData, setCompare }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(accessData);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("global.addMember.selectAllMembers"),
      dataIndex: "name",
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (a) => {
      setSelectedRowKeys(a);
      setCompare(a);
    },
    getCheckboxProps: (record) => ({
      disabled: record.key === exception,
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={membersList}
    />
  );
};

export default withTranslation()(AddMember);
