import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { editInvoiceRowAxios, deleteInvoiceRowAxios } from "common/ApiAxios";
import Icons from "assets/Icons";
import InputBlock from "pages/DealCardDetailView/components/Finance/InputBlock";
import DateBlock from "pages/DealCardDetailView/components/Finance/DateBlock";
import useCheckPath from "hooks/useCheckPath";

const InvoiceItem = ({ item, resetData }) => {
  useEffect(() => {
    setInvoiceID(item.id);
    setInvoiceNumber(item.invoice_number);
    setAmount(item.amount);
    setInvoiceDate(item.invoicing_date_read);
    setDueDate(item.due_date_read);
    setRemark(item.remark);
  }, [item]);
  const [invoiceID, setInvoiceID] = useState(item.id);
  const [invoiceNumber, setInvoiceNumber] = useState(item.invoice_number);
  const [invoiceNumberEdit, setInvoiceNumberEdit] = useState(false);
  const [amount, setAmount] = useState(item.amount);
  const [amountEdit, setAmountEdit] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(item.invoicing_date_read);
  const [dueDate, setDueDate] = useState(item.due_date_read);
  const [remark, setRemark] = useState(item.remark);
  const [remarkEdit, setRemarkEdit] = useState(false);

  const checkMobile = useCheckPath("mobile");

  return (
    <InvoiceRow isMobile={checkMobile}>
      <InputBlock
        edit={invoiceNumberEdit}
        setEdit={setInvoiceNumberEdit}
        value={invoiceNumber}
        setValue={setInvoiceNumber}
        axios={editInvoiceRowAxios}
        invoiceID={invoiceID}
        type={"invoice_number"}
        width={!checkMobile ? 20 : 100}
      />
      <InputBlock
        edit={amountEdit}
        setEdit={setAmountEdit}
        value={amount}
        setValue={setAmount}
        axios={editInvoiceRowAxios}
        invoiceID={invoiceID}
        type={"amount"}
        width={!checkMobile ? 18 : 100}
        inputType={"number"}
        resetData={resetData}
      />
      <DateBlock
        value={invoiceDate}
        setValue={setInvoiceDate}
        axios={editInvoiceRowAxios}
        invoiceID={invoiceID}
        type={"invoicing_date"}
        width={!checkMobile ? 18 : 100}
      />
      <DateBlock
        value={dueDate}
        setValue={setDueDate}
        axios={editInvoiceRowAxios}
        invoiceID={invoiceID}
        type={"due_date"}
        width={!checkMobile ? 18 : 100}
      />
      <InputBlock
        edit={remarkEdit}
        setEdit={setRemarkEdit}
        value={remark}
        setValue={setRemark}
        axios={editInvoiceRowAxios}
        invoiceID={invoiceID}
        type={"remark"}
        width={!checkMobile ? 21 : 100}
      />
      {!checkMobile && (
        <RowBlock
          width={5}
          onClick={() =>
            deleteInvoiceRowAxios(invoiceID).then(() => resetData())
          }
          className="delete"
        >
          <Icons type="fas" name="trash" />
        </RowBlock>
      )}
    </InvoiceRow>
  );
};

export default InvoiceItem;

const RowBlock = styled.div`
  width: ${({ width }) => width}%;
  padding: 3px 2px;
  height: 36px;
  text-align: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const InvoiceRow = styled.div`
  display: flex;
  width: 100%;
  ${(props) => !props.isMobile && "padding: 8px 0;"}
  border-bottom: 1px solid ${({ theme }) => theme.colors.color_base_darker};
  .delete {
    display: none;
    opacity: 0.7;
  }
  &:hover .delete {
    display: flex;
  }
  ${(props) =>
    props.isMobile &&
    css`
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    `}
`;
