import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailThreadDisconnect = () => {
  async function disconnectThread(disconnectData) {
    const { data } = await emailThreadAPIs.disconnectThreadAxios(
      disconnectData
    );
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(disconnectThread, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/connected_cards/");
    },
  });
};
