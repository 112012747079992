import { takeLatest } from "redux-saga/effects";

// import { startLoading, finishLoading } from "../Modules/loading";
import createRequestSaga from "../CreateSaga/createRequestSaga";
import { createAction } from "redux-actions";
import * as api from "../../common/ApiAxios";

const UPDATE = "addTeamMetaInfoSaga/UPDATE";

export const addTeamMetaInfo = createAction(UPDATE);

const updateSaga = createRequestSaga(UPDATE, api.getTeamMetaInfoAxios);

export default function* addTeamMetaInfoSaga() {
  yield takeLatest(UPDATE, updateSaga);
}
