import { handleActions, createAction } from "redux-actions";

const SETINFO = "emailInfo/SETINFO";

export const setEmailInfo = createAction(SETINFO);

const initialState = {
  synced_email_current: "",
  synced_emails: [],
  provider: "",
  sync_state: "",
  thread_count: 25,
};

const emailInfo = handleActions(
  {
    [SETINFO]: (state, { payload: data }) => {
      return { ...state, ...data };
    },
  },
  initialState
);

export default emailInfo;
