import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TextArea } from "ui";
import {
  putOrderInfoAxios,
  getCardViewPartialInfoAxios,
} from "common/ApiAxios";
import * as comps from "../CardComponents";
import standard from "./InputSectionStandard";
import { useTranslation, withTranslation } from "react-i18next";
import useCheckPath from "hooks/useCheckPath";

const OrderInformation = ({ id, result }) => {
  // const [orderData, setOrderData] = useState(data);
  const [orderNumber, setOrderNumber] = useState("");
  // const [product, setProduct] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [deliveryDeadline, setDeliveryDeadline] = useState("");
  const [incoterms, setIncoterms] = useState("");
  const [shipment, setShipment] = useState("");
  const [loadingPort, setLoadingPort] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [destinationPort, setDestinationPort] = useState("");
  const [otherTerms, setOtherTerms] = useState("");
  const [orderID, setOrderID] = useState("");
  const notifications = useSelector((state) => state.notifications);
  const user = useSelector((state) => state.addTeamMetaInfo.user);

  const { t } = useTranslation();

  const checkMobile = useCheckPath("mobile");

  // const [CardviewID, setCardViewID] = useState("");
  // const customers = useSelector((state) => state.addTeamContactsInfo);
  // const states = useSelector((state) => state.addTeamMetaInfo);
  // const [customerName, setCustomerName] = useState("Choose a customer");
  // const [productDescription, setProductDescription] = useState("");
  // const [quantityValue, setQuantityValue] = useState("");
  // const [amountValue, setAmountValue] = useState("");
  // const [qunit, setQunit] = useState("Unit");
  // const [aunit, setAunit] = useState("Unit");

  // useEffect(() => {
  //   setOrderData(data);
  //   setCustomerName(data.customer ? data.customer : "Choose a customer");
  //   setOrderNumber(data.order_information.order_number);
  //   setProduct(data.order_information.product);
  //   setOrderDate(data.order_information.ordered_date_read);
  //   setProductDescription(data.order_information.product_description);
  //   setIncoterms(data.order_information.incoterms);
  //   setShipment(data.order_information.shipment);
  //   setLoadingPort(data.order_information.loading_port);
  //   setPaymentTerms(data.order_information.payment_terms);
  //   setDestinationPort(data.order_information.destination_port);
  //   setOtherTerms(data.order_information.other_terms);
  //   setOrderID(data.order_information.id);
  //   setCardViewID(data.id);
  //   setQuantityValue(data.quantity);
  //   setQunit(data.quantity_unit);
  //   setAmountValue(data.amount);
  //   setAunit(data.currency);
  // }, [data]);
  // const quantityUnitList = optionList(states.all_quantity_units);
  // const currencyList = optionList(states.all_currencies);

  useEffect(() => {
    getCardViewPartialInfoAxios(id, "?fields=order_information").then((res) => {
      setOrderNumber(res.data.order_information.order_number);
      // setProduct(res.data.order_information.product);
      setOrderDate(res.data.order_information.ordered_date_read);
      setDeliveryDeadline(res.data.order_information.delivery_deadline_read);
      setIncoterms(res.data.order_information.incoterms);
      setShipment(res.data.order_information.shipment);
      setLoadingPort(res.data.order_information.loading_port);
      setPaymentTerms(res.data.order_information.payment_terms);
      setDestinationPort(res.data.order_information.destination_port);
      setOtherTerms(res.data.order_information.other_terms);
      setOrderID(res.data.order_information.id);
    });
  }, [id]);

  useEffect(() => {
    const msgs = ["order_information"];
    if (
      notifications.card_id &&
      id.localeCompare(notifications.card_id.toString()) === 0 &&
      user.id.toString().localeCompare(notifications.user_id.toString()) !==
        0 &&
      msgs.includes(notifications.msg_type)
    ) {
      getCardViewPartialInfoAxios(id, "?fields=order_information").then(
        (res) => {
          setOrderNumber(res.data.order_information.order_number);
          // setProduct(res.data.order_information.product);
          setOrderDate(res.data.order_information.ordered_date_read);
          setDeliveryDeadline(
            res.data.order_information.delivery_deadline_read
          );
          setIncoterms(res.data.order_information.incoterms);
          setShipment(res.data.order_information.shipment);
          setLoadingPort(res.data.order_information.loading_port);
          setPaymentTerms(res.data.order_information.payment_terms);
          setDestinationPort(res.data.order_information.destination_port);
          setOtherTerms(res.data.order_information.other_terms);
          setOrderID(res.data.order_information.id);
        }
      );
    }
  }, [id, notifications, user.id]);

  const changeOrderInfoData = (key, data) => {
    putOrderInfoAxios(orderID, { [key]: data });
  };

  const [height, setHeight] = useState(false);

  useEffect(() => {
    result === "won" ? setHeight(true) : setHeight(false);
  }, [result]);

  return (
    <>
      {checkMobile && <Divider />}
      <OutsideWrapper isMobile={checkMobile} height={height.toString()}>
        <OrderInfoTitle height={height.toString()}>
          <div>{t("cardView.orderInformation.title")}</div>
          {/* <UpOutlined onClick={() => setHeight(!height)} /> */}

          {height ? (
            <div />
          ) : (
            <InfoText isMobile={checkMobile}>
              {t("cardView.orderInformation.infoText")}
            </InfoText>
          )}
        </OrderInfoTitle>
        <BodyContainer height={height.toString()}>
          <OrderInfoContainer>
            <Row isMobile={checkMobile}>
              {/* <RowSection width="49%">
                  <SubTitle>Customer</SubTitle>
                  <SubInput>
                    <SelectAddNew
                      placeholder1="Choose a customer"
                      placeholder2="Add customer"
                      selectAxios={putCardViewAxios}
                      id={id}
                      itemList={customers.all_customers}
                      saga={addTeamContactsInfo}
                      // defaultValue={}
                      keyValue="customer"
                    ></SelectAddNew>
                  </SubInput>
                </RowSection> */}
              {standard(
                t("cardView.orderInformation.orderNumber.label"),
                t("cardView.orderInformation.orderNumber.placeholder"),
                orderNumber,
                setOrderNumber,
                "order_number",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "50%"
              )}
              {checkMobile && <DividerNonColor />}
              {standard(
                t("cardView.orderInformation.orderDate.label"),
                t("cardView.orderInformation.orderDate.placeholder"),
                orderDate,
                setOrderDate,
                "ordered_date",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "60%" : "50%",
                "orderDateVisible"
              )}
            </Row>
            {/* <Row>
              {standard(
                t("cardView.orderInformation.product.label"),
                t("cardView.orderInformation.product.placeholder"),
                product,
                setProduct,
                "product",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "100%"
              )}
            </Row> */}
            <Row isMobile={checkMobile}>
              {standard(
                t("cardView.orderInformation.incoterms.label"),
                t("cardView.orderInformation.incoterms.placeholder"),
                incoterms,
                setIncoterms,
                "incoterms",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "50%"
              )}
              {checkMobile && <DividerNonColor />}
              {standard(
                t("cardView.orderInformation.destinationPort.label"),
                t("cardView.orderInformation.destinationPort.placeholder"),
                destinationPort,
                setDestinationPort,
                "destination_port",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "50%"
              )}
              {/* <RowSection width="100%">
              <SubTitle>Product Description</SubTitle>
              <TextArea
                className="OtherTerms"
                placeholder="Enter Product Description"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                onPressEnter={(e) =>
                  putOrderInfoAxios(orderID, {
                    product_description: e.target.value,
                  })
                }
                onBlur={(e) =>
                  putOrderInfoAxios(orderID, {
                    product_description: e.target.value,
                  })
                }
                autoSize={{ minRows: 3, maxRows: 20 }}
                style={{ fontSize: "12px", Color: "black" }}
              />
            </RowSection> */}
            </Row>
            {/* <Row>
                {unit(
                  "Quantity",
                  "Enter Quantity",
                  quantityValue,
                  setQuantityValue,
                  "quantity",
                  qunit,
                  setQunit,
                  "quantity_unit_id",
                  quantityUnitList,
                  putCardViewAxios,
                  CardviewID,
                  "50%"
                )}
                {unit(
                  "Amount",
                  "Enter Amount",
                  amountValue,
                  setAmountValue,
                  "amount",
                  aunit,
                  setAunit,
                  "currency_id",
                  currencyList,
                  putCardViewAxios,
                  CardviewID,
                  "50%"
                )}
              </Row> */}
            <Row isMobile={checkMobile}>
              {standard(
                t("cardView.orderInformation.deliveryDeadline.label"),
                t("cardView.orderInformation.deliveryDeadline.placeholder"),
                deliveryDeadline,
                setDeliveryDeadline,
                "delivery_deadline",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "70%" : "50%",
                "orderDateVisible"
              )}
              {checkMobile && <DividerNonColor />}
              {standard(
                t("cardView.orderInformation.loadingPort.label"),
                t("cardView.orderInformation.loadingPort.placeholder"),
                loadingPort,
                setLoadingPort,
                "loading_port",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "50%"
              )}
            </Row>
            <Row>
              {standard(
                t("cardView.orderInformation.shipmentTerms.label"),
                t("cardView.orderInformation.shipmentTerms.placeholder"),
                shipment,
                setShipment,
                "shipment",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "100%"
              )}
            </Row>
            <Row>
              {standard(
                t("cardView.orderInformation.paymentTerms.label"),
                t("cardView.orderInformation.paymentTerms.placeholder"),
                paymentTerms,
                setPaymentTerms,
                "payment_terms",
                putOrderInfoAxios,
                orderID,
                checkMobile ? "80%" : "100%"
              )}
            </Row>
            <Row>
              <RowSection width="100%">
                <SubTitle>
                  {t("cardView.orderInformation.otherTerms.label")}
                </SubTitle>
                <TextArea
                  className="OtherTerms"
                  placeholder={t(
                    "cardView.orderInformation.otherTerms.placeholder"
                  )}
                  value={otherTerms}
                  onChange={(e) => setOtherTerms(e.target.value)}
                  onPressEnter={(e) =>
                    changeOrderInfoData("other_terms", e.target.value)
                  }
                  onBlur={(e) =>
                    changeOrderInfoData("other_terms", e.target.value)
                  }
                  autoSize={{ minRows: 4, maxRows: 20 }}
                  style={{ fontSize: "12px", Color: "black" }}
                />
              </RowSection>
            </Row>
          </OrderInfoContainer>
          {/* <ButtonArea>
          <MyButton onClick={() => getExportContractAxios(id)}>
            Contract Export
          </MyButton>
        </ButtonArea> */}
        </BodyContainer>
      </OutsideWrapper>
      {/* <DividerNonColor height="30px" /> */}
    </>
  );
};

export default withTranslation()(OrderInformation);

const InfoText = styled.div`
  font-size: ${({ isMobile, theme }) =>
    isMobile ? "12px" : theme.fontSizes.txt_small};
  font-weight: normal;
`;

const RowSection = styled.div`
  width: ${(props) => `${props.width}`};
  padding-right: 5%;
`;

const SubTitle = styled.div`
  font-size: 14px;
  height: 15px;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  ${(props) =>
    props.isMobile ? "flex-direction: column;" : "flex-direction: row;"}
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
`;

const OutsideWrapper = styled(comps.CardViewInnerSectionStyle)`
  margin-top: ${(props) => (props.isMobile ? "0" : "12px")};
  /* padding-bottom: ${(props) => props.isMobile && "45px"}; */
  ${(props) =>
    props.isMobile ? "border: none;" : "border: 1px solid #d6dee5;"}
  ${(props) => props.height === "false" && "border: 1px solid #d6dee5;"};
`;

const Divider = styled.div`
  background-color: #f4f4f4;
  height: 10px;
`;

const DividerNonColor = styled.div`
  height: ${(props) => (props.height ? props.height : "10px")};
`;

const BodyContainer = styled.div`
  ${(props) => (props.height === "false" ? "display: none" : "")};
  transition: all 1s ease-in-out;
  overflow: hidden;
`;

const OrderInfoTitle = styled(comps.CardViewInnerSectionTitleStyle)`
  display: flex;
  justify-content: space-between;
  ${(props) => (props.height === "false" ? "border-bottom: none" : "")};
  background-color: ${({ height, theme }) =>
    height === "false" && theme.colors.color_base_dark};
  ${({ height }) => height === "false" && "opacity: 0.5"};
  ${({ height }) => height === "false" && "cursor: not-allowed !important"};
  width: 100%;
`;

const OrderInfoContainer = styled.div`
  padding: 15px;
`;
