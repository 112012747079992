import React, { useState } from "react";
import styled from "styled-components";
import { Input, DatePicker } from "ui";
import moment from "moment";
import Icons from "assets/Icons";

const InputSectionStandard = (
  title,
  placeholder,
  value,
  setter,
  key,
  axios,
  id,
  width,
  orderDateVisible
) => {
  const [saveOrderDate, setSaveOrderDate] = useState("");
  const [edit, setEdit] = useState(false);

  const handleOrderDateChange = (date, dateString) => {
    setSaveOrderDate(dateString === "" ? null : dateString);
    setter(dateString === "" ? null : dateString);
    dateString === "" &&
      axios(id, {
        [key]: null,
      });
  };

  const handleSaveOrderDate = () => {
    if (value === null) {
      axios(id, {
        [key]: saveOrderDate,
      });
    } else {
      axios(id, {
        [key]: `${value.replaceAll("/", "-")}T23:59`,
      });
    }
  };

  return (
    <RowSection width={width}>
      <SubTitle>{title}</SubTitle>
      <SubInput>
        {orderDateVisible === "orderDateVisible" ? (
          value === null ? (
            <OrderDate
              placeholder={placeholder}
              onChange={handleOrderDateChange}
              onBlur={handleSaveOrderDate}
            />
          ) : (
            <OrderDate
              placeholder={placeholder}
              onChange={handleOrderDateChange}
              value={moment(value, placeholder)}
              format={placeholder}
              onBlur={handleSaveOrderDate}
            />
          )
        ) : (
          <>
            {edit ? (
              <EditInput
                placeholder={placeholder}
                value={value}
                onChange={(e) => setter(e.target.value)}
                onPressEnter={(e) => {
                  e.target.blur();
                }}
                onBlur={(e) => {
                  axios(id, { [key]: e.target.value });
                  setEdit(!edit);
                }}
                autoFocus
              />
            ) : (
              <EditTextBox onClick={() => setEdit(!edit)}>
                <EditText>
                  {value ? value : <span>&nbsp;&nbsp;-</span>}
                </EditText>
                <EditIcon className="hidden">
                  <Icons type="fas" name="edit" />
                </EditIcon>
              </EditTextBox>
              // <Text onClick={() => setEdit(!edit)}>
              //   {value ? value : <span>&nbsp;&nbsp;-</span>}
              // </Text>
            )}
          </>
        )}
      </SubInput>
    </RowSection>
  );
};

export default InputSectionStandard;

const RowSection = styled.div`
  width: ${(props) => `${props.width}`};
  padding-right: 5%;
`;

const SubTitle = styled.div`
  font-size: 14px;
  height: 15px;
  margin-bottom: 12px;
  font-weight: bold;
`;

const SubInput = styled.div``;

const EditInput = styled(Input)`
  height: 31px;
  line-height: 31px;
  padding-left: 4px;
  /* padding: 0 !important; */
`;

const OrderDate = styled(DatePicker)`
  width: 100%;
  height: 31px;
  border-color: ${({ theme }) => theme.colors.color_base_dark} !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  &:focus {
    border-color: ${({ theme }) =>
      theme.colors.color_primary_regular} !important;
  }
`;

const EditIcon = styled.div`
  display: none;
  padding-bottom: 4px;
  padding-right: 4px;
  color: ${({ theme }) => theme.colors.color_text_body};
`;

const EditTextBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_regular};
  }
  &:hover .hidden {
    display: inline-block;
  }
`;

const EditText = styled.div`
  min-height: 31px;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  line-height: 31px;
  padding-left: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
