import { emailAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";

export const useEmailAccountDeletion = () => {
  async function disconnectEmail() {
    const { data } = await emailAPIs.deleteSettingEmailAxios();
    return data;
  }
  const queryClient = useQueryClient();
  return useMutation(disconnectEmail, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/account/");
    },
  });
};
