import React from "react";
import { Option } from "ui";

export const OptionListUnit = (data: any[]) =>
  data.map((unit, i) => (
    <Option
      key={unit.id}
      value={`${unit.name} (${unit.code})`}
      code={unit.code}
      name={unit.name}
      id={unit.id}
      label={unit.code}
    >
      <div>
        {unit.name}
        {` (${unit.code})`}
      </div>
    </Option>
  ));
