import { handleActions, createAction } from "redux-actions";

const MULTIEDIT = "multiEdit/MULTIEDIT";
const INITIALIZE = "multiEdit/INITIALIZE";

export const multiEdit = createAction(MULTIEDIT);
export const initializeMultiEdit = createAction(INITIALIZE);

const initialState = {
  editType: "",
  cardsId: [],
  membersId: [],
  ownerId: [],
};

const multiEditCards = handleActions(
  {
    [MULTIEDIT]: (state, { payload: info }) => {
      return info;
    },
    [INITIALIZE]: (state, { payload: info }) => {
      return initialState;
    },
  },
  initialState
);

export default multiEditCards;
