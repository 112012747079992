import { emailThreadAPIs, dealCardAPIs } from "api-cache/ApiAxios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { sendNotification } from "modules/Global/notificationSocket";
import { notification } from "ui";
import { useTranslation } from "react-i18next";
import { useTeamMetaInfo } from "api-cache";
export const useEmailToNewCardConnect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: { user },
  } = useTeamMetaInfo();
  const queryClient = useQueryClient();
  async function createCard({ newCardData, id }) {
    const { data } = await dealCardAPIs
      .createCardAxios(newCardData)
      .then((res) => {
        notification(
          "success",
          `${t("email.emailBox.emailBoxRow.connectingEmailToCard")}`,
          "",
          "bottomRight"
        );
        emailThreadAPIs
          .connectCardToEmailAxios({
            thread_ids: [id],
            card_id: res.data.id,
          })
          .then((res) => {
            dispatch(
              sendNotification({
                board_id: newCardData.board,
                card_id: "",
                msg_type: "addCard",
                team_id: user.team_id,
                type: "sendMessage",
                user_id: user.id,
              })
            );
          });

        return res;
      });
    return data;
  }
  return useMutation(createCard, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries("get/email/threads/");
      queryClient.invalidateQueries("get/email/connected_cards/");
    },
  });
};
