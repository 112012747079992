import React, { useState, useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Input, ClearButton, DatePicker, Checkbox } from "ui";
import { DeleteOutlined } from "@ant-design/icons";
import * as api from "common/ApiAxios";
import moment from "moment";
import useCheckPath from "hooks/useCheckPath";

const TodoItem = ({ todo, setTodoList, id, todoList }) => {
  const dateFormat = "YYYY/MM/DD";
  const [checked, setChecked] = useState(todo.is_done);
  const [todoText, setTodoText] = useState(todo.content);
  const [edit, setEdit] = React.useState(true);
  const theme = useContext(ThemeContext);

  const checkMobile = useCheckPath("mobile");

  useEffect(() => {
    setChecked(todo.is_done);
    setTodoText(todo.content);
  }, [todo]);

  const onChange = (date, dateString) => {
    api.editToDoItemAxios(todo.id, id, {
      deadline: dateString.replaceAll("/", "-"),
    });
  };

  const onCheck = (e) => {
    setTodoList(
      todoList.map((item) => {
        return item.id !== todo.id ? item : { ...item, is_done: !todo.is_done };
      })
    );
    setChecked(!checked);
    api.editToDoItemAxios(todo.id, id, { is_done: e.target.checked });
  };

  const deleteTodo = () => {
    setTodoList(todoList.filter((item) => item.id !== todo.id && item));
    api.deleteToDoItemAxios(todo.id, id);
  };

  const editTodo = () => {
    setTodoList(
      todoList.map((item) => {
        return item.id !== todo.id ? item : { ...item, content: todoText };
      })
    );
    setEdit(!edit);
    api.editToDoItemAxios(todo.id, id, { content: todoText });
  };
  return (
    <TodoRow checked={checked}>
      <ToDoCheck>
        <DoneCheckBox
          type="checkbox"
          onClick={(e) => onCheck(e)}
          // checkbox={todo.done}
          readOnly={true}
          checked={checked}
        />
      </ToDoCheck>
      <div className="task">
        <CheckBoxNtextBox isMobile={checkMobile}>
          {edit ? (
            <TodoText
              checked={checked}
              className="textBox"
              onClick={() => setEdit(!edit)}
            >
              {todoText ? todoText : "Add Todo Item"}
            </TodoText>
          ) : (
            <Input
              style={{
                minHeight: 25,
                borderRadius: 3,
                fontSize: 14,
                fontWeight: "bold",
                width: 500,
                // backgroundColor: "#7ab6ff",
                // color: "white",
                // fontWeight: "bold",
              }}
              onChange={(e) => setTodoText(e.target.value)}
              value={todoText}
              onPressEnter={() => editTodo()}
              onBlur={() => editTodo()}
              autoFocus
            />
          )}
          {/* <div className="textBox">{todo.content}</div> */}
        </CheckBoxNtextBox>
      </div>
      {/* <div className="today">
        {date.getDate()} {monthNames[monthIndex]}
      </div> */}
      <SelectDeadline isMobile={checkMobile}>
        <DatePickerStyle
          style={{
            border: "none",
            fontSize: theme.fontSizes.txt_smaller,
          }}
          onChange={onChange}
          defaultValue={moment(todo.deadline_read, dateFormat)}
          format={dateFormat}
          size="small"
        />
        {/* <DatePicker onChange={onChange} va/> */}
      </SelectDeadline>
      <div className="remove">
        <ClearButton className="deleteButton" onClick={() => deleteTodo()}>
          <DeleteOutlined style={{ marginBottom: 3 }} />
        </ClearButton>
      </div>
    </TodoRow>
  );
};

export default TodoItem;

const TodoText = styled.div`
  padding-left: 1px;
  text-decoration: ${({ checked }) =>
    checked ? "underline line-through" : "none"};
  width: 500px;
  min-height: 25px;
  line-height: 25px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.color_base_dark};
  }
`;

const TodoRow = styled.div`
  width: 100%;
  position: relative;
  /* min-height: 36px; */
  display: flex;
  text-align: center;
  align-items: stretch;
  justify-content: center;
  background-color: ${({ checked, theme }) =>
    checked && theme.colors.color_primary_lighter};
  /* .today {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      font-size: 13px;
      width: 10%;
      height: 36px;
      border-bottom: 0.5px solid #d9d9d9;
    } */
  .task {
    width: 65%;
    min-height: 36px;
    display: flex;
    align-items: center;
    /* border: 0.5px solid #d9d9d9; */
    border-top: none;
  }
  .remove {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    width: 8.5%;
    min-height: 36px;
    /* border: 0.5px solid #d9d9d9; */
    border-top: none;
  }
`;

const CheckBoxNtextBox = styled.div`
  text-align: center;
  display: flex;
  width: ${(props) => (props.isMobile ? "100%" : "95%")};
  ${(props) => (props.isMobile ? "padding-left: 5px;" : "padding-left: 10px;")}
  font-size: ${({ theme }) => theme.fontSizes.txt_smaller};
`;

const ToDoCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.5%;
  min-height: 36px;
  /* border: 0.5px solid #d9d9d9; */
  border-top: none;
  border-right: none;
`;

const SelectDeadline = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.isMobile ? "38%" : "20%")};
  min-height: 36px;
  /* border-bottom: 0.5px solid #d9d9d9; */
`;

const DatePickerStyle = styled(DatePicker)`
  .ant-picker-input {
    input {
      font-size: ${({ theme }) => theme.fontSizes.txt_smaller} !important;
    }
  }
`;

const DoneCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }
`;
