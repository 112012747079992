import React from "react";
// import styled from "styled-components";
import { CardLink, ContactLink } from "ui";
// import { MemberIcon } from "UI";
const columnGenerator = (types) => {
  const typeObject = {
    card: {
      title: "Card",
      field: "card.name",
      render: (rowData) => {
        return (
          rowData.card && (
            <CardLink name={rowData.card.name} id={rowData.card.id} />
          )
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    board: {
      title: "Board",
      field: "board.name",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    created_at: {
      title: "Created At",
      field: "created_at",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    stage: {
      title: "Stage",
      field: "stage",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    card_list: {
      title: "Stage",
      field: "card_list",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    amount: {
      title: "Amount",
      field: "amount",
      type: "numeric",
      render: (rowData) => {
        return (
          rowData.amount &&
          Number(rowData.amount).toLocaleString("us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    currency: {
      title: "Currency",
      field: "currency",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    quantity: {
      title: "Quantity",
      field: "quantity",
      type: "numeric",
      render: (rowData) => {
        return (
          rowData.quantity &&
          Number(rowData.quantity).toLocaleString("us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    quantity_unit: {
      title: "Unit",
      field: "quantity_unit",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    customer: {
      title: "Customer",
      field: "customer.name",
      render: (rowData) => {
        return rowData.customer ? (
          <ContactLink
            type="customer"
            name={rowData.customer.name}
            id={rowData.customer.id}
          />
        ) : (
          <div></div>
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    supplier: {
      title: "Supplier",
      field: "supplier.name",
      render: (rowData) => {
        return rowData.supplier ? (
          <ContactLink
            type="supplier"
            name={rowData.supplier.name}
            id={rowData.supplier.id}
          />
        ) : (
          <div></div>
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    user: {
      title: "Owner",
      field: "user.user_name",
      // render: (rowData) => {
      //   return rowData.owner ? (
      //     <MemberItem>
      //       <MemberIcon
      //         member={{
      //           user_name: rowData.owner.user_name,
      //           user_email: rowData.owner.user_email,
      //           user_profile_image: rowData.owner.user_profile_image,
      //         }}
      //       ></MemberIcon>
      //       <NameEmail>
      //         <div>{rowData.owner.user_name}</div>
      //         <div>{rowData.owner.user_email}</div>
      //       </NameEmail>
      //     </MemberItem>
      //   ) : (
      //     <div></div>
      //   );
      // },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      // customFilterAndSearch: (term, rowData) => {
      //   return (rowData.owner.user_name + " " + rowData.owner.user_email)
      //     .toLowerCase()
      //     .includes(term);
      // },
    },
    owner: {
      title: "Owner",
      field: "owner.user_name",
      // render: (rowData) => {
      //   return rowData.owner ? (
      //     <MemberItem>
      //       <MemberIcon
      //         member={{
      //           user_name: rowData.owner.user_name,
      //           user_email: rowData.owner.user_email,
      //           user_profile_image: rowData.owner.user_profile_image,
      //         }}
      //       ></MemberIcon>
      //       <NameEmail>
      //         <div>{rowData.owner.user_name}</div>
      //         <div>{rowData.owner.user_email}</div>
      //       </NameEmail>
      //     </MemberItem>
      //   ) : (
      //     <div></div>
      //   );
      // },
      cellStyle: {
        whiteSpace: "nowrap",
      },
      // customFilterAndSearch: (term, rowData) => {
      //   return (rowData.owner.user_name + " " + rowData.owner.user_email)
      //     .toLowerCase()
      //     .includes(term);
      // },
    },
    result: {
      title: "Result",
      field: "result",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    lost_reason: {
      title: "Lost Reason",
      field: "lost_reason",
      cellStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => {
        return rowData.lost_reason.name;
      },
    },
    lost_description: {
      title: "Lost Description",
      field: "lost_description",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    local_result_updated_at: {
      title: "Result Updated At",
      field: "local_result_updated_at",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    invoice_total: {
      title: "Invoice Total",
      field: "invoice_total",
      type: "numeric",
      render: (rowData) => {
        return (
          rowData.invoice_total &&
          Number(rowData.invoice_total).toLocaleString("us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    payment_total: {
      title: "Payment Total",
      field: "payment_total",
      type: "numeric",
      render: (rowData) => {
        return (
          rowData.payment_total &&
          Number(rowData.payment_total).toLocaleString("us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    balance: {
      title: "Balance",
      field: "balance",
      type: "numeric",
      render: (rowData) => {
        return (
          rowData.balance &&
          Number(rowData.balance).toLocaleString("us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    country: {
      title: "Country",
      field: "country",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    order_number: {
      title: "Order Number",
      field: "order_number",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    local_ordered_date: {
      title: "Order Date",
      field: "local_ordered_date",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    product: {
      title: "Product",
      field: "product",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    products: {
      title: "Products",
      field: "products",
      render: (rowData) => {
        return (
          rowData.products &&
          rowData.products.map((product) => product.name).join(", ")
        );
      },
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    incoterms: {
      title: "Incoterms",
      field: "incoterms",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    local_delivery_deadline: {
      title: "Delivery Deadline",
      field: "local_delivery_deadline",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    destination_port: {
      title: "Destination Port",
      field: "destination_port",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    loading_port: {
      title: "Loading Port",
      field: "loading_port",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    payment_terms: {
      title: "Payment Terms",
      field: "payment_terms",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    other_terms: {
      title: "Other Terms",
      field: "other_terms",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    archived_at: {
      title: "Archived At",
      field: "archived_at",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
  };
  return types.map((type) => typeObject[type]);
};

export default columnGenerator;

// const MemberItem = styled.div`
//   display: flex;
//   padding: 3px 5px;
//   align-items: center;
// `;

// const NameEmail = styled.div`
//   font-size: 14px;
//   margin-left: 10px;
//   height: 100%;
// `;
