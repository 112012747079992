import { emailThreadAPIs } from "api-cache/ApiAxios";
import { useQuery } from "react-query";
export const useConnectedCards = (id, enabled) => {
  async function getConnectedCards() {
    const { data } = await emailThreadAPIs.getConnectedCards(id);
    return data;
  }
  return useQuery({
    queryKey: ["get/email/connected_cards/", id],
    queryFn: getConnectedCards,
    placeholderData: [],
    enabled: enabled,
  });
};
