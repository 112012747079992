/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-assign */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Comment, Link, MemberIcon, Popconfirm } from "ui";
// import "antd/dist/antd.css";
import CommentEditor from "./CommentEditor";
import { deleteCommentAxios } from "../../../../common/ApiAxios";
import Linkify from "react-linkify";
import { useTranslation, withTranslation } from "react-i18next";

import { imageTypes } from "constants/ImageTypes";
import loadable from "@loadable/component";
import useCheckPath from "hooks/useCheckPath";
const PreviewModal = loadable(() => import("ui/Organisms/PreviewModal/PreviewModal"));
const CommentListItem = ({
  user,
  id,
  commentInfo,
  setLoading,
  loading,
  setEditCommentAdd,
  setPreventClose,
  setIsEditing,
}) => {
  const [commentEdit, setCommentEdit] = useState(false);
  // const imageTypes = [
  //   "img",
  //   "jpg",
  //   "png",
  //   "gif",
  //   "PNG",
  //   "IMG",
  //   "JPG",
  //   "GIF",
  //   "jpeg",
  //   "JPEG",
  // ];
  // const user = useSelector((state) => state.addTeamMetaInfo.user);
  const [previewVisible, setPreviewVisible] = useState(false);
  // useEffect(() => {
  //   setPreventClose && setPreventClose(previewVisible);
  // }, [previewVisible]);
  useEffect(() => {
    setPreventClose &&
      (previewVisible
        ? setPreventClose(previewVisible)
        : setTimeout(() => {
            setPreventClose(previewVisible);
          }, 500));
  }, [previewVisible]);
  const [word, setWord] = useState("");

  const { t } = useTranslation();

  const checkMobile = useCheckPath("mobile");

  // useEffect(() => {
  //   word !== "" && setPreviewVisible(true);
  //   word !== "" && reload();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [word]);

  const handleShowCommentEditor = () => {
    setCommentEdit(true);
    setIsEditing(true);
    //setLoading(false);
  };

  const handleCommentDelete = async (deleteId) => {
    const cardId = parseInt(id.slice(5));

    await deleteCommentAxios(deleteId, cardId).then(() => {
      setEditCommentAdd(true);
      //setLoading(true);
      // setCommentEdit(false);
      //setLoading(false);
    });
  };

  return (
    <Comment
      key={commentInfo.id}
      className="CreatedComment"
      datetime={
        commentInfo.local_created_at === commentInfo.local_updated_at
          ? commentInfo.datetime
          : `${commentInfo.datetime} (${t(
              "cardView.commentPage.commentListItem.edited"
            )})`
      }
      author={commentInfo.user.user_name}
      content={
        commentEdit ? (
          <CommentEditor
            id={id}
            commnetId={commentInfo.id}
            commentContent={commentInfo.content}
            setCommentEdit={setCommentEdit}
            setEditCommentAdd={setEditCommentAdd}
            setLoading={setLoading}
            loading={loading}
            setIsEditing={setIsEditing}
          />
        ) : (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <CommentTextBox
              from={user.id === commentInfo.user.id ? "me" : "else"}
            >
              {commentInfo.content.split("|^|").map((word, i) =>
                word.includes("|||") ? (
                  <Link
                    key={`${i}preview`}
                    onClick={() => {
                      if (!checkMobile) {
                        setWord(word);
                      } else {
                        window.ReactNativeWebView &&
                          window.ReactNativeWebView.postMessage(
                            word.split("|||")[0]
                          );
                      }
                    }}
                  >
                    {word.split("|||")[2]}
                  </Link>
                ) : (
                  word
                )
              )}
            </CommentTextBox>
          </Linkify>
        )
      }
      avatar={<MemberIcon member={commentInfo.user}></MemberIcon>}
      actions={
        commentEdit === false &&
        commentInfo.user.user_email === user.user_email && [
          // <CommentEditTextBox>
          <CommentEditText onClick={handleShowCommentEditor}>
            {t("cardView.commentPage.commentListItem.edit")}
          </CommentEditText>,
          <Popconfirm
            title={
              t("cardView.commentPage.commentListItem.confirmText")
              // <ConfirmTitle>
              //   <Icons
              //     type="fas"
              //     name="question-circle"
              //     color={theme.colors.color_text_titles}
              //   />
              //   &nbsp;
              //   {t("cardView.commentPage.commentListItem.confirmText")}
              // </ConfirmTitle>
            }
            destroyTooltipOnHide={true}
            onConfirm={() => {
              handleCommentDelete(commentInfo.id);
            }}
            getPopupContainer={(trigger) => trigger.parentElement}
            okText={t("cardView.commentPage.commentListItem.yes")}
            cancelText={t("cardView.commentPage.commentListItem.no")}
            placement="bottomLeft"
            trigger="click"
          >
            <CommentEditText>
              {t("cardView.commentPage.commentListItem.delete")}
            </CommentEditText>
          </Popconfirm>,
          // </CommentEditTextBox>,
        ]
      }
    >
      <PreviewModal
        setType={setWord}
        type={word === "" ? "" : word.split("|||")[1]}
        fileName={word.split("|||")[2]}
        previewUrl={word.split("|||")[0]}
        setPreviewVisible={setPreviewVisible}
        previewVisible={previewVisible}
        imageTypes={imageTypes}
      />
      {/* <MyModal
        window={height}
        wrapClassName="previewModal"
        visible={previewVisible}
        footer={null}
        centered={true}
        onCancel={() => {
          setWord("");
          setPreviewVisible(false);
          setLoaded(false);
        }}
        maskClosable={true}
        width="90%"
        destroyOnClose={true}
      >
        {!loaded && !imageTypes.includes(word.split("|||")[1]) && (
          <MySpin size="large" />
        )}
        {imageTypes.includes(word.split("|||")[1]) ? (
          <img
            height={`${height - 200}px`}
            src={word.split("|||")[0]}
            alt="previewimage"
          />
        ) : (
          <iframe
            key={`${commentInfo.id}image`}
            ref={frame}
            title="preview"
            className={word.split("|||")[1]}
            width="100%"
            height="750"
            frameBorder="0"
            onLoad={() => setLoaded(true)}
            src={`https://docs.google.com/viewer?url=${
              word.split("|||")[0]
            }&embedded=true`}
          ></iframe>
        )}
      </MyModal> */}
    </Comment>
  );
};

export default React.memo(withTranslation()(CommentListItem));

const CommentTextBox = styled.pre`
  user-select: text;
  font-family: San Francisco;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  color: ${({ theme }) => theme.colors.color_text_body};
  /* background-color: ${({ theme }) => theme.colors.color_base_dark}; */
  background-color: ${(props) =>
    props.from !== "me" ? props.theme.colors.color_base_dark : "#D3EEF1"};
  white-space: pre-wrap;
  word-wrap: break-all;
  margin: 0;
  width: 100%;
  padding: 12px 16px;
  border-radius: 7px;
  a {
    text-decoration: underline;
  }
`;

const CommentEditText = styled(Link)`
  padding-right: 7px;
  opacity: 0.8;

  :hover {
    cursor: pointer;
  }
`;
