import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DealCardDetailView from "pages/DealCardDetailView/DealCardDetailView";
import { Link, PageModal } from "ui";
const CardLink = ({ name, id, maxWidth }) => {
  const [cardID, setCardID] = useState(null);
  const [visible, setVisible] = useState(false);
  const [preventClose, setPreventClose] = useState(false);
  useEffect(() => {
    cardID !== null && setVisible(true);
  }, [cardID]);
  useEffect(() => {
    visible === false && setCardID(null);
  }, [visible]);
  return (
    <>
      <PageModal
        visible={visible}
        onCancel={() => !preventClose && setVisible(false)}
        footer={null}
        width="100%"
        centered={true}
        destroyOnClose
      >
        <DealCardDetailView
          setPreventClose={setPreventClose}
          id={cardID}
          setVisible={setVisible}
          visible={visible}
          cardname={name}
        />
      </PageModal>
      <LinkText
        maxWidth={maxWidth}
        onClick={(e) => {
          e.stopPropagation();
          setCardID(`card_${id}`);
        }}
      >
        {name}
      </LinkText>
    </>
  );
};

export default CardLink;

const LinkText = styled(Link)`
  pointer-events: auto !important;
  text-decoration: none !important;
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  /* max-width: ${({ maxWidth }) => maxWidth}px;
  overflow: hidden;
  text-overflow: ellipsis; */
  &:hover {
    text-decoration: underline !important;
  }
`;
