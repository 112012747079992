import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, ClearButton, DatePicker, TimePicker, Checkbox } from "ui";
import { DeleteOutlined } from "@ant-design/icons";
import * as api from "common/ApiAxios";
import moment from "moment";
import useCheckPath from "hooks/useCheckPath";
import useViewport from "hooks/useViewPort";
import { useTranslation } from "react-i18next";
const MobileScheduleItem = ({
  schedule,
  setScheduleList,
  id,
  scheduleList,
}) => {
  const [checked, setChecked] = useState(schedule.is_done);
  const [title, setTitle] = useState(schedule.title);
  const [start, setStart] = useState("");
  const [startTime, setStartTime] = useState("00:00:00");
  const [end, setEnd] = useState("");
  const [endTime, setEndTime] = useState("23:59:59");
  const [edit, setEdit] = React.useState(true);
  const [allDay, setAllDay] = useState(true);
  const checkMobile = useCheckPath("mobile");
  const [eventType, setEventType] = useState(3);
  const { width } = useViewport();
  const { t } = useTranslation();
  useEffect(() => {
    setChecked(schedule.is_done);
    setTitle(schedule.title);
    let tmp = schedule.start.split("T");
    setStart(tmp[0]);
    !schedule.allDay && setStartTime(tmp[1]);
    tmp = schedule.end.split("T");
    setEnd(tmp[0]);
    !schedule.allDay && setEndTime(tmp[1]);
    setAllDay(schedule.allDay);
    setEventType(schedule.event_type);
    console.log("schedule.event_type: ", schedule.event_type);
  }, [schedule]);

  console.log("width: ", width);

  // const onChange = (date, dateString) => {
  //   api.editToDoItemAxios(schedule.id, id, {
  //     deadline: dateString.replaceAll("/", "-"),
  //   });
  // };
  const timeString = (date, time) => {
    if (date === "") {
      return "";
    } else {
      if (time === "") {
        return date;
      } else {
        return `${date}T${time}`;
      }
    }
  };
  const onCheck = (e) => {
    setScheduleList(
      scheduleList.map((item) => {
        return item.id !== schedule.id
          ? item
          : { ...item, is_done: !schedule.is_done };
      })
    );
    setChecked(!checked);
    api.updateScheduleAxios(schedule.id, id, { is_done: e.target.checked });
  };

  const deleteTodo = () => {
    setScheduleList(
      scheduleList.filter((item) => item.id !== schedule.id && item)
    );
    api.deleteScheduleAxios(schedule.id, id);
  };

  const editTitle = () => {
    setScheduleList(
      scheduleList.map((item) => {
        return item.id !== schedule.id ? item : { ...item, title };
      })
    );
    setEdit(!edit);
    api.updateScheduleAxios(schedule.id, id, { title });
  };
  const handleAllDay = () => {
    api.updateScheduleAxios(schedule.id, id, {
      deadline: timeString(
        allDay
          ? moment(end, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD")
          : moment(end, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"),
        !allDay ? "00:00:00" : endTime
      ),
      allDay: !allDay,
    });
    allDay
      ? setEnd(
          moment(end, "YYYY-MM-DD").subtract(1, "days").format("YYYY-MM-DD")
        )
      : setEnd(moment(end, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"));
    setAllDay(!allDay);
  };
  return (
    <ScheduleSection checked={checked}>
      <ScheduleRow>
        <ToDoCheck>
          <DoneCheckBox
            type="checkbox"
            onClick={(e) => onCheck(e)}
            // checkbox={schedule.done}
            readOnly={true}
            checked={checked}
          />
        </ToDoCheck>
        <ScheduleTitle>
          <CheckBoxNtextBox isMobile={checkMobile}>
            {{
              1: <TitleText checked={checked}>Invoice Due Date</TitleText>,
              2: <TitleText checked={checked}>Shipment Deadline</TitleText>,
            }[eventType] ||
              (edit ? (
                <TodoText
                  checked={checked}
                  className="textBox"
                  onClick={() => setEdit(!edit)}
                >
                  {title ? title : "Add Todo Item"}
                </TodoText>
              ) : (
                <Input
                  style={{
                    minHeight: 25,
                    borderRadius: 3,
                    fontSize: 14,
                    marginRight: 40,
                    fontWeight: "bold",
                    width: "-webkit-fill-available",
                    // backgroundColor: "#7ab6ff",
                    // color: "white",
                    // fontWeight: "bold",
                  }}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  onPressEnter={() => editTitle()}
                  onBlur={() => editTitle()}
                  autoFocus
                />
              ))}
            {/* <div className="textBox">{schedule.content}</div> */}
          </CheckBoxNtextBox>
        </ScheduleTitle>
      </ScheduleRow>
      {eventType === 3 ? (
        <>
          <ScheduleRow allDay={allDay} isMobile={checkMobile} date>
            <TimeSection isMobile={checkMobile} width={width}>
              <RequiredTitle>
                {t("cardView.schedule.startDate").split(" ")[1]}
              </RequiredTitle>
              <TimeBody>
                <DatePickers
                  checked={checked}
                  allowClear={false}
                  value={start !== "" && moment(start, "YYYY-MM-DD")}
                  format="YYYY-MM-DD"
                  onChange={(date, dateString) => {
                    api.updateScheduleAxios(schedule.id, id, {
                      start_date: timeString(
                        dateString,
                        allDay ? "00:00:00" : startTime
                      ),
                    });
                    setStart(dateString);
                  }}
                />
              </TimeBody>
            </TimeSection>

            <TimeSection isMobile={checkMobile} width={width}>
              <div> - </div>
              <TimeBody>
                <DatePickers
                  checked={checked}
                  allowClear={false}
                  value={
                    allDay
                      ? end !== "" &&
                        moment(end, "YYYY-MM-DD").subtract(1, "days")
                      : end !== "" && moment(end, "YYYY-MM-DD")
                  }
                  format="YYYY-MM-DD"
                  onChange={(date, dateString) => {
                    api.updateScheduleAxios(schedule.id, id, {
                      deadline: timeString(
                        allDay
                          ? moment(dateString, "YYYY-MM-DD")
                              .add(1, "days")
                              .format("YYYY-MM-DD")
                          : moment(dateString, "YYYY-MM-DD").format(
                              "YYYY-MM-DD"
                            ),
                        allDay ? "00:00:00" : endTime
                      ),
                    });
                    setEnd(
                      allDay
                        ? moment(dateString, "YYYY-MM-DD")
                            .add(1, "days")
                            .format("YYYY-MM-DD")
                        : moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD")
                    );
                  }}
                />
              </TimeBody>
            </TimeSection>

            {allDay && <Filler />}
          </ScheduleRow>
          {checkMobile && (
            <ScheduleRow allDay={allDay} isMobile={checkMobile}>
              {!allDay && checkMobile && (
                <TimeSection isMobile={checkMobile} width={width}>
                  <TimeTitle>
                    {t("cardView.schedule.startTime").split(" ")[1]}
                  </TimeTitle>
                  <TimeBody>
                    <TimePickers
                      // defaultValue
                      checked={checked}
                      allowClear={false}
                      defaultValue={
                        startTime !== "" && moment(startTime, "HH:mm:ss")
                      }
                      defaultOpenValue={moment("11:00:00", "HH:mm:ss")}
                      onChange={(time, timeString) => {
                        api.updateScheduleAxios(schedule.id, id, {
                          start_date: timeString(
                            start,
                            allDay ? "00:00:00" : timeString
                          ),
                        });
                        setStartTime(timeString);
                      }}
                    />
                  </TimeBody>
                </TimeSection>
              )}

              {!allDay && checkMobile ? (
                <TimeSection isMobile={checkMobile} width={width}>
                  <div> - </div>
                  <TimeBody>
                    <TimePickers
                      checked={checked}
                      allowClear={false}
                      defaultValue={
                        endTime !== "" && moment(endTime, "HH:mm:ss")
                      }
                      defaultOpenValue={moment("11:00:00", "HH:mm:ss")}
                      onChange={(time, timeString) => {
                        api.updateScheduleAxios(schedule.id, id, {
                          deadline: timeString(
                            end,
                            allDay ? "00:00:00" : timeString
                          ),
                        });
                        setEndTime(timeString);
                      }}
                    />
                  </TimeBody>
                </TimeSection>
              ) : (
                <Filler />
              )}
            </ScheduleRow>
          )}
          <AllDayRow>
            <AllDayChecker checked={!allDay} onClick={handleAllDay}>
              Select Time
            </AllDayChecker>
          </AllDayRow>
        </>
      ) : (
        <>
          <ScheduleRow>
            <TimeSection>
              <RequiredTitle>
                {
                  {
                    1: "Invoice Number",
                    2: "Order Number",
                  }[eventType]
                }
              </RequiredTitle>
              <div style={{ width: 10 }} />
              <TimeBody>
                <Input
                  style={{
                    minHeight: 25,
                    borderRadius: 3,
                    fontSize: 14,
                    marginRight: 40,
                    fontWeight: "bold",
                    width: "-webkit-fill-available",
                    // backgroundColor: "#7ab6ff",
                    // color: "white",
                    // fontWeight: "bold",
                  }}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  onPressEnter={() => editTitle()}
                  onBlur={() => editTitle()}
                />
              </TimeBody>
            </TimeSection>
          </ScheduleRow>
          <ScheduleRow>
            <TimeSection>
              <RequiredTitle>
                {eventType &&
                  {
                    1: "Due Date",
                    2: "Deadline",
                  }[eventType]}
              </RequiredTitle>
              <div style={{ width: 10 }} />
              <TimeBody>
                <DatePickers
                  checked={checked}
                  allowClear={false}
                  value={start !== "" && moment(start, "YYYY-MM-DD")}
                  format="YYYY-MM-DD"
                  onChange={(date, dateString) => {
                    api.updateScheduleAxios(schedule.id, id, {
                      start_date: timeString(dateString, "00:00:00"),
                      deadline: timeString(
                        moment(dateString, "YYYY-MM-DD")
                          .add(1, "days")
                          .format("YYYY-MM-DD"),
                        "00:00:00"
                      ),
                    });
                    setStart(dateString);
                    setEnd(
                      moment(dateString, "YYYY-MM-DD")
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                    );
                  }}
                />
              </TimeBody>
            </TimeSection>
          </ScheduleRow>
        </>
      )}
      <RemoveSection className="hidden">
        <ClearButton onClick={deleteTodo}>
          <DeleteOutlined style={{ marginBottom: 3 }} />
        </ClearButton>
      </RemoveSection>
    </ScheduleSection>
  );
};

export default MobileScheduleItem;

const AllDayChecker = styled(Checkbox)``;

const Filler = styled.div`
  width: 134px;
`;

const TimeSection = styled.div`
  margin: 0 4px;
  /* ${(props) => props.isMobile && `width: ${props.width}px;`} */
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

const TimeTitle = styled.div`
  text-align: left;
  margin-top: 4px;
  width: 70px;
`;

const RequiredTitle = styled(TimeTitle)`
  &:after {
    content: " *";
    color: red;
  }
`;

const TimeBody = styled.div``;

const TitleText = styled.div`
  padding-left: 1px;
  text-decoration: ${({ checked }) =>
    checked ? "underline line-through" : "none"};
  width: -webkit-fill-available;
  margin-right: 40px;
  min-height: 32px;
  line-height: 32px;
  font-size: 14px;
  text-align: start;
  width: 95%;
`;

const TodoText = styled.div`
  padding-left: 1px;
  text-decoration: ${({ checked }) =>
    checked ? "underline line-through" : "none"};
  width: -webkit-fill-available;
  margin-right: 40px;
  min-height: 32px;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, checked }) =>
      checked ? theme.colors.color_base_white : theme.colors.color_base_dark};
  }
`;

const ScheduleRow = styled.div`
  display: flex;
  text-align: center;
  align-items: flex-start;
  justify-content: flex-start;
`;

const AllDayRow = styled.div`
  display: flex;
  text-align: center;
  align-items: stretch;
  justify-content: flex-end;
  padding-left: 5px;
  padding-top: 8px;
`;

const RemoveSection = styled.div`
  position: absolute;
  top: 6px;
  right: 4px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  min-height: 36px;
  border-top: none;
`;

const ScheduleTitle = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  border-top: none;
  width: -webkit-fill-available;
  text-align: left;
`;

const ScheduleSection = styled.div`
  width: 100%;
  position: relative;
  /* display: flex;
  text-align: center;
  align-items: stretch;
  justify-content: center; */
  padding: 8px 0;
  background-color: ${({ checked, theme }) =>
    checked && theme.colors.color_primary_lighter};
  &:hover .hidden {
    display: flex;
  }
  border-bottom: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.color_base_dark : theme.colors.color_base_dark};
`;

const CheckBoxNtextBox = styled.div`
  text-align: center;
  display: flex;
  width: 100%;
  ${(props) => (props.isMobile ? "padding-left: 5px;" : "padding-left: 0px;")}
  font-size: ${({ theme }) => theme.fontSizes.txt_small};
  padding-top: 3px;
`;

const ToDoCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  width: 36px;
  /* border: 0.5px solid #d9d9d9; */
  border-top: none;
  border-right: none;
  .ant-checkbox-wrapper {
    margin-bottom: 2px;
  }
`;

const DoneCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }
`;

const TimePickers = styled(TimePicker)`
  border: 0px solid black;
  background-color: ${({ checked, theme }) =>
    checked && theme.colors.color_primary_lighter};
`;

const DatePickers = styled(DatePicker)`
  border: 0px solid black;
  background-color: ${({ checked, theme }) =>
    checked && theme.colors.color_primary_lighter};
`;
